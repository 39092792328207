<script>
import { getExecuteModePage, getUnitListLike } from "@/axios";

export default {
  name: 'BaseInfoEdit',
  emits: ['update:form'],
  data(){
    return {
      unitList: [],
      workerList: [],
    }
  },
  props: {
    form: {},
    batch:{},
    showOutCode: {
      default(){
        return true
      }
    }
  },
  computed: {
    editForm: {
      get() {
        return this.form
      },
      set(val) {
        this.$emit('update:form', val)
      }
    },
    refIdLength() {
      if (!this.editForm.refId) {
        return 0
      }
      let refList = this.editForm.refId.split(',')
      return refList.length - 1
    },
    showReassignNotice() {
      let times = Number(
        this.dictStore.getDictItemByTypeValue('systemParams', 'reassignTimes').label
      )
      return this.refIdLength >= times
    },
    unitWorkerList() {
      if (this.editForm.receptionDeptId) {
        let list = this.workerList.filter((item) => {
          return item.unit === this.editForm.receptionDeptId
        })
        return list
      }
      return []
    },
  },
  mounted() {
    this.init()
  },
  methods:{
    init() {
      this.initUnitList()
      this.initWorkerList()
    },
    initUnitList() {
      getUnitListLike({ key: ' ' }).then((res) => {
        if (res) {
          this.unitList = res.data
        }
      })
    },
    initWorkerList() {
      let reqData = {
        pageSize: 99999,
        pageNumber: 1,
        viewId: 'DD_STAT_WORKLOAD_02',
        params: { unitId: '' }
      }
      getExecuteModePage(reqData).then((res) => {
        if(res){
          this.workerList = res.data
        }
      })
    },
    consumerChange(val) {
      val = val || {}
      this.editForm.consumerId = val.id
      this.editForm.consumerName = val.name
      this.editForm.contact = val.contact
      this.editForm.contactPhone = val.contactPhone
      this.editForm.district = val.district
      this.editForm.street = val.street
      this.editForm.community = val.community
      this.editForm.address = val.address
      this.editForm.lat = val.latitude
      this.editForm.lng = val.longitude
      this.editForm.householdNumber = val.householdNumber
    },
    unitChange(val) {
      val = val || {}
      this.editForm.receptionDeptId = val.id
      this.editForm.receptionDeptName = val.name
      this.leaderChange()
    },
    leaderChange(val) {
      val = val || {}
      this.editForm.leaderId = val.person
      this.editForm.leaderName = val.name
    },
  }
}
</script>

<template>
  <el-col :span="24">
    <el-alert
      v-if="showReassignNotice"
      :title="`该工单已重派${refIdLength}次`"
      type="warning"
      :closable="false"
    />
  </el-col>
  <slot name="header"></slot>
  <el-col :span="24">
    <Divider title="客户信息"></Divider>
  </el-col>
  <SelectConsumer
    :span="8"
    v-model="editForm"
    :disabled="batch"
    :key-map="{
      id: 'consumerId',
      name: 'consumerName',
      contact: 'contact',
      contactPhone: 'contactPhone'
    }"
    :change="consumerChange"
  ></SelectConsumer>
  <el-col :span="8">
    <el-form-item label="用户燃气编号" prop="householdNumber">
      <el-input v-model="editForm.householdNumber" disabled />
    </el-form-item>
  </el-col>
  <SelectFullAddress :span="8" v-model="editForm"></SelectFullAddress>
  <el-col :span="24">
    <Divider title="工单信息"></Divider>
  </el-col>
  <el-col :span="8">
    <el-form-item label="工单编号" prop="code">
      <el-input v-model="editForm.code" placeholder="系统自动生成" disabled />
    </el-form-item>
  </el-col>
  <el-col :span="8" v-if="showOutCode">
    <el-form-item label="外部编号">
      <el-input v-model="editForm.outCode" placeholder="请输入" />
    </el-form-item>
  </el-col>
  <el-col :span="8">
    <el-form-item label="信息来源" prop="workOrderSource">
      <SelectV2
        v-model="editForm.workOrderSource"
        :list="dictStore.getDictByType('workOrderSource')"
        labelKey="label"
        valueKey="val"
      ></SelectV2>
    </el-form-item>
  </el-col>
  <el-col :span="8">
    <el-form-item label="接件日期" prop="takeDate">
      <DatePicker v-model="editForm.takeDate"></DatePicker>
    </el-form-item>
  </el-col>
  <el-col :span="8">
    <el-form-item label="业务类型" prop="bizType">
      <SelectV2
        v-model="editForm.bizType"
        :list="dictStore.getBizTypeListByOrderType(editForm.workOrderType)"
        labelKey="label"
        valueKey="val"
      ></SelectV2>
    </el-form-item>
  </el-col>
  <el-col :span="4">
    <el-form-item label="上门时间" prop="visitDate">
      <DatePicker v-model="editForm.visitDate"></DatePicker>
    </el-form-item>
  </el-col>
  <el-col :span="4">
    <el-form-item label="&nbsp;">
      <el-radio-group v-model="editForm.visitPeriod">
        <el-radio
          :label="item.val"
          v-for="(item, index) in dictStore.getDictByType('visitPeriod')"
          :key="index"
          >{{ item.label }}
        </el-radio>
      </el-radio-group>
    </el-form-item>
  </el-col>
  <slot name="default"></slot>
  <el-col :span="8">
    <el-form-item label="接单部门" prop="receptionDeptId">
      <SelectV2
        v-model="editForm.receptionDeptId"
        :list="unitList"
        labelKey="name"
        valueKey="id"
        @selectChange="unitChange"
      ></SelectV2>
    </el-form-item>
  </el-col>
  <el-col :span="8">
    <el-form-item label="服务人员">
      <SelectV2
        v-model="editForm.leaderId"
        :list="unitWorkerList"
        labelKey="name"
        valueKey="person"
        @selectChange="leaderChange"
      ></SelectV2>
    </el-form-item>
  </el-col>
  <el-col :span="8" v-if="batch">
    <el-form-item label="工单数" prop="workNum">
      <el-input type="number" v-model="editForm.workNum"></el-input>
    </el-form-item>
  </el-col>
  <slot name="other"></slot>
  <el-col :span="24">
    <el-form-item label="备注">
      <el-input type="textarea" v-model="editForm.remark"></el-input>
    </el-form-item>
  </el-col>
</template>

<style scoped lang="scss"></style>