<script>
import { defineComponent } from "vue";
import { uploadFile, uploadImage } from "@/axios";
import FileList from "./FileList.vue";
import DownloadAll from "./DownloadAll.vue";
import _ from "lodash"

export default defineComponent({
  name: "UploadFileInList",
  emits: ["update:modelValue", "checkChange", "upload"],
  components:{ DownloadAll, FileList},
  data() {
    return {
      loading: false
    };
  },
  props: {
    modelValue: {
      type: Array
    },
    type: {
      type: String,
      default() {
        return "";
      }
    },
    maxSize: {
      type: Number
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      }
    },
    upload: {
      type: Function
    }
  },
  computed: {
    fileList: {
      get() {
        if (this.modelValue) {
          return this.modelValue;
        }
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    },
    hasFileList(){
      return this.fileList && this.fileList.length > 0
    },
    fileLength(){
      if(!this.fileList){
        return 0
      }
      return this.fileList.length
    },
    fileEmpty(){
      return this.fileLength === 0
    },
    fileHasMax(){
      if(!this.maxSize){
        return false
      }
      return this.fileLength >= this.maxSize
    },
    accept() {
      if (this.type === "image") {
        return "image/*";
      } else {
        return "";
      }
    },
    buttonDisabled(){
      return this.fileHasMax || this.disabled
    }
  },
  mounted() {
    this.initUploadMethod();
  },
  methods: {
    fileChange(uploadFile, uploadFiles) {
      if (uploadFiles.length > 0) {
        if (this.type === "image") {
          if (!this.checkImageType(uploadFiles)) {
            return;
          }
        }
        this.uploadMethod(uploadFiles);
      }
    },
    checkImageType(list) {
      let checkFail = list.some(item => {
        return item.raw.type.indexOf("image/") === -1;
      });
      if (checkFail) {
        this.clearFiles();
        this.$message.warning("请选择图片格式文件");
      }
      return !checkFail;
    },
    initUploadMethod() {
      this.uploadMethod = _.debounce(
        async (uploadFiles) => {
          let formData = new FormData();
          uploadFiles.forEach((item) => {
            formData.append("files", item.raw);
          });
          let uploadMethod;
          if (this.type === "image") {
            uploadMethod = uploadImage;
          } else {
            uploadMethod = uploadFile;
          }
          this.loading = true;
          uploadMethod(formData)
            .then(async (res) => {
              if (res) {
                if (this.upload) {
                  await this.upload(res.data, uploadFiles, this.uploadCallback);
                } else {
                  this.updateList(res.data);
                }
                this.uploadCallback();
              }
            })
            .catch(() => {
            })
            .finally(() => {
              this.loading = false;
              this.clearFiles();
            });
        },
        300,
        {
          leading: false,
          trailing: true
        }
      );
    },
    updateList(data) {
      this.fileList.push(...data);
    },
    uploadCallback() {
      if (this?.$parent?.validate) {
        this.$parent.validate("change", (trigger, callback) => {
          return trigger;
        });
      }
    },
    clearFiles() {
      if (this.$refs.upload) {
        this.$refs.upload.clearFiles();
      }
    }
  }
});
</script>

<template>
  <el-popover
    placement="bottom-start"
    trigger="click"
    width="300"
    v-if="!fileEmpty || !disabled"
  >
    <template #reference>
      <el-button type="primary" plain>
        <template #default>
          <span v-if="hasFileList">已上传文件【{{fileLength}}】</span>
          <el-icon size="20"><UploadFilled /></el-icon>
        </template>
      </el-button>
    </template>
    <template #default>
      <el-upload
        v-if="!buttonDisabled"
        class="upload"
        ref="upload"
        multiple
        v-loading="loading"
        :accept="accept"
        :auto-upload="false"
        :show-file-list="false"
        :on-change="fileChange"
      >
        <el-button type="primary" plain class="upload-file-in-list-button" icon="UploadFilled">上传附件</el-button>
      </el-upload>
      <FileList v-model:list="fileList" type="file" :disabled="disabled"></FileList>
      <DownloadAll v-if="hasFileList" :list="fileList"></DownloadAll>
    </template>
  </el-popover>
</template>

<style scoped lang="scss">
.upload{
  .dubhe-button{
    width: 100%;
  }
  :deep(.dubhe-upload){
    width: 100%;
  }
}

.download-all{
  margin-top: 10px;
}
</style>