<script>
import { defineComponent } from 'vue'
import { journey } from '@/axios/wx'

let center = null
let map = null
let markerLayer = null
let trail = null
let markerData = []
let zoom = 14

export default defineComponent({
  name: 'TrajectoryDialog',
  emits: ['update:show'],
  data() {
    return {
      isMoving: false,
      loading: false,
      trailData: [],
      speed: 100
    }
  },
  props: {
    show: {
      type: Boolean
    },
    data: {
      type: Object
    }
  },
  computed: {
    showDrawer: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    }
  },
  mounted() {
    this.mapStore.loadMapScript()
  },
  methods: {
    async open() {
      let res = await journey(this.data)
      let data = res.data
      if (data.length === 0) {
        this.$message.warning('暂无轨迹')
        return
      }
      let start = data[0]
      let end = data[data.length - 1]
      let TMap = window.TMap
      center = new TMap.LatLng(start.lat, start.lng)
      let endPosition = new TMap.LatLng(end.lat, end.lng)
      // 初始化地图
      map = new TMap.Map(this.$refs.container, {
        zoom: zoom,
        pitch: 50,
        // mapStyleId:'style1',
        baseMap: {
          type: 'vector',
          features: 'all'
        },
        center
      })

      let rotation
      let position
      let path = []
      let colorOffset = []
      let dataLength = data.length
      let playRate = dataLength / 1
      this.speed = playRate * 3.6
      data
        .filter((item) => {
          return item.lat !== 0 && item.lng !== 0
        })
        .forEach((i) => {
          markerData.push(new TMap.LatLng(i.lat, i.lng))
          path.push([i.lat, i.lng])
          // 不同速度区间对应颜色
          if (i.speed >= 5.05 && i.speed <= 5.13) {
            colorOffset.push(0.99)
          } else if (i.speed <= 5.58 && i.speed >= 5.34) {
            colorOffset.push(0.01)
          } else {
            colorOffset.push(0.5)
          }
        })

      this.trailData.push({ path, colorOffset })
      //初始化marker并添加至map图层
      markerLayer = new TMap.MultiMarker({
        id: 'whiteDot',
        map,
        styles: {
          whiteDot: new TMap.MarkerStyle({
            width: 25,
            height: 25,
            anchor: {
              x: 14.5,
              y: 14.5
            },
            faceTo: 'map',
            rotate: 180,
            src: 'https://mapapi.qq.com/web/lbs/visualizationApi/demo/img/white.png'
          }),
          start: new TMap.MarkerStyle({
            width: 22,
            height: 32,
            anchor: {
              x: 10,
              y: 27
            },
            src: 'https://mapapi.qq.com/web/miniprogram/demoCenter/images/marker-start.png'
          }),
          end: new TMap.MarkerStyle({
            width: 22,
            height: 32,
            anchor: {
              x: 2,
              y: 27
            },
            src: 'https://mapapi.qq.com/web/miniprogram/demoCenter/images/marker-end.png'
          })
        },
        geometries: [
          {
            id: 'whiteDot',
            styleId: 'whiteDot',
            position: new TMap.LatLng(start.lat, start.lng)
          },
          {
            id: 'start',
            styleId: 'start',
            position: new TMap.LatLng(start.lat, start.lng)
          }
        ]
      })

      //初始化轨迹图并添加至map图层
      trail = new TMap.visualization.Trail({
        pickStyle: (item) => {
          //轨迹图样式映射函数
          return {
            width: 6,
            color: new TMap.GradientColor({
              stops: {
                0.01: '#f45e0c',
                0.5: '#f6cd0e',
                0.99: '#2ad61d'
              }
            })
          }
        },
        showDuration: Infinity, //动画中轨迹点高亮的持续时间
        playTimes: 1,
        playRate: playRate, //动画播放倍速，默认为1m/s
        enableColorOffset: true
      }).addTo(map)

      markerLayer.on('moving', (e) => {
        if (e.whiteDot) {
          rotation = TMap.geometry.computeHeading(
            e.whiteDot.passedLatLngs[e.whiteDot.passedLatLngs.length - 2],
            e.whiteDot.passedLatLngs[e.whiteDot.passedLatLngs.length - 1]
          )
          position = TMap.geometry.computeDestination(
            markerLayer.getGeometryById('whiteDot').position,
            rotation,
            60
          )
          map.easeTo(
            {
              center: position,
              rotation: e.whiteDot.angle,
              zoom: zoom,
              pitch: 50
            },
            {
              duration: 500
            }
          )
        }
      })

      markerLayer.on('move_ended', () => {
        this.isMoving = false
        markerLayer.updateGeometries([
          {
            id: 'end',
            styleId: 'end',
            position: endPosition
          }
        ])
        map.easeTo(
          {
            zoom: zoom,
            pitch: 50,
            rotation: 0,
            center: endPosition
          },
          {
            duration: 100
          }
        )
        markerLayer.remove('whiteDot')
      })
      this.carMove()
    },
    close() {
      if(markerLayer){
        markerLayer.stopMove()
      }
      center = null
      map = null
      markerLayer = null
      trail = null
      markerData = []
    },
    carMove() {
      markerLayer.moveAlong(
        {
          whiteDot: {
            path: markerData,
            speed: this.speed // 速度 单位：千米/小时
          }
        },
        {
          autoRotation: true
        }
      )
      trail.setData(this.trailData) //设置数据
      this.isMoving = true
    }
  }
})
</script>

<template>
  <el-drawer
    v-model="showDrawer"
    size="95%"
    direction="btt"
    destroy-on-close
    @opened="open"
    @close="close"
  >
    <template #header>
      <div>轨迹回放</div>
    </template>
    <template #default>
      <div ref="container" class="detail-wrapper detail-wrapper-flex"></div>
    </template>
    <!--    <template #footer>-->
    <!--      <div class="drawer-footer">-->
    <!--        <el-button type="primary" @click="carMove" :disabled="isMoving">开始展示轨迹</el-button>-->
    <!--      </div>-->
    <!--    </template>-->
  </el-drawer>
</template>

<style scoped lang="scss"></style>