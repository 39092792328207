import axios from './axios.config'
// 显示列表数据 HeatingQuotation
export const getHeatingQuotationFields = (tableId= 'heatingQuotation')=>axios.get(`/jaxrs/heating_quotation/fields?tableId=${tableId}`)
// 查询条件
export const getHeatingQuotationConditions = () =>axios.get('/jaxrs/heating_quotation/conditions')
// 分页数据查询
export const getHeatingQuotationPage = (data)=>axios.post('/jaxrs/heating_quotation/page',data)
// 详情
export const getHeatingQuotationDetail = (data)=>axios.get(`/jaxrs/heating_quotation/${data.id}`)
// 保存
export const addHeatingQuotation = (data)=>axios.post(`/jaxrs/heating_quotation`,data)
// 修改
export const editHeatingQuotation = (data)=>axios.put(`/jaxrs/heating_quotation`,data)
// 成交
export const bargainHeatingQuotation = (data)=>axios.post(`/jaxrs/heating_quotation/cooperation/${data.id}`)
// 未成交
export const unBargainHeatingQuotation = (data)=>axios.post(`/jaxrs/heating_quotation/unsettled/${data.id}`)
// 导出采暖报价
export const exportHeatingQuotation = (data) =>
  axios.post(`/jaxrs/heating_quotation/export`,data,{
    responseType:'blob'
  })
// 导出
export const downloadHeatingQuotation = (data) =>axios.get(`/jaxrs/heating_quotation/export/${data.id}`, {
  responseType:'blob'
})