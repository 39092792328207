import axios from "./axios.config"
// 邮票管理 OilCouponApply
//获取显示字段
export const getOilCouponApplyFields = (tableId="oilCouponApply")=>axios.get(`/jaxrs/oil_coupon_apply/fields?tableId=${tableId}`)
// 获取查询条件
export const getOilCouponApplyConditions = () =>axios.get('/jaxrs/oil_coupon_apply/conditions')
// 查询
export const getOilCouponApplyPage = (data)=>axios.post('/jaxrs/oil_coupon_apply/page',data)
// 详情
export const getOilCouponApplyDetail = (data)=>axios.get(`/jaxrs/oil_coupon_apply/${data.id}`)
// 保存
export const addOilCouponApply = (data)=>axios.post(`/jaxrs/oil_coupon_apply`,data)
// 编辑
export const editOilCouponApply = (data)=>axios.put(`/jaxrs/oil_coupon_apply`,data)
// 删除
export const deleteOilCouponApply = (data)=>axios.delete(`/jaxrs/oil_coupon_apply/${data.id}`)
// 发放接口
export const grantOilCouponApply = (data)=>axios.put(`/jaxrs/oil_coupon_apply/issue`,data)
