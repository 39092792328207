<script>
import { defineComponent } from 'vue'
import TableNoEdit from "@/components/TableEdit/TableNoEdit.vue";

export default defineComponent({
  name: 'PersonalCenterCommonItems',
  components: { TableNoEdit },
  emits: ['update:form'],
  data() {
    return {}
  },
  props: {
    type: {
      type: String
    },
    form: {},
    listKey: {},
    required: {
      type: Boolean,
      default() {
        return true
      }
    },
    disabled: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  computed: {
    itemsMap() {
      let required = this.required
      let map = {
        教育经历: {
          title: '教育经历（从高中开始填写）',
          option: {
            items: [
              {
                type: 'input',
                required,
                name: 'schoolName',
                label: '学校名称',
                fixed: 'left',
                width: 180
              },
              {
                type: 'date',
                required,
                name: 'graduationDate',
                label: '毕业时间',
                default: 1,
                width: 150
              },
              {
                type: 'select',
                params: {
                  labelKey: 'label',
                  valueKey: 'val',
                  list: this.dictStore.getDictByType('graduationStatus')
                },
                name: 'graduationStatus',
                label: '毕业情况',
                width: 150
              },
              {
                type: 'select',
                name: 'scienceAndEngineering',
                params: {
                  labelKey: 'label',
                  valueKey: 'val',
                  list: [
                    {
                      label: '是',
                      val: true
                    },
                    {
                      label: '否',
                      val: false
                    }
                  ]
                },
                label: '是否理工科',
                width: 100,
                filter: this.filterTrueOrFalse
              },
              {
                type: 'input',
                name: 'major',
                label: '专业',
                width: 220
              },
              {
                type: 'selectWithButton',
                params: {
                  labelKey: 'label',
                  valueKey: 'val',
                  list: this.dictStore.getDictByType('highestEducation')
                },
                name: 'educationLevel',
                label: '学历',
                width: 180
              },
              {
                type: 'upload',
                name: 'fileUrls',
                label: '上传文件',
                params:{
                  type:'file'
                },
                width: 180
              },
              {
                type: 'input',
                name: 'remark',
                label: '备注'
              }
            ],
            total: ['']
          },
          rules: [
            {
              validator: (rule, value, callback) => {
                if (value.length > 0) {
                  if (value.some((item) => !item.schoolName)) {
                    callback(new Error('请输入学校名称'))
                  }
                  if (value.some((item) => !item.graduationDate)) {
                    callback(new Error('请输入毕业时间'))
                  }
                }
                callback()
              }
            }
          ]
        },
        工作经历: {
          title: '工作经历',
          option: {
            items: [
              {
                type: 'input',
                name: 'companyName',
                required,
                label: '公司名称',
                fixed: 'left',
                width: 180
              },
              {
                type: 'date',
                name: 'startDate',
                label: '开始时间',
                fixed: 'left',
                width: 150
              },
              {
                type: 'date',
                name: 'endDate',
                label: '结束时间',
                default: 1,
                width: 150
              },
              {
                type: 'input',
                name: 'position',
                label: '岗位',
                width: 100
              },
              {
                type: 'input',
                name: 'duties',
                label: '职务',
                width: 100
              },
              {
                type: 'input',
                name: 'referencePerson',
                label: '证明人',
                width: 100
              },
              {
                type: 'input',
                name: 'contactInfo',
                label: '联系方式',
                width: 200
              },
              {
                type: 'input',
                name: 'remark',
                label: '备注'
              }
            ],
            total: ['']
          },
          rules: [
            {
              validator: (rule, value, callback) => {
                if (value.length > 0) {
                  if (value.some((item) => !item.companyName)) {
                    callback(new Error('请输入公司名称'))
                  }
                }
                callback()
              }
            }
          ]
        },
        技能证书: {
          title: '技能证书',
          option: {
            items: [
              {
                type: 'selectWithButton',
                required,
                params: {
                  labelKey: 'label',
                  valueKey: 'val',
                  list: this.dictStore.getDictByType('skillCertificate')
                },
                name: 'name',
                label: '技能证书',
                fixed: 'left',
                width: 180
              },
              {
                type: 'date',
                name: 'issueDate',
                label: '发放时间',
                fixed: 'left',
                width: 180
              },
              {
                type: 'date',
                name: 'expirationDate',
                label: '有效期',
                default: 1,
                fixed: 'left',
                width: 180
              },
              {
                type: 'upload',
                name: 'fileUrls',
                label: '上传文件',
                params:{
                  type:'file'
                },
                width: 180
              },
              {
                type: 'input',
                name: 'remark',
                label: '备注'
              }
            ],
            total: ['']
          },
          rules: [
            {
              validator: (rule, value, callback) => {
                if (value.length > 0) {
                  if (value.some((item) => !item.name)) {
                    callback(new Error('请选择技能证书'))
                  }
                }
                callback()
              }
            }
          ]
        },
        技术职称: {
          title: '技术职称',
          option: {
            items: [
              {
                type: 'selectWithButton',
                required,
                params: {
                  labelKey: 'label',
                  valueKey: 'val',
                  list: this.dictStore.getDictByType('technicalTitles')
                },
                name: 'title',
                label: '职称',
                fixed: 'left',
                width: 180
              },
              {
                type: 'date',
                name: 'acquisitionDate',
                label: '获得时间',
                fixed: 'left',
                width: 180
              },
              {
                type: 'date',
                name: 'expirationDate',
                label: '有效期',
                default: 1,
                width: 180
              },
              {
                type: 'upload',
                name: 'fileUrls',
                label: '上传文件',
                params:{
                  type:'file'
                },
                width: 180
              },
              {
                type: 'input',
                name: 'remark',
                label: '备注'
              }
            ],
            total: ['']
          },
          rules: [
            {
              validator: (rule, value, callback) => {
                if (value.length > 0) {
                  if (value.some((item) => !item.title)) {
                    callback(new Error('请输入职称'))
                  }
                }
                callback()
              }
            }
          ]
        },
        家庭成员情况: {
          title: '家庭成员情况',
          option: {
            items: [
              {
                type: 'input',
                name: 'name',
                required,
                label: '姓名',
                fixed: 'left',
                width: 180
              },
              {
                type: 'selectWithButton',
                params: {
                  labelKey: 'label',
                  valueKey: 'val',
                  list: this.dictStore.getDictByType('relationship')
                },
                name: 'relationship',
                label: '与本人关系',
                fixed: 'left',
                width: 180
              },
              {
                type: 'input',
                name: 'workplace',
                label: '工作地点',
                width: 180
              },
              {
                type: 'input',
                name: 'position',
                label: '职务',
                width: 100
              },
              {
                type: 'input',
                name: 'contactInfo',
                label: '联系方式',
                width: 200
              },
              {
                type: 'input',
                name: 'remark',
                label: '备注'
              }
            ],
            total: ['']
          },
          rules: [
            {
              validator: (rule, value, callback) => {
                if (value.length > 0) {
                  if (value.some((item) => !item.name)) {
                    callback(new Error('请输入姓名'))
                  }
                }
                callback()
              }
            }
          ]
        }
      }
      return map
    },
    tableForm: {
      get() {
        return this.form
      },
      set(val) {
        this.$emit('update:form', val)
      }
    },
    tableList() {
      if (this.tableForm && this.listKey) {
        return this.tableForm[this.listKey]
      }
      return []
    },
    tableMap() {
      return this.itemsMap[this.type]
    },
    tableOption() {
      return this.tableMap.option.items
    },
    tableRules() {
      if (this.required) {
        return this.tableMap.rules
      }
      return []
    },
    tableTitle() {
      if (this.title) {
        return this.title
      }
      return this.tableMap.title
    }
  }
})
</script>

<template>
  <Divider :title="tableTitle"></Divider>
  <el-row :gutter="20">
    <el-col :span="24">
      <el-form-item :prop="listKey" :rules="tableRules">
        <tableNoEdit :table-option="tableOption" :list="tableList" v-if="disabled"></tableNoEdit>
        <tableEdit v-model:list="tableList" :tableOption="tableOption" v-else></tableEdit>
      </el-form-item>
    </el-col>
  </el-row>
</template>

<style scoped lang="scss"></style>