import axios from './axios.config'
// 加油站管理 GasStation
// 获取显示字段
export const getGasStationFields = (tableId='gasStation')=>axios.get(`/jaxrs/gas_station/fields?tableId=${tableId}`)
// 查询
export const getGasStationConditions = ()=>axios.get('/jaxrs/gas_station/conditions')
// 分页
export const getGasStationPage = (data)=>axios.post('/jaxrs/gas_station/page',data)
// 详情
export const getGasStationDetail = (data)=>axios.get(`/jaxrs/gas_station/${data.id}`)
// 保存
export const addGasStation = (data)=>axios.post(`/jaxrs/gas_station`,data)
// 修改
export const editGasStation = (data) =>axios.put(`/jaxrs/gas_station`,data)
// 删除
export const deleteGasStation = (data)=>axios.delete(`/jaxrs/gas_station/${data.id}`)