<script>
import { defineComponent } from 'vue'
import _ from 'lodash'

export default defineComponent({
  name: 'SelectUserDialog',
  data() {
    return {
      selectValue: []
    }
  },
  props: {
    value: {
      required: true
    },
    multiple: {
      type: Boolean
    },
    list: {
      type: Array,
      required: true
    },
    user:{
      type:Boolean,
      default(){
        return false
      }
    },
    unit:{
      type:Boolean,
      default(){
        return false
      }
    },
    valueKey:{
      type: String,
      default(){
        return 'id'
      }
    }
  },
  computed: {
    treeList() {
      if(this.user){
        return this.list.map(item=>{
          return {
            ...item,
            label:item.name
          }
        })
      }
      return this.filterTreeNode(_.cloneDeep(this.list))
    },
    defaultCheckedKeys(){
      if (this.multiple) {
        return this.selectValue
      } else {
        return this.selectValue?[this.selectValue]:[]
      }
    }
  },
  watch: {
    value(val) {
      this.setValue(val)
    },
    selectValue:{
      handler(val){
        this.handleSelectChange(val)
      },
      deep:true
    }
  },
  methods: {
    filterTreeNode(list) {
      let tree = []
      list.forEach((item) => {
        // item.levelList = item.unitLevelName.split('/')
        item.levelList = [item.unitName]
        this.getNodeReduce(tree, item, 0)
      })
      return tree
    },
    getNodeReduce(list, item, index) {
      let levelList = item.levelList
      let levelLength = levelList.length
      let child = list.find((lItem) => lItem.label === levelList[index])
      if (!child) {
        child = {
          id:'unCheck',
          label: item.levelList[index],
          disabled: true,
          children: []
        }
        list.push(child)
      }
      if (index === levelLength - 1) {
        let userItem = {
          ...item,
          label: item.name
        }
        child.children.push(userItem)
      } else {
        this.getNodeReduce(child.children, item, index + 1)
      }
    },
    handleCancelClick() {
      this.$emit('update:modelValue', false)
    },
    handleConfirmClick() {
      if (this.multiple) {
        this.$emit('update:value', this.selectValue)
      } else {
        this.$emit('update:value', this.selectValue ? [this.selectValue] : [])
      }
      this.$emit('update:modelValue', false)
    },
    setValue(val) {
      if (this.multiple) {
        this.selectValue = val
      } else {
        this.selectValue = val[0] || null
      }
    },
    open() {
      this.setValue(this.value)
    },
    handleTreeChange(node, checked) {
      let { checkedKeys } = checked
      let keys = checkedKeys.filter((item) => {
        return item !== 'unCheck'
      })
      if(this.multiple){
        this.setValue(keys)
      }else{
        if(keys.includes(node[this.valueKey])){
          keys = [node[this.valueKey]]
        }else{
          keys = []
        }
        this.setValue(keys)
        this.$refs.tree.setCheckedNodes(keys)
      }
    },
    handleSelectChange(val) {
      let checkList
      if (this.multiple) {
        checkList = val
      } else {
        checkList = val ? [val] : []
      }
      if(this.$refs.tree){
        this.$refs.tree.setCheckedNodes(checkList)
      }
    },
    customNodeClass(data, node) {
      if (node.level === 1) {
        return 'unCheck'
      }
      return null
    },
    filterUserName(item) {
      if(this.user){
        return item.name
      }
      // return `${item.name}-${item.unitLevelName}`
      return `${item.name}-${item.unitName}`
    }
  }
})
</script>

<template>
  <el-dialog
    v-bind="$attrs"
    title="用户选择"
    width="50%"
    append-to-body
    destroy-on-close
    @open="open"
  >
    <div class="select-multiple-dialog">
      <div class="left">
        <el-select
          class="multiple-all"
          placeholder="请选择"
          filterable
          :multiple="multiple"
          v-model="selectValue"
        >
          <el-option
            v-for="item in list"
            :key="item[valueKey]"
            :label="filterUserName(item)"
            :value="item[valueKey]"
          >
            <div class="select-options">
              <span>{{ item.name }}</span>
<!--              <span class="light" v-if="!user">{{ item.unitLevelName }}</span>-->
              <span class="light" v-if="!user">{{ item.unitName }}</span>
            </div>
          </el-option>
        </el-select>
      </div>
      <div class="right">
        <el-tree
          ref="tree"
          class="tree"
          :data="treeList"
          :node-key="valueKey"
          :default-checked-keys="defaultCheckedKeys"
          empty-text="暂无数据"
          highlight-current
          show-checkbox
          check-on-click-node
          @check="handleTreeChange"
        />
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleCancelClick">取消</el-button>
        <el-button type="primary" @click="handleConfirmClick">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<style scoped lang="scss">
.select-multiple-dialog {
  width: 100%;
  height: 500px;
  display: flex;

  & > * {
    height: 100%;
    flex: 1;
    padding: 0 10px;
  }

  .left {
    .multiple-all {
      width: 100%;
    }
  }

  .right {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    & > * {
      width: 100%;
    }

    .tree {
      //margin-top: 10px;
      flex: 1;
      overflow: auto;

      :deep(.is-disabled) {
        display: none !important;
      }
    }
  }
}

.select-options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .light {
    color: var(--dubhe-text-color-secondary);
  }
}
</style>
