import axios from "./axios.config"
//公寓管理 Apartment
// 显示字段
export const getApartmentFields = (tableId ="apartment") =>axios.get(`/jaxrs/apartment/fields?tableId=${tableId}`)
// 获取查询条件
export const getApartmentConditions = ()=>axios.get('/jaxrs/apartment/conditions')
// 查询
export const getApartmentPage = (data)=>axios.post('/jaxrs/apartment/page',data)
// 详情
export const getApartmentDetail = (data)=>axios.get(`/jaxrs/apartment/${data.id}`)
// 保存
export const addApartment = (data)=>axios.post(`/jaxrs/apartment`,data)
// 编辑
export const editApartment = (data)=>axios.put(`/jaxrs/apartment`,data)
// 删除
export const deleteApartment= (data)=>axios.delete(`/jaxrs/apartment/${data.id}`)
// 导出
export const exportApartment = (data)=>axios.post(`/jaxrs/apartment/export`,data,{
  responseType:'blob'
})