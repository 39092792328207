<script>
import { defineComponent } from 'vue'
import { detailMixins } from '@/common/detailMixins'
import { workOrderEditMixin } from '@/views/WorkOrder/workOrderEditMixins'
import BaseInfoEdit from '@/views/WorkOrder/BaseInfoEdit.vue'

export default defineComponent({
  name: 'InstallWorkOrderEdit',
  mixins: [detailMixins, workOrderEditMixin],
  components: {
    BaseInfoEdit
  },
  props: {},
  data() {
    return {}
  },
  computed: {
    rules() {
      let rules = {
        consumerId: [{ required: true, message: '请选择客户', trigger: 'change' }],
        district: [{ required: true, trigger: 'change', message: '请选择区' }],
        address: [{ required: true, trigger: 'change', message: '请输入详细地址' }],
        workOrderSource: [{ required: true, message: '请选择信息来源', trigger: 'change' }],
        bizType: [{ required: true, message: '请选择业务类型', trigger: 'change' }],
        workOrderMode: [{ required: true, message: '请选择工单模式', trigger: 'change' }],
        workNum: [{ required: true, trigger: 'change', message: '请输入工单数' }],
        receptionDeptId: [{ required: true, message: '请选择接单部门', trigger: 'change' }]
      }
      if (this.batch) {
        delete rules.district
        delete rules.address
        delete rules.consumerId
      } else {
        delete rules.receptionDeptId
      }
      if(this.editForm.workOrderMode === 'grab_order'){
        rules.projectId = [{ required: true, message: '请选择项目', trigger: 'change' }]
      }
      return rules
    }
  },
  watch: {},
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.baseForm.workOrderType = 'install'
      this.initProjectList()
      this.initMaterialList()
    }
  }
})
</script>
<template>
  <el-drawer v-model="showDrawer" size="95%" direction="btt" destroy-on-close @open="getDetail">
    <template #header>
      <div>{{ title }}</div>
    </template>
    <template #default>
      <div class="detail-wrapper" v-loading="loading">
        <el-form :model="editForm" :label-position="labelPosition" :rules="rules" ref="form">
          <el-row :gutter="20">
            <BaseInfoEdit v-model:form="editForm" :batch="batch">
              <template #default>
                <el-col :span="8">
                  <el-form-item label="所属项目" prop="projectId">
                    <SelectWithButton
                      :clearable="true"
                      :conditions="[
                        {
                          alias: 'p',
                          field: 'projectType',
                          operator: 'in',
                          params: ['civil', 'maintenance']
                        }
                      ]"
                      :remote-method="getProjectAllSelectList"
                      v-model="editForm.projectId"
                      :default-label="editForm.projectName"
                      labelKey="name"
                      valueKey="id"
                      dialogName="ProjectAll"
                      @select-change="projectChange"
                    >
                    </SelectWithButton>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="工单模式" prop="workOrderMode">
                    <SelectV2
                      v-model="editForm.workOrderMode"
                      :list="dictStore.getDictByType('workOrderMode')"
                      labelKey="label"
                      valueKey="val"
                    ></SelectV2>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="机器型号">
                    <SelectWithButton
                      clearable="true"
                      v-model="editForm.materialId"
                      :remote-method="getMaterialSelectList"
                      :conditions="materialConditions"
                      labelKey="name"
                      valueKey="id"
                      lightLabelKey="code"
                      :default-label="editForm.materialName"
                      dialogName="Material"
                      @selectChange="materialChange"
                      :label-filter="materialLabelFilter"
                    >
                    </SelectWithButton>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="购机日期">
                    <DatePicker v-model="editForm.buyDate"></DatePicker>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="上门带货">
                    <el-switch v-model="editForm.carryGoods"></el-switch>
                  </el-form-item>
                </el-col>
              </template>
            </BaseInfoEdit>
          </el-row>
        </el-form>
      </div>
    </template>
    <template #footer>
      <div class="drawer-footer">
        <el-button
          @click="handleResetClick"
          :disabled="loading"
          v-if="showByAuditStatus(['pending_submission'])"
          >重置
        </el-button>
        <el-button type="primary" @click="handleSaveClick(false)" :disabled="loading"
          >保存
        </el-button>
        <el-button
          type="primary"
          @click="handleSaveClick(true)"
          icon="Promotion"
          :disabled="loading"
          v-if="showByAuditStatus(['pending_submission'])"
          >提交
        </el-button>
      </div>
    </template>
  </el-drawer>
</template>
<style scoped lang="scss"></style>