import axios from './axios.config'
// 合同用户信息   CommonSupplier
// 获取列表信息
export const getCommonSupplierFields = (tableId='commonSupplier')=>axios.get(`/jaxrs/common_supplier/fields?tableId=${tableId}`)
// 查询条件
export const getCommonSupplierConditions = ()=>axios.get('/jaxrs/common_supplier/conditions')
// 查询
export const getCommonSupplierPage = (data)=>axios.post('/jaxrs/common_supplier/page',data)
// 详情
export const getCommonSupplierDetail = (data)=>axios.get(`/jaxrs/common_supplier/${data.id}`)
// 保存
export const addCommonSupplier = (data)=>axios.post(`/jaxrs/common_supplier`,data)
// 修改
export const editCommonSupplier = (data)=>axios.put(`/jaxrs/common_supplier`,data)
