import axios from './axios.config'
// 获取显示字段
export const getMaintenanceContractTerminateFields = (tableId='maintenanceContractTerminate')=>axios.get(`/jaxrs/maintenance/contract/terminate/fields?tableId=${tableId}`)
// 获取查询条件
export const getMaintenanceContractTerminateConditions = ()=>axios.get('/jaxrs/maintenance/contract/terminate/conditions')
// 查询
export const getMaintenanceContractTerminatePage = (data) =>axios.post('/jaxrs/maintenance/contract/terminate/page',data)
// 详情
export const getMaintenanceContractTerminateDetail = (data) => {
  let url
  if (data.oper) {
    url = `/jaxrs/maintenance/contract/terminate/${data.id}?oper=${data.oper}`
  } else {
    url = `/jaxrs/maintenance/contract/terminate/${data.id}`
  }
  return axios.get(url)
}
// 保存
export const addMaintenanceContractTerminate = (data) =>axios.post(`/jaxrs/maintenance/contract/terminate`,data)
// 编辑
export const editMaintenanceContractTerminate = (data) =>axios.put(`/jaxrs/maintenance/contract/terminate`,data)
// 删除
export const deleteMaintenanceContractTerminate = (data) =>axios.delete(`/jaxrs/maintenance/contract/terminate/${data.id}`)
// 获取维保合同根据商用项目ID
export const getMaintenanceContractTerminateByCommercialProject = (data)=>axios.get(`/jaxrs/maintenance/contract/terminate/project_id/${data.id}`)
