<script>
import { defineComponent } from "vue";
import { filterMixins } from "../common/filterMixins";

export default defineComponent({
  name: "InputNumber",
  mixins: [filterMixins],
  emits: ["inputChange", "update:modelValue"],
  data() {
    return {
      isEdit: false
    };
  },
  props: {
    modelValue: {
      required: true
    },
    showUnit: {
      type: Boolean,
      default() {
        return false;
      }
    },
    unit: {
      default() {
        return "元";
      }
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      }
    },
    placeholder: {
      type: String,
      default() {
        return "--";
      }
    },
    digit: {
    },
    change: {
      type: Function
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    },
    showValue: {
      get() {
        return this.formatNumber(this.modelValue,this.digit);
      },
      set(val) {
        // this.$emit('update:modelValue', this.parserNumber(val))
      }
    }
  },
  watch: {},
  methods: {
    valueChange(val) {
      // this.inputValue = _.toNumber(val)
      this.$emit('inputChange')
      if(this.change){
        this.change(val)
      }
    },
    showEdit() {
      if (this.disabled) {
        return;
      }
      this.isEdit = true;
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.input.focus();
        }, 0);
      });
    },
    hiddenEdit() {
      this.isEdit = false;
    },
    inputWheelStop(e){
      e.preventDefault()
    }
  }
});
</script>

<template>
  <div class="input-number" :class="{ disabled: disabled }" v-if="!isEdit" @click="showEdit">
    <el-input v-show="!isEdit" :modelValue="showValue" :placeholder="placeholder" readonly>
      <template #append v-if="showUnit">{{ unit }}</template>
    </el-input>
  </div>
  <div class="input">
    <el-input
      ref="input"
      type="number"
      v-show="isEdit"
      v-model="inputValue"
      :placeholder="placeholder"
      @input="valueChange"
      @blur="hiddenEdit"
      @wheel.stop="inputWheelStop"
    >
      <template #append v-if="showUnit">{{ unit }}</template>
    </el-input>
  </div>
</template>

<style scoped lang="scss">
.input-number {
  width: 100%;
  cursor: pointer;

  :deep(.dubhe-input__wrapper) {
    background: var(--dubhe-input-bg-color) !important;
  }

  :deep(.dubhe-input__inner) {
    //-webkit-text-fill-color: var(--dubhe-input-text-color) !important;
    cursor: pointer;
    //&::placeholder{
    //  color: green;
    //}
  }

  &.disabled {
    :deep(.dubhe-input__wrapper) {
      background: var(--dubhe-disabled-bg-color) !important;
    }

    :deep(.dubhe-input__inner) {
      color: var(--dubhe-disabled-text-color) !important;
      -webkit-text-fill-color: var(--dubhe-disabled-text-color) !important;
      cursor: not-allowed;
    }
  }

  & > * {
    cursor: pointer;
  }
}

.input {
  width: 100%;
}
</style>