import axios from './axios.config'
// 显示用章申请
export const getSealApplyFields = (tableId='sealApply')=>axios.get(`/jaxrs/seal_apply/fields?tableId=${tableId}`)
// 获取查询条件
export const getSealApplyConditions = ()=>axios.get('/jaxrs/seal_apply/conditions')
// 获取分页数据
export const getSealApplyPage = (data)=>axios.post('/jaxrs/seal_apply/page',data)
// 详情页
export const getSealApplyDetail = (data)=>axios.get(`/jaxrs/seal_apply/${data.id}`)
// 保存
export const addSealApply = (data)=>axios.post(`/jaxrs/seal_apply`,data)
// 修改
export const editSealApply = (data)=>axios.put(`/jaxrs/seal_apply`,data)
// 保存流程状态
export const changeSealApplyStatus = (data) =>axios.post('/jaxrs/seal_apply/status',data)
// 删除
export const deleteSealApply = (data)=>axios.delete(`/jaxrs/seal_apply/${data.id}`)