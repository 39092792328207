<script>
import { defineComponent } from "vue";
import _ from 'lodash'
import SendNoticeDrawer from "./SendNoticeDrawer.vue";

export default defineComponent({
  name: "SendNotice",
  components: { SendNoticeDrawer },
  emits:['update:show','callback'],
  data() {
    return {
      loading: false,
      editForm:{},
      notice: '',
      showSendNoticeDrawer: false
    };
  },
  component:{
  },
  props: {
    show: {
      type: Boolean
    },
    option:{}
  },
  computed: {
    showDrawer: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("update:show", val);
      }
    },
    rules(){
      let rules = {}
      if(this.option){
        this.option.forEach((item)=>{
          rules[item.key] = [{ required: true, message: `请输入${item.label}`, trigger: 'change' }]
        })
      }
      return rules
    }
  },
  methods: {
    open(){
      let obj = {}
      if(this.option){
        this.option.forEach((item)=>{
          obj[item.key] = null
        })
      }
      this.editForm = obj
      this.$refs.form.resetFields()
    },
    close(){
      for(let key in this.editForm){
        this.editForm[key] = null
      }
    },
    confirm(){
      this.$refs.form.validate((valid) => {
        if(valid){
          this.$emit('callback',_.cloneDeep(this.editForm))
          this.showDrawer = false
        }
      })
    },
    showNoticeDrawer(notice){
      this.notice = notice
      this.showSendNoticeDrawer = true
    }
  }
});
</script>

<template>
  <el-dialog v-model="showDrawer" width="50%" destroy-on-close @open="open" @close="close">
    <template #header>
      <div>发送通知</div>
    </template>
    <template #default>
      <el-form :model="editForm" label-position="top" ref="form" :rules="rules" v-loading="loading">
        <el-form-item
          v-for="(item, index) in option"
          :label="item.label"
          :prop="item.key"
          :key="index"
        >
          <el-input v-model="editForm[item.key]" />
        </el-form-item>
      </el-form>
    </template>
    <template #footer>
      <el-button type="primary" @click="confirm">确认</el-button>
    </template>
  </el-dialog>
  <SendNoticeDrawer v-model:show="showSendNoticeDrawer" :content="notice"></SendNoticeDrawer>
</template>

<style scoped lang="scss"></style>