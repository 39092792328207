import axios from './axios.config'
// 车辆保险
// 获取显示字段
export const getVehicleAccidentFields = (tableId="vehicleInsurance") =>axios.get(`/jaxrs/vehicle_accident/fields?tableId=${tableId}`)
// 获取查询条件
export const getVehicleAccidentConditions = () =>axios.get('/jaxrs/vehicle_accident/conditions')
// 查询
export const getVehicleAccidentPage = (data) =>axios.post('/jaxrs/vehicle_accident/page',data)
// 详情
export const getVehicleAccidentDetail = (data) =>{
    let url
    if(data.oper){
        url=`/jaxrs/vehicle_accident/${data.id}?oper=${data.oper}`
    }else{
        url=`/jaxrs/vehicle_accident/${data.id}`
    }
    return axios.get(url)
}
// 保存（新增）
export const addVehicleAccident = (data) =>axios.post(`/jaxrs/vehicle_accident`,data)
// 修改
export const editVehicleAccident = (data) =>axios.put(`/jaxrs/vehicle_accident`,data)
// 删除
export const deleteVehicleAccident = (data) =>axios.delete(`/jaxrs/vehicle_accident/${data.id}`)
// 导出
export const exportVehicleAccident = (data) =>
    axios.post(`/jaxrs/vehicle_accident/export`,data,{
        responseType:'blob'
    })
// 处理
export const handleVehicleAccident = (data) =>axios.put(`/jaxrs/vehicle_accident/handle`,data)

