<script>
import { defineComponent } from "vue";
import { detailMixins } from "../../common/detailMixins";
import MapHome from "./MapHome.vue";
export default defineComponent({
  name: "MapDrawer",
  mixins: [detailMixins],
  components: {MapHome},
  methods: {
    init() {

    }
  }
});
</script>

<template>
  <el-drawer v-model="showDrawer" size="95%" direction="btt" destroy-on-close @opened="init">
    <template #header>
      <div>地图</div>
    </template>
    <template #default v-loading="loading">
      <div class="detail-wrapper">
        <MapHome></MapHome>
      </div>
    </template>
  </el-drawer>
</template>

<style scoped lang="scss">
</style>