import axios from './axios.config'

/**
 * 获取采购订单需要申请列表
 * @param data
 * @returns {*}
 */
export const listPurchaseRequisitionForOrder = () =>
  axios.get('/jaxrs/purchase_requisition/list_for_purchase_order')

// 获取采购申请显示字段
export const getPurchaseRequisitionFields = (tableId='purchaseRequisition') => axios.get(`/jaxrs/purchase_requisition/fields?tableId=${tableId}`)
// 获取采购申请查询条件
export const getPurchaseRequisitionConditions = () =>
  axios.get('/jaxrs/purchase_requisition/conditions')
// 获取采购申请分页数据
export const getPurchaseRequisitionPage = (data) =>
  axios.post('/jaxrs/purchase_requisition/page', data)
// 获取采购申请详情
export const getPurchaseRequisitionDetail = (data) => {
  let url
  if (data.oper) {
    url = `/jaxrs/purchase_requisition/${data.id}?oper=${data.oper}`
  } else {
    url = `/jaxrs/purchase_requisition/${data.id}`
  }
  return axios.get(url)
}
// 修改采购申请
export const editPurchaseRequisition = (data) => axios.put(`/jaxrs/purchase_requisition`, data)
// 添加采购申请
export const addPurchaseRequisition = (data) => axios.post(`/jaxrs/purchase_requisition`, data)
// 删除采购申请
export const deletePurchaseRequisition = (data) =>
  axios.delete(`/jaxrs/purchase_requisition/${data.id}`)

export const exportPurchaseRequisition = (data) =>
  axios.post(`/jaxrs/purchase_requisition/export`, data, {
    responseType: 'blob'
  })
