import axios from './axios.config'
// 获取显示字段
export const getThirdCheckReportFields = (tableId = 'thirdCheckReport') =>axios.get(`/jaxrs/third_check_report/fields?tableId=${tableId}`)
// 获取查询条件
export const getThirdCheckReportConditions = ()=>axios.get('/jaxrs/third_check_report/conditions')
// 获取查询
export const getThirdCheckReportPage = (data) =>axios.post('/jaxrs/third_check_report/page',data)
// 获取查询  到期
export const getThirdCheckReportPageExpire = (data) =>axios.post('/jaxrs/third_check_report/expire_page',data)
// 详情页
export const getThirdCheckReportDetail = (data) =>{
    let url
    if (data.oper) {
        url = `/jaxrs/third_check_report/${data.id}?oper=${data.oper}`
    } else {
        url = `/jaxrs/third_check_report/${data.id}`
    }
    return axios.get(url)
}
//保存
export const addThirdCheckReport = (data) =>axios.post(`/jaxrs/third_check_report`,data)
// 编辑
export const editThirdCheckReport = (data) =>axios.put(`/jaxrs/third_check_report`,data)
// 删除
export const deleteThirdCheckReport = (data) =>axios.delete(`/jaxrs/third_check_report/${data.id}`)
// 获取检测根据项目ID
export const getThirdCheckReportByProject = (data) =>axios.get(`/jaxrs/third_check_report/project_id/${data.id}`)
// 作废
export const cancelThirdCheckReport = (data)=>axios.get(`/jaxrs/third_check_report/cancel/${data.id}`)
// 撤回申请
export const retractThirdCheckReport = (data)=>axios.get(`/jaxrs/third_check_report/retract/${data.id}`)
