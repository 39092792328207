import axios from './axios.config'
// 获取打印模板显示字段
export const getPrintTemplateFields = (tableId='printTemplate') => axios.get(`/jaxrs/print_template/fields?tableId=${tableId}`)
// 获取打印模板查询条件
export const getPrintTemplateConditions = () => axios.get('/jaxrs/print_template/conditions')
// 获取打印模板分页数据
export const getPrintTemplatePage = (data) => axios.post('/jaxrs/print_template/page',data)
// 获取打印模板详情
export const getPrintTemplateDetail = (data) => axios.get(`/jaxrs/print_template/${data.id}`)
// 编辑打印模板
export const editPrintTemplate = (data) => axios.put(`/jaxrs/print_template`,data)
// 添加打印模板
export const addPrintTemplate = (data) => axios.post(`/jaxrs/print_template`,data)
// 删除打印模板
export const deletePrintTemplate = (data) => axios.delete(`/jaxrs/print_template/${data.id}`)

