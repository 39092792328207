import axios from './axios.config'
//获取出库单显示字段
export const  getStockOutFields = (tableId='stockOut') => axios.get(`/jaxrs/stock_out/fields?tableId=${tableId}`)
//获取出库查询条件
export  const getStockOutConditions = () => axios.get('/jaxrs/stock_out/conditions')
//获取出库分页数据
export const getStockOutPage =(data) =>axios.post('/jaxrs/stock_out/page',data)
//获取出库详情
export const getStockOutDetail = (data) =>{
    let url
    if(data.oper){
        url = `/jaxrs/stock_out/${data.id}?oper=${data.oper}`
    }else{
        url = `/jaxrs/stock_out/${data.id}`
    }
    return axios.get(url)
}
//修改出库单
export const editStockOut = (data) => axios.put(`/jaxrs/stock_out`,data)
//添加出库单
export const addStockOut = (data) =>axios.post(`/jaxrs/stock_out`,data)
//删除出库单
export const deleteStockOut = (data) =>axios.delete(`/jaxrs/stock_out/${data.id}`)
//导出
export const exportStockOut = (data) =>
    axios.post(`/jaxrs/stock_out/export`,data,{
        responseType:'blob'
    })
//出库单出库
export const outStockOut = (data) =>axios.post(`/jaxrs/stock_out/stockOut`,data)
