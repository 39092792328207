<script>
import { defineComponent } from 'vue'
import TextareaDialog from '../TextareaDialog/TextareaDialog.vue'

export default defineComponent({
  name: 'TableTextarea',
  components: { TextareaDialog },
  data() {
    return {
      dialogShow: false
    }
  },
  props: {
    modelValue: {
      required: true
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    }
  },
  methods: {
    handleEditClick() {
      this.dialogShow = true
    }
  }
})
</script>

<template>
  <el-tooltip :content="value" placement="top" :disabled="!value">
    <div class="table-textarea" @click="handleEditClick">
      {{ value }}
    </div>
  </el-tooltip>
  <TextareaDialog v-model="dialogShow" v-model:text="value"></TextareaDialog>
</template>

<style scoped lang="scss">
.table-textarea {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  min-height: var(--dubhe-component-size);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.text {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>