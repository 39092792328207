<script>
import { defineComponent } from 'vue'
import _ from 'lodash'

export default defineComponent({
  name: 'SelectMultipleDialog',
  data() {
    return {
      selectValue: [],
      filterKey: ''
    }
  },
  props: {
    value: {
      required: true
    },
    multiple: {
      type: Boolean,
      default() {
        return true
      }
    },
    list: {
      type: Array,
      required: true
    },
    filterOption: {
      type: Array,
      required: true
    },
    labelKey:{
      type: String,
      default() {
        return 'label'
      }
    },
    valueKey:{
      type: String,
      default() {
        return 'id'
      }
    }
  },
  computed: {
    treeList() {
      if (!this.filterKey) {
        return []
      }
      let listMap = {}
      this.list.forEach((item) => {
        if (!listMap[item[this.filterKey]]) {
          listMap[item[this.filterKey]] = []
        }
        listMap[item[this.filterKey]].push(item)
      })
      let list = []
      for (let key in listMap) {
        let firstNode = {
          label: key,
          disabled: true,
          children: listMap[key]
        }
        list.push(firstNode)
      }
      return list
    }
  },
  watch: {
    value(val) {
      this.setValue(val)
    }
  },
  methods: {
    handleCancelClick() {
      this.$emit('update:modelValue', false)
    },
    handleConfirmClick() {
      this.$emit('update:value', this.selectValue)
      this.$emit('update:modelValue', false)
    },
    setValue(val) {
      this.selectValue = val
    },
    open() {
      this.setValue(this.value)
      if (this.filterOption.length > 0) {
        this.filterKey = this.filterOption[0].key
      }
    },
    handleTreeChange(node, checked) {
      let { checkedKeys } = checked
      let keys = checkedKeys.filter((item) => {
        return !!(_.isNumber(item) || !!item)
      })
      this.setValue(keys)
    },
    handleSelectChange(val) {
      this.$refs.tree.setCheckedNodes(val)
    },
    customNodeClass(data,node){
      if(node.level === 1){
        return 'unCheck'
      }
      return null
    }
  }
})
</script>

<template>
  <el-dialog v-bind="$attrs" title="多选" width="50%" append-to-body destroy-on-close @open="open">
    <div class="select-multiple-dialog">
      <div class="left">
        <el-select
          class="multiple-all"
          placeholder="请选择"
          filterable
          multiple
          v-model="selectValue"
          @change="handleSelectChange"
        >
          <el-option v-for="item in list" :key="item[valueKey]" :label="item[labelKey]" :value="item[valueKey]">
          </el-option>
        </el-select>
      </div>
      <div class="right">
        <el-radio-group v-model="filterKey">
          <el-radio-button v-for="item in filterOption" :label="item.key" :key="item.key"
            >{{ item.label }}
          </el-radio-button>
        </el-radio-group>
        <el-tree
          ref="tree"
          class="tree"
          :data="treeList"
          :node-key="valueKey"
          :default-checked-keys="selectValue"
          empty-text="暂无数据"
          highlight-current
          show-checkbox
          check-on-click-node
          @check="handleTreeChange"
        />
<!--        如有更复杂情况，通过自定义class解决-->
<!--        :props="{ class: customNodeClass }"-->
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleCancelClick">取消</el-button>
        <el-button type="primary" @click="handleConfirmClick">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<style scoped lang="scss">
.select-multiple-dialog {
  width: 100%;
  height: 500px;
  display: flex;

  & > * {
    height: 100%;
    flex: 1;
    padding: 0 10px;
  }

  .left {
    .multiple-all {
      width: 100%;
    }
  }

  .right {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    & > * {
      width: 100%;
    }
    .tree {
      margin-top: 10px;
      flex: 1;
      overflow: auto;

      :deep(.is-disabled){
        display: none !important;
      }
    }
  }
}
</style>