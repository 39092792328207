<script>
import { defineComponent } from 'vue'
import FullScreenIcon from './FullScreenIcon.vue'

export default defineComponent({
  name: 'LargeScreen',
  components: {
    FullScreenIcon
  },
  data() {
    return {
      scaleX: 1,
      scaleY: 1,
      resizeDom: null,
      resizeObserver: null,
      time: null,
      timeInterval: null
    }
  },
  props:{
    title:{},
    hasMap:{}
  },
  computed: {
    bodyStyle() {
      // let scale = this.scaleX > this.scaleY ? this.scaleY : this.scaleX
      let style = {
        transform: `scale(${this.scaleX},${this.scaleY}) translate(-50%,-50%)`
      }
      return style
    },
    computedTitle(){
      return this.title || this.$route.meta.name || ''
    }
  },
  mounted() {
    this.screenInit()
  },
  unmounted() {
    this.screenDestroy()
  },
  methods: {
    screenInit(){
      this.initResize()
      this.initTimeInterval()
    },
    screenDestroy(){
      this.clearResize()
      this.clearTimeInterval()
    },
    initResize() {
      this.resizeDom = this.$refs.wrapper
      this.resizeObserver = new ResizeObserver(this.wrapperResize)
      this.resizeObserver.observe(this.resizeDom)
    },
    clearResize() {
      this.resizeObserver.unobserve(this.resizeDom)
    },
    wrapperResize() {
      let parentDom = this.$refs.wrapper
      let childDom = this.$refs.body
      let pWidth = parentDom.offsetWidth
      let pHeight = parentDom.offsetHeight
      let cWidth = childDom.offsetWidth
      let cHeight = childDom.offsetHeight
      let scaleX = pWidth / cWidth
      let scaleY = pHeight / cHeight
      this.scaleX = scaleX
      this.scaleY = scaleY
    },
    initTimeInterval() {
      this.setTime()
      this.timeInterval = setInterval(this.setTime, 1000)
    },
    setTime() {
      this.time = this.getTime(new Date(), 'YYYY-MM-DD HH:mm:ss')
    },
    clearTimeInterval() {
      if (this.timeInterval) {
        clearInterval(this.timeInterval)
        this.timeInterval = null
      }
    }
  }
})
</script>

<template>
  <div class="monitoring-large-screen-wrapper" ref="wrapper">
    <div class="monitoring-large-screen" ref="body" :style="bodyStyle">
      <slot name="full"></slot>
      <div class="screen-header">
        <div class="screen-header-bg"></div>
        <div class="screen-header-center"></div>
        <div class="screen-content">
          <div class="header-logo-wrapper">
            <img class="header-logo" src="../../assets/image/logo_color.png" alt="" />
            <img
              class="header-logo-footer"
              src="../../assets/image/bigScreen/center_logo_footer.webp"
              alt=""
            />
          </div>
          <div class="header-title">{{computedTitle}}</div>
        </div>
        <div class="screen-header-time">{{ time }}</div>
        <div class="screen-header-full">
          <FullScreenIcon :dom="resizeDom"></FullScreenIcon>
        </div>
      </div>
      <div class="screen-body" :class="{'has-map':hasMap}">
        <slot name="default"></slot>
      </div>
      <div class="screen-footer-left"></div>
      <div class="screen-footer-right"></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.monitoring-large-screen-wrapper {
  position: relative;
  z-index: 10;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url('../../assets/image/bigScreen/background.jpg');
}

.monitoring-large-screen {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  width: 1920px;
  height: 1080px;
  transition: all ease-out 0.2s;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transform-origin: top left;
  overflow: hidden;

  .screen-header {
    position: relative;
    width: 100%;
    height: 136px;
    //position: absolute;
    z-index: 2;
    will-change: transform;
    pointer-events: none;
    transform: translateX(0px) translateY(-6px);

    .screen-header-bg {
      position: relative;
      width: 100%;
      height: 100%;
      pointer-events: all;
      z-index: 2;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-image: url('../../assets/image/bigScreen/header.png');
    }

    .screen-header-center {
      position: absolute;
      width: 1192px;
      height: 104px;
      top: 0;
      left: 50%;
      z-index: 1;
      opacity: 0.45;
      transform: translateX(-50%) translateY(-11px);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-image: url('../../assets/image/bigScreen/header_center.webp');
    }

    .screen-content {
      position: absolute;
      z-index: 3;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      top: 0;
      height: 95px;

      .header-logo-wrapper {
        position: relative;
        height: 50px;
        width: 50px;

        .header-logo {
          position: relative;
          height: 100%;
          width: 100%;
          z-index: 2;
        }

        .header-logo-footer {
          position: absolute;
          top: 0;
          left: 50%;
          width: 95.68px;
          height: 73.6px;
          z-index: 1;
          transform: translateX(-50%) translateY(3px);
        }
      }

      .header-title {
        margin-left: 50px;
        font-size: 45px;
        font-weight: bold;
        color: #fff;
        font-family: 'fangsong';
      }
    }

    .screen-header-time {
      color: #fff;
      position: absolute;
      top: 45px;
      left: 35px;
      font-size: 20px;
    }
    .screen-header-full {
      position: absolute;
      color: #fff;
      top: 40px;
      right: 30px;
      font-size: 20px;
      z-index: 20;
      cursor: pointer;
      pointer-events: auto;
    }
  }

  .screen-body {
    position: relative;
    height: 923px;
    transform: translateY(-30px);
    z-index: 2;
    //pointer-events: none;
    pointer-events: auto;

    &.has-map{
      pointer-events: none;
    }

    .screen-body-left,
    .screen-body-right {
      position: absolute;
      width: 360px;
      //width: 475px;
      //width: 550px;
      height: 100%;
      padding: 20px;
      background-color: rgba(15, 27, 36, 0.6);
      pointer-events: all;
      overflow: hidden;
      backdrop-filter: blur(5px);
      border-radius: 4px;
      display: flex;
      flex-direction: column;
    }

    .screen-body-right {
      right: 0;
    }

    .screen-body-center {
      pointer-events: all;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        transform: scale(1.01);
        opacity: 0.45;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('../../assets/image/bigScreen/center_boder.png');
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(15, 27, 36, 0.6);
        backdrop-filter: blur(5px);
      }

      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 944px;
      height: 208px;
    }
  }

  .screen-footer-left {
    position: absolute;
    width: 894px;
    height: 43px;
    bottom: 0;
    z-index: 2;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url('../../assets/image/bigScreen/footer_left.png');
  }

  .screen-footer-right {
    position: absolute;
    width: 894px;
    height: 43px;
    bottom: 0;
    right: 0;
    z-index: 2;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url('../../assets/image/bigScreen/footer_left.png');
    transform: rotate(0deg) scaleX(-1) scaleY(1) rotateZ(360deg);
  }
}
.color-cyan{
  color: rgb(5,241,235);
}
</style>