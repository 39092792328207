import html2canvas from 'html2canvas';
import jsPDF from 'jspdf'
import { downloadFileByFilename } from "@/axios";

const dealImage = (imgNode) => {
  return new Promise((resolve) => {
    // let reg = new RegExp('(.*?)(/\\d+/\\w+\\..*?)(\\?.*)')
    // let reg = new RegExp('(.*?)((/\\d+)?/\\w+\\.\\w+)(\\?.*)?')
    let imgSrc = imgNode.getAttribute("src")
    let is64 = isBase64(imgSrc)
    if(is64){
      resolve(imgNode)
      return
    }
    if(imgSrc){
      downloadFileByFilename({url:imgSrc}).then(async res => {
        let reader = new FileReader()
        reader.readAsDataURL(res)
        reader.onload = (e) => {
          imgNode.src = e.target.result
          resolve(imgNode)
        }
      })
    }else{
      resolve(imgNode)
    }
  })
}
const isBase64  = (str) => {
  return /^data:([a-z]+\/[a-z0-9-+.]+)?;base64,/.test(str)
}
export const htmlToPDF = async (pdfDom, title = "报表", bgColor = "#fff") => {
  let nodeList = Array.from(pdfDom.querySelectorAll(".hiprint-printPaper"))
  let imgList =  Array.from(pdfDom.querySelectorAll("img"))
  for (let imgIndex of Object.keys(imgList)) {
    let imgNode = imgList[imgIndex]
    // if(!imgNode.src){
    //   console.log('noSrc')
    //   continue
    // }
    await dealImage(imgNode)
  }
  // const A4Width = 595.28;
  // const A4Height = 841.89;
  let PDF = new jsPDF({
    orientation:'p',
    unit: 'mm',
    format: 'a4'
  });
  const A4Width = 210;
  // const A4Height = 295.9;
  const A4Height = 296;
  // let pageHeight = (canvas.width / A4Width) * A4Height;
  // let leftHeight = canvas.height;
  let position = 0;
  let imgWidth = A4Width;
  // let imgHeight = Math.floor((A4Width / canvas.width) * canvas.height);
  let imgHeight = A4Height;
  for (let nodeIndex of Object.keys(nodeList)) {
    nodeIndex = Number(nodeIndex)
    let node = nodeList[nodeIndex]
    let canvas = await html2canvas(node, {
      scale: 1,
      useCORS: true,
      allowTaint: true,
      backgroundColor: bgColor,
    });
    let pageData = canvas.toDataURL("image/jpeg", 1.0);
    PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
    position = position + A4Height + 100
    if(nodeIndex !== (nodeList.length - 1)){
      PDF.addPage()
    }
  }
  PDF.save(title + ".pdf");
}
