<script>
import { defineComponent } from 'vue'
import { tableDataMixins } from '../../common/tableDataMixins'
import * as axios from '@/axios'
import _ from 'lodash'

export default defineComponent({
  name: 'CommonSelectDialog',
  mixins: [tableDataMixins],
  props: {
    value: {
      required: false
    },
    valueKey: {
      type: String,
      default() {
        return 'id'
      }
    },
    dialogTitle: {
      type: String
    },
    dialogName: {
      type: String,
      required: true
    },
    check: {
      type: Boolean,
      default() {
        return true
      }
    },
    multiple: {
      type: Boolean,
      default() {
        return false
      }
    },
    pageSize: {},
    defaultConditions: {},
    orsConditions: {},
    otherParams: {},
    callback: {},
    tableMergeName: {
      type: String
    }
  },
  data() {
    return {
      getFieldsMethod: null,
      getConditionsMethod: null,
      getPageMethod: null,
      checkValue: null
    }
  },
  computed: {
    checkItem() {
      let item = this.tableDataList.find((item) => {
        return item[this.valueKey] === this.checkValue
      })
      if (item) {
        return item
      }
      return null
    },
    hasDefaultConditions() {
      if (this.defaultConditions && this.defaultConditions.length > 0) {
        return true
      } else {
        return false
      }
    },
    hasOrsConditions() {
      if (this.orsConditions && this.orsConditions.length > 0) {
        return true
      } else {
        return false
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (this.pageSize) {
        this.page.pageSize = this.pageSize
      }
      let fieldsKey = `get${this.dialogName}Fields`
      let conditionsKey = `get${this.dialogName}Conditions`
      let pageKey = `get${this.dialogName}Page`
      if (!axios || !axios[fieldsKey] || !axios[conditionsKey] || !axios[pageKey]) {
        return
      }
      this.getFieldsMethod = axios[fieldsKey]
      this.getConditionsMethod = axios[conditionsKey]
      this.getPageMethod = axios[pageKey]
    },
    async getFields() {
      this.getFieldsMethod().then((res) => {
        if (res) {
          this.tableColList = this.getColList(res.data)
        }
      })
    },
    async getConditions() {
      this.getConditionsMethod().then(async (res) => {
        if (res) {
          let conditionOptions = {
            exportList: []
          }
          if (this.hasDefaultConditions) {
            conditionOptions.exportList = this.defaultConditions.map((item) => {
              if (item.alias) {
                return `${item.field}&${item.alias}`
              }
              return item.field
            })
          }
          await this.setConditionOptions(res.data, '', conditionOptions)
        }
      })
    },
    handleCancelClick() {
      this.$emit('update:modelValue', false)
    },
    handleConfirmClick() {
      if (this.multiple) {
        if (this.checkList.length === 0) {
          this.$message({
            type: 'warning',
            message: '请选择数据'
          })
          return
        }
        if (this.callback) {
          this.callback(this.checkList)
          this.$emit('update:modelValue', false)
        }
      } else {
        if (!this.checkItem) {
          this.$message({
            type: 'warning',
            message: '请选择当前页数据'
          })
          return
        }
        if (this.callback) {
          this.callback(this.checkItem)
        }
        this.$emit('update:value', this.checkValue)
        this.$emit('update:modelValue', false)
      }
    },
    async open() {
      this.tableLoading = true
      await Promise.all([this.getFields(), this.getConditions()])
      this.checkValue = this.value
      this.page.pageNumber = 1
      this.wrapper.conditions = []
      this.getPage(true)
    },
    getPage(open) {
      this.tableDataList = []
      this.tableLoading = true
      if (this.hasDefaultConditions) {
        this.wrapper.and = this.defaultConditions
      }
      if(this.hasOrsConditions) {
        this.wrapper.ors = this.orsConditions
      }
      let params = {
        pageSize: this.page.pageSize,
        pageNum: this.page.pageNumber,
        wrapper: this.wrapper,
        sorts: this.sortList
      }
      if(this.otherParams && _.isPlainObject(this.otherParams)){
        params = {
          ...params,
          ...this.otherParams
        }
      }
      this.getPageMethod(params,this.otherParams)
        .then((res) => {
          this.page.total = res.size
          this.tableDataList = res.data
          // this.$nextTick(() => {
          //   if (open && this.multiple) {
          //     console.log(this.checkValue)
          //     this.$refs.table.setSelection(this.checkValue)
          //   }
          // })
          // this.$nextTick(()=>{
          //   // setTimeout(()=>{
          //     if(open && this.multiple){
          //       console.log(this.checkValue);
          //       this.$refs.table.setSelection(this.checkValue)
          //     }
          //   // },1000)
          // })
        })
        .catch(() => {})
        .finally(() => {
          this.tableLoading = false
        })
    },
    searchChange(wrapper) {
      this.setWrapper(wrapper)
      this.page.pageNumber = 1
      this.getPage()
    }
  }
})
</script>

<template>
  <el-dialog
    :title="dialogTitle"
    width="1200"
    top="30px"
    v-bind="$attrs"
    destroy-on-close
    append-to-body
    @open="open"
  >
    <div class="dialog-detail">
      <TableTemp
        ref="table"
        v-model:page="page"
        v-model:checkValue="checkValue"
        :check="check"
        :multiply-check="multiple"
        v-model:check-list="checkList"
        v-model:colList="tableColList"
        :tableList="tableDataList"
        :loading="tableLoading"
        :searchKey="tableSearchKey"
        :conditionOptions="conditionOptions"
        :show-operate="false"
        :valueKey="valueKey"
        is-dialog
        :merge-name="tableMergeName"
        :show-search="false"
        @search="searchChange"
        @pageChange="getPage"
        @sortChange="sortChange"
      ></TableTemp>
    </div>
    <template #footer v-if="check || multiple">
      <el-button @click="handleCancelClick">取消</el-button>
      <el-button type="primary" @click="handleConfirmClick">确认</el-button>
    </template>
  </el-dialog>
</template>

<style scoped lang="scss">
.dialog-detail {
  display: flex;
  height: 600px;
  width: 100%;
  overflow: auto;
}
</style>