<script>
import { defineComponent } from "vue";
import _ from "lodash";

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Dispatch",
  emits: ["update:modelValue", "confirm"],
  data() {
    return {
      editForm:{
        followUpTime:'',
        attitudeEvaluation: 0,
        normativeEvaluation: 0,
        qualityEvaluation: 0,
        feeEvaluation: 0,
        followUpRemark:''
      },
      baseForm:{
        followUpTime:'',
        attitudeEvaluation: 0,
        normativeEvaluation: 0,
        qualityEvaluation: 0,
        feeEvaluation: 0,
        followUpRemark:''
      }
    };
  },
  props: {
    modelValue: {
      type: Boolean
    },
    form: {}
  },
  computed: {
    show: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    }
  },
  methods: {
    open(){
      this.editForm = _.cloneDeep(this.baseForm)
      this.editForm.followUpTime = this.getTime(new Date(),"YYYY-MM-DD HH:mm:ss")
    },
    handleCancelClick() {
      this.$emit("update:modelValue", false);
    },
    handleConfirmClick() {
      this.$emit('confirm',this.editForm)
      this.$emit("update:modelValue", false);
    }
  }
});
</script>

<template>
  <el-dialog v-model="show" v-bind="$attrs" title="回访" width="50%" destroy-on-close @open="open">
    <div class="dispatch-dialog">
      <el-form label-suffix="：">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="客户名称">
              {{ form.customerName }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话">
              {{ form.phone }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="回访时间">
              {{ form.followUpTime }}
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="服务态度满意度">
              <el-rate v-model="editForm.attitudeEvaluation" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="服务规范满意度">
              <el-rate v-model="editForm.normativeEvaluation" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="服务质量满意度">
              <el-rate v-model="editForm.qualityEvaluation" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="对本次收费满意度">
              <el-rate v-model="editForm.feeEvaluation" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="回访信息">
              <el-input type="textarea" v-model="editForm.followUpRemark"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleCancelClick">取消</el-button>
        <el-button type="primary" @click="handleConfirmClick">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<style scoped lang="scss">
.follow-up-dialog {
  & > :deep(*) {
    width: 100%;
  }
}
</style>