import axios from './axios.config'
// 车辆统计（vehicle_database）
// 车辆信息统计
export const getVehicleStatistics = () => axios.get('/jaxrs/vehicle_database/vehicle_statistics')
// 加油金额统计
export const getFuelingRecordStatistics = () =>
  axios.get('/jaxrs/vehicle_database/fueling_record_statistics')
// 车辆里程统计
export const getVehicleMileageStatistics = () =>
  axios.get('/jaxrs/vehicle_database/vehicle_mileage_statistics')
// 车辆事故违章统计
export const getVehicleAccidentIllegalStatistics = () =>
  axios.get('/jaxrs/vehicle_database/vehicle_accident_illegal_statistics')
// 查询车辆里程排行
export const getVehicleMileageRanking = (data) =>
  axios.get(`/jaxrs/vehicle_database/vehicle_mileage_ranking?beginDate=${data.beginDate}&endDate=${data.endDate}`)
// 车辆里程报表
export const getVehicleMileageReport = (data) =>
  axios.get(`/jaxrs/vehicle_database/vehicle_mileage_report?beginDate=${data.beginDate}&endDate=${data.endDate}`)
// 车辆离线预警
export const getVehicleOfflineWarning = () =>
  axios.get('/jaxrs/vehicle_database/vehicle_offline_warning')

