import axios from './axios.config'
// 获取显示字段
export const getWorkOrderPaymentFields = (tableId='workOrderPayment') => axios.get(`/jaxrs/work_order_payment/fields?tableId=${tableId}`)
// 获取查询条件
export const getWorkOrderPaymentConditions = () => axios.get('/jaxrs/work_order_payment/conditions')
// 获取分页数据
export const getWorkOrderPaymentPage = (data) => axios.post('/jaxrs/work_order_payment/page',data)
// 导出
export const exportWorkOrderPayment  = (data) => axios.post(`/jaxrs/work_order_payment/export`,data,{
  responseType:'blob'
})
// 修改状态
export const setWorkOrderPaymentStatus = (data) => axios.put('/jaxrs/work_order_payment/status',data)
