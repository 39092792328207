import axios from './axios.config'
// 获取查询条件
export const getCloudStorageConditions = ()=>axios.get('/jaxrs/cloud_storage/conditions')
// 获取查询
export const getCloudStoragePage = (data) =>axios.post('/jaxrs/cloud_storage/page',data)
// 保存
export const addCloudStorage = (data) =>axios.post(`/jaxrs/cloud_storage`,data)
// 编辑
export const editCloudStorage = (data) =>axios.put(`/jaxrs/cloud_storage`,data)
// 删除
export const deleteCloudStorage = (data) =>axios.delete(`/jaxrs/cloud_storage/${data.id}`)
// 获取文件夹树
export const getCloudStorageFolderTree = ()=>axios.get('/jaxrs/cloud_storage/folder_tree')
// 批量移动
export const batchMoveFolderCloudStorage = (data)=>axios.put(`/jaxrs/cloud_storage/batch_move_folder/${data.id||''}`,data.children)


