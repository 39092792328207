
import axios from './axios.config'
// 获取显示字段
export const getInvoiceApplyFields = (tableId='InvoiceApply') =>axios.get(`/jaxrs/invoice_apply/fields?tableId=${tableId}`)
// 获取查询条件
export const getInvoiceApplyConditions = () =>axios.get('/jaxrs/invoice_apply/conditions')
// 查询
export const getInvoiceApplyPage = (data) =>axios.post('/jaxrs/invoice_apply/page',data)
// 详情页
export const getInvoiceApplyDetail = (data) =>axios.get(`/jaxrs/invoice_apply/${data.id}`)
// 编辑
export const editInvoiceApply = (data) =>axios.put(`/jaxrs/invoice_apply`,data)
// 新增
export const  addInvoiceApply = (data) =>axios.post(`/jaxrs/invoice_apply`,data)
// 删除
export const deleteInvoiceApply = (data) =>axios.delete(`/jaxrs/invoice_apply/${data.id}`)
// 开票申请作废
export const cancelInvoiceApply = (data)=>axios.put(`/jaxrs/invoice_apply/cancel/${data.id}`)
// 开票申请撤回
export const retractInvoiceApply = (data)=>axios.get(`/jaxrs/invoice_apply/retract/${data.id}`)
// 导出
export const exportInvoiceApply = (data) => axios.post(`/jaxrs/invoice_apply/export`,data,{
  responseType:'blob'
})
