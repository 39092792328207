import axios from './axios.config'
//
// 显示字段
export const getApartmentOccupancyRecordFields = (tableId = 'apartmentOccupancyRecord') =>
  axios.get(`/jaxrs/apartment_occupancy_record/fields?tableId=${tableId}`)
// 查询条件
export const getApartmentOccupancyRecordConditions = () =>
  axios.get('/jaxrs/apartment_occupancy_record/conditions')
// 查询
export const getApartmentOccupancyRecordPage = (data) =>
  axios.post('/jaxrs/apartment_occupancy_record/page', data)
// 详情
export const getApartmentOccupancyRecordDetail = (data) =>
  axios.get(`/jaxrs/apartment_occupancy_record/${data.id}`)
// 编辑
export const editApartmentOccupancyRecord = (data) =>
  axios.put(`/jaxrs/apartment_occupancy_record`, data)
// 添加
export const addApartmentOccupancyRecord = (data) =>
  axios.post(`/jaxrs/apartment_occupancy_record`, data)
// 删除
export const deleteApartmentOccupancyRecord = (data) =>
  axios.delete(`/jaxrs/apartment_occupancy_record/${data.id}`)
// 导出
export const exportApartmentOccupancyRecord = (data) =>
  axios.post(`/jaxrs/apartment_occupancy_record/export`, data, {
    responseType: 'blob'
  })
// 上传协议
export const uploadFileApartmentOccupancyRecord = (data) =>
  axios.put(`/jaxrs/apartment_occupancy_record/upload_file`, data)
// 下载协议
export const downloadContractApartmentOccupancyRecord = (data) =>
  axios.post(`/jaxrs/apartment_occupancy_record/contract/download/${data.id}`, data, {
    responseType: 'blob'
  })
