import axios from './axios.config'
// 用车归还 VehicleRecord
// 显示字段
export const getVehicleRecordFields = (tableId='vehicleRecord')=>axios.get(`/jaxrs/vehicle_return/fields?tableId=${tableId}`)
// 获取查询条件
export const getVehicleRecordConditions = ()=>axios.get('/jaxrs/vehicle_return/conditions')
// 查询
export const getVehicleRecordPage= (data)=>axios.post('/jaxrs/vehicle_return/page',data)
// 详情
export const getVehicleRecordDetail = (data)=>{
    let url
    if (data.oper){
        url = `/jaxrs//vehicle_return/${data.id}?oper=${data.oper}`
    }else{
        url = `/jaxrs//vehicle_return/${data.id}`
    }
    return axios.get(url)
}
// 保存
export const addVehicleRecord = (data)=>axios.post(`/jaxrs/vehicle_return`,data)
// 修改
export const editVehicleRecord = (data)=>axios.put(`/jaxrs/vehicle_return`,data)
// 删除
export const deleteVehicleRecord = (data)=>axios.delete(`/jaxrs/vehicle_return/${data.id}`)