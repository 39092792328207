import axios from './axios.config'
// 显示字段 MeetingRoom
export const getMeetingRoomFields = (tableId = 'meetingRoom')=>axios.get(`/jaxrs/meeting_room/fields?tableId=${tableId}`)
// 获取查询条件
export const getMeetingRoomConditions = ()=>axios.get('/jaxrs/meeting_room/conditions')
// 查询
export const getMeetingRoomPage = (data)=>axios.post('/jaxrs/meeting_room/page',data)
// 导出
export const exportMeetingRoom = (data)=>axios.post(`/jaxrs/meeting_room/export`,data,{
  responseType: 'blob'
})
// 详情
export const getMeetingRoomDetail = (data)=>axios.get(`/jaxrs/meeting_room/${data.id}`,{
  responseType: 'blob'
})
// 保存
export const addMeetingRoom = (data) =>axios.post(`/jaxrs/meeting_room`,data)
// 编辑
export const editMeetingRoom = (data)=>axios.put(`/jaxrs/meeting_room`,data)
// 删除
export const deleteMeetingRoom = (data) =>axios.delete(`/jaxrs/meeting_room/${data.id}`)