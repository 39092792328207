<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'InputNumberBetween',
  props: {
    modelValue: {
      required: true
    },
    min:{
      type:Number,
      default(){
        return 0
      }
    },
    max:{
      type:Number
    }
  },
  methods:{
    inputWheelStop(e){
      e.preventDefault()
    }
  }
})
</script>

<template>
  <div class="input-number-between">
    <el-input type="number" v-model="modelValue[0]" :min="min" :max="modelValue[1]" @wheel.stop="inputWheelStop"></el-input>
    <span class="icon">-</span>
    <el-input type="number" v-model="modelValue[1]" :min="modelValue[0]" :max="max" @wheel.stop="inputWheelStop"></el-input>
  </div>
</template>

<style scoped lang="scss">
.input-number-between {
  display: flex;
  align-items: center;
  .icon{
    margin: 0 10px;
  }
}
</style>