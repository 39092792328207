import axios from './axios.config'
// 项目变更记录（ProjectContractChange）
// 获取列表信息
export const getProjectContractChangeFields = (tableId='projectContractChange') =>axios.get(`/jaxrs/project_contract_change/fields?tableId=${tableId}`)
// 查询条件
export const getProjectContractChangeConditions = () =>axios.get('/jaxrs/project_contract_change/conditions')
// 查询
export const getProjectContractChangePage = (data) =>axios.post('/jaxrs/project_contract_change/page',data)
// 详情
export const getProjectContractChangeDetail = (data) =>axios.get(`/jaxrs/project_contract_change/${data.id}`)
// 编辑
export const editProjectContractChange = (data) =>axios.put(`/jaxrs/project_contract_change`,data)
// 保存（新增）
export const addProjectContractChange = (data) =>axios.post(`/jaxrs/project_contract_change`,data)
// 删除
export const deleteProjectContractChange = (data) =>axios.delete(`/jaxrs/project_contract_change/${data.id}`)
// 修改流程状态
export const changeProjectContractChangeProcessStatus = (data) =>axios.put(`/jaxrs/project_contract_change/status`,data)
// 根据项目状态查询销售订单详情页信息
export const getSalesOrderDetailMessageConditions = (data) =>axios.get(`/jaxrs/sales_order/project_id/${data.projectId}`)
// 撤回申请
export const retractProjectContractChange = (data) =>axios.get(`/jaxrs/project_contract_change/retract/${data.id}`)
