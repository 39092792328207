import axios from './axios.config'
// 退料单（StockReturn）
// 获取显示字段
export const getStockReturnFields = (tableId='stockReturn') =>axios.get(`/jaxrs/stock_returned/fields?tableId=${tableId}`)
// 获取查询条件
export const getStockReturnConditions = () =>axios.get('/jaxrs/stock_returned/conditions')
// 查询
export const getStockReturnPage = (data) =>axios.post('/jaxrs/stock_returned/page',data)
// 导出
export const exportStockReturn =(data) =>
    axios.post(`/jaxrs/stock_returned/export`,data,{
        responseType:'blob'
    })
// 详情
export const getStockReturnDetail = (data)=>{
    let url
    if(data.oper){
        url=`/jaxrs/stock_returned/${data.id}?oper=${data.oper}`
    }else{
        url=`/jaxrs/stock_returned/${data.id}`
    }
    return axios.get(url)
}
// 保存
export const addStockReturn = (data) =>axios.post(`/jaxrs/stock_returned`,data)
// 修改
export const editStockReturn = (data) =>axios.put(`/jaxrs/stock_returned`,data)
// 删除
export const deleteStockReturn = (data) =>axios.delete(`/jaxrs/stock_returned/${data.id}`)
// 入库
export const inputStockReturn = (data)=>axios.put(`/jaxrs/stock_returned/stock_in`,data)
// 批量入库
export const batchStockIn = (data)=>axios.put(`/jaxrs/stock_returned/batch_stock_in`,data)
