import axios from './axios.config'
// 车辆年检 ProjectConfiguration
// 获取显示字段
export const getVehicleAnnualInspectionFields = (tableId='projectConfiguration') =>axios.get(`/jaxrs/vehicle_annual_inspection/fields?tableId=${tableId}`)
// 查询字段
export const getVehicleAnnualInspectionConditions = () =>axios.get('/jaxrs/vehicle_annual_inspection/conditions')
// 查询
export const getVehicleAnnualInspectionPage = (data) =>axios.post('/jaxrs/vehicle_annual_inspection/page',data)
// 根据id获取详情
export const getVehicleAnnualInspectionDetail = (data) =>axios.get(`/jaxrs/vehicle_annual_inspection/${data.id}`)
// 保存
export const addVehicleAnnualInspection = (data) =>axios.post(`/jaxrs/vehicle_annual_inspection`,data)
// 修改
export const editVehicleAnnualInspection = (data) =>axios.put(`/jaxrs/vehicle_annual_inspection`,data)
// 删除
export const deleteVehicleAnnualInspection = (data) =>axios.delete(`/jaxrs/vehicle_annual_inspection/${data.id}`)
// 导出
export const
    exportVehicleAnnualInspection = (data) =>
        axios.post(`/jaxrs/vehicle_annual_inspection/export`,data,{
            responseType:'blob'
        })
// 查询年检过期和将要过期
export const getAnnualInspection = () =>axios.get('/jaxrs/vehicle_annual_inspection/expireVehicleList')
