<script>
import { defineComponent } from "vue";
import SignCanvas from "./SignCanvas.vue";
import { uploadImage } from "@/axios";

export default defineComponent({
  name: "SignDrawer",
  components: { SignCanvas },
  emits: ["update:show", "callback"],
  data() {
    return {};
  },
  props: {
    show: {
      type: Boolean
    },
    title: {
      default() {
        return "签字";
      }
    }
  },
  computed: {
    showDrawer: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("update:show", val);
      }
    }
  },
  methods: {
    open() {
      this.clearCanvas();
    },
    async confirm() {
      let imgFile = await this.$refs.canvas.getCanvasData("file");
      let formData = new FormData();
      formData.append("files", imgFile);
      uploadImage(formData)
        .then((res) => {
          if (res) {
            let filesData = res.data;
            this.$emit("callback", filesData);
            this.showDrawer = false;
          }
        })
        .catch(() => {
        });
    },
    clearCanvas() {
      this.$refs.canvas.clear();
    }
  }
});
</script>

<template>
  <el-drawer
    v-model="showDrawer"
    size="95%"
    direction="btt"
    destroy-on-close
    @open="open"
  >
    <template #header>
      <div>{{ title }}</div>
    </template>
    <template #default>
      <div class="detail-wrapper">
        <SignCanvas ref="canvas"></SignCanvas>
      </div>
    </template>
    <template #footer>
      <div class="drawer-footer">
        <el-button @click="clearCanvas"
        >清空
        </el-button>
        <el-button type="primary" @click="confirm"
        >确认
        </el-button>
      </div>
    </template>
  </el-drawer>
</template>

<style scoped lang="scss">
.detail-wrapper {
  overflow: hidden !important;
}
</style>