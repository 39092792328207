import axios from './axios.config'
// 工作申请（jobApplication）
// 获取显示字段
export const getJobApplicationFields = (tableId='jobApplication')=>axios.get(`/jaxrs/hr/job_application/fields?tableId=${tableId}`)
// 获取查询条件
export const getJobApplicationConditions = ()=>axios.get('/jaxrs/hr/job_application/conditions')
// 查询
export const getJobApplicationPage = (data) =>axios.post('/jaxrs/hr/job_application/page',data)
// 详情
export const getJobApplicationDetail = (data) => {
    let url
    if (data.oper) {
        url = `/jaxrs/hr/job_application/${data.id}?oper=${data.oper}`
    } else {
        url = `/jaxrs/hr/job_application/${data.id}`
    }
    return axios.get(url)
}
// 保存
export const addJobApplication = (data) =>axios.post(`/jaxrs/hr/job_application`,data)
// 编辑
export const editJobApplication = (data) =>axios.put(`/jaxrs/hr/job_application`,data)
// 删除
export const deleteJobApplication = (data) =>axios.delete(`/jaxrs/hr/job_application/${data.id}`)
// 修改面试状态
export const editInterviewStatus = (data) =>axios.put(`/jaxrs/hr/job_application/interview`,data)
// 提交（不需要验证TOKEN）
export const submitJobApplication = (data) =>axios.put(`/jaxrs/hr/job_application/submit`,data)
// 获取面试通知邮件消息内容
export const getJobApplicationInterviewNotice = (data)=>axios.post(`/jaxrs/hr/job_application/get_interview_notice`,data)
// 终止
export const finishJobApplication = (data) =>axios.post(`/jaxrs/hr/job_application/finish`,data)

