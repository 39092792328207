<script>
import { defineComponent } from "vue";
import { getGoeParse } from "@/axios/wx";
// 不允许proxy代理
let map = null;
let markerLayer = null;
let zoom = 17;
export default defineComponent({
  name: "MapCoordinateDialog",
  emits: ["callback", "update:modelValue"],
  data() {
    return {
      location: {
        lat: "",
        lng: ""
      }
    };
  },
  props: {
    modelValue: {
      type: Boolean
    },
    address: {},
    // 纬度
    lat: {},
    // 经度
    lng: {}
  },
  computed: {
    show: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    },
    hasLocation() {
      return this.lat || this.lng;
    },
    realAddress(){
      if(this.address){
        return `上海市${this.address}`
      }else{
        return ''
      }
    }
  },
  async mounted() {
    await this.mapStore.loadMapScript();
  },
  methods: {
    async open() {
      await this.initMap();
      await this.getCenter();
    },
    async initMap() {
      if(this.map){
        return
      }
      //定义地图中心点坐标
      let latitude = this.mapStore.latitude;
      let longitude = this.mapStore.longitude;
      let TMap = window.TMap;
      let center = new TMap.LatLng(latitude, longitude);
      //定义map变量，调用 TMap.Map() 构造函数创建地图
      map = new TMap.Map(this.$refs.container, {
        center: center, //设置地图中心点坐标
        zoom: zoom, //设置地图缩放级别
        pitch: 0, //设置俯仰角
        viewMode: "3D",
        // mapStyleId: "style1",// 自定义主题
        // showControl: false,
        baseMap: {			//底图设置（参数为：VectorBaseMap对象）
          type: "vector",	//类型：失量底图
          // type: 'traffic',	//类型：失量底图
          features: ["base", "building2d", "building3d", "label", "point"]
          //仅渲染：道路及底面(base) + 2d建筑物(building2d)，以达到隐藏文字的效果
        }
        // rotation: 45 //设置地图旋转角度
      });

      //创建并初始化MultiMarker
      markerLayer = new TMap.MultiMarker({
        map: map
      });
      map.on("click", (evt) => {
        let location = {
          lat: evt.latLng.getLat().toFixed(6),
          lng: evt.latLng.getLng().toFixed(6)
        };
        this.setCenter(location);
      });
    },
    async getCenter() {
      if (this.hasLocation) {
        this.location.lat = this.lat;
        this.location.lng = this.lng;
      } else {
        await getGoeParse({ address: this.realAddress }).then(res => {
          this.location.lat = res.data.lat;
          this.location.lng = res.data.lng;
        }).catch(() => {
        });
      }
      this.setCenter();
    },
    setCenter(location) {
      if (location) {
        this.location = location;
      }
      let position = new TMap.LatLng(this.location.lat, this.location.lng)
      map.panTo(position)
      // if (location) {
      //   map.panTo(position)
      // }else{
      //   map.setCenter(position);
      // }
      markerLayer.setGeometries([])
      markerLayer.add({
        position: position
      })
    },
    handleCancelClick() {
      this.show = false;
    },
    handleConfirmClick() {
      this.$emit("callback",this.location);
      this.show = false;
    }
  }
});
</script>

<template>
  <el-dialog title="选择位置" v-model="show" v-bind="$attrs" width="80%" destroy-on-close @opened="open">
    <div class="map-coordinate-dialog">
      <div class="map-location"><span>{{ address }}</span><span>经度：{{ location.lng }}</span><span>纬度：{{ location.lat
        }}</span>
        <el-button type="primary" @click="getCenter">重新定位</el-button>
      </div>
      <div class="map-container" ref="container"></div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleCancelClick">取消</el-button>
        <el-button type="primary" @click="handleConfirmClick">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<style scoped lang="scss">
.map-coordinate-dialog {
  .map-location {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    & > * {
      margin-right: 10px;
    }
  }

  .map-container {
    width: 100%;
    height: 400px;
    background: #eeeeee;
  }
}
</style>