import axios from './axios.config'
// 资料库分类（databaseCategory）
// 获取树状列表
export const getDatabaseCategoryTree = (data) =>axios.post('/jaxrs/database_category/tree',data)
// 详情
export const getDatabaseCategoryDetail = (data) =>{
    let url
    if(data.oper){
        url=`/jaxrs/database_category/${data.id}?oper=${data.oper}`
    }else{
        url=`/jaxrs/database_category/${data.id}`
    }
    return axios.get(url)
}
// 保存
export const addDatabaseCategory = (data) =>axios.post(`/jaxrs/database_category`,data)
// 编辑
export const editDatabaseCategory = (data)=>axios.put(`/jaxrs/database_category`,data)
// 删除
export const deleteDatabaseCategory = (data) =>axios.delete(`/jaxrs/database_category/${data.id}`)