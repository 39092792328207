import axios from './axios.config'
// 题目分类（questionCategory）
// 获取树状列表
export const getQuestionCategoryTree = (data) =>axios.get('/jaxrs/question_category/tree',data)
// 详情
export const getQuestionCategoryDetail = (data) =>{
    let url
    if(data.oper){
        url=`/jaxrs/question_category/${data.id}?oper=${data.oper}`
    }else{
        url=`/jaxrs/question_category/${data.id}`
    }
    return axios.get(url)
}
// 保存
export const addQuestionCategory = (data) =>axios.post(`/jaxrs/question_category`,data)
// 编辑
export const editQuestionCategory = (data)=>axios.put(`/jaxrs/question_category`,data)
// 删除
export const deleteQuestionCategory = (data) =>axios.delete(`/jaxrs/question_category/${data.id}`)