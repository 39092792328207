import axios from './axios.config'
// 考核结果 PerformanceEvaluationResult
// 获取考核结果的显示字段
export const getPerformanceEvaluationResultFields = (tableId = 'performanceEvaluationResult') =>axios.get(`/jaxrs/hr/performance_evaluation_result/fields?tableId=${tableId}`)
// 获取查询条件
export const getPerformanceEvaluationResultConditions = ()=>axios.get('/jaxrs/hr/performance_evaluation_result/conditions')
// 查询
export const getPerformanceEvaluationResultPage = (data) =>axios.post('/jaxrs/hr/performance_evaluation_result/page',data)
// 详情
export const getPerformanceEvaluationResultDetail = (data) =>axios.get(`/jaxrs/hr/performance_evaluation_result/${data.id}`)
// 保存
export const addPerformanceEvaluationResult = (data)=>axios.post('/jaxrs/hr/performance_evaluation_result',data)
// 修改
export const editPerformanceEvaluationResult = (data) =>axios.put('/jaxrs/hr/performance_evaluation_result',data)
// 删除
export const deletePerformanceEvaluationResult = (data) =>axios.delete(`/jaxrs/hr/performance_evaluation_result/${data.id}`)
// 修改流程状态
export const editPerformanceEvaluationResultStates = (data)=>axios.put('/jaxrs/hr/performance_evaluation_result/status',data)
