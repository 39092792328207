import dayjs from 'dayjs'
import _ from 'lodash'
import { layout } from '@o2oa/component'
import { mapStores } from 'pinia'
import store from '../store'
import { downloadFileByFilename, processSubmit, processWork } from '../axios'
import quarterOfYear from "dayjs/plugin/quarterOfYear"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import minMax from "dayjs/plugin/minMax";
import isBetween from "dayjs/plugin/isBetween";

const moneyDigit = 2
export const commonMixins = {
  computed: {
    ...mapStores(...store.stores)
  },
  methods: {
    filterMoney(obj) {
      if (!obj) {
        return ''
      }
      let unit = obj.value
      if (!unit && !_.isNumber(unit)) {
        return ''
      }
      unit = Number(unit)
      if (_.isNumber(unit)) {
        return {
          name: unit.toFixed(moneyDigit).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
        }
      } else {
        return {
          name: unit.replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
        }
      }
    },
    filterNumberMoney(unit) {
      if (!unit && !_.isNumber(unit)) {
        return ''
      }
      let number = null
      if (_.isNumber(unit)) {
        number = unit
      } else {
        number = _.toNumber(unit)
      }
      return number.toFixed(moneyDigit).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
    },
    filterTrueOrFalse(val) {
      let value = val.value
      let name
      if (_.isNumber(value)) {
        name = value === 1 ? '是' : '否'
      } else if (_.isBoolean(value)) {
        name = value ? '是' : '否'
      } else if (_.isString(value)) {
        name = value === '1' ? '是' : '否'
      } else {
        name = value ? '是' : '否'
      }
      return {
        name
      }
    },
    getTime(date = new Date(), format = 'YYYY-MM-DD') {
      return dayjs(date).format(format)
    },
    disabledDateAfterNow(time) {
      return time.getTime() > Date.now()
    },
    getTotalByKey(list, key) {
      return list.reduce((num, item) => {
        if (_.isNumber(_.toNumber(item[key]))) {
          return num + _.toNumber(item[key])
        }
        return num
      }, 0)
    },
    filterMoneyBig(n) {
      if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n)) {
        return ''
      }
      if(n === 0){
        return ''
      }

      var unit = '仟佰拾亿仟佰拾万仟佰拾圆角分',
        str = ''
      n += '00'
      var p = n.indexOf('.')
      if (p >= 0) n = n.substring(0, p) + n.substr(p + 1, 2)

      unit = unit.substr(unit.length - n.length)

      for (var i = 0; i < n.length; i++)
        str += '零壹贰叁肆伍陆柒捌玖'.charAt(n.charAt(i)) + unit.charAt(i)
      return str
        .replace(/零(仟|佰|拾|角)/g, '零')
        .replace(/(零)+/g, '零')
        .replace(/零(万|亿|圆)/g, '$1')
        .replace(/(亿)万|壹(拾)/g, '$1$2')
        .replace(/^圆零?|零分/g, '')
        .replace(/圆$/g, '圆整')
    },
    copeArrayValueByKey({ from, fromId, fromKey, to, toId, toKey }) {
      let fromMap = {}
      from.forEach((item) => {
        fromMap[item[fromId]] = item[fromKey]
      })
      to.forEach((item) => {
        if (_.has(fromMap, item[toId])) {
          item[toKey] = fromMap[item[toId]]
        }
      })
      // 可不接收，值已经改变
      return to
    },
    getImageUrl(name) {
      return new URL(`../assets/image/${name}`, import.meta.url).href
    },
    validateField(newVal, oldVal, key, refName = 'form') {
      if (newVal !== oldVal) {
        if (this.$refs[refName]) {
          this.$refs[refName].validateField(key, () => {})
        }
      }
    },
    getMyIdentityOrUser() {
      let identityList = layout.session.user.identityList
      if (identityList.find((item) => item.major)) {
        return identityList.find((item) => item.major)
      }
      if (identityList.length > 0) {
        return identityList[0]
      }
      return layout.session.user
    },
    downLoadFile(blob) {
      let blobFilename = blob.filename || this.getTime(new Date(), 'YYYYMMDDHHmmss')
      let newBlob = new Blob([blob], { type: blob.type })
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = URL.createObjectURL(newBlob)
      a.download = blobFilename
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    downLoadFileByUrl(url) {
      fetch(url)
        .then(async (res) => {
          return await res.blob()
        })
        .then((blob) => {
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = URL.createObjectURL(blob)
          a.download = name
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        })
    },
    // 根据type获取字典下拉列表
    getDictSelectList(key) {
      let checkItem = this.dictStore.dictList.find((item) => item.type === key)
      let list = []
      if (checkItem) {
        list = checkItem.children.map((item) => {
          return {
            id: item.val,
            name: item.label
          }
        })
      }
      return list
    },
    getDateByDay(date = new Date(), num = 0) {
      let day = dayjs(date).add(num, 'day').format('YYYY-MM-DD')
      return day
    },
    getFirstDate(date = new Date(),type='month',format = 'YYYY-MM-DD'){
      dayjs.extend(quarterOfYear)
      let baseDate = dayjs(date)
      if(type === 'month'){
        baseDate = baseDate.set('date',1)
      }else if(type === 'quarter'){
        let quarter = baseDate.quarter()
        let month = (quarter - 1 ) * 3
        baseDate = baseDate.set('month',month)
        baseDate = baseDate.set('date',1)
      }else if(type === 'year'){
        baseDate = baseDate.set('month',0)
        baseDate = baseDate.set('date',1)
      }
      return baseDate.format(format)
    },
    getDateIsBefore(date) {
      let start = dayjs()
      let end = dayjs(date)
      let isOver = start.isBefore(end)
      return isOver
    },
    /**
     * 流程流转
     * @param processId   流程ID workId或jobId
     * @param routeName   下一步流程符号名称 如：开始，同意，不同意
     * @param opinion     备注
     * @param callback    回调事件
     * @returns {Promise<any>}
     */
    async processWork(processId, routeName, opinion, callback) {
      return await processWork(processId).then(async (res) => {
        if (res) {
          let taskList = res.data.taskList
          let distinguishedName = layout.user.distinguishedName
          const { id, identity } = taskList.find((item) => item.person === distinguishedName) || {}
          if (id) {
            let submitData = {
              id,
              identity,
              routeName: routeName,
              opinion: opinion
            }
            if (callback) {
              await callback()
            }
            await processSubmit(submitData).then((res) => {})
            return true
          } else {
            return false
          }
        }
      })
    },
    filterAllStatusList(list) {
      let returnList = list.map((item) => {
        return {
          name: item.label,
          value: item.val,
          canEdit: !!item.canEdit
        }
      })
      return returnList
    },
    showByStatus(statusName, status) {
      let checkItem = this.dictStore.dictList.find((dItem) => dItem.type === statusName)
      let filterList = this.filterAllStatusList(checkItem.children)
      let item = filterList.find((item) => item.value === status)
      if (item) {
        return item.canEdit
      } else {
        return false
      }
    },
    findTreeByKey(tree, val, key = 'id', childrenKey = 'children') {
      let checkItem = null
      for (let i = 0; i < tree.length; i++) {
        let item = tree[i]
        if (item[key] === val) {
          checkItem = item
        }
        if (item[childrenKey] && item[childrenKey].length > 0) {
          let childrenCheckItem = this.findTreeByKey(item[childrenKey], val, key, childrenKey)
          if (childrenCheckItem) {
            checkItem = childrenCheckItem
          }
        }
      }
      return checkItem
    },
    getChildrenIds(val, ids) {
      ids.push(val.id)
      if (val.children && val.children.length > 0) {
        val.children.forEach((c) => {
          this.getChildrenIds(c, ids)
        })
      }
    },
    getAmountByTaxPriceAndNumber(taxPrice, number, digit) {
      if (!taxPrice || !number) {
        return 0
      }
      let amount = _.multiply(_.toNumber(taxPrice),_.toNumber(number))
      return _.round(amount, digit || moneyDigit)
    },
    getTaxPriceByAmountAndNumber(amount, number, digit){
      if (!amount || !number) {
        return 0
      }
      let taxPrice = _.divide(_.toNumber(amount),_.toNumber(number))
      return _.round(taxPrice, digit || moneyDigit)
    },
    getTaxAmountByAmountAndTaxRate(amount, taxRate, digit) {
      if (!amount || !taxRate) {
        return 0
      }
      let excludeTaxAmount = _.divide(
        _.toNumber(amount),
        _.add(1, _.divide(_.toNumber(taxRate ? taxRate : 0), 100))
      )
      let taxAmount = _.subtract(_.toNumber(amount), _.toNumber(excludeTaxAmount))
      return _.round(taxAmount, digit || moneyDigit)
    },
    getExcludeTaxAmountByAmountAndTaxRate(amount, taxRate, digit){
      if (!amount) {
        return 0
      }
      if (!taxRate) {
        return amount
      }
      let excludeTaxAmount = _.divide(
        _.toNumber(amount),
        _.add(1, _.divide(_.toNumber(taxRate ? taxRate : 0), 100))
      )
      return _.round(excludeTaxAmount, digit || moneyDigit)
    },
    filterProcessListForPrint(list) {
      list = list.reverse()
      let map = {}
      list = list.filter((item) => {
        if (item.fromActivityName === '拟稿') {
          return false
        }
        if (item.taskCompletedList && item.taskCompletedList.length > 0) {
          item.fromActivityName = item.fromActivityName.replace('审批', '意见')
          item.fromActivityName = item.fromActivityName.replace('审核', '意见')
          let task = item.taskCompletedList[0]
          item.routeName = task.routeName
          item.completedTime = task.completedTime
          if (!map[item.fromActivityName]) {
            map[item.fromActivityName] = true
            item.content = `${task.routeName} [${task.identity.split('@')[0]} ${
              task.completedTime
            }]`
            return true
          }
        } else {
          return false
        }
      })
      list = list.reverse()
      let mergeList = []
      list.forEach((item) => {
        if (item.fromActivityName.includes('财务')) {
          item.fromActivityName = '财务部门意见'
          let financeItem = mergeList.find((fItem) => fItem.fromActivityName === '财务部门意见')
          if (financeItem) {
            financeItem.content = `${financeItem.content}</br>${item.content}`
          } else {
            mergeList.push(item)
          }
        } else {
          mergeList.push(item)
        }
      })
      return mergeList
    },
    materialStockError(list, errorList) {
      let errNameList = list
        .filter((item) => errorList.includes(item.materialId))
        .map((item) => {
          if (item.materialName) {
            return `${item.materialName}（${item.materialCode}）`
          } else {
            return `${item.name}（${item.code}）`
          }
        })
      let nameStr = _.uniq(errNameList).join(`、`)
      // this.$message.error(`${nameStr}，库存不足`)
      this.$messageBox.alert(`${nameStr}，库存不足`, '库存不足')
    },
    downloadFileByFilename(filename, name) {
      name = name || filename
      downloadFileByFilename({ filename }).then((res) => {
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = URL.createObjectURL(res)
        a.download = name
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      })
    },
    isPc() {
      let userAgentInfo = navigator.userAgent
      let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod']
      let flag = true
      for (let v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false
          break
        }
      }
      return flag
    },
    getSuffix(str) {
      if (!str) {
        return ''
      }
      let strList = str.split('.')
      let suffix = _.toLower(strList[strList.length - 1])
      return suffix
    },
    commonFilterLabel(item, keys, separator = '-') {
      let list = []
      keys.forEach((key) => {
        if (item[key]) {
          list.push(item[key])
        }
      })
      let str = list.join(separator)
      return str
    },
    // 上传文件
    upFileList (targetProperty,filesData){
      // 类型检查确保filesData是一个数组
      if(!Array.isArray(filesData)){
        throw new Error('filesData must be an array')
      }
      // 检查表单中是否已经存在相应的文件数据数组
      if(this.editForm[targetProperty]){
        this.editForm[targetProperty].push(...filesData)
      }else {
        this.editForm[targetProperty] = filesData
      }
    },
    validateNumber(value){
      if(_.isNumber(value)){
        return false
      }
      return !value
    },
    showByList(list,value,hidden){
      if(hidden){
        return !list.includes(value)
      }
      return list.includes(value)
    },
    filterDateStatus(date){
      let now = new Date()
      let expirationTime = new Date(date)
      let dateStatue
      if (now < expirationTime) {
        let day = Math.round((expirationTime.getTime() - now.getTime()) / (1000 * 3600 * 24))
        if (day === 0) {
          dateStatue = '即将到期'
        } else {
          dateStatue = `${day}天后到期`
        }
      } else {
        dateStatue = '已过期'
      }
      return dateStatue
    },
    secondsToHms(sec) {
      let hours = Math.floor(sec / 3600);
      let minutes = Math.floor((sec - (hours * 3600)) / 60);
      let seconds = sec % 60;
      let times = [minutes,seconds]
      if(hours > 0){
        times.unshift(hours)
      }
      return times
        .map(num => num < 10 ? '0' + num : num.toString())
        .filter(num => num)
        .join(':');
    },
    differentSecondsToHms(start,end){
      if(!start || !end){
        return ''
      }
      let startSeconds = dayjs(start)
      // let endSeconds = dayjs(end)
      let diff = startSeconds.diff(end, "seconds")
      if(_.isNaN(diff)){
        return ''
      }
      return this.secondsToHms(Math.abs(diff))
      // console.log(startSeconds.date());
      // console.log(endSeconds);
    },
    filterPercent(finishNum, totalNum) {
      if (!finishNum) {
        return `0%`
      }
      let percent = _.round(_.multiply(_.divide(finishNum, totalNum), 100), 2)
      return `${percent}%`
    },
    formatFileSize(bytes) {
      if (bytes === 0) return '0 B'
      const k = 1024
      const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      const i = Math.floor(Math.log(bytes) / Math.log(k))
      const size = parseFloat((bytes / Math.pow(k, i)).toFixed(2))
      return `${size} ${sizes[i]}`
    },
    logEmptyForm(form){
      for(let key in form){
        if(Array.isArray(form[key])){
          form[key] = []
        }else if(_.isString(form[key]) && form[key].includes('newArrayList')){
          form[key] = []
        }else{
          form[key] = null
        }
      }
      console.log(form);
    },
    showFileList(list) {
      return list && list.length > 0
    },
    isSameOrBeforeDate(date,unit='day'){
      dayjs.extend(isSameOrBefore)
      return dayjs(new Date()).isSameOrBefore(date, unit)
    },
    isSameOrAfterDate(date,unit='day'){
      dayjs.extend(isSameOrAfter)
      return dayjs(new Date()).isSameOrAfter(date, unit)
    },
    minMaxDate(list,type='min'){
      dayjs.extend(minMax)
      let dateList = list.map(item=>{
        return dayjs(item)
      })
      if(type==='min'){
        return dayjs.min(dateList)
      }else{
        return dayjs.max(dateList)
      }
    },
    isBetweenDate(date,start,end,type='day',eq='[]'){
      dayjs.extend(isBetween)
      let dateDay = this.getTime(date)
      let startDay = this.getTime(start)
      let endDay = this.getTime(end)
      return dayjs(dateDay).isBetween(startDay,endDay,type,eq)
    },
  }
}
