import axios from './axios.config'
// 员工档案
// 显示字段
export const getEmployeesFields = (tableId='employees') =>axios.get(`/jaxrs/hr/employees/fields?tableId=${tableId}`)
// 查询条件
export const getEmployeesConditions = () =>axios.get('/jaxrs/hr/employees/conditions')
// 查询
export const getEmployeesPage = (data) =>axios.post('/jaxrs/hr/employees/page',data)
// 详情
export const getEmployeesDetail = (data) =>axios.get(`/jaxrs/hr/employees/${data.id}`)
// 编辑
export const editEmployees = (data) =>axios.put(`/jaxrs/hr/employees`,data)
// 添加
export const addEmployees = (data) =>axios.post(`/jaxrs/hr/employees`,data)
// 删除
export const deleteEmployees = (data) =>axios.delete(`/jaxrs/hr/employees/${data.id}`)
// 导出
export const exportEmployees = (data) => axios.post(`/jaxrs/hr/employees/export`,data,{
    responseType:'blob'
})
// 参考workOrder.js
// 导入保存
export const importEmployees = (data) => axios.post(`/jaxrs/hr/employees/import`,data)
// 获取导入列表字段
export const getEmployeesImportFields = () => axios.get('/jaxrs/hr/employees/import/fields')