import axios from './axios.config'
// 收款认领   FundsClaim
// 获取显示字段
export const getFundsClaimFields = (tableId='fundsClaim')=>axios.get(`/jaxrs/funds_claim/fields?tableId=${tableId}`)
// 获取查询条件
export const getFundsClaimConditions = ()=>axios.get('/jaxrs/funds_claim/conditions')
// 查询
export const getFundsClaimPage = (data)=>axios.post('/jaxrs/funds_claim/page',data)
// 详情
export const getFundsClaimDetail = (data) =>axios.get(`/jaxrs/funds_claim/${data.id}`)
// 保存
export const addFundsClaim = (data)=>axios.post(`/jaxrs/funds_claim`,data)
// 编辑
export const editFundsClaim = (data) =>axios.put(`/jaxrs/funds_claim`,data)
// 删除
export const deleteFundsClaim = (data) =>axios.delete(`/jaxrs/funds_claim/${data.id}`)