<script>
import { defineComponent } from "vue";
import _ from "lodash";

export default defineComponent({
  name: "SelectV2",
  emits: ["update:modelValue", "selectChange"],
  data() {
    return {
      searchValue: ""
    };
  },
  props: {
    modelValue: {
      required: true
    },
    labelKey: {
      type: String,
      default() {
        return "label";
      }
    },
    valueKey: {
      type: String,
      default() {
        return "id";
      }
    },
    lightLabelKey: {
      type: String
    },
    labelFilter: {
      type: Function
    },
    list: {
      type: Array,
      required: true
    },
    change: {
      type: Function
    },
    filter: {
      type: Function
    },
    collapseTags:{
      type:Boolean,
      default:true
    },
    defaultLabel: {
      type: String
    }
  },
  computed: {
    selectValue: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    },
    selectItem() {
      if (_.isObject(this.selectValue)) {
        let valueMap = {};
        this.selectValue.forEach(item => {
          valueMap[item] = true;
        });
        let list = this.list.filter((item) => {
          return valueMap[item[this.valueKey]];
        });
        return list;
      } else {
        return this.list.find((item) => {
          return item[this.valueKey] === this.selectValue;
        });
      }
    },
    filterList() {
      if (!this.labelKey) {
        return this.list;
      }
      if (this.filter) {
        return this.filter(this.list, this.searchValue);
      }
      let list = this.list.filter((item) => {
        if (this.labelFilter) {
          return this.labelFilter(_.cloneDeep(item)).includes(this.searchValue);
        }

        let val = false;
        if (item[this.labelKey] && item[this.labelKey].includes(this.searchValue)) {
          val = true;
        }
        if (this.lightLabelKey && item[this.lightLabelKey] && item[this.lightLabelKey].includes(this.searchValue)) {
          val = true;
        }
        return val;
      });
      return list;
    }
  },
  watch: {
    defaultLabel: {
      handler(newVal) {
        this.updateDefaultLabel(newVal)
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
  },
  updated(){
    this.updateDefaultLabel(this.defaultLabel)
  },
  methods: {
    updateDefaultLabel(newVal){
      if (newVal) {
        this.$nextTick(() => {
          let selectRef = this.$refs.select;
          if(selectRef.selectedLabel == this.modelValue){
            selectRef.selected = {
              ...selectRef.selected,
              currentLabel: newVal
            };
            selectRef.query = newVal;
            selectRef.selectedLabel = newVal;
          }
        });
      }
    },
    initList(val) {
      this.searchValue = _.trim(val);
    },
    changeEvent() {
      this.$nextTick(() => {
        if (this.change) {
          this.change(this.selectItem);
        } else {
          this.$emit("selectChange", this.selectItem);
        }
      });
    },
    clearEvent() {
      this.selectValue = null;
    },
    labelFilterMethod(item) {
      let copyItem = _.cloneDeep(item);
      if (this.labelFilter) {
        return this.labelFilter(copyItem);
      } else {
        return item[this.labelKey];
      }
    }
  }
});
</script>

<template>
  <el-select
    class="select-v2"
    v-model="selectValue"
    v-bind="$attrs"
    ref="select"
    filterable
    clearable
    :reserve-keyword="false"
    @change="changeEvent"
    :filter-method="initList"
    :collapse-tags="collapseTags"
    :collapse-tags-tooltip="true"
    placement="bottom-start"
    @clear="clearEvent"
  >
    <!--    :collapse-tags="true"-->
    <!--    :collapse-tags-tooltip="false"-->
    <!--      <template #prefix>-->
    <!--        <slot name="prefix"></slot>-->
    <!--      </template>-->
    <el-option
      v-for="item in filterList"
      :label="labelFilterMethod(item)"
      :value="item[valueKey]"
      :key="item[valueKey]"
    >
      <slot :row="item">
        <div class="select-options">
          <span>{{ labelFilterMethod(item) }}</span>
          <span class="light" v-if="lightLabelKey">{{ item[lightLabelKey] }}</span>
        </div>
      </slot>
    </el-option>
  </el-select>
</template>

<style scoped lang="scss">
.select-v2 {
  width: 150px;
}

.select-options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .light {
    color: var(--dubhe-text-color-secondary);
  }
}
</style>