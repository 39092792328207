<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ExportExcelO2oa",
  emits: ["update:modelValue", "confirm"],
  data() {
    return {
      inputValue: [1, 1]
    };
  },
  props: {
    modelValue: {
      required: true,
      type: Boolean
    },
    name: {
      type: String
    },
    length: {
      required: true,
      type: Number
    }
  },
  computed: {
    title() {
      return `${this.name}导出`;
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    }
  },
  watch: {
    length: {
      handler(val) {
        this.inputValue = [1, val];
      }
    }
  },
  methods: {
    handleCancelClick() {
      this.value = false;
    },
    handleConfirmClick() {
      if(!this.checkValue()){
        return
      }
      this.$emit('confirm',this.inputValue)
      this.value = false;
    },
    checkValue(){
      let [start,end] = this.inputValue
      if(start > end){
        this.$message.warning('开始位置不得大于结束位置')
        return false
      }
      if(start>this.length){
        this.$message.warning('开始位置不得大于总数量')
        return false
      }
      if(end>this.length){
        this.$message.warning('结束位置不得大于总数量')
        return false
      }
      return true
    }
  }
});
</script>

<template>
  <el-dialog
    v-model="value"
    v-bind="$attrs"
    :title="title"
    width="70%"
    append-to-body
    destroy-on-close
  >
    <div>
      <div class="row-flex">
        <span>导出范围：</span>
        <span><InputNumberBetween v-model="inputValue" :min="1" :max="length"></InputNumberBetween></span>
      </div>
      <div>注：有{{ this.length }}条符合条件的数据。</div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleCancelClick">取消</el-button>
        <el-button type="primary" @click="handleConfirmClick">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<style scoped lang="scss">
.row-flex {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
</style>