<script>
import { defineComponent } from 'vue'
import { detailMixins } from '@/common/detailMixins'
import _ from 'lodash'
import InstallWorkOrderEdit from "@/views/WorkOrder/InstallWorkOrderEdit.vue";
import RepairWorkOrderEdit from '@/views/WorkOrder/RepairWorkOrderEdit.vue'
import DesignWorkOrderEdit from '@/views/WorkOrder/DesignWorkOrderEdit.vue'
import MaintainWorkOrderEdit from '@/views/WorkOrder/MaintainWorkOrderEdit.vue'
import DeliverGoodsWorkOrderEdit from '@/views/WorkOrder/DeliverGoodsWorkOrderEdit.vue'
import ReturnWorkOrderEdit from '@/views/WorkOrder/ReturnGoodsWorkOrderEdit.vue'
import CheckWorkOrderEdit from '@/views/WorkOrder/CheckWorkOrderEdit.vue'
import ReturnGoodsWorkOrderEdit from '@/views/WorkOrder/ReturnGoodsWorkOrderEdit.vue'
import InternalAcceptanceWorkOrderEdit from '@/views/WorkOrder/InternalAcceptanceWorkOrderEdit.vue'
export default defineComponent({
  name: 'WorkOrderEdit',
  emits: ['callback'],
  inject: ['phone'],
  components:{
    InstallWorkOrderEdit,
    RepairWorkOrderEdit,
    DesignWorkOrderEdit,
    MaintainWorkOrderEdit,
    DeliverGoodsWorkOrderEdit,
    ReturnWorkOrderEdit,
    CheckWorkOrderEdit,
    ReturnGoodsWorkOrderEdit,
    InternalAcceptanceWorkOrderEdit
  },
  mixins: [detailMixins],
  props:{
    componentType:{
      required:true
    }
  },
  computed:{
    workOrderEditName(){
      let type = _.camelCase(this.componentType)
      return `${type}WorkOrderEdit`
    },
  },
  mounted() {
  },
  methods:{
    componentCallback(val){
      this.$emit('callback', val)
    }
  }
})
</script>
<template>
  <!--  <WorkOrderEdit-->
  <!--    v-model:show="showEditDrawer"-->
  <!--    :edit-id="editId"-->
  <!--    :refId="refId"-->
  <!--    :batch="batch"-->
  <!--    :reassign="reassign"-->
  <!--    :workOrderType="workOrderType"-->
  <!--    :projectType="projectType"-->
  <!--    @callback="editCallback"-->
  <!--  ></WorkOrderEdit>-->
  <component
    v-if="this.componentType"
    :is="workOrderEditName"
    v-model:show="showDrawer"
    v-bind="$attrs"
    @callback="componentCallback"
  ></component>
</template>
<style scoped lang="scss"></style>
