import axios from './axios.config'
// 获取供应商变更显示字段
export const getSupplierChangeFields = (tableId='supplierChange') => axios.get(`/jaxrs/supplier_change/fields?tableId=${tableId}`)
// 获取供应商变更查询条件
export const getSupplierChangeConditions = () => axios.get('/jaxrs/supplier_change/conditions')
// 获取供应商变更分页数据
export const getSupplierChangePage = (data) => axios.post('/jaxrs/supplier_change/page',data)
// 获取供应商变更详情
export const getSupplierChangeDetail = (data) => axios.get(`/jaxrs/supplier_change/${data.id}`)
// 修改供应商变更
export const editSupplierChange = (data) => axios.put(`/jaxrs/supplier_change`,data)
// 添加供应商变更
export const addSupplierChange = (data) => axios.post(`/jaxrs/supplier_change`,data)
// 删除供应商变更
export const deleteSupplierChange = (data) => axios.delete(`/jaxrs/supplier_change/${data.id}`)

