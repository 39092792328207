// 获取到文档的距离
export function getToPageXY(e) {
  let touchE = e;
  let mouseE = e;
  if (touchE.changedTouches) {
    // 移动端
    return {
      x: touchE.changedTouches[0].pageX,
      y: touchE.changedTouches[0].pageY,
    };
  } else {
    return {
      x: mouseE.x || mouseE.pageX,
      y: mouseE.y || mouseE.pageY,
    };
  }
}

// 当前是否pc版本
export function IsPC() {
  let userAgentInfo = navigator.userAgent;
  let Agents = [
    "Android",
    "iPhone",
    "SymbianOS",
    "Windows Phone",
    "iPad",
    "iPod",
  ];
  let flag = true;
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
}

/**
 * canvas 是否为空
 * @param canvas
 * @returns boolean
 */
export function isCanvasBlank(canvas) {
  var blank = document.createElement("canvas"); //系统获取一个空canvas对象
  blank.width = canvas.width;
  blank.height = canvas.height;
  return canvas.toDataURL() === blank.toDataURL(); //比较值相等则为空
}

/**
 * 校验签字图片是不是太小
 * @param imgSrc
 * @param size
 * @returns
 */
export function validateImageSize(imgSrc, size = 10) {
  let img = new Image();
  img.src = imgSrc;
  return new Promise((resolve, reject) => {
    img.onload = (e) => {
      let target = e.target || e.srcElement;
      let width = target.width;
      let height = target.height;
      if (width < size && height < size) {
        reject({
          description: "签字太小了",
        });
      } else {
        resolve(true);
      }
    };
  });
}
//
/**
 * 裁剪 canvas 的指定区域
 * @param param0
 * @returns
 */
export function cropCanvas({
                             canvas, // 需要裁剪的canvas
                             sx, // 裁剪开始点的x
                             sy, // 裁剪开始点的y
                             sw, // 裁剪宽
                             sh, // 裁剪高
                           }) {
  if (!canvas) return null;
  let newCanvas = document.createElement("canvas");
  let newCxt = newCanvas.getContext("2d");
  let gap = 4; // 签字留空隙
  newCanvas.width = sw + 2 * gap;
  newCanvas.height = sh + 2 * gap;
  let imgData = canvas.getContext("2d")?.getImageData(sx - gap, sy - gap, newCanvas.width, newCanvas.height);
  newCxt?.putImageData(imgData, 0, 0);
  return newCanvas;
}

export function getSignImgPngSrc({
                                   canvas, // 需要裁剪的canvas
                                   sx, // 裁剪开始点的x
                                   sy, // 裁剪开始点的y
                                   sw, // 裁剪宽
                                   sh, // 裁剪高
                                 }) {
  let newCanvas = cropCanvas({
    canvas, // 需要裁剪的canvas
    sx, // 裁剪开始点的x
    sy, // 裁剪开始点的y
    sw, // 裁剪宽
    sh, // 裁剪高
  });
  console.log({newCanvas});
  if (!newCanvas) return null;
  // if (this.isMobile && this.height > this.width) {
  //   let canvas1 = document.createElement('canvas'), cxt1 = canvas1.getContext('2d');
  //   canvas1.width = canvas.height;
  //   canvas1.height = canvas.width;
  //   let xpos = canvas1.width / 2, ypos = canvas1.height / 2;
  //   cxt1.translate(xpos, ypos);
  //   cxt1.rotate(-90 * Math.PI / 180);
  //   cxt1.translate(-xpos, -ypos);
  //   cxt1.drawImage(canvas, xpos - canvas.width / 2, ypos - canvas.height / 2);
  //
  //   return this.isCanvasBlank(canvas1) ? null : canvas1.toDataURL('image/png');
  // }
  // return isCanvasBlank(newCanvas) ? null : newCanvas.toDataURL("image/png");
  if(isCanvasBlank(newCanvas)){
    return null
  }else{
    return new Promise((resolve) => {
      newCanvas.toBlob(resolve)
    })
  }
}
