// AnnualProductionAgreement
// 人事（安全生产协议）
import axios from "./axios.config"
// 获取显示字段
export const getAnnualProductionAgreementFields = (tableId="annualProductionAgreement")=>axios.get(`/jaxrs/annual_production_agreement/fields?tableId=${tableId}`)
// 查询条件
export const getAnnualProductionAgreementConditions = ()=>axios.get('/jaxrs/annual_production_agreement/conditions')
// 查询
export const getAnnualProductionAgreementPage = (data)=>axios.post('/jaxrs/annual_production_agreement/page',data)
// 详情
export const getAnnualProductionAgreementDetail = (data)=>axios.get(`/jaxrs/annual_production_agreement/${data.id}`)
// 保存
export const addAnnualProductionAgreement = (data)=>axios.post(`/jaxrs/annual_production_agreement`,data)
// 修改
export const editAnnualProductionAgreement = (data)=>axios.put(`/jaxrs/annual_production_agreement`,data)
// 删除
export const deleteAnnualProductionAgreement = (data)=>axios.delete(`/jaxrs/annual_production_agreement/${data.id}`)