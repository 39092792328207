import axios from './axios.config'
//获取入库单显示字段
export const getStockInFields = (tableId='stockIn') =>axios.get(`/jaxrs/stock_in/fields?tableId=${tableId}`)
//获取入库查询条件
export const getStockInConditions = () =>axios.get('/jaxrs/stock_in/conditions')
//获取入库单分页数据
export const getStockInPage = (data) =>axios.post(`/jaxrs/stock_in/page`,data)
//入库单详情页(上一页)
export const getStockInDetail = (data) =>{
    let url
    if(data.oper){
        url = `/jaxrs/stock_in/${data.id}?oper=${data.oper}`
    }else{
        url = `/jaxrs/stock_in/${data.id}`
    }
    return axios.get(url)
}
//修改入库单
export const editStockIn = (data) =>axios.put(`/jaxrs/stock_in`,data)
//新增入库单
export const addStockIn = (data) =>axios.post(`/jaxrs/stock_in`,data)
//删除入库单
export const deleteStockIn = (data) =>axios.delete(`/jaxrs/stock_in/${data.id}`)
//导出
export const exportStockIn = (data) =>
    axios.post(`/jaxrs/stock_in/export`,data,{
        responseType:'blob'
    })