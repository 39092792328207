<script>
import { defineComponent } from "vue";
import InputNumberBetween from "../InputNumber/InputNumberBetween.vue";
import DatePicker from "../DatePicker/DatePicker.vue";
import SelectV2 from "../SelectMultiple/SelectV2.vue";
import { conditionTypeMap } from "./conditionTypeData";
import _ from "lodash";

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Condition",
  components: {
    SelectV2,
    InputNumberBetween,
    DatePicker
  },
  data() {
    return {
      searchContent: "",
      visible: false,
      hasInit: false,
      initLoading: false,
      baseList: [],
      typeMap: conditionTypeMap,
      operator: "and",
      operatorList: [
        {
          name: "所有",
          value: "and"
        },
        {
          name: "任意一个",
          value: "or"
        }
      ],
      popoverPlacement: "bottom-start",
      searchParamsName: "",
      isFirstSearchParams: false,
      searchParamsVisible: false,
      searchParamsHistoryVisible: false
    };
  },
  props: {
    conditionOptions: {
      type: Array,
      default() {
        return [];
      }
    },
    tableId: {
      type: String
    },
    conditionListHeight: {
      type: Number,
      default() {
        return 180;
      }
    },
    defaultConditionsItem:{}
  },
  computed: {
    selectList() {
      return this.baseList.filter((item) => {
        if (!item.desc) {
          return false;
        }
        return !!item.desc.includes(this.searchContent);
      });
    },
    checkList() {
      let list = this.baseList.filter((item) => item.check === true);
      return list;
    },
    operatorName() {
      let operatorName = this.operatorList.find((item) => {
        return item.value === this.operator;
      }).name;
      return operatorName;
    },
    conditionListStyle() {
      return {
        "max-height": `${this.conditionListHeight}px`
      };
    },
    searchParamsHistory() {
      return this.searchParamsStore.getSearchParamsHistoryByTableId(this.tableId);
    },
    showSearchParamsHistory() {
      return this.searchParamsHistory.length > 0;
    }
  },
  watch: {
    conditionOptions: {
      handler(val) {
        this.baseList = val.map((item) => {
          let baseItem = {
            ..._.cloneDeep(item),
            check: false
          };
          this.setComponent(baseItem, 0);
          return baseItem;
        });
        this.getFieldMap(this.baseList);
      },
      deep: true,
      immediate: true
    },
    defaultConditionsItem() {
      this.initDefaultConditionsItem()
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init(){
      this.initDefaultConditionsItem()
    },
    initDefaultConditionsItem(){
      if(this.defaultConditionsItem){
        this.checkSearchParams(this.defaultConditionsItem);
        this.handleSearchClick()
      }
    },
    getFieldMap(list) {
      let map = {};
      list.forEach((item) => {
        item.fields.forEach((fItem) => {
          if (!map[fItem.type]) {
            map[fItem.type] = {};
          }
          map[fItem.type][fItem.operator] = {
            name: fItem.operatorName
          };
        });
      });
    },
    async handleTriggerClick() {
      this.visible = !this.visible;
      await this.initConditionCustom()
      if (this.visible) {
        this.$nextTick(() => {
          let element = this.$refs.popover;
          let handleClickOutside = (event) => {
            let dom = element.popperRef.contentRef;
            let array = []
            let poppers = Array.from(document.getElementsByClassName('dubhe-popper'))
            let dialogs = Array.from(document.getElementsByClassName('dubhe-dialog'))
            let overlays = Array.from(document.getElementsByClassName('dubhe-overlay'))
            array.push(...poppers, ...dialogs, ...overlays)
            if (array.some(item => {
              return item.contains(event.target)
            })) {
            } else {
              this.visible = false
              document.removeEventListener("mousedown", handleClickOutside);
            }
            // if (dom && !dom.contains(event.target)) {
            //   this.visible = false
            //   document.removeEventListener("mousedown", handleClickOutside);
            // }else{
            //   console.log({target:event.target});
            // }
          };
          document.addEventListener("mousedown", handleClickOutside);
        })
      }
    },
    getCheckField(item, index) {
      return item.fields[index];
    },
    handleCheckClick(item) {
      item.check = true;
      this.setComponent(item, 0);
    },
    setComponent(item, index) {
      item.checkFieldIndex = index;
      this.resetComponent(item, index);
    },
    resetComponent(item, index) {
      let { type, operator } = item.fields[index];
      try{
        let component = _.cloneDeep(this.typeMap[type][operator]);
        // if(component.component === "SelectV2"){
        //   component.props.teleported = false
        // }
        // console.log(type,operator);
        // console.log(component);
        if (component.props && item.props) {
          component.props = {
            ...component.props,
            ...item.props
          };
        }
        item.component = component;
      }catch (e){
        console.error(`搜索条件错误，${type},${operator}`)
        console.error(item)
      }
    },
    handleFieldCheckClick(item, index, checkIndex) {
      this.setComponent(item, index);
      this.$refs.field[checkIndex].hide();
    },
    handleDeleteClick(item) {
      item.check = false;
    },
    handleClearClick() {
      this.checkList.forEach((item) => {
        this.resetComponent(item, item.checkFieldIndex);
      });
    },
    handleResetClick() {
      this.baseList.forEach((item) => {
        this.handleDeleteClick(item);
      });
    },
    getSearchParams(useEmpty = false) {
      let list = this.checkList.map((item) => {
        let { field, operator, isExt } = item.fields[item.checkFieldIndex];
        let cItem = {
          alias: item.alias,
          field,
          operator,
          isExt
        };
        if (item.component.component) {
          if (Array.isArray(item.component.params)) {
            cItem.params = item.component.params;
          } else {
            cItem.params = [item.component.params];
          }
        } else {
          cItem.params = [];
        }
        return cItem;
      });
      if (!useEmpty) {
        list = list.filter((item) => {
          if (_.isBoolean(item.params[0]) || _.isNumber(item.params[0])) {
            return true;
          }
          return item.params[0];
        });
      }
      let obj = {
        conditions: list,
        operator: this.operator
      };
      return obj;
    },
    handleSearchClick() {
      let obj = this.getSearchParams();
      this.visible = false;
      this.$emit("callback", obj);
    },
    handleSaveSearchClick() {
      let obj = this.getSearchParams(true);
      if (!this.searchParamsName) {
        this.$message.warning("请输入模板名称");
        return;
      }
      let searchParamsItem = {
        name: this.searchParamsName,
        params: obj,
        tableId: this.tableId,
        isFirst: this.isFirstSearchParams
      };
      this.searchParamsStore.addSearchParamsHistory(searchParamsItem);
      this.searchParamsName = "";
      this.isFirstSearchParams = false;
      this.$message.success("保存成功");
      this.searchParamsVisible = false;
    },
    deleteSearchParams(item) {
      this.searchParamsStore.deleteSearchParamsHistory(item);
    },
    async checkSearchParams(item) {
      this.searchParamsHistoryVisible = false;
      await this.initConditionCustom()
      this.handleResetClick();
      let { conditions, operator } = item.params;
      this.operator = operator;
      conditions.forEach(cItem => {
        this.setSearchParamsToBaseList(cItem);
      });
    },
    setSearchParamsToBaseList(item) {
      let checkItem = this.baseList.find(bItem => {
        return bItem.alias === item.alias && bItem.field === item.field;
      });
      if (checkItem) {
        checkItem.check = true;
        let index = checkItem.fields.findIndex(fItem => fItem.operator === item.operator);
        this.setComponent(checkItem, index);
        if (checkItem.component.component) {
          if (Array.isArray(checkItem.component.params)) {
            checkItem.component.params = item.params;
          } else {
            checkItem.component.params = item.params[0];
          }
        }
      }
    },
    handleOperatorClick(item) {
      this.operator = item.value;
      this.$refs.operator.hide();
    },
    async initConditionCustom() {
      if (!this.hasInit) {
        this.hasInit = true
        this.initLoading = true
        let list = this.conditionOptions.filter(item=>{
          return item?.props?.type && item.props.type === 'list'
        })
        await Promise.all(list.map(async (item) => {
          let methodParams = _.cloneDeep(item.props.methodParams);
          if (item.props.listConditions) {
            methodParams = item.props.listConditions;
          }
          return item.props.list = await item.props.method(...methodParams);
        }))
        this.initLoading = false
      }
    }
  }
});
</script>

<template>
  <div class="condition-wrapper">
    <!--    <div class="overlay" v-if="visible" @click="handleTriggerClick"></div>-->
    <el-badge :value="checkList.length" :hidden="checkList.length === 0" type="primary">
      <el-button ref="buttonRef" link type="primary" @click="handleTriggerClick">
        <el-icon :size="18" color="#000">
          <Filter />
        </el-icon>
      </el-button>
    </el-badge>
    <!--    :visible="visible"-->
    <!--    trigger="click"-->
    <el-popover
      ref="popover"
      :placement="popoverPlacement"
      :visible="visible"
      :popper-style="{
        padding: 0
      }"
      width="800"
      :virtual-ref="$refs.buttonRef"
      :enterable="true"
      :teleported="true"
    >
      <template #default>
        <div class="condition" v-loading="initLoading">
          <div class="header">筛选出符合以下
            <el-popover
              trigger="click"
              ref="operator"
              :placement="popoverPlacement"
              :teleported="false"
              width="100"
            >
              <template #reference>
                <el-button link type="primary"
                >{{ operatorName }}
                  <el-icon>
                    <ArrowDown />
                  </el-icon>
                </el-button>
              </template>
              <template #default>
                <div class="select-wrapper">
                  <div class="select-list" :style="conditionListStyle">
                    <div
                      class="select-item"
                      v-for="(oItem) in operatorList"
                      :key="oItem.value"
                      @click="handleOperatorClick(oItem)"
                    >
                      {{ oItem.name }}
                    </div>
                  </div>
                </div>
              </template>
            </el-popover>
            条件的数据
          </div>
          <div class="list" v-if="checkList.length > 0" :style="conditionListStyle">
            <div class="list-item" v-for="(item, index) in checkList" :key="item.desc">
              <div class="name">{{ item.desc }}</div>
              <div class="dropdown">
                <el-popover
                  trigger="click"
                  ref="field"
                  :placement="popoverPlacement"
                  :teleported="false"
                  width="400"
                >
                  <template #reference>
                    <el-button link type="primary"
                    >{{ getCheckField(item, item.checkFieldIndex).operatorName }}
                      <el-icon>
                        <ArrowDown />
                      </el-icon>
                    </el-button>
                  </template>
                  <template #default>
                    <div class="select-wrapper">
                      <div class="select-list" :style="conditionListStyle">
                        <div
                          class="select-item"
                          v-for="(fItem, fIndex) in item.fields"
                          :key="fItem.operatorName"
                          @click="handleFieldCheckClick(item, fIndex, index)"
                        >
                          {{ fItem.operatorName }}
                        </div>
                      </div>
                    </div>
                  </template>
                </el-popover>
              </div>
              <div class="input">
                <template v-if="item?.component?.component">
                  <component
                    :key="item.checkFieldIndex"
                    :is="item.component.component"
                    v-bind="item.component.props"
                    v-model="item.component.params"
                  ></component>
                </template>
              </div>
              <div class="delete">
                <el-button
                  icon="Delete"
                  type="danger"
                  link
                  @click="handleDeleteClick(item)"
                ></el-button>
              </div>
            </div>
          </div>
          <div class="select">
            <el-popover
              trigger="click"
              :placement="popoverPlacement"
              :enterable="true"
              :teleported="false"
              width="400"
            >
              <template #reference>
                <el-button link type="primary" icon="Plus"> 添加过滤条件</el-button>
              </template>
              <template #default>
                <div class="select-wrapper">
                  <div class="select-input">
                    <el-input placeholder="输入过滤条件名称" v-model="searchContent"></el-input>
                  </div>
                  <div class="select-list" :style="conditionListStyle">
                    <div
                      class="select-item"
                      v-for="(item) in selectList"
                      :key="item.desc"
                      :class="{ disabled: item.check }"
                      @click="handleCheckClick(item)"
                    >
                      {{ item.desc }}
                    </div>
                  </div>
                </div>
              </template>
            </el-popover>
            <template v-if="tableId">
              <el-popover
                trigger="click"
                :placement="popoverPlacement"
                :enterable="true"
                :teleported="false"
                v-model:visible="searchParamsVisible"
                width="400"
              >
                <template #reference>
                  <el-button link type="primary" icon="Finished">保存模板</el-button>
                </template>
                <template #default>
                  <div class="select-wrapper">
                    <div class="select-input">
                      <el-form>
                        <el-form-item label="模板名称">
                          <el-input placeholder="请输入模板名称" v-model="searchParamsName"></el-input>
                        </el-form-item>
                        <el-form-item label="是否首选">
                          <el-switch v-model="isFirstSearchParams" />
                        </el-form-item>
                      </el-form>
                    </div>
                    <div class="flex-row-end-col-center margin-top-10">
                      <el-button type="primary" @click="handleSaveSearchClick">确认</el-button>
                    </div>
                  </div>
                </template>
              </el-popover>
              <el-popover
                v-if="showSearchParamsHistory"
                trigger="click"
                :placement="popoverPlacement"
                :enterable="true"
                :teleported="false"
                v-model:visible="searchParamsHistoryVisible"
                width="400"
              >
                <template #reference>
                  <el-button link type="primary" icon="Collection">过滤条件模板</el-button>
                </template>
                <template #default>
                  <div class="select-wrapper">
                    <div class="select-list" :style="conditionListStyle">
                      <div class="select-item flex-row-between-col-center"
                           v-for="(sItem, sIndex) in searchParamsHistory"
                           :key="sIndex" @click.stop="checkSearchParams(sItem)">
                        <div class="select-item-content">
                          <el-tag v-if="sItem.isFirst">首选</el-tag>
                          <span>{{ sItem.name }}</span>
                        </div>
                        <div class="select-item-content">
                          <el-button link type="danger" icon="Delete"
                                     @click.stop="deleteSearchParams(sItem)"></el-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </el-popover>
            </template>
          </div>
          <div class="footer">
            <div>
              <el-button type="primary" link icon="Delete" @click="handleClearClick"
              >清空值
              </el-button>
              <el-button type="primary" link icon="Refresh" @click="handleResetClick"
              >重置
              </el-button>
            </div>
            <div>
              <el-button type="primary" icon="search" @click="handleSearchClick">筛选</el-button>
            </div>
          </div>
        </div>
      </template>
    </el-popover>
  </div>
</template>

<style scoped lang="scss">
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
}

.condition-wrapper {
  position: relative;
}

.condition {
  width: 100%;
  font-size: 14px;
  overflow: hidden;

  .header {
    padding: 8px;
    //border-top: 1px solid var(--dubhe-border-color-dark);
    box-shadow: var(--dubhe-box-shadow-light);
    display: flex;
    align-items: center;
  }

  .list {
    max-height: 250px;
    overflow: auto;
    //display: flex;
    //flex-direction: column;
    padding: 4px 0 0;

    .list-item {
      height: 40px;
      display: flex;
      align-items: center;
      padding: 0 15px;

      .name {
        //padding-left: 10px;
        width: 200px;
      }

      .dropdown {
        flex: 1;
      }

      :deep(.input) {
        width: 400px;

        & > * {
          width: 100%;
        }
      }

      .delete {
        padding-left: 10px;
      }
    }
  }

  .select {
    padding: 4px 15px;

    & > * {
      margin-right: 10px;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border-top: 1px solid var(--dubhe-border-color-dark);
  }
}

.select-wrapper {
  .select-input {
    //padding: 10px;
  }

  .select-list {
    overflow: auto;

    .select-item {
      font-size: 14px;
      line-height: 32px;
      color: var(--dubhe-text-color-regular);
      cursor: pointer;

      &.disabled {
        color: var(--dubhe-text-color-disabled);
        cursor: default;
      }

      .select-item-content {
        display: flex;
        align-items: center;

        & > span {
          margin-left: 10px;
        }
      }
    }

    .flex-row-between-col-center {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
</style>
