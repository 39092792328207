import axios from './axios.config'
// 获取材料分类显示字段
export const getMaterialStockFields = (tableId='materialStock') => axios.get(`/jaxrs/material_stock/fields?tableId=${tableId}`)
// 获取材料分类查询条件
export const getMaterialStockConditions = () => axios.get('/jaxrs/material_stock/conditions')
// 获取材料分类分页数据
export const getMaterialStockPage = (data) => axios.post('/jaxrs/material_stock/page', data)
// 获取材料分类详情
export const getMaterialStockDetail = (data) => axios.get(`/jaxrs/material_stock/${data.id}`)
//导出
export const exportMaterialStock = (data) =>
  axios.post(`/jaxrs/material_stock/export`, data, {
    responseType: 'blob'
  })
//  根据仓库ID和材料ID获取材料库存
export const getMaterialStockByStoreIdAndMaterialId = (data) => axios.get(`/jaxrs/material_stock/store_id/${data.storeId}/material_id/${data.materialId}`)