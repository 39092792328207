import axios from './axios.config'
// 车辆违章
// 获取显示字段
export const getVehicleIllegalFields = (tableId="vehicleInsurance") =>axios.get(`/jaxrs/vehicle_illegal/fields?tableId=${tableId}`)
// 获取查询条件
export const getVehicleIllegalConditions = () =>axios.get('/jaxrs/vehicle_illegal/conditions')
// 查询
export const getVehicleIllegalPage = (data) =>axios.post('/jaxrs/vehicle_illegal/page',data)
// 详情
export const getVehicleIllegalDetail = (data) =>{
    let url
    if(data.oper){
        url=`/jaxrs/vehicle_illegal/${data.id}?oper=${data.oper}`
    }else{
        url=`/jaxrs/vehicle_illegal/${data.id}`
    }
    return axios.get(url)
}
// 保存（新增）
export const addVehicleIllegal = (data) =>axios.post(`/jaxrs/vehicle_illegal`,data)
// 修改
export const editVehicleIllegal = (data) =>axios.put(`/jaxrs/vehicle_illegal`,data)
// 删除
export const deleteVehicleIllegal = (data) =>axios.delete(`/jaxrs/vehicle_illegal/${data.id}`)
// 导出
export const exportVehicleIllegal = (data) =>
    axios.post(`/jaxrs/vehicle_illegal/export`,data,{
        responseType:'blob'
    })
// 处理
export const detalVehicleIllegal = (data)=>axios.put('/jaxrs/vehicle_illegal/handle',data)