<script>
import _ from 'lodash'

export default {
  name: 'ImageItem',
  inject: ['useItem', 'urlKey', 'urlList', 'disabled', 'downloadItem', 'deleteItem'],
  props: {
    item: {},
    index: {}
  },
  computed: {
    imageSrc() {
      return this.useItem ? this.item : this.item[this.urlKey]
    },
    filterFileType() {
      if(this.useItem){
        return ''
      }else{
        let typeList = this.item.type.split('.')
        let type = _.toUpper(typeList[typeList.length - 1])
        return type
      }
    }
  },
  methods: {
    isImage(filename) {
      let list = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.ico']
      let name = _.toLower(filename)
      if (list.includes(name)) {
        return true
      }
      return false
    }
  }
}
</script>

<template>
  <div class="image-list-item">
    <el-image
      ref="image"
      :src="imageSrc"
      :preview-src-list="urlList"
      :initial-index="index"
      :infinite="false"
      fit="contain"
    >
      <template #error>
        <div class="no-image">
<!--          <i class="ri-file-line"></i>-->
          <div class="no-image-icon">
            <i class="ri-file-3-line"></i>
<!--            <span class="no-image-type">{{filterFileType}}</span>-->
          </div>
          <div class="no-image-name">{{item.label}}</div>
<!--          <div class="no-image-text">非常用图片类型</div>-->
<!--          <el-button type="primary" plain @click="downloadItem(item,index)">下载</el-button>-->
        </div>
      </template>
    </el-image>
    <div class="image-icons">
      <el-icon @click="downloadItem(item,index)">
        <Download />
      </el-icon>
      <el-icon @click="deleteItem(item,index)" v-if="!disabled">
        <Delete />
      </el-icon>
    </div>
  </div>
</template>

<style scoped lang="scss">
.image-list-item {
  display: inline-flex;
  position: relative;
  //height: 200px;
  height: 123.6px;
  width: 200px;
  margin: 10px;
  border-radius: 10px;
  border: 1px solid var(--dubhe-border-color);
  box-shadow: var(--dubhe-box-shadow);
  overflow: hidden;
  cursor: pointer;
  .no-image{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: var(--dubhe-fill-color-light);
    color: var(--dubhe-text-color-secondary);
    text-align: center;
    .no-image-icon{
      position: relative;
      .no-image-type{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      i{
        font-size: 40px;
        line-height: 1.2;
      }
    }
    //i{
    //  position: absolute;
    //  top: 50%;
    //  left: 50%;
    //  transform: translate(-50%, -50%);
    //  font-size: 80px;
    //  line-height: 1.2;
    //}
    .no-image-text{
      margin-bottom: 10px;
    }
  }
  & > img,
  & > .dubhe-image {
    width: 100%;
    height: 100%;
  }

  &:hover {
    .image-icons {
      opacity: 1;
    }
  }

  .image-icons {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #ffffff;
    font-size: 15px;
    opacity: 0;
    transition: all linear 0.2s;
    display: flex;

    .dubhe-icon {
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.2);

      & + .dubhe-icon {
        margin-left: 10px;
      }
    }
  }
}
</style>