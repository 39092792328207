<script>
import { defineComponent } from "vue";
import { getToPageXY, IsPC, getSignImgPngSrc, cropCanvas, isCanvasBlank } from "./signCanvas";
import dayjs from "dayjs";

/**
 *   clear, // 清空画布
 *   getSignPNGImgSrc, // 获取签字图片src地址 - 裁剪过的
 *   downLoadSignPNGImg, // 下载签字图片
 *   undo, // 撤销
 *   redo // 恢复
 */
export default defineComponent({
  name: "SignCanvas",
  data() {
    return {
      lineColor: "#000000",
      isMouseDown: false, // 鼠标是否按下
      tickTimer: null,
      resizeObserver: ResizeObserver,
      isMobile: false,
      rectBoundary: { // 签字的最大使用区域，用于裁剪，去掉周围的空白区域
        minX: 0,
        minY: 0,
        maxX: 0,
        maxY: 0
      },
      undoList: [], // 撤销
      redoList: [] // 恢复
    };
  },
  mounted() {
    this.init();
  },
  unmounted() {
    this.removeEvents();
  },
  methods: {
    // 给签字的边界赋值
    setRectBoundary(x, y) {
      let { minX, minY, maxX, maxY } = this.rectBoundary;
      this.rectBoundary.minX = x < minX ? x : minX;
      this.rectBoundary.minY = y < minY ? y : minY;
      this.rectBoundary.maxX = x > maxX ? x : maxX;
      this.rectBoundary.maxY = y > maxY ? y : maxY;
    },
    // 给签字的边界初值
    initRectBoundary() {
      let canvas = this.$refs.canvasDomRef;
      let max = 99999999;
      this.rectBoundary = {
        // minX: canvas.width,
        // minY: canvas.height,
        minX: 99999999,
        minY: 99999999,
        maxX: 0,
        maxY: 0
      };
    },
    setUndoList() {
      let canvas = this.$refs.canvasDomRef;
      let ctx = canvas.getContext("2d");
      this.undoList.push({
        imgData: ctx.getImageData(0, 0, canvas.width, canvas.height),
        rectBoundary: { // 记录此刻的签字边界
          ...this.rectBoundary
        }
      });
    },
    // 撤销
    undo() {
      if (this.undoList.length > 0) {
        this.redoList.push(this.undoList.pop());
      }
      this.reDrawCanvas();
    },
    // 恢复
    redo() {
      if (this.redoList.length > 0) {
        this.undoList.push(this.redoList.pop());
      }
      this.reDrawCanvas();
    },
    // 将历史记录绘制到画布中
    reDrawCanvas() {
      if (this.undoList.length) {
        let canvas = this.$refs.canvasDomRef;
        let ctx = canvas.getContext("2d");
        let record = this.undoList[this.undoList.length - 1];
        this.rectBoundary = record.rectBoundary; // 恢复此时的签字边界
        ctx.putImageData(record.imgData, 0, 0);
      } else { // 清空画布
        this.clear();
      }
    },
    // 清空历史记录
    clearUndoRedoList() {
      this.undoList = [];
      this.redoList = [];
    },
// 转为在canvas画布中的像素
    getCanvasPx({ x, y }) {
      let canvas = this.$refs.canvasDomRef;
      let { left, top } = canvas.getBoundingClientRect();
      return {
        x: x - left,
        y: y - top
      };
    },
    // 清空画布
    clear() {
      let canvas = this.$refs.canvasDomRef;
      if (!canvas) return;
      let ctx = canvas.getContext("2d");
      // ctx.save();
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      // ctx.restore();
      this.initRectBoundary(); // 清空签字的边界
    },
    // resize
    resizeHandle() {
      clearTimeout(this.tickTimer);
      this.tickTimer = setTimeout(() => {
        this.clear();
        this.clearUndoRedoList(); // 每次reize 清空历史记录 - 因为宽高改变恢复了也是变形的
        let canvas = this.$refs.canvasDomRef;
        if (!canvas) return;
        let parentNode = canvas.parentNode;
        let wd = parentNode.clientWidth;
        let ht = parentNode.clientHeight;
        canvas.width = wd;
        canvas.height = ht;
        // canvas.style.width = wd + 'px'
        // canvas.style.height = ht + 'px'
      }, 100);
    },
    // mousedowm
    downHandle(e) {
      this.isMouseDown = true;
      let canvas = this.$refs.canvasDomRef;
      let { x, y } = this.getCanvasPx(getToPageXY(e));
      let ctx = canvas.getContext("2d");
      ctx.beginPath();
      ctx.moveTo(x, y);
      this.setRectBoundary(x, y); // 存储签字的最大使用区域
    },
    // mousemove
    moveHandle(e) {
      if (!this.isMouseDown) return;
      let canvas = this.$refs.canvasDomRef;
      let { x, y } = this.getCanvasPx(getToPageXY(e));
      let ctx = canvas.getContext("2d");
      ctx.lineTo(x, y);
      ctx.strokeStyle = this.lineColor;
      // ctx.lineWidth = 2 * (window.devicePixelRatio || 1)
      ctx.lineWidth = 2;
      ctx.lineCap = "round";
      ctx.lineJoin = "round";
      //移动端去掉模糊提高手写渲染速度
      if (this.isMobile) {
        ctx.shadowBlur = 1;
        ctx.shadowColor = this.lineColor;
      }
      ctx.stroke();
      this.setRectBoundary(x, y); // 存储签字的最大使用区域
    },
    // mouseup
    upHandle() {
      this.isMouseDown = false;
      this.setUndoList();
    },
    addEvents() {
      let canvas = this.$refs.canvasDomRef;
      if (!canvas) return;
      if (this.isMobile) {
        canvas.addEventListener("touchstart", this.downHandle, false);
        canvas.addEventListener("touchmove", this.moveHandle, false);
        canvas.addEventListener("touchend", this.upHandle, false);
      } else {
        canvas.addEventListener("pointerdown", this.downHandle, false);
        canvas.addEventListener("pointermove", this.moveHandle, false);
        canvas.addEventListener("pointerup", this.upHandle, false);
      }

      // 和传统 window.resize不同 ResizeObserver 可以在div上监听resize
      // 1.指定resize事件
      this.resizeObserver = new ResizeObserver(this.resizeHandle); // 会在绘制前和布局后调用 resize 事件，因此不用提前调用 event_windowResize 方法
      // 2.指定该resize事件的触发dom
      this.resizeObserver.observe(canvas);
    },
    removeEvents() {
      clearTimeout(this.tickTimer);
      let canvas = this.$refs.canvasDomRef;
      if (!canvas) return;
      if (this.isMobile) {
        canvas.removeEventListener("touchstart", this.downHandle);
        canvas.removeEventListener("touchmove", this.moveHandle);
        canvas.removeEventListener("touchend", this.upHandle);
      } else {
        canvas.removeEventListener("pointerdown", this.downHandle);
        canvas.removeEventListener("pointermove", this.moveHandle);
        canvas.removeEventListener("pointerup", this.upHandle);
      }

      this.resizeObserver.unobserve(canvas); // 结束对指定 Element 的监听。
    },
    async getCanvasData(type) {
      let canvas = this.$refs.canvasDomRef;
      let { minX, minY, maxX, maxY } = this.rectBoundary;
      if (!maxY && !maxX) { // 未曾签字 - 提示
        this.$message({
          showClose: true,
          message: "请签字后继续",
          type: "warning"
        });
        return null;
      }
      let newCanvas = this.cropCanvas({
        canvas, // 需要裁剪的canvas
        sx: minX, // 裁剪开始点的x
        sy: minY, // 裁剪开始点的y
        sw: maxX - minX, // 裁剪宽
        sh: maxY - minY // 裁剪高
      });
      if (!newCanvas) return null;
      if (isCanvasBlank(newCanvas)) {
        return null;
      } else if (type === "png") {
        return newCanvas.toDataURL("image/png");

      } else if (type === "blob") {
        return new Promise((resolve) => {
          newCanvas.toBlob((blob) => {
            resolve(blob);
          });
        });
      } else if (type === "file") {
        return new Promise((resolve) => {
          newCanvas.toBlob((blob) => {
            let file = this.blobToFile(blob);
            resolve(file);
          });
        });
      }
    },
    blobToFile(blob, fileName) {
      let name = fileName ? fileName : dayjs(new Date()).format("YYYYMMDDHHmmss");
      let files = new File([blob], name + ".png", {
        type: blob.type,
        endings: "native"
      });
      return files;
    },
    // 裁剪复制canvas
    cropCanvas({
                 canvas, // 需要裁剪的canvas
                 sx, // 裁剪开始点的x
                 sy, // 裁剪开始点的y
                 sw, // 裁剪宽
                 sh // 裁剪高
               }) {
      if (!canvas) return null;
      let newCanvas = document.createElement("canvas");
      let newCxt = newCanvas.getContext("2d");
      let gap = 4; // 签字留空隙
      newCanvas.width = sw + 2 * gap;
      newCanvas.height = sh + 2 * gap;
      let imgData = canvas.getContext("2d")?.getImageData(sx - gap, sy - gap, newCanvas.width, newCanvas.height);
      newCxt?.putImageData(imgData, 0, 0);
      return newCanvas;
    },
    // 下载签字图片
    downLoadSignPNGImg() {
      let url = this.getCanvasData("png");
      if (!url) return;
      // 创建a标签，用于跳转至下载链接
      const tempLink = document.createElement("a");
      tempLink.style.display = "none";
      tempLink.href = url;
      tempLink.setAttribute("download", url);
      // 兼容：某些浏览器不支持HTML5的download属性
      if (typeof tempLink.download === "undefined") {
        tempLink.setAttribute("target", "_blank");
      }
      // 挂载a标签
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
    },
    init() {
      this.$nextTick(() => {
        this.isMobile = !IsPC();
        this.addEvents();
        this.clear();
      });
    }
  }
});
</script>

<template>
  <canvas class="sign-canvas" ref="canvasDomRef">您的浏览器不支持 HTML5 canvas标签</canvas>
</template>

<style scoped lang="scss">
.sign-canvas {
  width: 100%;
  height: 100%;
}
</style>