<script>
import { defineComponent } from "vue";
import { setTableColumnConfig, setTableColumnConfigBatch } from "@/axios/table";
import _ from "lodash";

export default defineComponent({
  name: "ColCheckBox",
  emits: ["update:list"],
  data() {
    return {
      tableColList: []
    };
  },
  props: {
    tableId: {
      type: String
    },
    list: {
      type: Array
    }
  },
  watch: {
    list: {
      handler(val) {
        this.tableColList = val.map((item) => {
          let newItem = _.cloneDeep(item);
          if ("show" in newItem) {
          } else {
            newItem.show = true;
          }
          return newItem;
        });
      },
      immediate: true,
      deep: true
    }
  },
  computed: {},
  methods: {
    showChange(column) {
      this.$emit("update:list", this.tableColList);
      this.$nextTick(() => {
        if (this.tableId) {
          let reqData = {
            tableId: this.tableId,
            column: column.name,
            show: column.show,
            width: column.width
          };
          setTableColumnConfig(reqData);
        }
      });
    },
    sortChange(index, up) {
      let changeIndex = up ? index - 1 : index + 1;
      if (changeIndex < 0 || changeIndex >= this.tableColList.length) {
        return;
      }
      let exchangedItem = this.tableColList[index];
      let beExchangedItem = this.tableColList[changeIndex];
      let beExchangedOrder = beExchangedItem.order;
      beExchangedItem.order = exchangedItem.order;
      exchangedItem.order = beExchangedOrder;
      this.tableColList[index] = beExchangedItem;
      this.tableColList[changeIndex] = exchangedItem;
      this.$emit("update:list", this.tableColList);
      let configArray = [exchangedItem, beExchangedItem];
      let reqData = {
        tableId: this.tableId,
        configs: configArray.map(item => {
          return {
            tableId: this.tableId,
            column: item.name,
            show: item.show,
            width: item.width,
            order: item.order
          };
        })
      };
      setTableColumnConfigBatch(reqData);
    }
  }
});
</script>

<template>
  <el-dropdown trigger="click" placement="bottom-start">
    <el-button link type="primary">
      <el-icon :size="18" color="#000">
        <View />
      </el-icon>
    </el-button>
    <template #dropdown>
      <div class="table-show-check">
        <div class="check-content" v-for="(item,index) in tableColList" :key="item.name">
          <span>
            <el-switch v-model="item.show" @change="showChange(item)"></el-switch>
            <span class="check-content-label">{{ item.label }}</span>
          </span>
          <span class="button-group" v-if="tableId">
            <el-button-group>
              <el-button link type="primary" icon="CaretTop" @click.stop="sortChange(index,true)"
                         :disabled="index===0"></el-button>
              <el-button link type="primary" icon="CaretBottom" @click.stop="sortChange(index,false)"
                         :disabled="index===tableColList.length-1"></el-button>
            </el-button-group>
          </span>
        </div>
      </div>
    </template>
  </el-dropdown>
</template>

<style scoped lang="scss">
.table-show-check {
  max-height: 300px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 0 10px;

  & > * {
    flex-shrink: 0;
  }

  :deep(.dubhe-checkbox) {
    margin-right: 0;

    .dubhe-checkbox__label {
      flex: 1;
    }
  }
}

.check-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .check-content-label {
    margin-left: 10px;
  }

  .button-group {
    margin-left: 10px;
  }
}
</style>