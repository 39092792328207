import axios from './axios.config'
// 售后二维码ServiceCode
// 获取显示字段
export const getServiceCodeFields = (tableId='serviceCode') =>axios.get(`/jaxrs/service_code/fields?tableId=${tableId}`)
// 获取查询条件
export const getServiceCodeConditions = ()=>axios.get('/jaxrs/service_code/conditions')
// 查询
export const getServiceCodePage = (data) =>axios.post('/jaxrs/service_code/page',data)
// 详情
export const getServiceCodeDetail = (data) =>axios.get(`/jaxrs/service_code/${data.id}`)
// 保存
export const addServiceCode = (data) =>axios.post(`/jaxrs/service_code`,data)
// 修改
export const editServiceCode = (data) =>axios.put(`/jaxrs/service_code`,data)
// 删除
export const deleteServiceCode = (data) =>axios.delete(`/jaxrs/service_code/${data.id}`)
// 获取项目主材
export const getMaterialsByProject = (data) =>axios.get(`/jaxrs/service_code/get_materials_by_project/${data.ids}`)
// 获取项目关联的维修工单
export const getRepairWorkOrderByProject = (data) =>axios.get(`/jaxrs/service_code/get_repair_work_order_by_project/${data.ids}`)
// 获取项目关联维保工单
export const getMaintenanceWorkOrderByProject = (data) =>axios.get(`/jaxrs/service_code/get_maintenance_work_order_by_project/${data.ids}`)
// 获取项目关联的检测报告
export const getInspectionReportsByProject = (data) =>axios.get(`/jaxrs/service_code/get_inspection_reports_by_project/${data.ids}`)