import { selectListMixins } from './selectListMixins'
import { commonMixins } from './commonMixins'

export const detailMixins = {
  mixins: [selectListMixins, commonMixins],
  data() {
    return {
      loading: false
    }
  },
  props: {
    show: {
      type: Boolean
    },
    showEditButton:{
      default(){
        return false
      }
    }
  },
  computed: {
    showDrawer: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    },
    showDetailEditButton(){
      if(this?.editForm?.auditStatus && (this.editForm.auditStatus === 'draft' || this.editForm.auditStatus === 'reject')){
        return true
      }
      return false
    }
  },
  mounted() {},
  methods: {
    // 上一条
    handlePrevClick() {
      this.getDetail('prev')
    },
    // 下一条
    handleNextClick() {
      this.getDetail('next')
    },
    goEditDialog(){
      this.componentStore.showEditDrawer()
    }
  }
}
