<script>
import { defineComponent } from 'vue'
import { getViewBundle, getViewExecute } from '@/axios'
import TrajectoryDialog from './TrajectoryDialog.vue'

export default defineComponent({
  name: 'UserDialog',
  components: { TrajectoryDialog },
  emits: ['update:show'],
  data() {
    return {
      loading: false,
      activeTab: 'current',
      workOrderViewId: 'DD_WORK_ORDER_CIVIL_02',
      currentList: [],
      historyList: [],
      trajectoryShow:false,
      trajectoryData:{}
    }
  },
  props: {
    userData: {
      type: Object
    },
    show: {
      type: Boolean
    }
  },
  computed: {
    showDialog: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    }
  },
  watch: {
    userData: {
      handler(val) {
        this.init()
      },
      immediate: false,
      deep: true
    }
  },
  methods: {
    close() {
      this.showDialog = false
    },
    async init() {
      this.loading = true
      await Promise.all([this.initCurrentList(), this.initHistoryList()]).then((res)=>{
      }).finally(() => {
        this.loading = false
      })
    },
    async initCurrentList() {
      let reqData = {
        filterList: [
          {
            title: '接单员',
            value: this.userData.name,
            otherValue: '',
            path: 'worker.*.name',
            formatType: 'textValue',
            logic: 'and',
            comparison: 'like'
          },
          {
            title: '工单状态',
            value: '服务中',
            path: 'status',
            formatType: 'textValue',
            logic: 'and',
            comparison: 'equals'
          }
        ]
      }
      let list = await this.getViewList(reqData)
      this.currentList = list
    },
    async initHistoryList() {
      let reqData = {
        filterList: [
          {
            title: '接单员',
            value: this.userData.name,
            otherValue: '',
            path: 'worker.*.name',
            formatType: 'textValue',
            logic: 'and',
            comparison: 'like'
          }
        ]
      }
      let list = await this.getViewList(reqData)
      this.historyList = list
    },
    async getViewList(reqData) {
      // TODO:如需重新启用，接自建表工单
      return []
      // return new Promise((resolve) => {
      //   let key = ''
      //   let valueList = []
      //   getViewBundle(this.workOrderViewId, reqData)
      //     .then((res) => {
      //       if (res) {
      //         key = res.data.key
      //         valueList = res.data.valueList
      //       }
      //     })
      //     .catch(()=>{})
      //     .finally(async () => {
      //       if(valueList.length === 0){
      //         resolve([])
      //         return
      //       }
      //       let reqDataExecute = {
      //         key: key,
      //         bundleList: valueList
      //       }
      //       await getViewExecute(this.workOrderViewId, reqDataExecute)
      //         .then((res) => {
      //           if (res) {
      //             let list = res.data.grid.map((item) => {
      //               return item.data
      //             })
      //             resolve(list)
      //           }
      //         })
      //         .catch(()=>{})
      //     })
      // })
    },
    showTrajectory(item) {
      this.trajectoryData = {
        person: this.userData.id,
        day: item.appointmentDate
      }
      this.trajectoryShow = true
    }
  }
})
</script>

<template>
  <div class="user-dialog" v-if="show" v-loading="loading">
    <div class="detail-title">
      <div class="detail-title-content">人员详情</div>
      <div class="detail-title-close" @click="close">
        <el-icon>
          <Close />
        </el-icon>
      </div>
    </div>
    <div class="detail-header">
      <div class="detail-header-image">
        <img v-if="userData.iconLdpi" :src="`data:image/png;base64,${userData.iconLdpi}`" alt="" />
        <img v-else src="@/assets/image/user_default.png" alt="" />
      </div>
      <div class="detail-header-content">
        <div class="detail-header-name">{{ userData.name }}</div>
        <div>
          <el-tag type="primary">已派工</el-tag>
        </div>
      </div>
    </div>
    <el-divider></el-divider>
    <el-tabs v-model="activeTab">
      <el-tab-pane label="当前任务" name="current">
        <div class="order-wrapper">
          <el-collapse accordion>
            <el-collapse-item v-for="(item, index) in currentList" :key="index">
              <template #title>
                <div class="order-title">
                  <span class="order-title-text">{{ item.projectName }}</span>
                  <span class="order-title-time">{{ item.appointmentTime }}</span>
                </div>
              </template>
              <template #default>
                <div class="order-detail">
                  <div class="order-item">
                    <div class="order-label">接件单号</div>
                    <div class="order-value">{{ item.code }}</div>
                  </div>
                  <div class="order-item">
                    <div class="order-label">用户姓名</div>
                    <div class="order-value">{{ item.customerName }}</div>
                  </div>
                  <div class="order-item">
                    <div class="order-label">联系地址</div>
                    <div class="order-value">{{ item.address }}</div>
                  </div>
                  <div class="order-item">
                    <div class="order-label">联系电话</div>
                    <div class="order-value">{{ item.phone }}</div>
                  </div>
                  <div class="order-item">
                    <div class="order-label">预约时间</div>
                    <div class="order-value">{{ item.appointmentTime }}</div>
                  </div>
                  <div class="order-button">
                    <el-button type="primary" size="small" @click="showTrajectory(item)"
                      >当天人员轨迹</el-button
                    >
                  </div>
                </div>
              </template>
            </el-collapse-item>
          </el-collapse>
        </div>
      </el-tab-pane>
      <el-tab-pane label="历史任务" name="history">
        <div class="order-wrapper">
          <el-collapse accordion>
            <el-collapse-item v-for="(item, index) in historyList" :key="index">
              <template #title>
                <div class="order-title">
                  <span class="order-title-text">{{ item.projectName }}</span>
                  <span class="order-title-time">{{ item.appointmentTime }}</span>
                </div>
              </template>
              <template #default>
                <div class="order-detail">
                  <div class="order-item">
                    <div class="order-label">接件单号</div>
                    <div class="order-value">{{ item.code }}</div>
                  </div>
                  <div class="order-item">
                    <div class="order-label">用户姓名</div>
                    <div class="order-value">{{ item.customerName }}</div>
                  </div>
                  <div class="order-item">
                    <div class="order-label">联系地址</div>
                    <div class="order-value">{{ item.address }}</div>
                  </div>
                  <div class="order-item">
                    <div class="order-label">联系电话</div>
                    <div class="order-value">{{ item.phone }}</div>
                  </div>
                  <div class="order-item">
                    <div class="order-label">预约时间</div>
                    <div class="order-value">{{ item.appointmentTime }}</div>
                  </div>
                  <div class="order-button">
                    <el-button type="primary" size="small" @click="showTrajectory(item)">当天人员轨迹</el-button>
                  </div>
                </div>
              </template>
            </el-collapse-item>
          </el-collapse>
        </div>
      </el-tab-pane>
    </el-tabs>
    <TrajectoryDialog v-model:show="trajectoryShow" :data="trajectoryData"></TrajectoryDialog>
  </div>
</template>

<style scoped lang="scss">
.user-dialog {
  position: absolute;
  top: 120px;
  right: 50px;
  width: 363px;
  background: #ffffff;
  box-shadow: 0 0 7px 0 rgba(182, 182, 182, 0.5);
  border-radius: 11px;
  padding: 25px;

  & > * {
    width: 100%;
  }

  .detail-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > * {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .detail-title-content {
      font-size: 18px;

      &:before {
        content: ' ';
        width: 6px;
        height: 16px;
        background: var(--dubhe-color-primary);
        margin-right: 10px;
      }
    }

    .detail-title-close {
      font-size: 16px;
      cursor: pointer;
    }
  }

  .detail-header {
    display: flex;
    padding-top: 32px;

    .detail-header-image {
      width: 56px;
      height: 56px;
      margin-right: 20px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .detail-header-content {
      flex: 1;
      display: flex;
      flex-direction: column;

      .detail-header-name {
        font-size: 14px;
        padding-bottom: 10px;
      }
    }
  }

  .dubhe-divider {
    margin-bottom: 10px;
  }
}

.order-wrapper {
  max-height: 400px;
  padding: 15px;
  overflow: auto;
  background: #f5f7fa;
  border-radius: 7px;

  :deep(.dubhe-collapse-item__header) {
    background: transparent;
  }

  :deep(.dubhe-collapse-item__wrap) {
    background: transparent;
  }

  .order-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-right: 10px;

    .order-title-text {
      font-size: 16px;
      font-weight: 400;
    }

    .order-title-time {
      font-size: 12px;
      color: #666666;
    }
  }

  .order-detail {
    position: relative;

    .order-item {
      display: flex;
      align-items: center;
      font-size: 12px;

      .order-label {
        width: 65px;
      }
    }

    .order-button {
      position: absolute;
      right: 0;
      top: 0;

      :deep(.dubhe-button) {
        padding: 10px;
        font-size: 12px;
      }
    }
  }
}
</style>