<script>
import { defineComponent } from 'vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'

export default defineComponent({
  name: 'SelectDateDialog',
  components: { DatePicker },
  emits: ['update:modelValue','update:date','callback'],
  data() {
    return {
      form:{
        dateValue:'',
        date:'',
        time:'',
      },
      dateMapList:[
        {
          key: 'YYYY',
          title: '年',
          type: 'year'
        },
        {
          key: 'MM',
          title: '月',
          type: 'month'
        },
        {
          key: 'DD',
          title: '日期',
          type: 'date'
        },
        {
          key: 'HH',
          title: '时间',
          type: 'datetime'
        }
      ]
    }
  },
  props: {
    modelValue: {
      type: Boolean
    },
    date: {},
    required: {
      type: Boolean,
      default(){
        return true
      }
    },
    valueFormat: {
      default() {
        return 'YYYY-MM-DD'
      }
    }
  },
  computed: {
    show: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    },
    rules(){
      let rules = {}
      if(this.required){
        rules = {
          dateValue: [{ required: true, message: '请选择时间', trigger: 'change' }],
          date: [{ required: true, message: '请选择日期', trigger: 'change' }],
          time: [{ required: true, message: '请选择时间', trigger: 'change' }]
        }
      }
      return rules
    },
    dateMap(){
      let map = {}
      this.dateMapList.forEach(item=>{
        if(this.valueFormat.includes(item.key)){
          map = item
        }
      })
      return map
    },
    type(){
      return this.dateMap.type
    },
    isDateTime(){
      return this.type === 'datetime'
    }
  },
  watch:{
  },
  methods: {
    open() {
      if(this.date){
        if(this.isDateTime){
          let dateList = this.date.split(' ')
          this.form.date = dateList[0]
          this.form.time = dateList[1]
        }else{
          this.form.dateValue = this.date
        }
      }
    },
    close() {
      this.form = {
        dateValue:'',
        date:'',
        time:'',
      }
    },
    handleCancelClick(){
      this.show = false
    },
    handleConfirmClick(){
      this.$refs.form.validate((valid)=>{
        if(valid){
          if(this.isDateTime){
            this.form.dateValue = `${this.form.date} ${this.form.time}`
          }
          this.$emit('update:date',this.form.dateValue)
          this.$emit('callback',this.form.dateValue)
          this.show = false
        }
      })
    }
  }
})
</script>

<template>
  <el-dialog
    v-model="show"
    v-bind="$attrs"
    title="选择时间"
    width="50%"
    destroy-on-close
    @open="open"
    @closed="close"
  >
    <el-form ref="form" :model="form" :rules="rules">
      <template v-if="isDateTime">
        <el-form-item label="日期" :required="required" prop="date">
          <DatePicker v-model="form.date" valueFormat="YYYY-MM-DD"></DatePicker>
        </el-form-item>
        <el-form-item label="时间" :required="required" prop="time">
          <el-time-picker v-model="form.time" valueFormat="HH:mm:ss"/>
        </el-form-item>
      </template>
      <template v-else>
        <el-form-item label="日期" :required="required" prop="dateValue">
          <DatePicker v-model="form.dateValue" :valueFormat="valueFormat" :type="type"></DatePicker>
        </el-form-item>
      </template>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleCancelClick">取消</el-button>
        <el-button type="primary" @click="handleConfirmClick">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<style scoped lang="scss"></style>