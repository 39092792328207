<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "TableTempTitle",
  emits: ["refresh"],
  props: {
    title: {},
    showCollect:{
      type: Boolean,
      default(){
        return true
      }
    }
  },
  computed: {
    hasCollect() {
      return !!this.collectStore.collectList.find((item) => item.router === this.$route.path);
    }
  },
  methods: {
    handleCollectClick() {
      let routeParams = {
        name: this.$route.meta.name,
        router: this.$route.path
      };
      this.collectStore.changeCollect(routeParams);
    },
    refresh() {
      this.$emit("refresh");
    }
  }
});
</script>

<template>
  <div class="nav-bar">
    <div class="nav-bar-title">
      <h3 class="">
        <el-button icon="Document" type="primary" />
        <span>{{ title }}</span>
      </h3>
      <template v-if="showCollect">
        <el-divider direction="vertical" />
        <el-tooltip effect="dark" content="设置为常用流程，可在【首页-常用流程】中使用">
          <div class="icon-width" v-if="hasCollect">
            <el-icon size="24" color="#ff6600" @click="handleCollectClick">
              <StarFilled />
            </el-icon>
          </div>
          <div class="icon-width" v-else>
            <el-icon size="20" color="#9eacc4" @click="handleCollectClick">
              <Star />
            </el-icon>
          </div>
        </el-tooltip>
      </template>
    </div>
    <div class="nav-bar-title-buttons">
      <slot name="buttons"></slot>
      <el-button type="" link icon="RefreshRight" @click="refresh">刷新</el-button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.nav-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  border-bottom: 1px solid var(--dubhe-border-color);
  background: #ffffff;

  .nav-bar-title {
    font-size: 14px;
    display: flex;
    align-items: center;

    .icon-width {
      width: 22px;
      display: flex;
      justify-content: center;
    }

    .dubhe-icon {
      margin: 0 8px;
      cursor: pointer;

      &:hover {
        color: #f60;
      }
    }

    h3 {
      display: flex;
      align-items: center;
      padding-right: 10px;

      button {
        margin-right: 10px;
        padding: 0 8px;

        .dubhe-icon {
          margin: 0;

          &:hover {
            color: #fff;
          }
        }
      }
    }
  }

  .nav-bar-title-buttons{
    display: flex;
    align-items: center;
    &>*{
      margin-left: 5px;
    }
  }
}
</style>