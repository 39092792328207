import axios from './axios.config'
// 获取供应商显示字段
export const getSupplierFields = (tableId='supplier') => axios.get(`/jaxrs/supplier/fields?tableId=${tableId}`)
// 获取供应商查询条件
export const getSupplierConditions = () => axios.get('/jaxrs/supplier/conditions')
// 获取供应商分页数据
export const getSupplierPage = (data) => axios.post('/jaxrs/supplier/page',data)
// 获取供应商详情
export const getSupplierDetail = (data) => axios.get(`/jaxrs/supplier/${data.id}`)
// 修改供应商
export const editSupplier = (data) => axios.put(`/jaxrs/supplier`,data)
// 添加供应商
export const addSupplier = (data) => axios.post(`/jaxrs/supplier`,data)
// 删除供应商
export const deleteSupplier = (data) => axios.delete(`/jaxrs/supplier/${data.id}`)
// 导入工单
export const importSupplierOrder = (data) =>axios.post(`/jaxrs/supplier/batch_save`,data)
// 导入表头接口(获取导入字段)
export const  getSupplierImportFields = () =>axios.get('/jaxrs/supplier/import/fields')
// 获取供应商显示字段
export const getSupplierAllFields = (tableId='supplier') => axios.get(`/jaxrs/supplier/fields?tableId=${tableId}`)
// 获取全部供应商分页数据
export const getSupplierAllPage = (data) => axios.post('/jaxrs/supplier/all/page',data)
// 获取供应商查询条件
export const getSupplierAllConditions = () => axios.get('/jaxrs/supplier/conditions')