<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MenuItem',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  inject: ['open'],
  computed: {
    total() {
      if (this.data.component === 'MyTask/MyTask') {
        try {
          let props = this.data.props
          let type = JSON.parse(props).type
          let typeList = ['myTask', 'notifyTask']
          if (typeList.includes(type)) {
            return this.messageStore.task[type].total
          }
        } catch (e) {}
      }
      return 0
    }
  },
  methods: {
    checkActive(val) {
      if (!val.index) {
        return
      }
      let route = this.permissionStore.getRouteById(val.index)
      this.$router.push({
        path: `/${route.path}`
      })
    }
  }
})
</script>

<template>
  <el-sub-menu v-if="data.children && data.children.length > 0" :index="data.name">
    <template #title>
      <el-icon v-if="data.children || data.icon">
        <component v-if="data.icon" :is="data.icon"></component>
        <Folder v-else />
      </el-icon>
      <span>{{ data.name }}</span>
    </template>
    <MenuItem v-for="(item, index) in data.children" :key="index" :data="item"></MenuItem>
  </el-sub-menu>
  <!--  :route="data.path"-->
  <el-menu-item v-else :index="data.id" @click="checkActive">
    <template v-if="open">
      <el-icon v-if="data.children || data.icon">
        <component v-if="data.icon" :is="data.icon"></component>
        <Folder v-else />
      </el-icon>
      <el-badge v-if="total" :value="total">
        {{ data.name }}
      </el-badge>
      <span v-else>
        {{ data.name }}
      </span>
    </template>
    <template v-else>
      <el-badge v-if="total" :value="total">
        <el-icon v-if="data.children || data.icon">
          <component v-if="data.icon" :is="data.icon"></component>
          <Folder v-else />
        </el-icon>
      </el-badge>
      <el-icon v-else>
        <component v-if="data.icon" :is="data.icon"></component>
        <Folder v-else />
      </el-icon>
      <div class="menu-item-name">
        <el-badge v-if="total" :value="total">
          {{ data.name }}
        </el-badge>
        <span v-else>
        {{ data.name }}
      </span>
      </div>
    </template>
  </el-menu-item>
</template>

<style scoped lang="scss"></style>