import axios from './axios.config'
// 获取显示字段
export const getExamFields = (tableId='exam')=>axios.get(`/jaxrs/exam/fields?tableId=${tableId}`)
// 获取查询条件
export const getExamConditions = () =>axios.get('/jaxrs/exam/conditions')
// 查询
export const getExamPage = (data) =>axios.post('/jaxrs/exam/page',data)
// 详情页
export const getExamDetail = (data) =>{
    let url
    if(data.oper){
        url = `/jaxrs/exam/${data.id}?oper=${data.oper}`
    }else{
        url = `/jaxrs/exam/${data.id}`
    }
    return axios.get(url)
}
// 保存
export const addExam = (data) =>axios.post(`/jaxrs/exam`,data)
// 修改
export const editExam = (data) =>axios.put(`/jaxrs/exam`,data)
// 删除
export const deleteExam = (data) =>axios.delete(`/jaxrs/exam/${data.id}`)
// 开始考试
export const startExam = (data) =>axios.put(`/jaxrs/exam/start/${data.id}`,data)
// 停止考试
export const stopExam = (data) =>axios.put(`/jaxrs/exam/stop/${data.id}`,data)