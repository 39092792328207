import _ from "lodash";

const moneyDigit = 2
export const filterMixins = {
  methods: {
    isNaN(val) {
      return _.isNaN(val) || val === "NaN";
    },
    getTotalByKey(list, key, digit) {
      return list.reduce((num, item) => {
        let addNum = _.get(item,key,0)
        if (!addNum || this.isNaN(Number(addNum))) {
          return num;
        } else {
          return num + Number(parseFloat(addNum)
            .toFixed(digit || moneyDigit));
        }
      }, 0);
    },
    formatNumber(val,digit) {
      let num = parseFloat(val)
        .toFixed(digit || moneyDigit)
        .replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
      if (this.isNaN(num)) {
        return '';
      }
      return num;
    }
  }
};