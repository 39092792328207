<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ViewCharts',
  components:{
  },
  data(){
    return{
      xData:[],
      yData:[]
    }
  },
  props: {
    type:{
    },
    icon:{
      default:'InfoFilled'
    },
    loadData:{
      type:Function
    }
  },
  computed: {
    priceTrend(){
      return{
        title: {
          text: '历史单价',
          left:'center',
          textStyle:{
            //文字颜色
            color:'#333',
            //字体大小
            fontSize:12
          }
        },
        xAxis: {
          type: 'category',
          data: this.xData,
          axisLine:{
            lineStyle:{
              color:'#A0A7B8',
              width:1,//这里是为了突出显示加上的
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLine:{
            lineStyle:{
              color:'#A0A7B8',
              width:1,//这里是为了突出显示加上的
            }
          }
        },
        grid:{
          left:'0',
          right:'0',
          top:'16%',
          bottom:'16',
          containLabel:true
        },
        series: [{
          data: this.yData,
          type: 'line',
          smooth:true,
          itemStyle:{
            normal:{
              color:'#5B8FF9', //折点颜色
              lineStyle:{
                color:'#5B8FF9' //折线颜色
              }
            }
          }
        }]
      }
    },
    hasData(){
      return this.xData.length > 0
    }
  },
  methods:{
    async showChartsData() {
      let data = await this.loadData
      // console.log(data,'折线数据');
      if(data){
        this.xData = data.xData
        this.yData = data.yData
      }
    }
  }
})
</script>

<template>
  <div class="charts-cont">
    <el-popover
        placement="top"
        :width="400"
        trigger="hover"
        @show="showChartsData"
    >
      <template #reference>
        <el-icon size="14" color="#888">
          <component :is="icon" />
        </el-icon>
      </template>
      <div class="charts" v-if="hasData">
        <v-chart
            :option="priceTrend"
            autoresize
            style="width: 100%;height: 200px;"/>
      </div>
      <div v-else>
        <el-empty description="暂无数据" :image-size="100" />
      </div>
    </el-popover>
  </div>
</template>

<style scoped lang="scss">
:deep(.dubhe-icon){
  cursor: pointer;
  position: absolute;
  left:6px;
  top:50%;
  color:#666;
  transform: translate(0,-50%);
  z-index: 999;
  &:hover{
    color:#007aff;
  }
}

</style>
