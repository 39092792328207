import axios from './axios.config'
// 加油登记
export const getFuelingRecordFields = (tableId='fuelingRecord')=>axios.get(`/jaxrs/fueling_record/fields?tableId=${tableId}`)
// 查询
export const getFuelingRecordConditions = ()=>axios.get('/jaxrs/fueling_record/conditions')
// 分页
export const getFuelingRecordPage = (data)=>axios.post('/jaxrs/fueling_record/page',data)
// 详情
export const getFuelingRecordDetail = (data)=>axios.get(`/jaxrs/fueling_record/${data.id}`)
// 保存
export const addFuelingRecord = (data)=>axios.post(`/jaxrs/fueling_record`,data)
// 修改
export const editFuelingRecord = (data)=>axios.put(`/jaxrs/fueling_record`,data)
// 删除
export const deleteFuelingRecord = (data)=>axios.delete(`/jaxrs/fueling_record/${data.id}`)
// 导出
export const exportFuelingRecord = (data) => axios.post(`/jaxrs/fueling_record/export`,data,{
    responseType:'blob'
})
