import axios from './axios.config'
// 车辆违章
// 获取显示字段
export const getVehicleMaintainFields = (tableId="vehicleInsurance") =>axios.get(`/jaxrs/vehicle_maintain/fields?tableId=${tableId}`)
// 获取查询条件
export const getVehicleMaintainConditions = () =>axios.get('/jaxrs/vehicle_maintain/conditions')
// 查询
export const getVehicleMaintainPage = (data) =>axios.post('/jaxrs/vehicle_maintain/page',data)
// 详情
export const getVehicleMaintainDetail = (data) =>{
    let url
    if(data.oper){
        url=`/jaxrs/vehicle_maintain/${data.id}?oper=${data.oper}`
    }else{
        url=`/jaxrs/vehicle_maintain/${data.id}`
    }
    return axios.get(url)
}
// 保存（新增）
export const addVehicleMaintain = (data) =>axios.post(`/jaxrs/vehicle_maintain`,data)
// 修改
export const editVehicleMaintain = (data) =>axios.put(`/jaxrs/vehicle_maintain`,data)
// 删除
export const deleteVehicleMaintain = (data) =>axios.delete(`/jaxrs/vehicle_maintain/${data.id}`)
// 导出
export const exportVehicleMaintain = (data) =>
    axios.post(`/jaxrs/vehicle_maintain/export`,data,{
        responseType:'blob'
    })
// 完善信息
export const improveVehicleMaintain = (data) =>axios.post('/jaxrs/vehicle_maintain/improve',data)
