<script>
import { defineComponent } from "vue";
import InputNumberBetween from "../InputNumber/InputNumberBetween.vue";
import DatePicker from "../DatePicker/DatePicker.vue";
import SelectV2 from "../SelectMultiple/SelectV2.vue";
import { listFilterMap, listFilterListMap } from "./conditionTypeData";
import _ from "lodash";

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: "ConditionV2",
  emits: ["callback"],
  components: {
    SelectV2,
    InputNumberBetween,
    DatePicker
  },
  data() {
    return {
      searchContent: "",
      visible: false,
      baseList: [],
      typeMap: listFilterMap,
      typeListMap: listFilterListMap,
      operator: "and",
      operatorList: [
        {
          name: "所有",
          value: "and"
        },
        {
          name: "任意一个",
          value: "or"
        }
      ]
    };
  },
  props: {
    conditionOptions: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    selectList() {
      return this.baseList.filter((item) => {
        if (!item.title) {
          return false;
        }
        return !!item.title.includes(this.searchContent);
      });
    },
    checkList() {
      let list = this.baseList.filter((item) => item.check === true);
      return list;
    },
    operatorName(){
      let operatorName = this.operatorList.find((item) => {
        return item.value === this.operator
      }).name
      return operatorName
    }
  },
  watch: {
    conditionOptions: {
      handler(val) {
        this.baseList = this.getBaseList(val);
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
  },
  methods: {
    getBaseList(val) {
      return val.map((item) => {
        let { formatType } = item;
        let typeList = _.cloneDeep(this.typeListMap[formatType]);
        let baseItem = {
          ..._.cloneDeep(item),
          check: false,
          fields: typeList
        };
        this.setComponent(baseItem, 0);
        return baseItem;
      });
    },
    handleTriggerClick() {
      this.visible = !this.visible;
    },
    getCheckField(item, index) {
      return item.fields[index];
    },
    handleCheckClick(item) {
      item.check = true;
      this.setComponent(item, 0);
    },
    setComponent(item, index) {
      item.checkFieldIndex = index;
      this.resetComponent(item, index);
    },
    resetComponent(item, index) {
      let component = _.cloneDeep(item.fields[index]);
      // 将script作为select处理
      if (item.valueType === "script") {
        component = {
          ...component,
          component: "SelectV2",
          props: {
            list: [],
            labelKey: "label",
            valueKey: "id",
            filterable: true
          }
        }
      }
      if (component.props && item.props) {
        component.props = {
          ...component.props,
          ...item.props
        };
      }
      item.component = component;
    },
    handleFieldCheckClick(item, index, checkIndex) {
      this.setComponent(item, index);
      this.$refs.field[checkIndex].hide();
    },
    handleDeleteClick(item) {
      item.check = false;
    },
    handleClearClick() {
      this.checkList.forEach((item) => {
        this.resetComponent(item, item.checkFieldIndex);
      });
    },
    handleResetClick() {
      this.baseList.forEach((item) => {
        this.handleDeleteClick(item);
      });
    },
    handleSearchClick() {
      let list = this.checkList.map((item) => {
        let cItem = {
          path: item.path,
          formatType: item.formatType,
          logic: item.logic,
          comparison: item.component.value
        };
        if (Array.isArray(item.component.params)) {
          cItem.value = item.component.params[0];
          cItem.otherValue = item.component.params[1];
        } else {
          cItem.value = item.component.params;
        }
        return cItem;
      });
      list = list.filter(item => {
        if (_.isNumber(item.value)) {
          return true;
        }
        return !!item.value;
      });
      this.visible = false;
      this.$emit("callback", list);
    }
  }
});
</script>

<template>
  <div class="condition-wrapper">
    <div class="overlay" v-if="visible" @click="handleTriggerClick"></div>
    <el-badge :value="checkList.length" :hidden="checkList.length === 0" type="primary">
      <el-button ref="buttonRef" link type="primary" @click="handleTriggerClick">
        <el-icon :size="18" color="#000">
          <Filter />
        </el-icon>
      </el-button>
    </el-badge>
    <el-popover
      placement="bottom-start"
      popper-class="condition-popover"
      width="800"
      :virtual-ref="$refs.buttonRef"
      :visible="visible"
      :enterable="true"
      :teleported="false"
    >
      <template #default>
        <div class="condition">
          <div class="header">筛选出符合以下
            <el-popover
            trigger="click"
            ref="operator"
            placement="bottom-start"
            :teleported="false"
            width="100"
          >
            <template #reference>
              <el-button link type="primary"
              >{{ operatorName }}
                <el-icon>
                  <ArrowDown />
                </el-icon>
              </el-button>
            </template>
            <template #default>
              <div class="select-wrapper">
                <div class="select-list">
                  <div
                    class="select-item"
                    v-for="(oItem) in operatorList"
                    :key="oItem.value"
                    @click="handleOperatorClick(oItem)"
                  >
                    {{ oItem.name }}
                  </div>
                </div>
              </div>
            </template>
          </el-popover>条件的数据</div>
          <div class="list" v-if="checkList.length > 0">
            <div class="list-item" v-for="(item, index) in checkList" :key="item.title">
              <div class="name">{{ item.title }}</div>
              <div class="dropdown">
                <el-popover
                  trigger="click"
                  ref="field"
                  placement="bottom-start"
                  :teleported="false"
                  width="400"
                >
                  <template #reference>
                    <el-button link type="primary"
                    >{{ getCheckField(item, item.checkFieldIndex).name }}
                      <el-icon>
                        <ArrowDown />
                      </el-icon>
                    </el-button>
                  </template>
                  <template #default>
                    <div class="select-wrapper">
                      <div class="select-wrapper">
                        <div class="select-list">
                          <div
                            class="select-item"
                            v-for="(fItem, fIndex) in item.fields"
                            :key="fItem.name"
                            @click="handleFieldCheckClick(item, fIndex, index)"
                          >
                            {{ fItem.name }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </el-popover>
              </div>
              <div class="input">
                <template v-if="item?.component?.component">
                  <component
                    :is="item.component.component"
                    v-bind="item.component.props"
                    v-model="item.component.params"
                  ></component>
                </template>
              </div>
              <div class="delete">
                <el-button
                  icon="Delete"
                  type="danger"
                  link
                  @click="handleDeleteClick(item)"
                ></el-button>
              </div>
            </div>
          </div>

          <div class="select">
            <el-popover
              trigger="click"
              placement="bottom-start"
              :enterable="true"
              :teleported="false"
              width="400"
            >
              <template #reference>
                <el-button link type="primary" icon="Plus"> 添加过滤条件</el-button>
              </template>
              <template #default>
                <div class="select-wrapper">
                  <div class="select-input">
                    <el-input v-model="searchContent"></el-input>
                  </div>
                  <div class="select-list">
                    <div
                      class="select-item"
                      v-for="(item) in selectList"
                      :key="item.title"
                      :class="{ disabled: item.check }"
                      @click="handleCheckClick(item)"
                    >
                      {{ item.title }}
                    </div>
                  </div>
                </div>
              </template>
            </el-popover>
          </div>
          <div class="footer">
            <div>
              <el-button type="primary" link icon="Delete" @click="handleClearClick"
              >清空值
              </el-button>
              <el-button type="primary" link icon="Refresh" @click="handleResetClick"
              >重置
              </el-button>
            </div>
            <div>
              <el-button type="primary" icon="search" @click="handleSearchClick">筛选</el-button>
            </div>
          </div>
        </div>
      </template>
    </el-popover>
  </div>
</template>

<style scoped lang="scss">
:deep(.condition-popover) {
  padding: 0;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
}

.condition-wrapper {
  position: relative;
  //:deep(&> .dubhe-popover) {
  //  padding: 0;
  //}
}

.condition {
  width: 100%;
  font-size: 14px;
  overflow: hidden;

  .header {
    padding: 15px;
    //border-top: 1px solid var(--dubhe-border-color-dark);
    box-shadow: var(--dubhe-box-shadow-light);
    display: flex;
    align-items: center;
  }

  .list {
    max-height: 400px;
    overflow: auto;
    //display: flex;
    //flex-direction: column;
    padding: 10px 0;

    .list-item {
      height: 40px;
      display: flex;
      align-items: center;
      padding: 0 15px;

      .name {
        //padding-left: 10px;
        width: 200px;
      }

      .dropdown {
        flex: 1;
      }

      :deep(.input) {
        width: 400px;

        & > * {
          width: 100%;
        }
      }

      .delete {
        padding-left: 10px;
      }
    }
  }

  .select {
    padding: 15px;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-top: 1px solid var(--dubhe-border-color-dark);
  }
}

.select-wrapper {
  .select-input {
    //padding: 10px;
  }

  .select-list {
    max-height: 300px;
    overflow: auto;
    padding: 10px 0;

    .select-item {
      font-size: 14px;
      line-height: 32px;
      color: var(--dubhe-text-color-regular);
      cursor: pointer;

      &.disabled {
        color: var(--dubhe-text-color-disabled);
        cursor: default;
      }
    }
  }
}
</style>
