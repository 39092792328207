<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Icon",
  props:{
    name:{
      required:true
    }
  }
});
</script>

<template>
  <el-icon>
    <component :is="name"></component>
  </el-icon>
</template>

<style scoped lang="scss">

</style>