import axios from './axios.config'
// 资料库（database）
// 获取显示字段
export const getDatabaseFields = (tableId='database')=>axios.get(`/jaxrs/database/fields?tableId=${tableId}`)
// 获取查询条件
export const getDatabaseConditions = () =>axios.get('/jaxrs/database/conditions')
// 获取分页数据
export const getDatabasePage = (data) =>axios.post('/jaxrs/database/page',data)
// 详情
export const getDatabaseDetail = (data) =>{
    let url
    if(data.oper){
        url=`/jaxrs/database/${data.id}?oper=${data.oper}`
    }else{
        url=`/jaxrs/database/${data.id}`
    }
    return axios.get(url)
}
// 保存
export const addDatabase = (data) =>axios.post(`/jaxrs/database`,data)
// 编辑
export const editDatabase = (data)=>axios.put(`/jaxrs/database`,data)
// 删除
export const deleteDatabase = (data) =>axios.delete(`/jaxrs/database/${data.id}`)