// 分包供应商 OutsourcingSupplier
// 获取查询字段
import axios from './axios.config'
// 获取显示字段
export const getOutsourcingSupplierFields = (tableId = 'outsourcingSupplier') =>axios.get(`/jaxrs/outsourcing_supplier/fields?tableId=${tableId}`)
// 获取查询条件
export const getOutsourcingSupplierConditions = () =>axios.get('/jaxrs/outsourcing_supplier/conditions')
// 查询
export const getOutsourcingSupplierPage = (data) =>axios.post('/jaxrs/outsourcing_supplier/page',data)
// 详情
export const getOutsourcingSupplierDetail = (data) =>axios.get(`/jaxrs/outsourcing_supplier/${data.id}`)
// 保存
export const addOutsourcingSupplier = (data) =>axios.post(`/jaxrs/outsourcing_supplier`,data)
// 修改
export const editOutsourcingSupplier = (data) =>axios.put(`/jaxrs/outsourcing_supplier`,data)