<script>
import { defineComponent } from "vue";
import PageHeaderLeft from "./PageHeaderLeft.vue";
import PageHeaderRight from "./PageHeaderRight.vue";

export default defineComponent({
  name: "PageHeader",
  components:{PageHeaderLeft,PageHeaderRight}
});
</script>

<template>
  <div class="page-header dubhe-box-shadow-lighter">
    <PageHeaderLeft></PageHeaderLeft>
    <PageHeaderRight></PageHeaderRight>
  </div>
</template>

<style scoped lang="scss">
.page-header{
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  //padding: 0 20px;
  box-shadow: var(--dubhe-box-shadow-lighter);
  z-index: 1;
  background: #fff;
}
</style>