<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "FullScreenIcon",
  data(){
    return {
      hasFullScreen: false
    }
  },
  props:{
    dom:{}
  },
  mounted() {
    document.addEventListener('fullscreenchange', this.initFullScreen)
    document.addEventListener('keydown', this.handleKeyDown)
    this.initFullScreen()
  },
  unmounted() {
    document.removeEventListener('fullscreenchange', this.initFullScreen)
    document.removeEventListener('keydown', this.handleKeyDown)
  },
  methods:{
    initFullScreen() {
      this.hasFullScreen = this.isFullScreen()
    },
    isFullScreen() {
      return (
        (document.fullscreenElement && document.fullscreenElement !== null) ||
        (document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
        (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
        (document.msFullscreenElement && document.msFullscreenElement !== null)
      )
    },
    fullScreen(el) {
      if (el.requestFullscreen) {
        el.requestFullscreen()
      } else if (el.mozRequestFullScreen) {
        el.mozRequestFullScreen()
      } else if (el.webkitRequestFullscreen) {
        el.webkitRequestFullscreen()
      } else if (el.msRequestFullscreen) {
        el.msRequestFullscreen()
      }
    },
    exitFullScreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    },
    handleKeyDown(e) {
      if (e && e.keyCode === 122) {
        e.preventDefault() // 阻止默认事件
        this.changeScreen()
      }
    },
    changeScreen() {
      let dom = this.dom
      if (this.isFullScreen()) {
        this.exitFullScreen()
      } else {
        this.fullScreen(dom)
      }
    }
  }
});
</script>

<template>
  <i
    v-if="hasFullScreen"
    class="ri-fullscreen-exit-line message-button"
    @click="changeScreen"
  ></i>
  <i v-else class="ri-fullscreen-line message-button" @click="changeScreen"></i>
</template>

<style scoped lang="scss">
  .message-button{
    font-size: 30px;
  }
</style>