import axios from './axios.config'
//获取结算方案显示字段
export const getSettlementSchemeFields = (tableId='settlementStrategy') =>axios.get(`/jaxrs/settlement_strategy/fields?tableId=${tableId}`)
//获取查询结算方案
export const getSettlementSchemeConditions = () =>axios.get('/jaxrs/settlement_strategy/conditions')
// 查询
export const getSettlementSchemePage = (data) =>axios.post('/jaxrs/settlement_strategy/page',data)
// 财务查询
export const getSettlementSchemeFinancePage = (data) =>axios.post('/jaxrs/settlement_strategy/all/page',data)
//详情
export const getSettlementSchemeDetail = (data) =>axios.get(`/jaxrs/settlement_strategy/${data.id}`)
//保存
export const addSettlementScheme = (data) =>axios.post(`/jaxrs/settlement_strategy`,data)
//修改
export const editSettlementScheme = (data) =>axios.put(`/jaxrs/settlement_strategy`,data)
// 删除
export const deleteSettlementScheme = (data) =>axios.delete(`/jaxrs/settlement_strategy/${data.id}`)
// 侧边树
export const settlementSchemeTree = (data) =>axios.get(`/jaxrs/settlement_type/tree`,data)
//字典值查询
export const getBiz = (data) =>axios.get(`/jaxrs/dict/get_by_type/${data.type}`)

//字典值查询条件依据（下拉）
export const getConditionSource = () =>axios.get('/jaxrs/dict/get_by_type/conditionSource')
// //字典查询条件类型（下拉）
export const getType = () =>axios.get('/jaxrs/dict/get_by_type/conditionType')
// //字典查询条件字段（下拉）
export const getConditon = () =>axios.get('/jaxrs/dict/get_by_type/materialProperty')
export const getWorkConditon = () =>axios.get('/jaxrs/dict/get_by_type/workOrderProperty')
// // 分配方式
// export const getModelWay = () =>axios.get('/jaxrs/dict/get_by_type/allotMode')
// 计算依据
export const getSourceType = () =>axios.get('/jaxrs/dict/get_by_type/sourceType')
// 固定值（计算依据）
export const getCalculationBasis = () =>axios.get('/jaxrs//dict/get_by_type/sourceType')
// 获取结算方案管理 查询绩效匹配条件
export const getMeritPay = () =>axios.get('/jaxrs/settlement_strategy/match_conditions')
