<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "SystemMenu",
  data(){
    return {
      showDrawer: false
    }
  },
  mounted() {
    window.that = this
  },
  methods:{
    filterStyle(item) {
      if (item.cssClass) {
        return {
          background: item.cssClass
          // color: item.cssClass
        }
      }
    },
    handleItemClick(item) {
      this.permissionStore.module = item.module
      this.permissionStore.goFirstRouter(this.$router)
      this.showDrawer = false
    },
    handleHomeClick(){
      this.$router.push({
        name:`/Home`
      })
    },
    handleModuleClick() {
      this.showDrawer = true
    },
    filterActive(item){
      return item.module === this.permissionStore.module
    },
    filterHome(item){
      return item.module === this.permissionStore.homeModule
    }
  }
});
</script>

<template>
  <div class="system-menu">
    <div class="system-menu-icon" @click="handleModuleClick">
      <img v-if="loginStore?.loginConfig?.logoUrl" :src="loginStore.loginConfig.logoUrl" alt="" />
    </div>
    <div class="system-menu-body">
      <div class="system-menu-body-absolute">
        <div
          class="system-menu-item"
          :class="{'active':filterActive(item),'isHome':filterHome(item)}"
          v-for="(item, index) in permissionStore.moduleList"
          :key="index"
          @click="handleItemClick(item)"
        >
          <div class="system-menu-item-icon">
            <component :is="item.icon" size="28"></component>
          </div>
          <div class="system-menu-item-name">{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
  <el-drawer v-model="showDrawer" direction="ltr" size="400px">
    <div class="module-list">
      <div
        class="module-item"
        v-for="(item, index) in permissionStore.moduleList"
        :key="index"
        @click="handleItemClick(item)"
      >
        <div class="module-item-icon" :style="filterStyle(item)">
          <component :is="item.icon"></component>
        </div>
        <div class="module-item-name">{{ item.name }}</div>
      </div>
      <img class="module-bg" src="../../assets/image/module_background.png" alt="">
    </div>
  </el-drawer>
</template>

<style scoped lang="scss">
.system-menu {
  width: 80px;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  user-select: none;
  .system-menu-icon {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    //background: #61AD34;
    background: var(--dubhe-color-primary);
    cursor: pointer;
    & > img {
      width: 45px;
      height: 45px;
      filter: brightness(0) contrast(1) invert(1);
    }
  }
  .system-menu-body {
    flex: 1;
    background: var(--dubhe-color-primary-dark-7);
    position: relative;
    .system-menu-body-absolute{
      position:absolute;
      height: 100%;
      width: 100%;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .system-menu-item {
      position: relative;
      width: 100%;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;
      transition: all ease-in-out 0.2s;
      color: #fff;
      //&:first-child{
      //  .system-menu-item-name {
      //   display: none;
      //  }
      //}
      &:hover {
        background: rgba(255, 255, 255, 0.2);
        //.system-menu-item-name {
        //  opacity: 1;
        //}
      }
      &.active{
        background: rgba(255, 255, 255, 0.2);
        //.system-menu-item-name {
        //  opacity: 1;
        //}
      }
      &.isHome{
        .system-menu-item-icon{
          background: rgba(255, 255, 255, 0.2);
        }
        //.system-menu-item-name {
        //  opacity: 0 !important;
        //}
        .system-menu-item-icon{
          height: 40px;
        }
      }
      .system-menu-item-icon {
        width: 40px;
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
      .system-menu-item-name {
        font-size: 12px;
        //opacity: 0;
        transition: all ease-in-out 0.2s;
      }
    }
  }
}
.module-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .module-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 25%;
    height: 110px;
    cursor: pointer;

    .module-item-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      color: #ffffff;
      font-size: 30px;
    }
    .module-item-name {
      margin-top: 10px;
      font-size: 14px;
    }
  }
  .module-bg{
    position: absolute;
    bottom: -166px;
    left: -100px;
    width: 600px;
    opacity: 0.06;
    pointer-events: none;
  }
}
</style>
