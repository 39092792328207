import axios from './axios.config'
// 获取材料显示字段
export const getMaterialFields = (tableId='material') => axios.get(`/jaxrs/material/fields?tableId=${tableId}`)
// 获取材料查询条件
export const getMaterialConditions = () => axios.get('/jaxrs/material/conditions')
// 获取材料分页数据
export const getMaterialPage = (data) => axios.post('/jaxrs/material/page',data)
// 获取材料详情
export const getMaterialDetail = (data) => axios.get(`/jaxrs/material/${data.id}`)
// 编辑材料
export const editMaterial = (data) => axios.put(`/jaxrs/material`,data)
// 添加材料
export const addMaterial = (data) => axios.post(`/jaxrs/material`,data)
// 删除材料
export const deleteMaterial = (data) => axios.delete(`/jaxrs/material/${data.id}`)
// 侧边树
 export const MaterialTree = (data) => axios.get(`/jaxrs/material_type/tree`,data)
// 获取材料需求明细
export const getMaterialDemand = (data) => axios.post(`/jaxrs/home/material_demand`,data)
// 编辑材料
export const updateMaterialBatch = (data) => axios.put(`/jaxrs/material/update_batch`,data)
// 导出
export const exportMaterial = (data)=>
    axios.post(`/jaxrs/material/export`,data,{
     responseType:'blob'
    })