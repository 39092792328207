<script>
import { defineComponent } from "vue";
import { getUserIdentityListLike, getPersonAll } from "@/axios";
import SelectWorkerDialog from "./SelectWorkerDialog.vue";

/**
 * 验证trigger需指定change
 */
export default defineComponent({
  name: "SelectWorker",
  components: {
    SelectWorkerDialog
  },
  emits: ["change", "update:modelValue"],
  data() {
    return {
      totalList: [],
      showDialog: false
    };
  },
  props: {
    modelValue: {
      required: true
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      }
    },
    placeholder: {
      type: String
    },
    user: {
      type: Boolean,
      default() {
        return false;
      }
    },
    labelKey: {
      type: String,
      default() {
        return "workerName";
      }
    },
    valueKey: {
      type: String,
      default() {
        return "workerId";
      }
    }
  },
  computed: {
    dialogList() {
      if (this.list) {
        return this.list;
      } else {
        return this.totalList;
      }
    },
    firstTag() {
      let leader = this.value.find(item=>item.isLeader)
      if(leader){
        return leader
      }
      if (this.value.length > 0) {
        return this.value[0];
      } else {
        return false;
      }
    },
    otherTagLength() {
      if (this.value.length > 1) {
        return this.value.length - 1;
      } else {
        return false;
      }
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
        this.$emit("change", val);
      }
    },
    inputPlaceholder() {
      if (this.placeholder) {
        return this.placeholder;
      }
      return "请选择（可多选）";
    }
  },
  mounted() {
  },
  methods: {
    openDialog() {
      if (this.disabled) {
        return;
      }
      this.showDialog = true;
    },
    filterUserName(item) {
      return item[this.labelKey]
      // if (this.user) {
      //   return item.name;
      // }
      // return `${item.name}-${item.unitLevelName}`;
    },
    dialogConfirm(val) {
      this.value = val
    }
  }
});
</script>

<template>
  <div class="dubhe-select" @click="openDialog" :class="{ 'is-disabled': disabled }">
    <div class="select-trigger dubhe-tooltip__trigger dubhe-tooltip__trigger">
      <div class="dubhe-select__tags">
        <span class="dubhe-select-tags-wrapper has-prefix">
          <el-tag
            v-if="firstTag"
            :type="disabled ? 'info' : ''"
          >
            {{ filterUserName(firstTag) }}
          </el-tag>
          <el-tag v-if="otherTagLength"> +{{ otherTagLength }} </el-tag>
        </span>
      </div>
      <div class="dubhe-input dubhe-input--suffix" :class="{ 'is-disabled': disabled }">
        <div class="dubhe-input__wrapper">
          <div class="input-dom">
            <span v-if="!firstTag" class="select-placeholder">{{ inputPlaceholder }}</span>
          </div>
          <span class="dubhe-input__suffix">
            <span class="dubhe-input__suffix-inner">
              <el-icon><User /></el-icon>
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
  <SelectWorkerDialog v-model="showDialog" v-model:value="value" :label-key="labelKey" :value-key="valueKey" @confirm="dialogConfirm"></SelectWorkerDialog>
</template>

<style scoped lang="scss">
.dubhe-select__tags {
  width: 100%;
}

.dubhe-select.is-disabled {
  cursor: not-allowed;

  & * {
    cursor: not-allowed;
  }
}

.select-placeholder {
  color: var(--dubhe-input-placeholder-color)
}

.input-dom {
  flex: 1;
  //height: 30px;
  height: calc(var(--dubhe-component-size) - 2px);
}
</style>
