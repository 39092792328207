import axios from "./axios.config"
//安检计划支援 SecurityCheckPlanSupport
// 显示字段
export const getSecurityCheckPlanSupportFields = (tableId ="securityCheckPlanSupport") =>axios.get(`/jaxrs/security_check_plan_support/fields?tableId=${tableId}`)
// 获取查询条件
export const getSecurityCheckPlanSupportConditions = ()=>axios.get('/jaxrs/security_check_plan_support/conditions')
// 查询
export const getSecurityCheckPlanSupportPage = (data)=>axios.post('/jaxrs/security_check_plan_support/page',data)
// 详情
export const getSecurityCheckPlanSupportDetail = (data)=>axios.get(`/jaxrs/security_check_plan_support/${data.id}`)
// 保存
export const addSecurityCheckPlanSupport = (data)=>axios.post(`/jaxrs/security_check_plan_support`,data)
