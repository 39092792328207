import axios from './axios.config'
// 物品入库单（行政）
// 获取入库单的显示字段
export const getGoodsStockInFields = (tableId='goodsStockIn')=>axios.get(`/jaxrs/goods_stock_in/fields?tableId=${tableId}`)
// 获取查询条件
export const getGoodsStockInConditions = () =>axios.get('/jaxrs/goods_stock_in/conditions')
// 查询
export const getGoodsStockInPage = (data)=>axios.post(`/jaxrs/goods_stock_in/page`,data)
// 详情
export const getGoodsStockInDetail = (data)=>{
  let url
  if(data.oper){
    url = `/jaxrs/goods_stock_in/${data.id}?oper=${data.oper}`
  }else{
    url = `/jaxrs/goods_stock_in/${data.id}`
  }
  return axios.get(url)
}
// 保存
export const addGoodsStockIn = (data)=>axios.post(`/jaxrs/goods_stock_in`,data)
// 编辑
export const editGoodsStockIn = (data) =>axios.put(`/jaxrs/goods_stock_in`,data)
// 删除
export const deleteGoodsStockIn = (data) =>axios.delete(`/jaxrs/goods_stock_in/${data.id}`)