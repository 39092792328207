<script>
import { defineComponent } from 'vue'
import ImportExcel from '../ImportExcel/ImportExcel.vue'
import SelectWithButton from '../SelectWithButton/SelectWithButton.vue'
import InputNumber from '../InputNumber/InputNumber.vue'
import DatePicker from '../DatePicker/DatePicker.vue'
import SelectV2 from '../SelectMultiple/SelectV2.vue'
import ViewCharts from '../ViewCharts/ViewCharts.vue'
import ColorPicker from '../ColorPicker/ColorPicker.vue'
import UploadFileInList from '../UploadFile/UploadFileInList.vue'
import CommonSelectDialog from '../SelectDialog/CommonSelectDialog.vue'
import _ from 'lodash'
import { filterMixins } from '../common/filterMixins'
import DictTag from '@/components/DictTag/DictTag.vue'

export default defineComponent({
  name: 'TableEdit',
  mixins: [filterMixins],
  components: {
    DictTag,
    CommonSelectDialog,
    ImportExcel,
    SelectWithButton,
    InputNumber,
    DatePicker,
    SelectV2,
    ViewCharts,
    ColorPicker,
    UploadFileInList
  },
  data() {
    return {
      close: false,
      importShow: false,
      batchDialogShow: false,
      page: {
        pageNumber: 1,
        pageSize: 10
      }
    }
  },
  props: {
    title: {
      type: String,
      required: true
    },
    list: {
      type: Array,
      required: true
    },
    showUpload: {
      type: Boolean,
      default() {
        return false
      }
    },
    tableOption: {
      type: Array,
      required: true
    },
    totalOption: {
      type: Array
    },
    disabled: {
      type: Boolean,
      default() {
        return false
      }
    },
    addCheck: {
      type: Function
    },
    showAdd: {
      type: Boolean,
      default() {
        return true
      }
    },
    showDelete: {
      type: Boolean,
      default() {
        return true
      }
    },
    parentIndex: {},
    batchOption: {
      type: Object,
      default() {
        return {}
      }
    },
    deleteCallback: {
      type: Function
    },
    afterDeleteCallback: {
      type: Function
    },
    pagination: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  watch: {},
  computed: {
    tableList: {
      get() {
        return this.list
      },
      set(val) {
        this.$emit('update:list', this.list)
      }
    },
    pageList() {
      let { pageSize, pageNumber } = this.page
      let start = (pageNumber - 1) * pageSize
      let end = pageNumber * pageSize
      let list = this.tableList.slice(start, end)
      return list
    },
    totalList: {
      get() {
        if (this.tableList.length === 0) {
          return []
        } else if (this.totalOption) {
          let list = [...this.tableList]
          let totalItem = _.cloneDeep(this.totalItem)
          this.totalOption.forEach((item) => {
            let tableOptionItem = this.tableOption.find((tItem) => tItem.name === item)
            if (tableOptionItem?.params?.digit) {
              totalItem[item] = this.getTotalByKey(
                this.tableList,
                item,
                tableOptionItem.params.digit
              )
            } else {
              totalItem[item] = this.getTotalByKey(this.tableList, item)
            }
          })
          list.push(totalItem)
          return list
        } else {
          return this.tableList
        }
      },
      set(val) {
        // this.$emit('update:list', this.totalList)
      }
    },
    showList() {
      if (this.pagination) {
        return this.pageList
      } else {
        return this.totalList
      }
    },
    tableItem() {
      let obj = {}
      this.tableOption.forEach((item) => {
        if (_.has(item, 'defaultValue')) {
          obj[item.name] = item.defaultValue
        } else if (item.type === 'percent' || item.type === 'money' || item.type === 'number') {
          obj[item.name] = null
        } else if (item.type === 'switch' || item.type === 'check') {
          obj[item.name] = false
        } else if (item.type === 'upload') {
          obj[item.name] = []
        } else {
          obj[item.name] = ''
        }
      })
      return obj
    },
    totalItem() {
      let obj = {
        isTotalRow: true
      }
      this.tableOption.forEach((item) => {
        obj[item.name] = ''
      })
      return obj
    },
    isBatch() {
      return !!this.batchOption.dialogName
    },
    pageTotal() {
      return this.tableList.length
    },
    showPagination(){
      return this.pagination && (this.pageTotal > this.page.pageSize)
    }
  },
  mounted() {},
  methods: {
    handleAddClick() {
      if (this.addCheck) {
        if (this.addCheck()) {
          this.tableList.push(_.cloneDeep(this.tableItem))
        }
      } else {
        this.tableList.push(_.cloneDeep(this.tableItem))
      }
    },
    handleDeleteClick(scope) {
      if (this.showDelete) {
        let index = scope.$index
        let deleteItem = this.tableList[index]
        if (this.deleteCallback) {
          this.deleteCallback(deleteItem, index)
        } else {
          this.tableList.splice(index, 1)
        }
        if (this.afterDeleteCallback) {
          this.afterDeleteCallback()
        }
      }
    },
    handleToggleClick() {
      this.close = !this.close
    },
    handleImportClick() {
      this.importShow = true
    },
    getImportData(data) {},
    change(row, index, callback) {
      if (callback) {
        callback(
          {
            index,
            row
          },
          this.parentIndex
        )
      }
    },
    filterCustom(item, row) {
      if (item.filter) {
        let filterObj = item.filter({ row: row, name: item.name, value: row[item.name] })
        return {
          params: filterObj.params || {},
          name: _.isNumber(filterObj.name) ? filterObj.name : (filterObj.name || '')
        }
      }
    },
    getComponentParams(item, row) {
      let params = {}
      if (item.params) {
        params = {
          ...params,
          ...item.params
        }
      }
      if (item.componentParams) {
        params = {
          ...params,
          ...item.componentParams
        }
      }
      if (item.filter) {
        params = {
          ...params,
          ...this.filterCustom(item, row).params
        }
      }
      return params
    },
    chartsData(item, row) {
      return item.echarts.load(row)
    },
    filterSelectList(list, row) {
      if (!list) {
        return []
      }
      if (_.isArray(list)) {
        return list
      } else {
        return list(row)
      }
    },
    handleBatchClick() {
      if (this.addCheck) {
        if (this.addCheck()) {
          this.batchDialogShow = true
        }
      } else {
        this.batchDialogShow = true
      }
    },
    inputWheelStop(e) {
      e.preventDefault()
    },
    computedIndex(index) {
      if (this.pagination) {
        let { pageNumber, pageSize } = this.page
        let start = (pageNumber - 1) * pageSize
        return start + index + 1
      }
      return index + 1
    },
    batchCallback(val){
      this.batchOption.callback(val,this.parentIndex)
    }
  }
})
</script>

<template>
  <div class="table-edit">
    <div class="header">
      <div class="title">
        <span>{{ title }}</span
        >（{{ list.length }}条）
      </div>
      <div class="button-group">
        <slot name="headerButton"></slot>
        <el-icon v-if="showUpload" @click="handleImportClick">
          <Upload />
        </el-icon>
        <el-button type="primary" text v-if="isBatch" @click="handleBatchClick">批量添加</el-button>
        <el-icon class="close-icon" @click="handleToggleClick" :class="{ close: close }">
          <ArrowUp />
        </el-icon>
      </div>
    </div>
    <div class="table" v-if="!close">
      <el-table :data="showList" stripe border empty-text="暂无数据">
        <el-table-column label="序号" align="center" width="80" fixed="left">
          <template #default="scope">
            <div v-if="scope.row.isTotalRow" class="total">汇总</div>
            <div v-else class="order-number">
              <span class="number">
                {{ computedIndex(scope.$index) }}
              </span>
            </div>
          </template>
        </el-table-column>
        <template v-for="item in tableOption" :key="item.name">
          <el-table-column
            :label="item.label"
            :width="item.width"
            :min-width="item.minWidth"
            :align="item.align"
            :fixed="item.fixed"
          >
            <template #header="scope">
              <span class="table-header-required" v-if="item.required">*</span>{{ item.label }}
            </template>
            <template #default="scope">
              <template v-if="item.echarts && !scope.row.isTotalRow">
                <ViewCharts
                  :icon="item.echarts.icon"
                  :type="item.echarts.type"
                  :load-data="chartsData(item, scope.row)"
                >
                </ViewCharts>
              </template>
              <template v-if="scope.row.isTotalRow || disabled">
                <div class="cell-content" v-if="item?.params?.digit">
                  {{ formatNumber(scope.row[item.name], item.params.digit) }}
                </div>
                <div class="cell-content" v-else>
                  {{ formatNumber(scope.row[item.name]) }}
                </div>
              </template>
              <template v-else>
                <SelectWithButton
                  v-if="item.type === 'selectWithButton'"
                  v-model="scope.row[item.name]"
                  v-bind="item.params"
                  :labelKey="item.params.labelKey"
                  :valueKey="item.params.valueKey"
                  :lightLabelKey="item.params.lightLabelKey"
                  :list="item.params.list"
                  :dialogComponents="item.params.dialogComponents"
                  :defaultLabel="item.params.defaultLabel && item.params.defaultLabel(scope.row)"
                  @selectChange="change($event, scope.$index, item.params.selectChange)"
                >
                </SelectWithButton>
                <el-input
                  :ref="item.name"
                  v-if="item.type === 'input'"
                  v-model="scope.row[item.name]"
                  :disabled="item.disabled"
                  @input="change(scope.row, scope.$index, item.change)"
                  v-bind="getComponentParams(item, scope.row)"
                ></el-input>
                <InputNumber
                  v-if="item.type === 'money' || item.type === 'percent'"
                  v-model="scope.row[item.name]"
                  :disabled="item.disabled"
                  @inputChange="change(scope.row, scope.$index, item.change)"
                  v-bind="getComponentParams(item, scope.row)"
                ></InputNumber>
                <el-input
                  v-if="item.type === 'number'"
                  type="number"
                  v-model="scope.row[item.name]"
                  :disabled="item.disabled"
                  placeholder="--"
                  @wheel.stop="inputWheelStop"
                  @input="change(scope.row, scope.$index, item.change)"
                  v-bind="getComponentParams(item, scope.row)"
                ></el-input>
                <DatePicker
                  v-if="item.type === 'date'"
                  v-model="scope.row[item.name]"
                  :disabled="item.disabled"
                  @change="change(scope.row, scope.$index, item.change)"
                ></DatePicker>
                <el-input
                  v-if="item.type === 'textarea'"
                  type="textarea"
                  resize="none"
                  v-model="scope.row[item.name]"
                  :disabled="item.disabled"
                  @input="change(scope.row, scope.$index, item.change)"
                ></el-input>
                <SelectUser
                  v-if="item.type === 'selectUser'"
                  v-model="scope.row[item.name]"
                  @change="change($event, scope.$index, item.change)"
                  v-bind="item.params"
                ></SelectUser>
                <div class="center-component" v-if="item.type === 'switch'">
                  <el-switch
                    v-model="scope.row[item.name]"
                    @change="change(scope.row, scope.$index, item.change)"
                    v-bind="item.params"
                  ></el-switch>
                </div>
                <SelectV2
                  v-if="item.type === 'select'"
                  v-model="scope.row[item.name]"
                  v-bind="item.params"
                  @selectChange="change($event, scope.$index, item.change)"
                  :defaultLabel="item.params.defaultLabel && item.params.defaultLabel(scope.row)"
                  :list="filterSelectList(item.params.list, scope.row)"
                ></SelectV2>
                <div v-if="item.type === 'dict'" class="custom">
                  <DictTag
                    :value="scope.row[item.name]"
                    v-bind="getComponentParams(item, scope.row)"
                  ></DictTag>
                </div>
                <div class="text" v-if="item.type === 'text'">
                  <template v-if="item.cellClick">
                    <el-button link type="primary" size="small" @click.stop="item.cellClick(scope)">
                      {{ filterCustom(item, scope.row).name }}
                    </el-button>
                  </template>
                  <template v-else-if="item.filter">
                    {{ filterCustom(item, scope.row).name }}
                  </template>
                  <template v-else>
                    {{ scope.row[item.name] }}
                  </template>
                </div>
                <div v-if="item.type === 'color'" class="custom">
                  <ColorPicker
                    v-model="scope.row[item.name]"
                    @change="change(scope.row, scope.$index, item.change)"
                    v-bind="item.params"
                  ></ColorPicker>
                </div>
                <div v-if="item.type === 'check'" class="custom">
                  <el-checkbox v-model="scope.row[item.name]"></el-checkbox>
                </div>
                <UploadFileInList
                  v-if="item.type === 'upload'"
                  v-model="scope.row[item.name]"
                  v-bind="getComponentParams(item, scope.row)"
                ></UploadFileInList>
                <div v-if="item.type === 'custom'" class="custom">
                  <component
                    :is="item.component"
                    v-bind="getComponentParams(item, scope.row)"
                    v-model="scope.row[item.name]"
                  >
                    <template v-if="item.filter">
                      {{ filterCustom(item, scope.row).name }}
                    </template>
                  </component>
                </div>
                <!--                   -->
                <!--                <TableTextarea-->
                <!--                  v-if="item.type === 'textarea'"-->
                <!--                  v-model="scope.row[item.name]"-->
                <!--                  @change="change(scope.row, scope.$index, item.change)"-->
                <!--                ></TableTextarea>-->
              </template>
            </template>
            <!--            <template v-if="disabled" #default="scope">-->
            <!--&lt;!&ndash;              <div class="cell-content">{{ scope.row[item.name] }}</div>&ndash;&gt;-->
            <!--              <div class="cell-content">{{ scope.row }}</div>-->
            <!--            </template>-->
          </el-table-column>
        </template>
        <el-table-column label="操作" align="center" width="80" fixed="right" v-if="showDelete">
          <template #default="scope">
            <el-button
              v-if="!scope.row.isTotalRow"
              link
              type="danger"
              size="small"
              icon="Delete"
              @click.stop="handleDeleteClick(scope)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer" v-if="!close">
      <span>
        <el-button icon="Plus" type="primary" plain @click="handleAddClick" v-if="!disabled && showAdd"></el-button>
      </span>
      <el-pagination
        v-if="showPagination"
        layout="total,sizes, prev, pager, next"
        background
        :page-sizes="[10, 20, 50]"
        v-model:current-page="page.pageNumber"
        v-model:page-size="page.pageSize"
        :total="pageTotal"
      />
    </div>
  </div>
  <ImportExcel v-model="importShow" @callback="getImportData"></ImportExcel>
  <CommonSelectDialog
    v-if="isBatch"
    v-model="batchDialogShow"
    v-bind="batchOption"
    :defaultConditions="batchOption.conditions"
    :callback="batchCallback"
    :multiple="true"
    :check="false"
  ></CommonSelectDialog>
</template>

<style scoped lang="scss">
.table-edit {
  width: 100%;
  background: var(--dubhe-fill-color-light);

  :deep(.table-header-required) {
    color: var(--dubhe-color-danger);
    margin-right: 4px;
  }

  .title {
    & > span {
      font-weight: bold;
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    border-bottom: 1px solid #eee;

    .title {
      & > span {
        line-height: 32px;
        font-weight: bold;
      }
    }

    .button-group {
      display: flex;
      align-items: center;
      justify-content: center;

      .dubhe-icon {
        font-size: 20px;
        margin: 0 10px;
        cursor: pointer;

        &:nth-last-child(1) {
          margin-right: 0;
        }
      }

      .close {
        transform: rotate(180deg);
      }
    }
  }

  .table {
    :deep(td.dubhe-table__cell) {
      padding: 0;

      &:first-child {
        .cell {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .cell {
        padding: 0;

        & > .text {
          display: flex;
          align-items: center;
          min-height: 30px;
          padding: 0 10px;
        }

        &:has(.dubhe-textarea) {
          position: absolute;
          top: 0;
          bottom: 0;
          height: 100%;
          width: 100%;

          .dubhe-textarea {
            position: absolute;
            top: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            border: 1px solid transparent;
            transition: 0.3s;

            &:hover {
              border: 1px solid var(--dubhe-color-primary);
            }

            textarea {
              height: 100%;
              box-shadow: none;
              border-radius: 0;
            }
          }
        }

        .cell-content,
        .total {
          font-weight: bold;
          color: #000;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .dubhe-input {
          width: 100%;
          border-width: 0;

          .dubhe-input__wrapper {
            border-radius: 0;
            box-shadow: 0 0 0 0;
            border: 1px solid transparent;
            transition: 0.3s;

            &:hover {
              border: 1px solid var(--dubhe-color-primary);
            }
          }
        }

        .dubhe-input-group__append {
          border-radius: 0;
          box-shadow: 0 0 0 0;
        }

        .dubhe-select {
          width: 100%;
        }

        .dubhe-button {
          border-radius: 0;
          border-width: 0;
        }

        .center-component {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .custom {
          height: 100%;
          display: flex;
          align-items: center;
          width: 100%;

          &:has(.dubhe-tag, .color-picker-wrapper,.dubhe-checkbox) {
            padding: 0 10px;
          }

          &:has(.dubhe-select) {
            & > * {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .order-number {
    .button {
      display: none;
    }

    &.canDelete {
      cursor: pointer;

      &:hover {
        .number {
          display: none;
        }

        .button {
          display: inline-block;
        }
      }
    }
  }

  .footer {
    background: #fff;
    display: flex;
    justify-content: space-between;

    .dubhe-button {
      width: 80px;
      border: none;
      border-radius: 0 0 4px 4px;
      margin-top: -4px;
      font-size: 20px;
      font-weight: bold;

      &:visited {
        background: #e1f6ff;
      }

      &:hover,
      &:focus {
        color: #3296fa;
        background: #c0ecff;
      }
    }
  }
}
</style>