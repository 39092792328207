// PerformanceEvaluationPlan
// 绩效模板
import axios from "./axios.config"
// 获取显示字段
export const getPerformanceEvaluationPlanFields = (tableId="performanceEvaluationPlan")=>axios.get(`/jaxrs/hr/performance_evaluation_plan/fields?tableId=${tableId}`)
// 查询条件
export const getPerformanceEvaluationPlanConditions = ()=>axios.get('/jaxrs/hr/performance_evaluation_plan/conditions')
// 查询
export const getPerformanceEvaluationPlanPage = (data)=>axios.post('/jaxrs/hr/performance_evaluation_plan/page',data)
// 详情
export const getPerformanceEvaluationPlanDetail = (data)=>axios.get(`/jaxrs/hr/performance_evaluation_plan/${data.id}`)
// 保存
export const addPerformanceEvaluationPlan = (data)=>axios.post(`/jaxrs/hr/performance_evaluation_plan`,data)
// 修改
export const editPerformanceEvaluationPlan = (data)=>axios.put(`/jaxrs/hr/performance_evaluation_plan`,data)
// 删除
export const deletePerformanceEvaluationPlan = (data)=>axios.delete(`/jaxrs/hr/performance_evaluation_plan/${data.id}`)
// 修改流程状态
export const editPerformanceEvaluationPlanStatus = (data)=>axios.post(`/jaxrs/hr/performance_evaluation_plan/status`,data)