import axios from './axios.config'
// 通话记录（call_records）
// 获得显示字段
export const getCallRecordsFields = (tableId='callRecords') =>axios.get(`/jaxrs/call_records/fields?tableId=${tableId}`)
// 获取查询条件
export const getCallRecordsConditions = () =>axios.get('/jaxrs/call_records/conditions')
// 获取查询
export const getCallRecordsPage = (data) =>axios.post('/jaxrs/call_records/page',data)
// 详情页
export const getCallRecordsDetail = (data) =>axios.get(`/jaxrs/call_records/${data.id}`)
// 保存
export const addCallRecords = (data) =>axios.post(`/jaxrs/call_records`,data)