import {
  addWorkOrder,
  editWorkOrder,
  getCheckReportDetail,
  getConsumerByPhone, getConsumerDetail,
  getExecuteModePage, getProjectDetail, getThirdCheckReportDetail,
  getUnitListLike,
  getWorkOrderDetail,
  reassignWorkOrder,
  submitWorkOrder
} from "@/axios";
import _ from 'lodash'

export const workOrderEditMixin = {
  emits: ['callback'],
  inject: ['phone'],
  data() {
    return {
      labelPosition: 'top',
      editForm: {
        id: null,
        createAt: null,
        createBy: null,
        creator: null,
        updateAt: null,
        updateBy: null,
        updater: null,
        isAudit: null,
        extFieldData: null,
        idx: null,
        code: null,
        outCode: null,
        workOrderType: null,
        workOrderMode: 'assign_order',
        workOrderStatus: null,
        workAuditStatus: null,
        invoiceStatus: null,
        workOrderSource: '自接',
        bizType: null,
        consumerId: null,
        consumerCode: null,
        consumerName: null,
        householdNumber: null,
        contact: null,
        contactPhone: null,
        takeDate: this.getTime(),
        visitDate: null,
        visitPeriod: 'all',
        leaderId: null,
        leaderName: null,
        receptionDeptId: null,
        receptionDeptName: null,
        projectId: null,
        projectName: null,
        district: null,
        street: null,
        community: null,
        address: null,
        lat: null,
        lng: null,
        assignerId: null,
        assignerName: null,
        assignAt: null,
        remark: null,
        refId: null,
        clockInAt: null,
        finishAt: null,
        serviceType: 'insure_out',
        materialCode: null,
        materialName: null,
        carryGoods: false,
        finishRemark: null,
        returnType: null,
        returnRemark: null,
        returnVisitDate: null,
        returnVisitRemark: null,
        drawBackReason: null,
        buyDate: null,
        materialId: null,
        payMode: null,
        paymentStatus: null,
        serviceAmount: null,
        laborAmount: null,
        materialAmount: null,
        actualAmount: null,
        discountReason: null,
        heatingCleaningPrice: null,
        heatingCleaningNum: null,
        repairType: null,
        faultDesc: null,
        faultType: null,
        processType: null,
        willful: null,
        recycle: null,
        issueDate: null,
        checkStatus: null,
        checkRemark: null,
        qa: null,
        overallScore: null,
        simpleAt: null,
        simplePersonId: null,
        simplePersonName: null,
        drawingImageList: [],
        returnImageList: [],
        payReceiptList: [],
        customerSignatureList: [],
        returnVisitList: [],
        imageList: [],
        workerList: [],
        usedMaterials: [],
        clockList: [],
        workNum: null,
        forceSubmit: null,
        isSubmit: null,
        checkReportId: null,
        checkReportCode: null,
        isOrderPay: null
      },
      baseForm: {
        id: null,
        createAt: null,
        createBy: null,
        creator: null,
        updateAt: null,
        updateBy: null,
        updater: null,
        isAudit: null,
        extFieldData: null,
        idx: null,
        code: null,
        outCode: null,
        workOrderType: null,
        workOrderMode: 'assign_order',
        workOrderStatus: null,
        workAuditStatus: null,
        invoiceStatus: null,
        workOrderSource: '自接',
        bizType: null,
        consumerId: null,
        consumerCode: null,
        consumerName: null,
        householdNumber: null,
        contact: null,
        contactPhone: null,
        takeDate: this.getTime(),
        visitDate: null,
        visitPeriod: 'all',
        leaderId: null,
        leaderName: null,
        receptionDeptId: null,
        receptionDeptName: null,
        projectId: null,
        projectName: null,
        district: null,
        street: null,
        community: null,
        address: null,
        lat: null,
        lng: null,
        assignerId: null,
        assignerName: null,
        assignAt: null,
        remark: null,
        refId: null,
        clockInAt: null,
        finishAt: null,
        serviceType: 'insure_out',
        materialCode: null,
        materialName: null,
        carryGoods: false,
        finishRemark: null,
        returnType: null,
        returnRemark: null,
        returnVisitDate: null,
        returnVisitRemark: null,
        drawBackReason: null,
        buyDate: null,
        materialId: null,
        payMode: null,
        paymentStatus: null,
        serviceAmount: null,
        laborAmount: null,
        materialAmount: null,
        actualAmount: null,
        discountReason: null,
        heatingCleaningPrice: null,
        heatingCleaningNum: null,
        repairType: null,
        faultDesc: null,
        faultType: null,
        processType: null,
        willful: null,
        recycle: null,
        issueDate: null,
        checkStatus: null,
        checkRemark: null,
        qa: null,
        overallScore: null,
        simpleAt: null,
        simplePersonId: null,
        simplePersonName: null,
        drawingImageList: [],
        returnImageList: [],
        payReceiptList: [],
        customerSignatureList: [],
        returnVisitList: [],
        imageList: [],
        workerList: [],
        usedMaterials: [],
        clockList: [],
        workNum: null,
        forceSubmit: false,
        isSubmit: false,
        checkReportId: null,
        checkReportCode: null,
        isOrderPay: null
      },
      materialOption: [
        {
          type: 'selectWithButton',
          required: true,
          params: {
            labelKey: 'name',
            valueKey: 'id',
            lightLabelKey: 'spec',
            dialogName: 'Material',
            conditions: [],
            remoteMethod: this.getMaterialSelectList,
            labelFilter: this.materialLabelFilter,
            selectChange: this.materialCheck,
            defaultLabel: this.materialDefaultLabel
          },
          name: 'materialId',
          label: '材料名称',
          width: 200,
          fixed: 'left'
        },
        {
          type: 'input',
          name: 'code',
          disabled: true,
          label: '材料编号'
        },
        {
          type: 'input',
          name: 'spec',
          disabled: true,
          label: '规格型号'
        },
        {
          type: 'input',
          name: 'unit',
          disabled: true,
          label: '单位'
        },
        {
          type: 'number',
          name: 'num',
          required: true,
          label: '数量'
        }
      ],
      materialNoEditOption: [
        {
          name: 'name',
          label: '材料名称',
          width: 200,
          fixed: 'left'
        },
        {
          name: 'code',
          label: '材料编号'
        },
        {
          name: 'spec',
          label: '规格型号'
        },
        {
          name: 'unit',
          label: '单位'
        },
        {
          name: 'num',
          label: '数量'
        }
      ],
      workerList: [],
      projectList: [],
      materialList: [],
      unitList: []
    }
  },
  props: {
    editId: {},
    refId: {},
    reassign: {},
    workOrderType: {},
    projectType: {},
    batch: {},
    checkReportId: {}
  },
  computed: {
    isAdd() {
      return !this.editForm.id
    },
    title() {
      let name = this.dictStore.getDictItemByTypeValue(
        'workOrderType',
        this.editForm.workOrderType
      ).label
      let baseTitle = `${name}工单`
      if (this.isAdd) {
        return `${baseTitle}（新增）`
      } else {
        return `${baseTitle}（编辑）`
      }
    },
    isDefaultRepair() {
      return !!(this.workOrderType && this.workOrderType === 'repair')
    },
    materialConditions() {
      if (this.editForm.workOrderType === 'repair') {
        return []
      }
      return [
        {
          alias: 'm',
          field: 'isCivilUse',
          operator: 'eq',
          params: [1]
        }
      ]
    },
    materialSelectList(){
      let list = this.materialList.map(item => {
        return {
          value: this.materialLabelFilter(item)
        }
      })
      return list
    }
  },
  methods: {
    initWorkerList() {
      let reqData = {
        pageSize: 99999,
        pageNumber: 1,
        viewId: 'DD_STAT_WORKLOAD_02',
        params: { unitId: '' }
      }
      getExecuteModePage(reqData).then((res) => {
        this.workerList = res.data
      })
    },
    initUnitList() {
      getUnitListLike({ key: ' ' }).then((res) => {
        if (res) {
          this.unitList = res.data
        }
      })
    },
    async initProjectList() {
      let list = await this.getProjectSelectList()
      this.projectList = list
    },
    async initMaterialList() {
      let list = await this.getMaterialSelectList()
      this.materialList = list
    },
    getDetail() {
      this.loading = true
      if (this.editId) {
        getWorkOrderDetail({ id: this.editId })
          .then((res) => {
            this.editForm = res.data
            if (this.reassign) {
              this.editForm.id = null
              this.editForm.workOrderStatus = null
              this.editForm.code = null
              this.editForm.returnType = null
              this.editForm.returnImageList = []
              this.editForm.returnRemark = null
              this.editForm.leaderId = null
              this.editForm.leaderName = null
              this.editForm.visitDate = null
              this.editForm.checkStatus = null
              this.editForm.checkRemark = null
              this.editForm.finishRemark = null
              this.editForm.repairType = null
              this.editForm.faultType = null
              this.editForm.processType = null
              this.editForm.willful = null
              this.editForm.recycle = null
              this.editForm.drawingImageList = []
              this.editForm.customerSignatureList = []
              this.editForm.imageList = []
              this.editForm.payMode = null
              this.editForm.laborAmount = null
              this.editForm.materialAmount = null
              this.editForm.heatingCleaningNum = null
              this.editForm.heatingCleaningPrice = null
              this.editForm.receivableAmount = null
              this.editForm.actualAmount = null
              this.editForm.paymentStatus = null
              this.editForm.discountReason = null
              this.editForm.payReceiptList = null
              this.editForm.returnVisitList = []
              this.editForm.returnVisitRemark = null
              if (this.refId) {
                this.editForm.refId = this.refId
              }
            }
          })
          .catch(() => {})
          .finally(() => {
            this.loading = false
          })
      } else {
        this.editForm = _.cloneDeep(this.baseForm)
        if (this.workOrderType) {
          this.editForm.workOrderType = this.workOrderType
        }
        if (this.editForm.workOrderType === 'install') {
          this.editForm.carryGoods = true
        }
        if (this.phone) {
          this.getConsumerByPhone(this.phone)
        }
        if (this.isDefaultRepair) {
          this.setUnitByName('维保售后本部')
        }
        if (this.checkReportId) {
          this.checkReportChange({id:this.checkReportId})
        }
        this.$refs.form.resetFields()
        this.loading = false
      }
    },
    setUnitByName(unitName) {
      let unitId = this.dictStore.getDictItemByTypeLabel('unit', unitName).val
      let unit = this.unitList.find((item) => item.unique === unitId)
      this.unitChange(unit)
    },
    unitChange(val) {
      if (val) {
        this.editForm.receptionDeptId = val.id;
        this.editForm.receptionDeptName = val.name;
      } else {
        this.editForm.receptionDeptId = null;
        this.editForm.receptionDeptName = null;
      }
      this.leaderChange();
    },
    leaderChange(val) {
      if (val) {
        this.editForm.leaderId = val.person;
        this.editForm.leaderName = val.name;
      } else {
        this.editForm.leaderId = null;
        this.editForm.leaderName = null;
      }
    },
    getConsumerByPhone(phone) {
      getConsumerByPhone({ phone })
        .then((res) => {
          if (res.data.length > 0) {
            let consumer = res.data[0]
            this.consumerChange(consumer)
          } else {
            this.editForm.contactPhone = phone
          }
        })
        .catch(() => {})
    },
    consumerChange(val) {
      val = val || {}
      this.editForm.consumerId = val.id
      this.editForm.consumerName = val.name
      this.editForm.contact = val.contact
      this.editForm.contactPhone = val.contactPhone
      this.editForm.district = val.district
      this.editForm.street = val.street
      this.editForm.community = val.community
      this.editForm.address = val.address
      this.editForm.lat = val.latitude
      this.editForm.lng = val.longitude
      this.editForm.householdNumber = val.householdNumber
    },
    clearConsumer() {
      this.editForm.consumerId = null
      this.editForm.consumerName = null
      this.editForm.contact = null
      this.editForm.contactPhone = null
    },
    close(isSubmit = false) {
      if (isSubmit) {
        this.$message.success('提交成功')
      } else {
        this.$message.success('保存成功')
      }
      this.showDrawer = false
      this.$emit('callback', this.isAdd)
    },
    async reassignWorkOrder() {
      if (this.reassign) {
        let res = await reassignWorkOrder({ id: this.editId })
        return !!res
      } else {
        return true
      }
    },
    async handleSaveClick(isSubmit, forceSubmit) {
      let saveMethod
      if (isSubmit) {
        saveMethod = submitWorkOrder
      } else if (this.reassign) {
        saveMethod = addWorkOrder
      } else {
        if (this.isAdd) {
          saveMethod = addWorkOrder
        } else {
          saveMethod = editWorkOrder
        }
      }
      if (this.batch) {
        this.clearConsumer()
      }
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          // let hasReassign = await this.reassignWorkOrder()
          // if (!hasReassign) {
          //   return
          // }
          this.loading = true
          let data = _.cloneDeep(this.editForm)
          if (forceSubmit) {
            data.forceSubmit = true
          }
          saveMethod(data)
            .then((res) => {
              if (res) {
                this.close(isSubmit)
              }
            })
            .catch((val) => {
              this.loading = false
              if (val.data === 'duplicate') {
                this.$messageBox
                  .confirm(`${val.message}，是否继续`, '提示', {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning'
                  })
                  .then(() => {
                    this.handleSaveClick(isSubmit, true)
                  })
                  .catch(() => {})
              }
            })
        }
      })
    },
    projectChange(val = {}) {
      this.editForm.projectId = val.id
      this.editForm.projectName = val.name
      if (val.pkgId) {
        this.editForm.materialId = val.pkgId
        this.editForm.materialName = val.pkgName
      }
      if (val.maintenanceStatus === 'maintenance' && val.allMaintenanceContractNum > 0) {
        this.editForm.serviceType = 'insure_in'
      } else {
        this.editForm.serviceType = 'insure_out'
      }
    },
    checkReportChange(val = {}) {
      if (val.id) {
        getCheckReportDetail({ id: val.id }).then((res) => {
          if (res?.data) {
            console.log(res.data);
            this.editForm.checkReportId = res.data.id
            this.editForm.checkReportCode = res.data.code
            this.editForm.usedMaterials = res.data.items.map((item) => {
              return {
                materialId: item.materialId,
                name: item.materialName,
                code: item.materialCode,
                spec: item.materialSpec,
                unit: item.materialUnit,
                num: item.num
              }
            })
            this.editForm.drawingImageList = res.data.drawingImageList || []
            if(res.data.consumerId){
              getConsumerDetail({id:res.data.consumerId}).then((consumerRes)=>{
                if(consumerRes.data){
                  this.consumerChange(consumerRes.data)
                }
              })
            }
            if(res.data.projectId){
              getProjectDetail({id:res.data.projectId}).then((projectRes)=>{
                if(projectRes.data){
                  this.projectChange(projectRes.data)
                }
              })
            }
          }
        })
      }else{
        this.editForm.checkReportId = null
        this.editForm.checkReportCode = null
        this.editForm.usedMaterials = []
        this.editForm.drawingImageList = []
      }
    },
    thirdCheckReportChange(val = {}) {
      if (val.id) {
        getThirdCheckReportDetail({ id: val.id }).then((res) => {
          if (res?.data) {
            console.log(res.data);
            this.editForm.checkReportId = val.id
            this.editForm.checkReportCode = val.code
            this.editForm.usedMaterials = res.data.items.map((item) => {
              return {
                materialId: item.materialId,
                name: item.materialName,
                code: item.materialCode,
                spec: item.materialSpec,
                unit: item.materialUnit,
                num: item.num
              }
            })
            if(res.data.consumerId){
              getConsumerDetail({id:res.data.consumerId}).then((consumerRes)=>{
                if(consumerRes.data){
                  this.consumerChange(consumerRes.data)
                }
              })
            }
            if(res.data.maintenanceProjectId){
              getProjectDetail({id:res.data.maintenanceProjectId}).then((projectRes)=>{
                if(projectRes.data){
                  this.projectChange(projectRes.data)
                }
              })
            }
          }
        })
      }else{
        this.editForm.checkReportId = null
        this.editForm.checkReportCode = null
        this.editForm.usedMaterials = []
      }
    },
    materialChange(val) {
      if (val) {
        let name = this.materialLabelFilter(val)
        this.editForm.materialId = val.id
        this.editForm.materialName = name
      } else {
        this.editForm.materialId = null
        this.editForm.materialName = null
      }
    },
    materialSelectQuery(queryString,callback){
      let list = _.cloneDeep(this.materialSelectList)
      if(queryString && queryString !== 'null'){
        list = list.filter(item=>item.value.includes(queryString))
      }
      callback(list)
    },
    materialLabelFilter(item) {
      if (item.spec) {
        return `${item.name}（${item.spec}）`
      }
      return item.name
    },
    handleResetClick() {
      this.editForm = _.cloneDeep(this.baseForm)
    },
    showByAuditStatus(list) {
      if (!this.editForm.workOrderStatus) {
        return true
      }
      return !!list.includes(this.editForm.workOrderStatus)
    },
    materialCheck(val = {}) {
      let item = this.editForm.usedMaterials[val.index]
      item.materialId = val.row.id
      item.name = val.row.name
      item.code = val.row.code
      item.spec = val.row.spec
      item.unit = val.row.unit
    },
    materialDefaultLabel(item) {
      return item.name || ''
    },
    imageCheckChange(filesData) {
      if (this.editForm.imageList) {
        this.editForm.imageList.push(...filesData)
      } else {
        this.editForm.imageList = filesData
      }
    }
  }
}
