import axios from './axios.config'
// 获取仓库显示字段
export const getStoreFields = (tableId='store') => axios.get(`/jaxrs/store/fields?tableId=${tableId}`)
// 获取仓库查询条件
export const getStoreConditions = () => axios.get('/jaxrs/store/conditions')
// 获取仓库分页数据
export const getStorePage = (data) => axios.post('/jaxrs/store/page',data)
// 获取仓库详情
export const getStoreDetail = (data) => axios.get(`/jaxrs/store/${data.id}`)
// 修改仓库
export const editStore = (data) => axios.put(`/jaxrs/store`,data)
// 添加仓库
export const addStore = (data) => axios.post(`/jaxrs/store`,data)
// 删除仓库
export const deleteStore = (data) => axios.delete(`/jaxrs/store/${data.id}`)
// 获取默认领料仓库
export const getDefaultStore = () => axios.get('/jaxrs/store/default_store')

