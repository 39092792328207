const components = {}

let getModules = () => {
  return import.meta.glob('./**/*.vue',{ eager: true })
}

const modulesFiles = getModules()

for (const key of Object.keys(modulesFiles)) {
  let file = modulesFiles[key]
  components[file.default.name] = file.default
}

export default components