import axios from './axios.config'
// 显示字段信息 FireEquipment
export const getFireEquipmentFields = (tableId = 'fireEquipment') =>axios.get(`/jaxrs/fire_equipment/fields?tableId=${tableId}`)
// 查询条件
export const getFireEquipmentConditions = ()=>axios.get('/jaxrs/fire_equipment/conditions')
// 查询
export const getFireEquipmentPage = (data)=>axios.post('/jaxrs/fire_equipment/page',data)
// 导出
export const exportFireEquipment = (data) =>axios.post(`/jaxrs/fire_equipment/export`,data,{
  responseType:'blob'
})
// 详情
export const getFireEquipmentDetail = (data) =>axios.get(`/jaxrs/fire_equipment/${data.id}`)
// 保存
export const addFireEquipment = (data)=>axios.post(`/jaxrs/fire_equipment`,data)
// 编辑
export const editFireEquipment = (data) =>axios.put(`/jaxrs/fire_equipment`,data)
// 删除
export const deleteFireEquipment = (data)=>axios.delete(`/jaxrs/fire_equipment/${data.id}`)