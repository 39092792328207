import axios from './axios.config'
//获取显示字段
export const getHighTemperatureAllowanceFields = (tableId='highTemperatureAllowance') =>axios.get(`/jaxrs/hr/high_temperature_allowance/fields?tableId=${tableId}`)
//获取查询条件
export const getHighTemperatureAllowanceConditions = () =>axios.get('/jaxrs/hr/high_temperature_allowance/conditions')
//查询
export const getHighTemperatureAllowancePage = (data) =>axios.post('/jaxrs/hr/high_temperature_allowance/page',data)
//详情
export const getHighTemperatureAllowanceDetail = (data) =>axios.get(`/jaxrs/hr/high_temperature_allowance/${data.id}`)
//保存
export const addHighTemperatureAllowance = (data) =>axios.post(`/jaxrs/hr/high_temperature_allowance`,data)
//编辑
export const editHighTemperatureAllowance = (data) =>axios.put(`/jaxrs/hr/high_temperature_allowance`,data)
//删除
export const deleteHighTemperatureAllowance = (data) =>axios.delete(`/jaxrs/hr/high_temperature_allowance/${data.id}`)