import axios from './axios.config'
// 证照管理
export const getCertificateFields = (tableId= 'Certificate')=>axios.get(`/jaxrs/certificate/fields?tableId=${tableId}`)
// 获取查询条件
export const getCertificateConditions = () =>axios.get('/jaxrs/certificate/conditions')
// 查询
export const getCertificatePage = (data)=>axios.post('/jaxrs/certificate/page',data)
// 详情
export const getCertificateDetail = (data)=>axios.get(`/jaxrs/certificate/${data.id}`)
// 保存
export const  addCertificate = (data) =>axios.post(`/jaxrs/certificate`,data)
// 修改
export const editCertificate = (data)=>axios.put(`/jaxrs/certificate`,data)
// 删除
export const deleteCertificate = (data)=>axios.delete(`/jaxrs/certificate/${data.id}`)