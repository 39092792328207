<script>
import { defineComponent } from "vue";
import { downloadFileByFilename } from "@/axios";

export default defineComponent({
  name: "DownloadAll",
  props:{
    list:{},
    urlKey: {
      type: String,
      default() {
        return 'url'
      }
    },
    nameKey: {
      type: String,
      default() {
        return 'label'
      }
    },
    filenameKey:{
      type: String,
      default() {
        return 'filename'
      }
    }
  },
  computed:{
    useItem(){
      return this.urlKey === '$'
    }
  },
  methods:{
    downloadAll(){
      if(!this.list || this.list.length === 0){
        this.$message.warning('没有附件需要下载')
      }
      this.list.forEach(item=>{
        this.downloadItem(item)
      })
    },
    downloadItem(item,index) {
      let url, name, filename;
      url = item[this.urlKey];
      name = item[this.nameKey];
      filename = item[this.filenameKey];
      downloadFileByFilename({filename}).then(res=>{
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = URL.createObjectURL(res);
        a.download = name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
    }
  }
});
</script>

<template>
  <el-button class="download-all" type="primary" text @click="downloadAll">全部下载</el-button>
</template>

<style scoped lang="scss">
</style>