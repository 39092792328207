<script>
import { defineComponent } from 'vue'
import { detailMixins } from '@/common/detailMixins'
import {
  getCommonSupplierDetail,
  addCommonSupplier,
  editCommonSupplier
} from '@/axios'
import _ from 'lodash'

export default defineComponent({
  name: 'CommonSupplierEdit',
  mixins: [detailMixins],
  components: {},
  props: {
    editId: {}
  },
  data() {
    return {
      labelPosition: 'top',
      editForm: {
        id: null,
        createAt: null,
        createBy: null,
        creator: null,
        updateAt: null,
        updateBy: null,
        updater: null,
        name: null,
        contact: null,
        phone: null,
        accountName: null,
        bankName: null,
        bankCode: null,
        address: null,
        manageAddress:null,
        remark: null,
        deleted: null,
        mainProduct:null,
        paymentPlan:null,
        images: null,
        imageList: [],
        files: null,
        fileList: [],
        field1: null,
        field2: null,
        field3: null,
        field4: null,
        field5: null,
        field6: null,
        amount: null
      },
      baseForm: {
        id: null,
        createAt: null,
        createBy: null,
        creator: null,
        updateAt: null,
        updateBy: null,
        updater: null,
        name: null,
        contact: null,
        phone: null,
        accountName: null,
        bankName: null,
        bankCode: null,
        address: null,
        manageAddress:null,
        remark: null,
        deleted: null,
        mainProduct:null,
        paymentPlan:null,
        images: null,
        imageList: [],
        files: null,
        fileList: [],
        field1: null,
        field2: null,
        field3: null,
        field4: null,
        field5: null,
        field6: null,
        amount: null
      },
      rules:{
        name: [
          { required: true, message: '请输入名称', trigger: 'change' }
        ],
      }
    }
  },
  computed: {
    isAdd(){
      return !this.editForm.id
    },
    title() {
      if (this.isAdd) {
        return '合同用户信息（新增）'
      } else {
        return '合同用户信息（编辑）'
      }
    }
  },
  watch: {},
  methods: {
    close(data) {
      this.$message.success('保存成功')
      this.showDrawer = false
      this.$emit('callback', this.isAdd,data)
    },
    getDetail(){
      this.loading = true
      if(this.editId){
        getCommonSupplierDetail({ id: this.editId }).then((res) => {
          this.editForm = res.data
        })
            .catch(()=>{})
            .finally(()=>{
              this.loading = false
            })
      }else{
        this.editForm = _.cloneDeep(this.baseForm)
        this.loading = false
      }
    },
    // 上传图片
    imageCheckChange(filesData) {
      if (this.editForm.imageList) {
        this.editForm.imageList.push(...filesData)
      } else {
        this.editForm.imageList = filesData
      }
    },
    fileCheckChange(filesData) {
      if (this.editForm.fileList) {
        this.editForm.fileList.push(...filesData)
      } else {
        this.editForm.fileList = filesData
      }
    },
    async handleSaveClick(){
      let saveMethod
      if (this.isAdd) {
        saveMethod = addCommonSupplier
      } else {
        saveMethod = editCommonSupplier
      }
     this.$refs.form.validate((valid) =>{
       if(valid){
         this.loading = true
         saveMethod(this.editForm)
           .then((res) =>{
             if(res){
               this.close(res.data)
             }
           })
           .catch(() => {
             this.loading = false
           })
       }
     })
    },
  }
})
</script>
<template>
  <el-drawer v-model="showDrawer" size="95%" direction="btt" destroy-on-close @open="getDetail">
    <template #header>
      <div>{{ title }}</div>
    </template>
    <template #default>
      <div class="detail-wrapper" >
        <Divider title="基本信息"></Divider>
        <el-form :model="editForm" :label-position="labelPosition"  :rules="rules" ref="form" v-loading="loading">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="名称" prop="name">
                <el-input v-model="editForm.name" placeholder="请输入"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系人" prop="contact">
                <el-input v-model="editForm.contact" placeholder="请输入"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系电话" prop="phone">
                <el-input v-model="editForm.phone" placeholder="请输入"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="开户名" prop="accountName">
                <el-input v-model="editForm.accountName" placeholder="请输入"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="开户行" prop="bankName">
                <el-input v-model="editForm.bankName" placeholder="请输入"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="银行账号" prop="bankCode">
                <el-input v-model="editForm.bankCode" placeholder="请输入"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="注册地址">
                <el-input v-model="editForm.address" placeholder="请输入"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="经营地址">
                <el-input v-model="editForm.manageAddress" placeholder="请输入"/>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注">
                <el-input type="textarea" v-model="editForm.remark" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="图片">
                <div class="max-width">
                  <UploadFile @checkChange="imageCheckChange"></UploadFile>
                  <FileList v-model:list="editForm.imageList"></FileList>
                </div>
              </el-form-item>
            </el-col>
            <el-col :gutter="24">
              <el-form-item label="附件">
                <div class="max-width" prop="fileList">
                  <UploadFile @checkChange="fileCheckChange" type="file"></UploadFile>
                  <FileList v-model:list="editForm.fileList" type="file"></FileList>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </template>
    <template #footer>
      <div class="drawer-footer">
        <el-button type="primary" @click="handleSaveClick" icon="Document" :disabled="loading">保存</el-button>
      </div>
    </template>
  </el-drawer>
</template>
<style scoped lang="scss">
</style>