import axios from './axios.config'
// 获取显示字段 InsuranceContributionRetes
export const getInsuranceContributionRetesFields = (tableId='insuranceContributionRetes')=>axios.get(`/jaxrs/hr/insurance_contribution_retes/fields?tableId=${tableId}`)
// 查询字段
export const getInsuranceContributionRetesConditions = ()=>axios.get('/jaxrs/hr/insurance_contribution_retes/conditions')
// 分页查询
export const getInsuranceContributionRetesPage = (data)=>axios.post('/jaxrs/hr/insurance_contribution_retes/page',data)
// 详情
export const getInsuranceContributionRetesDetail = (data)=>axios.get(`/jaxrs/hr/insurance_contribution_retes/${data.id}`)
// 保存
export const addInsuranceContributionRetes = (data)=>axios.post(`/jaxrs/hr/insurance_contribution_retes`,data)
// 修改
export const editInsuranceContributionRetes = (data)=>axios.put(`/jaxrs/hr/insurance_contribution_retes`,data)
// 删除
export const deleteInsuranceContributionRetes = (data)=>axios.delete(`/jaxrs/hr/insurance_contribution_retes/${data.id}`)