import axios from './axios.config'
// 离职申请（ResignationApplication）
// 获取显示字段
export const getResignationApplicationFields = (tableId='resignationApplication')=>axios.get(`/jaxrs/hr/resignation/fields?tableId=${tableId}`)
// 查询条件
export const getResignationApplicationConditions = () =>axios.get('/jaxrs/hr/resignation/conditions')
// 查询
export const getResignationApplicationPage = (data) =>axios.post('/jaxrs/hr/resignation/page',data)
// 详情
export const getResignationApplicationDetail = (data) =>{
    let url
    if(data.oper){
        url =`/jaxrs/hr/resignation/${data.id}?oper=${data.oper}`
    }else{
        url=`/jaxrs/hr/resignation/${data.id}`
    }
    return axios.get(url)
}
// 添加(保存)
export const addResignationApplication = (data) =>axios.post(`/jaxrs/hr/resignation`,data)
// 修改
export const editResignationApplication = (data) =>axios.put(`/jaxrs/hr/resignation`,data)
// 删除
export const deleteResignationApplication = (data) =>axios.delete(`/jaxrs/hr/resignation/${data.id}`)
// // 修改流程审核状态
// export const changeResignationApplicationProcessStatus = (data) =>axios.put(`/jaxrs/hr/resignation/status`,data)
// // 确认离职
// export const changeSeparationStatus = (data) =>axios.put(`/jaxrs/hr/resignation/confirm/${data.id}`,data)
// 确认离职
export const confirmResignationApplication = (data) =>axios.put(`/jaxrs/hr/resignation/resignation`,data)
