import axios from './axios.config'
// 工伤事故管理 WorkAccident
// 显示字段
export const getWorkAccidentFields = (tableId = 'workAccident') =>axios.get(`/jaxrs/work_accident/fields?tableId = ${tableId}`)
// 查询
export const getWorkAccidentConditions = ()=>axios.get('/jaxrs/work_accident/conditions')
// 分页
export const getWorkAccidentPage = (data)=>axios.post('/jaxrs/work_accident/page',data)
// 详情
export const getWorkAccidentDetail = (data)=>axios.get(`/jaxrs/work_accident/${data.id}`)
// 保存
export const addWorkAccident = (data)=>axios.post(`/jaxrs/work_accident`,data)
// 编辑
export const editWorkAccident = (data)=>axios.put(`/jaxrs/work_accident`,data)
// 删除
export const deleteWorkAccident = (data)=>axios.delete(`/jaxrs/work_accident/${data.id}`)
// 导出
export const exportWorkAccident = (data) =>axios.post(`/jaxrs/work_accident/export`,data,{
  responseType:'blob'
})