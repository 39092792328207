<script>
import { defineComponent } from 'vue'
import { detailMixins } from '../../common/detailMixins'
import * as RemixIcon from '@remixicon/vue'
import _ from 'lodash'

let list = []
for (let key in RemixIcon) {
  list.push({
    name: _.replace(_.kebabCase(key),'ri-',''),
    value: key
  })
}
export default defineComponent({
  name: 'RemixIconSelectDrawer',
  mixins: [detailMixins],
  emits: ['update:modelValue'],
  data() {
    return {
      list: list,
      searchValue: ''
    }
  },
  props: {
    modelValue: {}
  },
  computed: {
    filterList() {
      if (!this.searchValue) {
        return this.list
      }
      return this.list.filter(item=>item.name.includes(this.searchValue))
    }
  },
  methods: {
    init() {
      this.searchValue = ''
    },
    check(item) {
      this.$emit('update:modelValue', item.value)
      this.showDrawer = false
    }
  }
})
</script>

<template>
  <el-drawer v-model="showDrawer" size="95%" direction="btt" destroy-on-close @opened="init">
    <template #header>
      <div>选择图标</div>
    </template>
    <template #default>
      <div class="remix-icon-select-drawer-wrapper">
        <div class="wrapper-header">
          <el-input v-model="searchValue" placeholder="搜索图标"></el-input>
        </div>
        <div class="wrapper-body">
          <div class="wrapper-body-absolute">
            <div class="icon-item" v-for="(item, index) in filterList" :key="index" @click="check(item)">
              <component :is="item.value"></component>
              <div class="icon-item-name">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </el-drawer>
</template>

<style scoped lang="scss">
.remix-icon-select-drawer-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  & > * {
    width: 100%;
  }

  .wrapper-header {
    padding: 10px 0;
  }

  .wrapper-body {
    flex: 1;
    position: relative;

    .wrapper-body-absolute {
      position: absolute;
      height: 100%;
      width: 100%;
      overflow: auto;

      .icon-item {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 5px;
        font-size: 16px;
        cursor: pointer;
        width: 180px;
        height: 100px;

        &.no-padding {
          padding: 0;
        }

        .icon-item-name {
          margin-top: 10px;
          font-size: 14px;
        }
      }
    }
  }
}
</style>