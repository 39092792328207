import axios from './axios.config'
// 进项发票  inputInvoice
// 获取显示字段
export const getInputInvoiceFields = (tableId='inputInvoice')=>axios.get(`/jaxrs/invoice_in/fields?tableId=${tableId}`)
// 获取查询条件
export const getInputInvoiceConditions = () =>axios.get('/jaxrs/invoice_in/conditions')
// 查询
export const getInputInvoicePage = (data) =>axios.post('/jaxrs/invoice_in/page',data)
// 详情
export const getInputInvoiceDetail = (data) =>{
  let url
  if (data.oper){
    url=`/jaxrs/invoice_in/${data.id}?oper=${data.oper}`
  }else{
    url=`/jaxrs/invoice_in/${data.id}`
  }
  return axios.get(url)
}
// 保存
export const addInputInvoice = (data) =>axios.post(`/jaxrs/invoice_in`,data)
// 修改
export const editInputInvoice = (data) =>axios.put(`/jaxrs/invoice_in`,data)
// 删除
export const deleteInputInvoice = (data) =>axios.delete(`/jaxrs/invoice_in/${data.id}`)
// 发票识别
export const getInvoiceIn = (data) =>axios.post(`/jaxrs/invoice_in/ocr`,data,{timeout:120000})
// 批量保存
export const batchSaveVoiceIn = (data) =>axios.post(`/jaxrs/invoice_in/batch_save`,data)