import axios from './axios.config'
// 获取显示字段 PhysicalExaminationRegister
export const getPhysicalExaminationRegisterFields = (tableId='physicalExaminationProject')=>axios.get(`/jaxrs/hr/physical_examination_project/fields?tableId=${tableId}`)
// 查询字段
export const getPhysicalExaminationRegisterConditions = ()=>axios.get('/jaxrs/hr/physical_examination_project/conditions')
// 分页查询
export const getPhysicalExaminationRegisterPage = (data)=>axios.post('/jaxrs/hr/physical_examination_project/page',data)
// 详情
export const getPhysicalExaminationRegisterDetail = (data)=>axios.get(`/jaxrs/hr/physical_examination_project/${data.id}`)
// 保存
export const addPhysicalExaminationRegister = (data)=>axios.post(`/jaxrs/hr/physical_examination_project`,data)
// 修改
export const editPhysicalExaminationRegister = (data)=>axios.put(`/jaxrs/hr/physical_examination_project`,data)
// 删除
export const deletePhysicalExaminationRegister = (data)=>axios.delete(`/jaxrs/hr/physical_examination_project/${data.id}`)