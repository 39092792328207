<script>
import { defineComponent,computed } from 'vue'
import MenuTree from './MenuTree.vue'

export default defineComponent({
  name: 'PageMenu',
  components: {
    MenuTree
  },
  props: {
    width: {
      type: Number,
      default() {
        return 200
      }
    }
  },
  data() {
    return {
      open: true
    }
  },
  provide() {
    return {
      open: computed(() => this.open)
    }
  },
  watch:{
  },
  computed: {
    menuStyle() {
      if (this.open) {
        return {
          width: `${this.width}px`
        }
      } else {
        return {
          // width: `auto`
          width: `64px`
        }
      }
    },
    permissionMenuList() {
      let list = this.permissionStore.permissionsMenuList.filter((item) => {
        if (item.children && item.children.length === 0) {
          return false
        }
        return true
      })
      return list
    }
  },
  methods: {
    handleToggleClick() {
      this.open = !this.open
    }
  }
})
</script>

<template>
  <div class="menu" :style="menuStyle">
    <div class="menu-list">
      <MenuTree :open="open" :list="permissionMenuList"></MenuTree>
    </div>
    <div class="toggle" :class="{ open: open }" @click="handleToggleClick">
      <el-icon v-if="open">
        <Fold />
      </el-icon>
      <el-icon v-else>
        <Expand />
      </el-icon>
    </div>
  </div>
</template>

<style scoped lang="scss">
.menu {
  height: 100%;
  flex-shrink: 0;
  transition: all linear 0.2s;
  background: #fff;
  box-shadow: var(--dubhe-box-shadow-lighter);
  z-index: 1;
  user-select: none;
  :deep(.dubhe-sub-menu__title) {
    &:hover {
      background: var(--dubhe-color-primary-light-9);
      cursor: pointer;
    }
  }
  :deep(.dubhe-menu) {
    border-right: 0;

    ul.dubhe-menu {
      background: #fafafa;
      li {
        padding-left: 46px;
      }
    }

    &.dubhe-menu--collapse{
      .menu-item-name{
        display: none;
      }
    }
    .dubhe-badge{
      display: flex;
      align-items: center;
      height: 100%;
      //justify-content: center;
    }
    .dubhe-badge__content{
      top: 11px;
    }
  }

  .menu-list {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 50px);
  }

  .toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-size: 24px;
    cursor: pointer;

    &.open {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding-right: 13px;
    }
  }
}
</style>