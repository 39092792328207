<script>
import { defineComponent } from 'vue'
import { logout } from '@/axios'
import { layout, o2 } from '@o2oa/component'
import Cookies from 'js-cookie'
import ChangePasswordDialog from '@/components/PageHeader/ChangePasswordDialog.vue'
import MessageBox from '@/components/MessageBox/MessageBox.vue'
import FullScreenIcon from "@/components/PageHeader/FullScreenIcon.vue";

export default defineComponent({
  name: 'PageHeaderRight',
  components: {
    MessageBox,
    ChangePasswordDialog,
    FullScreenIcon
  },
  data() {
    return {
      showChangePasswordDialog: false,
      showMessageBoxDrawer: false
    }
  },
  methods: {
    handleLogoutClick() {
      this.$messageBox
        .confirm('确定要注销吗？', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        })
        .then(() => {
          logout().then((res) => {
            layout.session.user.token = ''
            Cookies.remove('x-token')
            this.$message.success('注销成功')
            this.$router.push({
              name: 'Login'
            })
          })
        })
        .catch((err) => {})
    },
    handleEditPasswordClick() {
      this.showChangePasswordDialog = true
    },
    showMessageBox() {
      this.showMessageBoxDrawer = true
    },
    handleClearClick(){
      location.reload(true)
    }
  }
})
</script>

<template>
  <div class="page-header-right">
    <el-badge class="message-button" :value="messageStore.messageLength" :hidden="messageStore.messageLength === 0" @click="showMessageBox">
      <!--      <el-button type="success" icon="Message" circle @click="showMessageBoxDrawer = true"/>-->
      <i class="ri-notification-4-line"></i>
    </el-badge>
    <FullScreenIcon></FullScreenIcon>
    <el-popover placement="bottom-end" trigger="hover">
      <template #reference>
        <div class="header-user">
          <el-image
            class="header-image"
            round
            :src="`data:image/png;base64,${userStore.userInfo.iconMdpi}`"
          >
            <template v-slot:error>
              <el-image class="header-image" round :src="getImageUrl('user_default.png')" />
            </template>
          </el-image>
          <span>{{ userStore.userInfo.name }}<i class="ri-arrow-down-s-line"></i></span>
        </div>
      </template>
      <template #default>
        <div class="header-user-buttons">
          <el-button text @click="handleEditPasswordClick">
            <template #icon>
              <i class="ri-settings-3-line"></i>
            </template>
            <span>个人设置</span></el-button
          >
          <el-button text @click="handleClearClick">
            <template #icon><i class="ri-delete-bin-7-line"></i></template>
            <span>清除缓存</span></el-button
          >
          <el-button text @click="handleLogoutClick">
            <template #icon><i class="ri-logout-box-r-line"></i></template>
            <span>注销</span></el-button
          >
        </div>
      </template>
    </el-popover>
  </div>
  <ChangePasswordDialog v-model="showChangePasswordDialog"></ChangePasswordDialog>
  <MessageBox v-model="showMessageBoxDrawer"></MessageBox>
</template>

<style scoped lang="scss">
.page-header-right {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  padding: 0 20px;
  position: relative;
  margin-left: 10px;
  &:before{
    content: '';
    position: absolute;
    left:0;
    top:50%;
    transform: translateY(-50%);
    height: 10px;
    width: 1px;
    background: #ddd;
  }
  .message-button {
    display: flex;
    align-items: center;
    margin-right: 20px;
    font-size: 20px;
    cursor: pointer;
  }
  i{
    color:#999;
    &:hover{
      color: var(--dubhe-color-primary);
    }
  }

  .header-user {
    display: flex;
    align-items: center;

    i {
      margin-left: 6px;
    }
  }

  .header-image {
    height: 26px;
    width: 26px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .dubhe-button {
    margin-left: 10px;
  }
}

.header-user-buttons {
  display: flex;
  flex-direction: column;
  width: 100%;

  .dubhe-button {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-left: 0 !important;
  }
}
</style>
