// PerformanceEvaluationTemplate
// 绩效模板
import axios from "./axios.config"
// 获取显示字段
export const getPerformanceEvaluationTemplateFields = (tableId="performanceEvaluationTemplate")=>axios.get(`/jaxrs/hr/performance_evaluation_template/fields?tableId=${tableId}`)
// 查询条件
export const getPerformanceEvaluationTemplateConditions = ()=>axios.get('/jaxrs/hr/performance_evaluation_template/conditions')
// 查询
export const getPerformanceEvaluationTemplatePage = (data)=>axios.post('/jaxrs/hr/performance_evaluation_template/page',data)
// 详情
export const getPerformanceEvaluationTemplateDetail = (data)=>axios.get(`/jaxrs/hr/performance_evaluation_template/${data.id}`)
// 保存
export const addPerformanceEvaluationTemplate = (data)=>axios.post(`/jaxrs/hr/performance_evaluation_template`,data)
// 修改
export const editPerformanceEvaluationTemplate = (data)=>axios.put(`/jaxrs/hr/performance_evaluation_template`,data)
// 删除
export const deletePerformanceEvaluationTemplate = (data)=>axios.delete(`/jaxrs/hr/performance_evaluation_template/${data.id}`)