import axios from './axios.config'
//获取盘点单显示字段
export const getStockCountFields = (tableId='stockCount') =>axios.get(`/jaxrs/stock_count/fields?tableId=${tableId}`)
//获取盘点查询条件
export const getStockCountConditions = () =>axios.get('/jaxrs/stock_count/conditions')
//获取盘点单分页数据
export const getStockCountPage = (data) =>axios.post(`/jaxrs/stock_count/page`,data)
//盘点单详情（上一页）
export const getStockCountDetail = (data) =>{
    let url
    if(data.oper){
        url = `/jaxrs/stock_count/${data.id}?oper=${data.oper}`
    }else{
        url = `/jaxrs/stock_count/${data.id}`
    }
    return axios.get(url)
}
//修改盘点单
export const editStockCount = (data) =>axios.put(`/jaxrs/stock_count`,data)
//新增盘点单
export const addStockCount = (data) =>axios.post(`/jaxrs/stock_count`,data)
//删除盘点单
export const deleteStockCount = (data) =>axios.delete(`/jaxrs/stock_count/${data.id}`)
//导出盘点单
export const exportStockCount = (data) =>
    axios.post(`/jaxrs/stock_count/export`,data,{
        responseType:'blob'
    })
//发起盘点单
export const originatingStockCount = (data) =>axios.post(`/jaxrs/stock_count/originating`,data)
//录入盘点单
export const enterStockCount = (data) =>axios.post(`/jaxrs/stock_count/enter`,data)
//发起复核盘点单
export const sendCheckStockCount = (data) =>axios.post(`/jaxrs/stock_count/send_check`,data)
//复核盘点单
export const checkStockCount = (data) =>axios.post(`/jaxrs/stock_count/check`,data)
//处理盘点单
export const auditStockCount = (data) =>axios.post(`/jaxrs/stock_count/audit`,data)
//导出盘点明细
export const exportStockCountItem = (data) =>
  axios.post(`/jaxrs/stock_count/item/export`,data,{
      responseType:'blob'
  })
