import axios  from './axios.config'
// 获取显示字段
export const getEquipmentAccessoryFields = (tableId='equipmentAccessory')=>axios.get(`/jaxrs/equipment_accessory/fields?tableId=${tableId}`)
// 获取查询条件
export  const getEquipmentAccessoryConditions = () =>axios.get('/jaxrs/equipment_accessory/conditions')
// 查询
export const getEquipmentAccessoryPage = (data) =>axios.post(`/jaxrs/equipment_accessory/page`,data)
// 详情
export const getEquipmentAccessoryDetail = (data) =>axios.get(`/jaxrs/equipment_accessory/${data.id}`)
// 保存
export const addEquipmentAccessory = (data)=>axios.post(`/jaxrs/equipment_accessory`,data)
// 编辑
export const editEquipmentAccessory = (data)=>axios.put(`/jaxrs/equipment_accessory`,data)
// 删除
export const deleteEquipmentAccessory = (data)=>axios.delete(`/jaxrs/equipment_accessory/${data.id}`)
// 导出
export const exportEquipmentAccessory = (data) => axios.post(`/jaxrs/equipment_accessory/export`,data,{
  responseType:'blob'
})