<script>
import { defineComponent } from "vue";
import SelectV2 from "../SelectMultiple/SelectV2.vue";

export default defineComponent({
  name: "EditListDialog",
  components: { SelectV2 },
  emits: ["update:modelValue", "confirm"],
  data() {
    return {
      tableList: []
    };
  },
  props: {
    modelValue: {
      type: Boolean
    },
    items: {
      type: String,
      required: true
    }
  },
  computed: {
    show: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    }
  },
  methods: {
    open() {
      if (this.items) {
        this.tableList = this.items.split(";").map(item => {
          return {
            name: item
          };
        });
      } else {
        this.tableList = [];
      }
    },
    handleAddClick() {
      let newItem = {
        name: ""
      };
      this.tableList.push(newItem);
    },
    handleDeleteClick(scope) {
      this.tableList = this.tableList.filter((item, index) => index !== scope.$index);
    },
    handleCancelClick() {
      this.show = false;
    },
    handleConfirmClick() {
      if (!this.checkTableList()) {
        return;
      }
      let items = this.tableList.map(item => item.name).join(";");
      this.$emit("confirm", items);
      this.show = false;
    },
    checkTableList() {
      let nameMap = {};
      let nameRepeat = false
      this.tableList.forEach((item, index) => {
        if(nameMap[item.name]){
          nameRepeat = true
        }
        nameMap[item.name] = true
      });
      if(nameRepeat){
        this.$message.warning('名称重复，请编辑')
        return false
      }
      let check = this.tableList.every(item => {
        return !!item.name
      });
      if (!check) {
        this.$message.warning("请填写完整数据");
      }
      return check;
    }
  }
});
</script>

<template>
  <el-dialog v-model="show" v-bind="$attrs" title="子项编辑" width="30%" destroy-on-close @open="open">
    <div>
      <el-table class="table" :data="tableList" stripe border empty-text="暂无数据" v-bind="$attrs">
        <el-table-column label="名称" align="center">
          <template #default="scope">
            <el-input v-model="scope.row.name"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="80">
          <template #header="scope">
            <el-button type="primary" link icon="Plus" @click="handleAddClick"></el-button>
          </template>
          <template #default="scope">
            <el-button type="danger" link icon="Delete" @click="handleDeleteClick(scope)"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleCancelClick">取消</el-button>
        <el-button type="primary" @click="handleConfirmClick">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<style scoped lang="scss">
.table {
  :deep(td.dubhe-table__cell) {
    padding: 0;

    &:first-child {
      .cell {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .cell {
      padding: 0;

      & > .text {
        display: flex;
        align-items: center;
        min-height: 30px;
      }

      &:has(.dubhe-textarea) {
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;

        .dubhe-textarea {
          position: absolute;
          top: 0;
          bottom: 0;
          height: 100%;
          width: 100%;
          border: 1px solid transparent;
          transition: 0.3s;

          &:hover {
            border: 1px solid var(--dubhe-color-primary);
          }

          textarea {
            height: 100%;
            box-shadow: none;
            border-radius: 0;
          }
        }
      }

      .cell-content, .total {
        font-weight: bold;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .dubhe-input {
        width: 100%;
        border-width: 0;

        .dubhe-input__wrapper {
          border-radius: 0;
          box-shadow: 0 0 0 0;
          border: 1px solid transparent;
          transition: 0.3s;

          &:hover {
            border: 1px solid #4a90e2;
          }
        }
      }

      .dubhe-input-group__append {
        border-radius: 0;
        box-shadow: 0 0 0 0;
      }

      .dubhe-select {
        width: 100%;
      }

      .dubhe-button {
        border-radius: 0;
        border-width: 0;
      }

      .center-component {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .custom {
        height: 100%;
        display: flex;
        align-items: center;
        width: 100%;

        &:has(.dubhe-tag) {
          padding: 0 10px;
        }

        &:has(.dubhe-select) {
          & > * {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>