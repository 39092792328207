<script>
import { defineComponent } from 'vue'
import ProcessStart from '@/components/ProcessStart/ProcessStart.vue'

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Collect',
  components: { ProcessStart },
  props: {
    auto: {}
  },
  data() {
    return {
      process: {},
      identityList: [],
      showIdentityDialog: false,
      loading: false
    }
  },
  methods: {
    handleCollectRouter(item) {
      let checkPage = this.permissionStore.allPermissionsMenuList.find(
        (pItem) => `/${pItem.path}` === item.router
      )
      let props = checkPage.props ? JSON.parse(checkPage.props) : {}
      if (props.dataViewId) {
        this.$refs.processStart.addProcessByDataView(props.dataViewId)
      } else {
        this.$router.push({
          path: item.router
        })
      }
    },
    findPage(list, router) {
      let checkItem = null
      checkItem = list.find((pItem) => `/${pItem.path}` === router)
      if (!checkItem) {
        list.forEach((item) => {
          if (item.children) {
            let checkChildItem = this.findPage(item.children, router)
            if (checkChildItem) {
              checkItem = checkChildItem
            }
          }
        })
      }
      return checkItem
    },
    handleCollectDelete(item) {
      this.collectStore.changeCollect(item)
    },
    filterItemColor(item) {
      let checkModule = this.permissionStore.moduleList.find(
        (mItem) => mItem.module === item.module
      )
      if (checkModule) {
        return {
          color: checkModule.cssClass
        }
      }
      return {}
    }
  }
})
</script>

<template>
  <el-card class="collect-card" :class="{ auto: auto }">
    <template #default>
      <div class="collect-wrapper" v-loading="loading">
        <div class="collect-wrapper-header">常用流程</div>
        <div class="collect-wrapper-body">
          <el-scrollbar class="collect-wrapper-body-absolute">
            <div class="common-use-list">
              <div
                class="common-use-list-item"
                :style="filterItemColor(item)"
                v-for="(item, index) in collectStore.permissionCollectList"
                :key="index"
                @click="handleCollectRouter(item)"
              >
                <el-icon v-if="item.icon" class="common-use-list-item-icon" :size="20">
                  <component :is="item.icon" :size="20"></component>
                </el-icon>
<!--                                <span class="common-use-list-item-module" v-if="item.moduleName">{{item.moduleName}}</span>-->
                <span class="common-use-list-item-name">{{ item.name }}</span>
              </div>
            </div>
          </el-scrollbar>
        </div>
        <!--        <div class="collect-wrapper-footer">更多</div>-->
      </div>
      <ProcessStart ref="processStart"></ProcessStart>
    </template>
  </el-card>
</template>

<style scoped lang="scss">
.collect-card {
  //height: 100%;
  //flex-shrink: 0;
  &.auto {
    height: auto;
  }

  :deep(.dubhe-card__body) {
    padding: 0 25px;
  }
}

.collect-wrapper {
  display: flex;
  align-items: center;

  & > div {
    height: 50px;
    display: flex;
    align-items: center;
  }

  .collect-wrapper-header {
    height: 15px;
    font-size: 15px;
    line-height: 1;
    color: var(--dubhe-color-primary);
    border-right: 1px solid #dce0e8;
    padding-right: 20px;
    margin-right: 20px;
  }

  .collect-wrapper-body {
    position: relative;
    flex: 1;

    .collect-wrapper-body-absolute {
      position: absolute;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;

      :deep(.dubhe-scrollbar__view) {
        height: 100%;
      }
    }
  }

  .collect-wrapper-footer {
    font-size: 14px;
    color: #979797;
    cursor: pointer;
    padding-left: 20px;
  }
}

.common-use-list {
  height: 100%;
  //width: 100%;
  //overflow-x: auto;
  //overflow-y: hidden;
  display: flex;
  flex-wrap: nowrap;

  .common-use-list-item {
    position: relative;
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    height: 100%;
    color: var(--dubhe-color-primary);
    font-size: 16px;
    line-height: 1;
    cursor: pointer;

    & + .common-use-list-item {
      margin-left: 20px;
    }

    .common-use-list-item-name {
      color: #000;
      font-size: 14px;
      //padding-left: 10px;
      //border-left: 1px solid rgba(#0b69fb,0.2);
    }

    .common-use-list-item-icon {
      margin-right: 10px;
    }

    .common-use-list-item-module {
      margin-right: 10px;
    }
  }
}
</style>