import axios from './axios.config'
//获取客户显示字段
export const getConsumerFields = (tableId='consumer') =>axios.get(`/jaxrs/consumer/fields?tableId=${tableId}`)
//获取客户查询条件
export const getConsumerConditions = () =>axios.get('/jaxrs/consumer/conditions')
//查询
export const getConsumerPage = (data) =>axios.post('/jaxrs/consumer/page',data)
//详情
export const getConsumerDetail = (data) =>axios.get(`/jaxrs/consumer/${data.id}`)
//保存
export const addConsumer = (data) =>axios.post(`/jaxrs/consumer`,data)
//编辑
export const editConsumer = (data) =>axios.put(`/jaxrs/consumer`,data)
//删除
export const deleteConsumer = (data) =>axios.delete(`/jaxrs/consumer/${data.id}`)
// 获取区
export const getDistrict =()  =>axios.get('/jaxrs/dict/get_by_type/district')
// 获取行政规划树
export const getRegionTree =()  =>axios.get('/jaxrs/region/tree')