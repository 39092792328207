import axios from './axios.config'
//获取材料显示字段
export const getSaleReturnFields = (tableId='salesReturn') =>axios.get(`/jaxrs/sales_return/fields?tableId=${tableId}`)
//获取查询字段
export const getSaleReturnConditions = () =>axios.get('/jaxrs/sales_return/conditions')
//获取分页数据(查询)
export const getSaleReturnPage = (data) => axios.post('/jaxrs/sales_return/page',data)
// 详情
export const getSaleReturnDetail = (data) =>axios.get(`/jaxrs/sales_return/${data.id}`)
// 保存
export const addSaleReturn = (data) =>axios.post(`/jaxrs/sales_return`,data)
// 修改
export const editSaleReturn = (data) =>axios.put(`/jaxrs/sales_return`,data)
// 删除
export const deleteSaleReturn = (data) =>axios.delete(`/jaxrs/sales_return/${data.id}`)
//修改流程状态
export const changeProcessStatus = (data) =>axios.put(`/jaxrs/sales_return/status`,data)
// 导出销售退货单
export const exportSaleReturn = (data) =>
    axios.post(`/jaxrs/sales_return/export`,data,{
        responseType:'blob'
    })