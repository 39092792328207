import axios from './axios.config'
// 设备维修
// 显示字段
export const getEquipmentRepairFields = (tableId='equipmentRepair') =>axios.get(`/jaxrs/equipment_repair/fields?tableId=${tableId}`)
// 查询条件
export const getEquipmentRepairConditions = () =>axios.get('/jaxrs/equipment_repair/conditions')
// 查询
export const getEquipmentRepairPage = (data) =>axios.post('/jaxrs/equipment_repair/page',data)
// 详情
export const getEquipmentRepairDetail = (data) =>axios.get(`/jaxrs/equipment_repair/${data.id}`)
// 编辑
export const editEquipmentRepair = (data) =>axios.put(`/jaxrs/equipment_repair`,data)
// 添加
export const addEquipmentRepair = (data) =>axios.post(`/jaxrs/equipment_repair`,data)
// 删除
export const deleteEquipmentRepair = (data) =>axios.delete(`/jaxrs/equipment_repair/${data.id}`)
// 寄修
export const sendEquipmentRepair = (data) =>axios.put(`/jaxrs/equipment_repair/send`,data)
// 签收
export const signEquipmentRepair = (data) =>axios.put(`/jaxrs/equipment_repair/sign`,data)
// 维修报价
export const quoteEquipmentRepair = (data) =>axios.put(`/jaxrs/equipment_repair/quote`,data)
// 寄回确认
export const receivedEquipmentRepair = (data) =>axios.put(`/jaxrs/equipment_repair/received/${data.id}`,data)
// 送货返还
export const sendReturnEquipmentRepair = (data) =>axios.put(`/jaxrs/equipment_repair/send_return/${data.id}`,data)
// 上传签收单
export const deliveryEquipmentRepair = (data) =>axios.put(`/jaxrs/equipment_repair/delivery`,data)