import axios from './axios.config'
// 工单投诉记录(WorkOrderComplain)
// 获取显示字段
export const getWorkOrderComplainFields = (tableId="workOrderComplain")=>axios.get(`/jaxrs/work_order_complain/fields?tableId=${tableId}`)
// 获取查询条件
export const getWorkOrderComplainConditions = () =>axios.get("/jaxrs/work_order_complain/conditions")
// 查询
export const getWorkOrderComplainPage = (data) =>axios.post('/jaxrs/work_order_complain/page',data)
// 详情
export const getWorkOrderComplainDetail = (data) =>{
    let url
    if(data.oper){
        url=`/jaxrs/work_order_complain/${data.id}?oper=${data.oper}`
    }else{
        url=`/jaxrs/work_order_complain/${data.id}`
    }
    return axios.get(url)
}
// 保存
export const addWorkOrderComplain = (data) =>axios.post(`/jaxrs/work_order_complain`,data)
// 删除
export const  deleteWorkOrderComplain = (data) =>axios.delete(`/jaxrs/work_order_complain/${data.id}`)
// 修改
export const editWorkOrderComplain = (data) =>axios.put(`/jaxrs/work_order_complain`,data)
// 处理
export const handleCustomerComplaint = (data) =>axios.put(`/jaxrs/work_order_complain/result`,data)
// 导出
export const exportCustomerComplaint = (data)=>
    axios.post(`/jaxrs/work_order_complain/export`,data,{
        responseType:'blob'
    })