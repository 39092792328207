<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DatePicker',
  emits:['update:modelValue'],
  props: {
    modelValue: {
      required: true
    },
    valueFormat: {
      default() {
        return 'YYYY-MM-DD'
      }
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    }
  }
})
</script>

<template>
  <el-date-picker v-model="value" :value-format="valueFormat" :format="valueFormat" v-bind="$attrs"></el-date-picker>
</template>

<style scoped lang="scss"></style>