import axios from './axios.config'
//获取采购订单显示字段
export const  getPurchaseOrderFields = (tableId='purchaseOrder') => axios.get(`/jaxrs/purchase_order/fields?tableId=${tableId}`)
//获取采购订单查询条件
export const getPurchaseOrderConditions = () =>axios.get('/jaxrs/purchase_order/conditions')
//获取采购订单分页数据
export const getPurchaseOrderPage = (data) =>axios.post(`/jaxrs/purchase_order/page`,data)

/**
 * 获取采购订单列表
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const listPurchaseOrder = (params) =>axios.get(`/jaxrs/purchase_order/list`,params);
//获取采购订单详情页
export const getPurchaseOrderDetail = (data) =>{
    let url
    if(data.oper){
        url = `/jaxrs/purchase_order/${data.id}?oper=${data.oper}`
    }else{
        url = `/jaxrs/purchase_order/${data.id}`
    }
    return axios.get(url)
}
//修改采购订单
export const editPurchaseOrder = (data) =>axios.put(`/jaxrs/purchase_order`,data)
//新增采购订单
export const addPurchaseOrder = (data) =>axios.post(`/jaxrs/purchase_order`,data)
//删除采购订单
export const deletePurchaseOrder = (data) =>axios.delete(`/jaxrs/purchase_order/${data.id}`)
//采购订单的导出
export const exportPurchaseOrder = (data) =>
    axios.post(`/jaxrs/purchase_order/export`,data,{
        responseType: 'blob'
    })
// 查询材料价格
export const getMaterialPrice = (data) =>axios.get(`/jaxrs/purchase_order/getMaterialPrice/${data.id}`)
// 上传合同附件
export const uploadField = (data)=>axios.post(`/jaxrs/purchase_order/upload_contract`,data)
// 作废采购订单
export const cancelPurchaseOrder = (data) =>axios.put(`/jaxrs/purchase_order/cancel/${data.id}`)
