import axios from './axios.config'
// 获取显示字段
export const getEmploymentNeedsFields = (tableId='')=>axios.get(`/jaxrs/hr/employees_needs/fields?tableId=${tableId}`)
// 获取查询条件
export const getEmploymentNeedsConditions = ()=>axios.get('/jaxrs/hr/employees_needs/conditions')
// 分页查询
export const getEmploymentNeedsPage = (data)=>axios.post('/jaxrs/hr/employees_needs/page',data)
// 详情页
export const getEmploymentNeedsDetail = (data) =>axios.get(`/jaxrs/hr/employees_needs/${data.id}`)
// 保存
export const addEmploymentNeeds = (data)=>axios.post(`/jaxrs/hr/employees_needs`,data)
// 修改（编辑）
export const editEmploymentNeeds = (data)=>axios.put(`/jaxrs/hr/employees_needs`,data)
// 删除
export const deleteEmploymentNeeds = (data)=>axios.delete(`/jaxrs/hr/employees_needs/${data.id}`)
// 导出
export const exportEmploymentNeeds = (data)=>axios.post(`/jaxrs/hr/employees_needs/export`,data,{
    responseType:'blob'
})
// 处理
export const handelEmploymentNeeds = (data)=>axios.put(`/jaxrs/hr/employees_needs/handle`,data)