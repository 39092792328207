import { defineStore } from "pinia";
import { getDictAll } from "../axios";
import _ from "lodash";
import { openApiGetDict } from "@/axios/openApi";

export const useDictStore = defineStore({
  id: "dict",
  state: () => {
    return {
      dictList: []
    };
  },
  getters: {
  },
  actions: {
    // 根据type，value获取一条字典对象
    getDictItemByTypeValue(type, value) {
      let dict = this.getDictByType(type)
      let strValue = _.isBoolean(value)?(value?'1':'0'):_.toString(value)
      let item = dict.find(item=>item.val === strValue)
      return item || {
        label:'',
        val:''
      };
    },
    // 根据type，label获取一条字典对象
    getDictItemByTypeLabel(type, label) {
      let dict = this.getDictByType(type)
      let item = dict.find(item=>item.label === label)
      return item || {};
    },
    // 根据type获取字典列表
    getDictByType(type){
      let dict = this.getDictDetailByType(type)
      if(dict){
        return dict.children
      }
      return []
    },
    // 根据type获取字典详情
    getDictDetailByType(type){
      let dict = this.dictList.find(item=>item.type === type)
      if(dict){
        return dict
      }
      return null
    },
    async initDictList() {
      await getDictAll().then(res => {
        if (res) {
          this.dictList = res.data;
        }
      });
    },
    async initDictListNoUser(types) {
      await openApiGetDict({types:types}).then(res=>{
        if (res) {
          this.dictList = res.data;
        }
      })
    },
    getBizTypeListByOrderType(type){
      if(!type){
        return []
      }
      let dictType = `${type}_bizType`
      return this.getDictByType(dictType)
    }
  }
});

