import axios from './axios.config'
//获取入库显示字段
export const getMaterialPricingFields = (tableId='materialPricing') =>axios.get(`/jaxrs/material_pricing/fields?tableId=${tableId}`)
//获取入库查询条件
export const getMaterialPricingConditions = () =>axios.get('/jaxrs/material_pricing/conditions')
//分页数据
export const getMaterialPricingPage = (data) =>axios.post(`/jaxrs/material_pricing/page`,data)
//详情页
export const getMaterialPricingDetail = (data) =>axios.get(`/jaxrs/material_pricing/${data.id}`)
//保存(新增)
export const addMaterialPricing = (data) =>axios.post(`/jaxrs/material_pricing`,data)
//编辑
export const editMaterialPricing = (data) =>axios.put(`/jaxrs/material_pricing`,data)
//删除
export const deleteMaterialPricing = (data) =>axios.delete(`/jaxrs/material_pricing/${data.id}`)
// 获取导入字段列表
export const getImportDataFields = (data) =>axios.get('/jaxrs/material_pricing/import/fields')
// 导入数据接口
export const ImportMaterialPricingData = (data) =>axios.post(`/jaxrs/material_pricing/import`,data)