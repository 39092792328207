import axios from './axios.config'
//获取领料单显示字段
export const  getStockRequisitionFields = (tableId='stockRequisition') => axios.get(`/jaxrs/stock_requisition/fields?tableId=${tableId}`)
//获取出库查询条件
export  const getStockRequisitionConditions = () => axios.get('/jaxrs/stock_requisition/conditions')
//获取出库分页数据
export const getStockRequisitionPage =(data) =>axios.post('/jaxrs/stock_requisition/page',data)
//获取出库详情
export const getStockRequisitionDetail = (data) =>{
    let url
    if(data.oper){
        url = `/jaxrs/stock_requisition/${data.id}?oper=${data.oper}`
    }else{
        url = `/jaxrs/stock_requisition/${data.id}`
    }
    return axios.get(url)
}
//修改领料单
export const editStockRequisition = (data) => axios.put(`/jaxrs/stock_requisition`,data)

// 预出库
export const preStockOut = (data) => axios.put(`/jaxrs/stock_requisition/pre_stock_out`,data)
// 出库
export const stockOut = (data) => axios.put(`/jaxrs/stock_requisition/stock_out`,data)
//添加领料单
export const addStockRequisition = (data) =>axios.post(`/jaxrs/stock_requisition`,data)
//删除领料单
export const deleteStockRequisition = (data) =>axios.delete(`/jaxrs/stock_requisition/${data.id}`)
//导出
export const exportStockRequisition = (data) =>
    axios.post(`/jaxrs/stock_requisition/export`,data,{
        responseType:'blob'
    })
// 获取项目详情
export const getProjectByCode = (data) => axios.get(`/jaxrs/project/${data.code}`)


// 出库接口 put
export const deliveryFromStorage = (data)=>axios.put(`/jaxrs/stock_requisition/stock_out`,data)

