<script>
import { defineComponent } from "vue";
import _ from "lodash";
import CommonSelectDialog from "../SelectDialog/CommonSelectDialog.vue";

/**
 * remoteMethod
 * 接收 searchValue 检索字段 conditions 接口检索参数
 *
 * selectChange 选择改变回调方法
 * filterChange 过滤改变回调方法
 */
export default defineComponent({
  name: "SelectWithButtonNew",
  components: { CommonSelectDialog },
  emits: ["filterChange", "selectChange", "update:modelValue"],
  data() {
    return {
      dialogVisible: false,
      baseList: [],
      searchValue: "",
      placeholder: "请选择",
      getList: null,
      selectLoading: false
    };
  },
  props: {
    clearable: {
      type: Boolean,
      default: false,
      required: false
    },
    modelValue: {
      required: true
    },
    labelKey: {
      type: String,
      default() {
        return "name";
      }
    },
    valueKey: {
      type: String,
      default() {
        return "id";
      }
    },
    lightLabelKey: {
      type: String
    },
    list: {
      type: Array
    },
    remoteMethod: {
      type: Function
    },
    labelFilter: {
      type: Function
    },
    conditions: {
      type: Array
    },
    defaultLabel: {
      type: String
    },
    dialogName: {
      type: String
    },
    dialogTitle: {
      type: String
    },
    customFilterMethod: {
      type: Function
    },
    openCheck: {
      type: Function
    },
    pageSize:{}
  },
  computed: {
    showButton() {
      return !!this.dialogName;
    },
    useRemoteMethod() {
      return !!this.remoteMethod;
    },
    selectValue: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    },
    selectItem() {
      return this.list.find((item) => {
        return item[this.valueKey] === this.selectValue;
      });
    },
    filterList() {
      if (this.useRemoteMethod) {
        return this.baseList;
      } else {
        if (!this.labelKey) {
          return this.list;
        }

        if (this.customFilterMethod) {
          return this.customFilterMethod(this.list, this.searchValue);
        }

        let list = this.list.filter((item) => {
          if (this.labelFilter) {
            return this.labelFilter(_.cloneDeep(item)).includes(this.searchValue);
          }
          if (item[this.labelKey]) {
            return item[this.labelKey].includes(this.searchValue);
          }
          return false;
        });
        return list;
      }
    }
  },
  watch: {
    searchValue: {
      handler(newVal, oldVal) {
        this.$emit("filterChange", newVal);
        if (newVal !== oldVal && this.$refs.select.visible) {
          if (this.useRemoteMethod) {
            this.getList();
          }
        }
      }
    },
    defaultLabel: {
      handler(newVal) {
        this.updateDefaultLabel(newVal)
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.initGetList();
  },
  updated(){
    this.updateDefaultLabel(this.defaultLabel)
  },
  methods: {
    updateDefaultLabel(newVal){
      if (newVal) {
        this.$nextTick(() => {
          let selectRef = this.$refs.select.selectRef;
          // let placeholderDom = selectRef.querySelectorAll('.dubhe-select__placeholder span');
          // if(placeholderDom[0] && (placeholderDom[0].innerHTML == this.modelValue)){
          //   this.setBaseListFromLabel()
          // }
          // if(selectRef.currentPlaceholder == this.modelValue){
            // this.setBaseListFromLabel()
            // selectRef.currentPlaceholder = newVal
            // selectRef.selected = {
            //   ...selectRef.selected,
            //   currentLabel: newVal
            // };
            // selectRef.query = newVal;
            // selectRef.selectedLabel = newVal;
          // }
        });
      }
    },
    setBaseListFromLabel(){
      if(this.defaultLabel){
        if(this.baseList.find(item=>item.valueKey == this.modelValue)){
          return
        }
        let item = {}
        item[this.valueKey] = this.modelValue
        item[this.labelKey] = this.defaultLabel
        this.baseList = [item,...this.baseList]
      }
    },
    filterMethod(val) {
      this.searchValue = val;
    },
    initGetList() {
      this.getList = _.debounce(
        async () => {
          if(this.openCheck){
            let checkOk = await this.openCheck()
            if(!checkOk){
              return
            }
          }
          this.baseList = await this.remoteMethod(this.searchValue, this.conditions || []);
        },
        300,
        {
          leading: false,
          trailing: true
        }
      );
    },
    async handleLinkClick() {
      if (this.openCheck) {
        let checkOk = await this.openCheck()
        if (!checkOk) {
          return
        }
      }
      this.dialogVisible = true;
    },
    changeEvent(val) {
      let item;
      if (_.isObject(val)) {
        item = val;
        // if (this.labelKey && this.labelFilter) {
        //   item[this.labelKey] = this.labelFilter(item);
        // }
        this.$emit("update:modelValue", val[this.valueKey]);
        this.$emit("selectChange", item || {});
      } else {
        this.$nextTick(() => {
          item = this.getSelectItem();
          // if (item && this.labelKey && this.labelFilter) {
          //   item[this.labelKey] = this.labelFilter(item);
          // }
          this.$emit("update:modelValue", val);
          this.$emit("selectChange", item || {});
        });
      }
      this.$nextTick(()=>{
        console.log(this.$refs)
      })
    },
    getSelectItem() {
      let selectItem;
      let list;
      if (this.useRemoteMethod) {
        list = this.baseList;
      } else {
        list = this.list;
      }
      selectItem = _.cloneDeep(
        list.find((item) => {
          return item[this.valueKey] === this.selectValue;
        })
      );
      return selectItem;
    },
    visibleChange(val) {
      if (val && this.useRemoteMethod) {
        this.getList();
      }
    },
    labelFilterMethod(item){
      let copyItem = _.cloneDeep(item)
      if(this.labelFilter){
        return this.labelFilter(copyItem)
      }else{
        return item[this.labelKey]
      }
    }
  }
});
</script>

<template>
  <div class="select-with-button">
    <el-select
      :clearable="clearable"
      ref="select"
      :placeholder="placeholder"
      v-model="selectValue"
      :class="{ border: showButton }"
      v-bind="$attrs"
      filterable
      :reserve-keyword="true"
      :filter-method="filterMethod"
      :suffix-icon="showButton ? '' : 'ArrowDown'"
      @change="changeEvent"
      @visible-change="visibleChange"
      :default-first-option="true"
    >
      <el-option
        v-for="item in filterList"
        :label="labelFilterMethod(item)"
        :value="item[valueKey]"
        :key="item[valueKey]"
      >
        <slot :row="item">
          <div class="select-options">
            <span>{{ labelFilterMethod(item) }}</span>
            <span class="light" v-if="lightLabelKey">{{ item[lightLabelKey] }}</span>
          </div>
        </slot>
      </el-option>
    </el-select>
    <el-button v-if="showButton" v-bind="$attrs" @click="handleLinkClick">
      <el-icon>
        <Link />
      </el-icon>
    </el-button>
  </div>
  <CommonSelectDialog
    v-if="dialogName"
    v-model="dialogVisible"
    v-model:value="selectValue"
    :dialog-name="dialogName"
    :dialog-title="dialogTitle"
    :default-conditions="conditions"
    :value-key="valueKey"
    :pageSize="pageSize"
    :callback="changeEvent"
  ></CommonSelectDialog>
</template>

<style scoped lang="scss">
.select-with-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .dubhe-select {
    flex: 1;

    &.border {
      :deep(.dubhe-select__wrapper) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .dubhe-select + .dubhe-button {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.select-options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .light {
    color: var(--dubhe-text-color-secondary);
  }
}
</style>