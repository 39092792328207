<script>
import { defineComponent } from "vue";
import { selectListMixins } from "../../common/selectListMixins";

export default defineComponent({
  name: "SelectUnitDialog",
  emits: ["update:modelValue", "callback"],
  mixins: [selectListMixins],
  data() {
    return {
      editForm: {
        unitId: null,
        unitName: null
      },
      rules: {
        unitId: [{ required: true, message: "请选择部门", trigger: "change" }]
      }
    };
  },
  props: {
    modelValue: {
      type: Boolean
    }
  },
  computed: {
    show: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    }
  },
  methods: {
    open() {
      this.editForm = {
        unitId: null,
        unitName: null
      }
      this.$refs.form.resetFields();
    },
    unitChange(val) {
      this.editForm.unitName = val.name;
    },
    handleCancelClick() {
      this.show = false;
    },
    handleConfirmClick() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit("callback", this.editForm);
          this.show = false;
        }
      });
    }
  }
});
</script>

<template>
  <template>
    <el-dialog
      v-model="show"
      v-bind="$attrs"
      title="部门选择"
      width="50%"
      append-to-body
      destroy-on-close
      @open="open"
    >
      <div class="select-unit-dialog">
        <el-form ref="form" :model="editForm" :rules="rules">
          <el-form-item label="部门" prop="unitId">
            <SelectWithButton
              v-model="editForm.unitId"
              :remote-method="getStatementDepartmentSelectList"
              labelKey="name"
              valueKey="id"
              placeholder="请选择部门"
              @select-change="unitChange"
            >
            </SelectWithButton>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleCancelClick">取消</el-button>
        <el-button type="primary" @click="handleConfirmClick">确认</el-button>
      </span>
      </template>
    </el-dialog>
  </template>
</template>

<style scoped lang="scss">

</style>