<script>
import { defineComponent } from "vue";
import MapCoordinateDialog from "./MapCoordinateDialog.vue";
import _ from "lodash";

export default defineComponent({
  name: "MapCoordinate",
  components: { MapCoordinateDialog },
  emits: ["update:lat", "update:lng"],
  props: {
    address: {
      default() {
        return "";
      }
    },
    // 纬度
    lat: {},
    // 经度
    lng: {}
  },
  data() {
    return {
      showDialog: false
    };
  },
  methods: {
    handleLocationClick() {
      if (_.isEmpty(this.address)) {
        this.$message.warning("请输入地址");
        return;
      }
      this.showDialog = true;
    },
    confirm(location) {
      this.$emit("update:lat", location.lat);
      this.$emit("update:lng", location.lng);
    }
  }
});
</script>

<template>
  <div class="map-coordinate">
    <el-input :modelValue="lng" disabled>
      <template #prepend>经度：</template>
    </el-input>
    <el-input :modelValue="lat" disabled>
      <template #prepend>纬度：</template>
    </el-input>
    <el-button icon="Location" @click="handleLocationClick"></el-button>
  </div>
  <MapCoordinateDialog v-model="showDialog" :address="address" v-bind="$props"
                       @callback="confirm"></MapCoordinateDialog>
</template>
<style scoped lang="scss">
.map-coordinate {
  width: 100%;
  display: flex;
  align-items: center;

  //.dubhe-button{
  //  width: 100%;
  //}
}
</style>
