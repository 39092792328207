import axios from './axios.config'
// 获取显示字段
export const getProjectConfigurationFields = (tableId='projectSetting') =>axios.get(`/jaxrs/project_setting/fields?tableId=${tableId}`)
// 查询字段(ProjectConfiguration)
export const getProjectConfigurationConditions = () =>axios.get('/jaxrs/project_setting/conditions')
// 查询
export const getProjectConfigurationPage = (data) =>axios.post('/jaxrs/project_setting/page',data)
// 根据id获取详情
export const getProjectConfigurationDetail = (data) =>axios.get(`/jaxrs/project_setting/${data.id}`)
// 保存
export const addProjectConfiguration = (data) =>axios.post(`/jaxrs/project_setting`,data)
// 编辑
export const editProjectConfiguration = (data) =>axios.put(`/jaxrs/project_setting`,data)
// 删除
export const deleteProjectConfiguration = (data) =>axios.delete(`/jaxrs/project_setting/${data.id}`)
// 根据来源查项目配置
export const getProjectSettingBySource = (workOrderSource) =>axios.get(`/jaxrs/project_setting/get_by_source?workOrderSource=${workOrderSource}`)
// 获取项目阶段
export const getProjectStage = () =>axios.get('/jaxrs/dict/get_by_type/projectStage')
// 获取项目状态
export const getProjectStatus = () =>axios.get('/jaxrs/dict/get_by_type/projectStatus')
// 工单来源
export const getWorkOrderSource = () =>axios.get('/jaxrs/dict/get_by_type/workOrderSource')

