<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ImageListDialog",
  emits: ["update:modelValue"],
  data() {
    return {
      tableList: []
    };
  },
  computed: {
    show: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    }
  },
  props:{
    modelValue: {
      type: Boolean
    },
    title:{
      required: true
    },
    list:{

    }
  }
});
</script>

<template>
  <el-dialog v-model="show" v-bind="$attrs" :title="title" width="80%" destroy-on-close>
    <FileList v-model:list="list" disabled></FileList>
  </el-dialog>
</template>

<style scoped lang="scss">

</style>