import axios from './axios.config'
// 行政中心的物品分类 GoodsType
export const getGoodsTypeFields = (tableId='foodsType')=>axios.get(`/jaxrs/goods_type/fields?tableId=${tableId}`)
// 查询条件
export const getGoodsTypeConditions = () =>axios.get('/jaxrs/goods_type/conditions')
// 查询
export const getGoodsTypePage = (data)=>axios.post('/jaxrs/goods_type/page',data)
// 详情
export const getGoodsTypeDetail = (data) =>axios.get(`/jaxrs/goods_type/${data.id}`)
// 保存/新增
export const addGoodsType = (data) =>axios.post(`/jaxrs/goods_type`,data)
// 修改
export const editGoodsType = (data)=>axios.put(`/jaxrs/goods_type`,data)
// 删除
export const deleteGoodsType = (data)=>axios.delete(`/jaxrs/goods_type/${data.id}`)
// 树结构
export const getGoodsTypeTree = () =>axios.get('/jaxrs/goods_type/tree')