<script>
import { defineComponent } from "vue";
import { tableDataMixins } from "../../common/tableDataMixins";
import {
  getDataChangeLogFields,
  getDataChangeLogPage,
  getDataChangeLogConditions
} from "@/axios";

export default defineComponent({
  name: "DataChangeLogDialog",
  mixins: [tableDataMixins],
  // emits: ["update:modelValue"],
  props: {
    // modelValue: {},
    bizType: {
      required: true
    },
    bizId: {
      required: true
    }
  },
  data() {
    return {
      tableId:'dataChangeLog'
    };
  },
  computed: {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
    },
    async getFields() {
      getDataChangeLogFields(this.tableId).then((res) => {
        if (res) {
          this.tableColList = this.getColList(res.data);
        }
      });
    },
    async getConditions() {
      getDataChangeLogConditions().then((res) => {
        if (res) {
          this.setConditionOptions(res.data, "content&l");
        }
      });
    },
    handleCancelClick() {
      this.$emit("update:modelValue", false);
    },
    async open() {
      this.tableLoading = true;
      this.wrapper.and = [
        {
          alias: "l",
          field: "bizId",
          operator: "eq",
          params: [
            this.bizId
          ]
        },
        {
          alias: "l",
          field: "bizType",
          operator: "eq",
          params: [
            this.bizType
          ]
        }
      ];
      await Promise.all([this.getFields(), this.getConditions()]);
      this.page.pageNumber = 1;
      this.wrapper.conditions = [];
      this.getPage();
    },
    getPage() {
      this.tableDataList = [];
      this.tableLoading = true;
      if (this.hasDefaultConditions) {
        this.wrapper.and = this.defaultConditions;
      }
      let params = {
        pageSize: this.page.pageSize,
        pageNum: this.page.pageNumber,
        wrapper: this.wrapper,
        sorts: this.sortList
      };
      getDataChangeLogPage(params)
        .then((res) => {
          this.page.total = res.size;
          this.tableDataList = res.data;
        })
        .catch(() => {
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    searchChange(wrapper) {
      this.setWrapper(wrapper);
      this.page.pageNumber = 1;
      this.getPage();
    }
  }
});
</script>

<template>
  <el-dialog
    title="修改记录"
    width="1200"
    top="30px"
    v-bind="$attrs"
    destroy-on-close
    append-to-body
    @open="open"
  >
    <div class="dialog-detail">
      <TableTemp
        v-model:page="page"
        v-model:checkValue="checkValue"
        :multiply-check="false"
        v-model:colList="tableColList"
        :tableList="tableDataList"
        :loading="tableLoading"
        :searchKey="tableSearchKey"
        :conditionOptions="conditionOptions"
        :show-operate="false"
        is-dialog
        :show-search="false"
        :tableId="tableId"
        @search="searchChange"
        @pageChange="getPage"
        @sortChange="sortChange"
        placeholder="请输入变更内容（按Enter搜索）"
      ></TableTemp>
    </div>
    <template #footer>
      <el-button @click="handleCancelClick">关闭</el-button>
    </template>
  </el-dialog>
</template>

<style scoped lang="scss">
.dialog-detail {
  display: flex;
  height: 600px;
  width: 100%;
  overflow: auto;
}
</style>