import axios from "./axios.config"
// 会议室预约
// 显示字段
export const getMeetingRoomReserveFields = (tableId ="meetingRoomReserve") =>axios.get(`/jaxrs/meeting_room_reserve/fields?tableId=${tableId}`)
// 获取查询条件
export const getMeetingRoomReserveConditions = ()=>axios.get('/jaxrs/meeting_room_reserve/conditions')
// 查询
export const getMeetingRoomReservePage = (data)=>axios.post('/jaxrs/meeting_room_reserve/page',data)
// 详情
export const getMeetingRoomReserveDetail = (data)=>axios.get(`/jaxrs/meeting_room_reserve/${data.id}`)
// 保存
export const addMeetingRoomReserve = (data)=>axios.post(`/jaxrs/meeting_room_reserve`,data)
// 编辑
export const editMeetingRoomReserve = (data)=>axios.put(`/jaxrs/meeting_room_reserve`,data)
// 删除
export const deleteMeetingRoomReserve= (data)=>axios.delete(`/jaxrs/meeting_room_reserve/${data.id}`)
// 导出
export const exportMeetingRoomReserve = (data)=>axios.post(`/jaxrs/meeting_room_reserve/export`,data,{
  responseType:'blob'
})