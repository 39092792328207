import axios from './axios.config'
// 获取显示字段 (projectAssetTransfer)
export const getProjectAssetTransferFields = (tableId='projectAssetTransfer')=>axios.get(`/jaxrs/project_asset_transfer/fields?tableId=${tableId}`)
// 获取查询条件
export const getProjectAssetTransferConditions = ()=>axios.get('/jaxrs/project_asset_transfer/conditions')
// 查询
export const getProjectAssetTransferPage = (data)=>axios.post('/jaxrs/project_asset_transfer/page',data)
// 详情
export const getProjectAssetTransferDetail = (data)=>{
    let url
    if(data.oper){
        url = `/jaxrs/project_asset_transfer/${data.id}?oper=${data.oper}`
    }else{
        url = `/jaxrs/project_asset_transfer/${data.id}`
    }
    return axios.get(url)
}
// 保存
export const addProjectAssetTransfer = (data)=>axios.post(`/jaxrs/project_asset_transfer`,data)
// 编辑
export const editProjectAssetTransfer = (data) =>axios.put(`/jaxrs/project_asset_transfer`,data)
// 删除
export const deleteProjectAssetTransfer = (data)=>axios.delete(`/jaxrs/project_asset_transfer/${data.id}`)
// 修改流程状态
export const  changeProjectAssetTransferStatus = (data) =>axios.put(`/jaxrs/project_asset_transfer/status`,data)