import axios from './axios.config'
// 获取材料分类显示字段
export const getMaterialTypeFields = (tableId='materialType') => axios.get(`/jaxrs/material_type/fields?tableId=${tableId}`)
// 获取材料分类查询条件
export const getMaterialTypeConditions = () => axios.get('/jaxrs/material_type/conditions')
// 获取材料分类分页数据
export const getMaterialTypePage = (data) => axios.post('/jaxrs/material_type/page',data)
// 获取材料分类详情
export const getMaterialTypeDetail = (data) => axios.get(`/jaxrs/material_type/${data.id}`)
// 编辑材料分类
export const editMaterialType = (data) => axios.put(`/jaxrs/material_type`,data)
// 添加材料分类
export const addMaterialType = (data) => axios.post(`/jaxrs/material_type`,data)
// 删除材料分类
export const deleteMaterialType = (data) => axios.delete(`/jaxrs/material_type/${data.id}`)
// 获取材料分类树状结构
export const getMaterialTypeTree = () => axios.get('/jaxrs/material_type/tree')
