<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TableAutoLoad',
  data() {
    return {
      observer: null,
      pageNum: 1,
      pageSize: 20,
      loading: false
    }
  },
  props: {
    list: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    pageList() {
      let { pageSize, pageNum } = this
      let end = pageNum * pageSize
      let list = this.list.filter((item, index) => index < end)
      return list
    },
    maxPageNum() {
      return Math.ceil(this.list.length / this.pageSize)
    },
    showLoading(){
      return this.pageNum < this.maxPageNum
    }
  },
  mounted() {
    this.initObserver()
  },
  beforeUnmount() {
    this.removeObserver()
  },
  methods: {
    initObserver() {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (this.pageNum < this.maxPageNum) {
              this.pageNum++
            }
          }
        })
      })
      this.observer.observe(this.$refs.bottom)
    },
    removeObserver() {
      if (this.observer) {
        this.observer.unobserve(this.$refs.bottom)
        this.observer = null
      }
    }
  }
})
</script>

<template>
  <div class="table-auto-load">
    <slot name="default" :list="pageList"></slot>
    <div ref="bottom" class="table-auto-load-bottom" v-show="showLoading">
      <LoadingIcon></LoadingIcon>
    </div>
  </div>
</template>

<style scoped lang="scss">
.table-auto-load {
  display: block;
  width: 100%;
}

.table-auto-load-bottom {
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: center;
}
</style>