<script>
import { defineComponent } from 'vue'
import RemixIconSelectDrawer from "./RemixIconSelectDrawer.vue";
import _ from 'lodash'
export default defineComponent({
  name: 'RemixIconSelect',
  components: { RemixIconSelectDrawer },
  emits: ['update:modelValue'],
  data() {
    return {
      showEditDrawer: false
    }
  },
  props: {
    modelValue: {},
    close:{
      default(){
        return true
      }
    }
  },
  computed:{
    iconValue:{
      get(){
        return this.modelValue
      },
      set(val){
        this.$emit('update:modelValue',val)
      }
    }
  },
  mounted() {
  },
  methods: {
    showDrawer() {
      this.showEditDrawer = true
    },
    filterName(val){
      let str = _.kebabCase(val)
      str = str.replace('ri-','')
      return str
    },
    clear(){
      this.iconValue = ''
    }
  }
})
</script>

<template>
  <div class="icon-wrapper" @click="showDrawer">
    <div class="check-icon-item">
      <template v-if="modelValue">
        <div class="flex-center">
          <component :is="modelValue"></component>
          <span>{{filterName(modelValue)}}</span>
        </div>
        <el-icon @click.stop="clear" v-if="close">
          <Close />
        </el-icon>
      </template>
      <span v-else class="placeholder">请选择图标</span>
    </div>
  </div>
  <RemixIconSelectDrawer v-model:show="showEditDrawer" v-model="iconValue"></RemixIconSelectDrawer>
</template>

<style scoped lang="scss">
.icon-wrapper {
  width: 100%;
}

.check-icon-item {
  //display: inline;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg{
    height: 16px;
  }
}

.placeholder {
  font-size: 14px;
  color: var(--dubhe-input-placeholder-color);
}
</style>