<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "FilePreview",
  emits: ["update:modelValue"],
  props: {
    modelValue: {},
    file: {}
  },
  computed: {
    show: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    },
    isVideo() {
      return this.file.type.includes('video')
    }
  }
});
</script>

<template>
  <el-dialog v-model="show" v-bind="$attrs" title="文件预览" top="5%" width="620" destroy-on-close @opened="open">
    <video class="file" :src="file.file.url" v-if="isVideo" controls></video>
    <img class="file" :src="file.file.url" alt="" v-else>
  </el-dialog>
</template>

<style scoped lang="scss">
  .file{
    width: 600px;
    height: 371px;
    object-fit: contain;
  }
</style>