import axios from './axios.config'
// employeesSkillCertificates 技能证书
// 获取显示字段
export const getEmployeesSkillCertificatesFields = (tableId='employeesSkillCertificates')=>axios.get(`/jaxrs/hr/employees_skill_certificates/fields?tableId=${tableId}`)
// 获取条件
export const getEmployeesSkillCertificatesConditions = ()=>axios.get('/jaxrs/hr/employees_skill_certificates/conditions')
// 分页查询
export const getEmployeesSkillCertificatesPage = (data) =>axios.post('/jaxrs/hr/employees_skill_certificates/page',data)
// 详情
export const getEmployeesSkillCertificatesDetail = (data)=>axios.get(`/jaxrs/hr/employees_skill_certificates/${data.id}`)
// 归档
export const archivedEmployeesSkillCertificates = (data) =>axios.put(`/jaxrs/hr/employees_skill_certificates/archived/${data.id}`,data)