import axios from './axios.config'
// 工单材料明细
// 获取工单材料明细显示字段
export const getWorkOrderMaterialFields = (tableId='workOrderMaterial') =>axios.get(`/jaxrs/work_order/material/detail/fields?tableId=${tableId}`)
// 获取查询条件
export const getWorkOrderMaterialConditions = () =>axios.get('/jaxrs/work_order/material/detail/conditions')
// 分页查询
export const getWorkOrderMaterialPage = (data) =>axios.post('/jaxrs/work_order/material/detail/page',data)
// 工单材料明细导出
export const exportWorkOrderMaterial = (data) =>
    axios.post(`/jaxrs/work_order/material/detail/export`, data, {
        responseType: 'blob'
    })
// 工单材料明细导出
export const exportWorkOrderMaterialDetail = (data) =>
    axios.post(`/jaxrs/work_order/material/detail/bill/export`, data, {
        responseType: 'blob'
    })
// 导出工单对账单
export const exportWorkOrderMaterialWorkload = (data) =>
  axios.post(`/jaxrs/work_order/material/detail/bill/workload/export`, data, {
    responseType: 'blob'
  })

// 导出辅材对账单
export const exportWorkOrderMaterialAuxiliary = (data) =>
  axios.post(`/jaxrs/work_order/material/detail/bill/auxiliary/export`, data, {
    responseType: 'blob'
  })
