<script>
import { defineComponent } from "vue";
import SignDrawerV2 from "./SignDrawerV2.vue";

export default defineComponent({
  name: "SignV2",
  components: {
    SignDrawerV2
  },
  emits:['callback'],
  data() {
    return {
      showDrawer: false
    };
  },
  props:{
    title:{},
    img:{},
    showNotice:{}
  },
  computed: {
    buttonText() {
      return this.img ? "重新签字" : "签字";
    }
  },
  methods: {
    handleShowClick() {
      this.showDrawer = true;
    },
    signDrawerCallback(imgList){
      if(imgList&&imgList.length > 0){
        let img = imgList[0]
        this.$emit('callback',img)
      }
    }
  }
});
</script>

<template>
  <div class="wrap">
    <div>
      <el-button type="primary" plain @click="handleShowClick">{{buttonText}}</el-button>
    </div>
    <div class="imgBox" v-if="img">
      <img :src="img" />
    </div>
  </div>
  <SignDrawerV2 v-model:show="showDrawer" :showNotice="showNotice" @callback="signDrawerCallback" :title="title"></SignDrawerV2>
</template>

<style scoped lang="scss">
p {
  padding: 20px;
}

.wrap {
  width: 100%;
  //text-align: center;
}

.canvasBox {
  // margin-top: 820px;
  margin-top: 10px;
  border: 1px solid #dddddd;
  width: 100%;
  height: 300px;
  box-sizing: border-box;
}

.imgBox {
  padding: 10px 0 0 0;

  img {
    height: 200px;
  }
}
</style>