<script>
import _ from 'lodash'
export default {
  name: "RankProgress",
  data(){
    return {
      colors:[
        { color: '#1989fa', percentage: 100 },
        { color: '#5cb87a', percentage: 80 },
        { color: '#e6a23c', percentage: 60 },
        { color: '#f56c6c', percentage: 40 },
        { color: '#6f7ad3', percentage: 20 }
      ]
    }
  },
  props:{
    data:{},
    height:{},
    unit:{}
  },
  computed:{
    max(){
      let max = 0
      this.data.forEach(item=>{
        if(item.value >= max){
          max = item.value
        }
      })
      return max
    }
  },
  methods:{
    filterPercent(value){
      return _.round(_.multiply(_.divide(value, this.max), 100), 2)
    }
  }
};
</script>

<template>
  <el-scrollbar :height="height">
    <div class="rank-progress-item" v-for="(item,index) in data" :key="index">
      <el-progress :text-inside="true" :stroke-width="12" :show-text="false" :percentage="filterPercent(item.value)" :color="colors"/>
      <div class="rank-progress-item-content">
        <span>{{item.name}}</span>
        <span><span v-if="unit" class="rank-progress-item-unit">{{unit}}</span>{{filterNumberMoney(item.value)}}</span>
      </div>
    </div>
  </el-scrollbar>
</template>

<style scoped lang="scss">
.rank-progress{
  height: 100%;
  width: 100%;
  background: #3a3a3c;
}

.rank-progress-item{
  font-size: 14px;
  &+.rank-progress-item{
    margin-top: 10px;
  }
  :deep(.dubhe-progress-bar__outer){
    background: transparent !important;
  }
  .rank-progress-item-content{
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .rank-progress-item-unit{
      margin-right: 5px;
    }
  }
}
</style>