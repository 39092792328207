<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Tags",
  props: {
    tags:{
      type:String
    }
  }
});
</script>

<template>
  <div class="tag-group">
    <el-tag
      :key="tag"
      v-for="tag in tags"
      closable
      :disable-transitions="false"
      @close="handleClose(tag)"
    >
      {{ tag }}
    </el-tag>
    <el-input
      v-if="inputVisible"
      v-model="inputValue"
      ref="saveTagInput"
      v-on:keyup.enter.native="handleInputConfirm"
      @blur="handleInputConfirm"
    >
    </el-input>
    <el-button v-else class="button-new-tag" size="small" @click="showInput"
    >+ 新标签
    </el-button
    >
  </div>
</template>

<style scoped lang="scss">
.tag-group {
  * + * {
    margin-left: 10px;
  }

  :deep(.dubhe-input) {
    display: inline;
    background: var(--dubhe-color-primary-light-9);
  }
}
</style>