import axios from './axios.config'
//获取显示字段
export const getProjectFields = (tableId='project') =>axios.get(`/jaxrs/project/fields?tableId=${tableId}`)
//获取查询条件
export const getProjectConditions = (tableId='project') =>axios.get(`/jaxrs/project/conditions?tableId=${tableId}`)
//查询
export const getProjectPage = (data) =>axios.post('/jaxrs/project/page',data)
//详情
export const getProjectDetail = (data) =>axios.get(`/jaxrs/project/${data.id}`)
//保存
export const addProject = (data) =>axios.post(`/jaxrs/project`,data)
//编辑
export const editProject = (data) =>axios.put(`/jaxrs/project`,data)
//删除
export const deleteProject = (data) =>axios.delete(`/jaxrs/project/${data.id}`)
//获取分类类型
export const getBizType = () =>axios.get('/jaxrs/dict/get_by_type/bizType')
// 项目类型
export const getProjectType = () =>axios.get('/jaxrs/dict/get_by_type/projectType')
// 项目状态
export const getProjectState = () =>axios.get('/jaxrs/dict/get_by_type/projectStatus')
// // 获取字典数值（工单来源）
// export const getWorkOrderSource = () =>axios.get('/jaxrs/dict/get_by_type/workOrderSource')
// // 项目状态
// export const getProjectStatus = () =>axios.get('/jaxrs/dict/get_by_type/projectStatus')
// 导出
export const
    exportProject = (data) =>
    axios.post(`/jaxrs/project/export`,data,{
        responseType:'blob'
    })
//编辑项目状态
export const editProjectStatus = (data) =>axios.put(`/jaxrs/project/project_status`,data)
// 指定负责人
export const designatedPerson  = (data) =>axios.put(`/jaxrs/project/leader`,data)
// 文件上传
export const projectUploadDrawings = (data) => axios.post('/jaxrs/project/upload_drawings',data)
//查询全部
export const getProjectAllPage = (data) =>axios.post('/jaxrs/project/all_page',data)
//获取全部显示字段
export const getProjectAllFields = (tableId='project') =>axios.get(`/jaxrs/project/fields?tableId=${tableId}`)
//获取全部查询条件
export const getProjectAllConditions = (tableId='project') =>axios.get(`/jaxrs/project/conditions?tableId=${tableId}`)
// 导出
export const exportAllProject = (data) => axios.post(`/jaxrs/project/export`,data,{
  responseType:'blob'
})
// 开工

