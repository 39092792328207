import axios from './axios.config'
// 行政中心的物品管理的物品清单
export const getGoodsFields = (tableId='goods')=>axios.get(`/jaxrs/goods/fields?tableId=${tableId}`)
// 获取查询条件
export const getGoodsConditions = () =>axios.get('/jaxrs/goods/conditions')
// 查询分页数据
export const getGoodsPage = (data)=>axios.post('/jaxrs/goods/page',data)
// 详情
export const getGoodsDetail = (data) =>axios.get(`/jaxrs/goods/${data.id}`)
// 保存
export const addGoods = (data)=>axios.post(`/jaxrs/goods`,data)
// 编辑
export const editGoods = (data)=>axios.put(`/jaxrs/goods`,data)
// 删除
export const deleteGoods = (data) =>axios.delete(`/jaxrs/goods/${data.id}`)