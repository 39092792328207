import axios from './axios.config'
// 获取显示字段 RewardsPunishment
export const getRewardsPunishmentFields = (tableId='rewardsPunishment')=>axios.get(`/jaxrs/hr/rewards_punishment/fields?tableId=${tableId}`)
// 查询字段
export const getRewardsPunishmentConditions = ()=>axios.get('/jaxrs/hr/rewards_punishment/conditions')
// 分页查询
export const getRewardsPunishmentPage = (data)=>axios.post('/jaxrs/hr/rewards_punishment/page',data)
// 详情
export const getRewardsPunishmentDetail = (data)=>axios.get(`/jaxrs/hr/rewards_punishment/${data.id}`)
// 保存
export const addRewardsPunishment = (data)=>axios.post(`/jaxrs/hr/rewards_punishment`,data)
// 修改
export const editRewardsPunishment = (data)=>axios.put(`/jaxrs/hr/rewards_punishment`,data)
// 删除
export const deleteRewardsPunishment = (data)=>axios.delete(`/jaxrs/hr/rewards_punishment/${data.id}`)