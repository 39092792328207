<script>
import { defineComponent } from "vue";
import { selectListMixins } from "../../common/selectListMixins";
import ConsumerManageEdit from "../../views/ConsumerManage/ConsumerManageEdit.vue";

/**
 * keyMap：不同页面使用，键值可能不同，例如
 * 默认值   {
 *           id: 'consumerId',
 *           name: 'consumerName',
 *           contact: 'contact',
 *           contactPhone: 'contactPhone',
 *           district: 'district',
 *           address: 'address'
 *         }
 * 自定义如  {
 *           id: "consumerId",
 *           name: "consumerName",
 *           contact: "consumerContact",
 *           contactPhone: "consumerContactPhone",
 *           district: "consumerDistrict",
 *           address: "consumerAddress"
 *         }
 */
export default defineComponent({
  name: "SelectConsumer",
  mixins: [selectListMixins],
  emits: ["update:modelValue", "change"],
  components: { ConsumerManageEdit },
  data() {
    return {
      editId: null,
      showConsumerDrawer: false,
      list: []
    };
  },
  props: {
    span: {
      type: Number,
      default() {
        return 6;
      }
    },
    modelValue: {
      type: Object,
      required: true
    },
    keyMap: {
      type: Object,
      default() {
        return {
          id: "consumerId",
          name: "consumerName",
          contact: "contact",
          contactPhone: "contactPhone",
          district: "district",
          address: "address"
        };
      }
    },
    consumerType: {
      type: String
    },
    conditions: {
      type: Array,
      default() {
        return [];
      }
    },
    change: {
      type: Function
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      }
    },
    showAdd: {
      type: Boolean,
      default() {
        return true;
      }
    },
    showEdit: {
      type: Boolean,
      default() {
        return true;
      }
    }
  },
  computed: {
    data: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    },
    allConditions() {
      let conditions = [];
      if (this.consumerType) {
        conditions.push({ operator: "eq", params: [this.consumerType], field: "consumerType" });
      }
      return [...this.conditions, ...conditions];
    }
  },
  watch:{
    allConditions:{
      handler(){
        this.initList()
      },
      immediate:true,
      deep:true
    }
  },
  methods: {
    async initList() {
      this.list = await this.getConsumerSelectList('', this.allConditions, true)
    },
    selectChange(val) {
      let item = val || {};
      for (let key in this.keyMap) {
        let dataKey = this.keyMap[key];
        this.data[dataKey] = item[key];
      }
      this.$emit("change", item);
      if (this.change) {
        this.change(item);
      }
    },
    addConsumer() {
      this.editId = null;
      this.showConsumerDrawer = true;
    },
    editConsumer() {
      this.editId = this.data[this.keyMap.id];
      this.showConsumerDrawer = true;
    },
    consumerCallback(isAdd, data) {
      this.initList()
      this.selectChange(data);
    },
    filterList(list,searchValue){
      let returnList = list.filter((item)=>{
        let keyMap = ['name','contactPhone','address']
        if (keyMap.some(kItem=>{
          if(!item[kItem]){
            return false
          }
          return item[kItem].includes(searchValue)
        })) {
          return true
        }
      })
      return returnList
    }
  }
});
</script>

<template>
  <el-col :span="span">
    <el-form-item label="客户名称" :prop="keyMap.id">
      <div class="form-item-body">
        <SelectV3
          class="max-width"
          v-model="data[keyMap.id]"
          :list="list"
          labelKey="name"
          valueKey="id"
          placeholder="请选择客户"
          :disabled="disabled"
          :filter="filterList"
          :change="selectChange"
          :defaultLabel="data[keyMap.name]"
        >
          <template #default="scope">
            <div class="select-item">
              <div>
                <span>{{scope.row.name}}</span>
                <span>{{scope.row.contactPhone}}</span>
              </div>
              <div>
                <span class="info">{{scope.row.address}}</span>
              </div>
            </div>
          </template>
        </SelectV3>
        <template v-if="!disabled">
          <el-button v-if="showAdd" type="primary" plain @click.stop="addConsumer">新增</el-button>
        </template>
        <el-button v-if="showEdit" :disabled="!data[keyMap.id]" type="primary" plain @click.stop="editConsumer">编辑</el-button>
      </div>
    </el-form-item>
  </el-col>
  <el-col :span="span" v-if="keyMap.contact">
    <el-form-item label="联系人">
      <el-input v-model="data[keyMap.contact]" disabled />
    </el-form-item>
  </el-col>
  <el-col :span="span" v-if="keyMap.contactPhone">
    <el-form-item label="联系方式">
      <el-input v-model="data[keyMap.contactPhone]" disabled />
    </el-form-item>
  </el-col>
  <el-col :span="span" v-if="keyMap.address && keyMap.district">
    <el-form-item label="联系地址">
      <el-input v-model="data[keyMap.address]" disabled>
        <template #prepend v-if="data[keyMap.district]">{{ data[keyMap.district] }}</template>
      </el-input>
    </el-form-item>
  </el-col>
  <ConsumerManageEdit v-model:show="showConsumerDrawer" :edit-id="editId" :consumer-type="consumerType"
                      @callback="consumerCallback">
  </ConsumerManageEdit>
</template>

<style scoped lang="scss">
.form-item-body {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;

  .dubhe-button {
    margin-left: 10px;
  }
}
.select-item{
  width: 100%;
  &>div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .info{
      font-size: 12px;
      line-height: 1;
      padding-bottom: 5px;
    }
  }
}
</style>