import {
  getMaterialPage,
  getStorePage,
  getSupplierPage,
  getWorkOrderPage,
  getMaterialTypePage,
  getPurchaseOrderPage,
  getPurchaseRequisitionPage,
  getMaterialStockPage,
  getMaterialRequisitionPage,
  getSalesOrderPage,
  getProjectPage,
  getPersonAll,
  getSubjectPage,
  getMaintenanceProjectPage,
  getMaintenanceContractPage,
  getEmployeesPage,
  getInvoiceApplyPage,
  getDatabasePage,
  getCheckReportPage,
  getInvoiceCompanyPage,
  getFundsClaimPage,
  getGasStationPage,
  getVehicleUserRecordPage,
  getCommonSupplierPage,
  getExpenditureContractPage,
  getProjectAllPage,
  getGoodsPage,
  getGoodsStockPage,
  getGoodsStorePage,
  getGoodsStorePermission,
  getHeatingQuotationPage,
  getMeetingRoomPage,
  getQuestionPage,
  getExamPage,
  getCoursePage,
  getApartmentPage,
  getThirdCheckReportPage,
  getSalaryStandardPage,
  getEquipmentRepairSupplierPage,
  getEquipmentAccessoryPage,
  getEquipmentRepairPage,
  getWorkOrderSettlementPage,
  getInputInvoicePage,
  getSupplierAllPage,
  getOutsourcingSupplierPage,
  getOrderSettlementWorkOrderPage,
  getPerformanceEvaluationTemplatePage,
  getPerformanceEvaluationPlanPage, getSalaryPage, getResignationApplicationPage
} from "@/axios";
import { getVehiclePage, getFuelCardPage } from '@/axios'

import { getConsumerPage } from '@/axios/consumer'
import { getUnitListLike } from '@/axios/o2oa'
import { getStockInPage } from '@/axios/stockIn'
import { getStockOutPage } from '@/axios/stockOut'
import { getCourseStudyLogPage, getCourseStudyLogSummaryPage } from '@/axios/courseStudyLog'
import { getJobApplicationPage } from '@/axios/jobApplication'

export const selectListMixins = {
  data() {
    return {
      selectSize: 100,
      maxSize: 999999
    }
  },
  methods: {
    // 物资管理的材料
    async getMaterialSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          ors: [],
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.ors.push(
          {
            alias: 'm',
            field: 'name',
            operator: 'like',
            params: [val]
          },
          {
            alias: 'm',
            field: 'code',
            operator: 'like',
            params: [val]
          },
          {
            alias: 'm',
            field: 'spec',
            operator: 'like',
            params: [val]
          }
        )
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getMaterialPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    async getMaterialTypeSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: '',
          field: 'name',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getMaterialTypePage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    async getMaterialStockSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          ors: [],
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.ors.push(
          {
            alias: 'm',
            field: 'name',
            operator: 'like',
            params: [val]
          },
          {
            alias: 'm',
            field: 'code',
            operator: 'like',
            params: [val]
          },
          {
            alias: 'm',
            field: 'spec',
            operator: 'like',
            params: [val]
          }
        )
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getMaterialStockPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 采购订单
    async getPurchaseOrderSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'o',
          field: 'code',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getPurchaseOrderPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 销售订单
    async getSalesOrderSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'o',
          field: 'code',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getSalesOrderPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    /**
     * 获取材料申请列表
     * @param val
     * @param conditions
     * @param all
     * @returns {Promise<*|*[]>}
     */
    // 材料申请
    async getMaterialRequisitionSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'mr',
          field: 'code',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getMaterialRequisitionPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 采购申请
    async getPurchaseRequisitionSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'r',
          field: 'code',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getPurchaseRequisitionPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 客户
    async getConsumerSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          ors: [],
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.ors.push(
          {
            alias: 'c',
            field: 'name',
            operator: 'like',
            params: [val]
          },
          {
            alias: 'c',
            field: 'contactPhone',
            operator: 'like',
            params: [val]
          }
        )
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getConsumerPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 课程选择
    async getCourseSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          ors: [],
          conditions: [],
          operator: 'and'
        }
      }
      // if (val) {
      //   params.wrapper.ors.push(
      //     {
      //       alias: 'c',
      //       field: 'name',
      //       operator: 'like',
      //       params: [val]
      //     },
      //     {
      //       alias: 'c',
      //       field: 'contactPhone',
      //       operator: 'like',
      //       params: [val]
      //     }
      //   )
      // }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getCoursePage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 结单部门(或人事选择所属部门)
    async getStatementDepartmentSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: '',
          field: 'name',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getUnitListLike({ key: ' ' })
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 考核主题（考核计划的下拉选择）
    async getPerformanceEvaluationPlanSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'p',
          field: 'title',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getPerformanceEvaluationPlanPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 仓库
    async getStoreSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 's',
          field: 'name',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getStorePage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 行政中心的库存余额
    // async getRequisitioningWarehouseList(val, conditions, all = false) {
    //   let params = {
    //     pageSize: all ? this.maxSize : this.selectSize,
    //     pageNum: 1,
    //     wrapper: {
    //       conditions: [],
    //       operator: 'and'
    //     }
    //   }
    //   if (val) {
    //     params.wrapper.conditions.push({
    //       alias: 's',
    //       field: 'name',
    //       operator: 'like',
    //       params: [val]
    //     })
    //   }
    //   if (conditions && Array.isArray(conditions)) {
    //     params.wrapper.conditions.push(...conditions)
    //   }
    //   let res = await getGoodsStorePermission(params)
    //   if (res) {
    //     console.log('输出打印minxin的领用仓库的res',res)
    //     return res.data
    //   } else {
    //     return []
    //   }
    // },
    // 行政中心的库存余额
    async getGoodsStockList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 's',
          field: 'name',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getGoodsStockPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 行政中心的仓库管理
    async getGoodsStoreList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      // if (val) {
      //   params.wrapper.conditions.push({
      //     alias: 's',
      //     field: 'name',
      //     operator: 'like',
      //     params: [val]
      //   })
      // }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getGoodsStorePage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    async getGoodsRequisitionStoreList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      // if (val) {
      //   params.wrapper.conditions.push({
      //     alias: 's',
      //     field: 'name',
      //     operator: 'like',
      //     params: [val]
      //   })
      // }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getGoodsStorePermission(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 全部供应商
    async getSupplierAllSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: '',
          field: 'name',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getSupplierAllPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 供应商
    async getSupplierSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 's',
          field: 'name',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getSupplierPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 维修供应商
    async getEquipmentRepairSupplierSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 's',
          field: 'name',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getEquipmentRepairSupplierPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 维修配件
    async getEquipmentAccessorySelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'a',
          field: 'name',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getEquipmentAccessoryPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    async getEquipmentRepairSelectList(val, conditions, all = false){
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'r',
          field: 'code',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getEquipmentRepairPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 工单（销售订单用，可传较多查询条件）
    async getOrderSettlementWorkOrderSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: '',
          field: 'name',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getOrderSettlementWorkOrderPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 工单
    async getWorkOrderSelectList(val, conditions, all = false,and=true) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: and?'and':'or'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'c',
          field: 'name',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getWorkOrderPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 工单对账
    async getWorkOrderSettlementSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'wo',
          field: 'code',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getWorkOrderSettlementPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 入库单号
    async getStockInSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'm',
          field: 'name',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getStockInPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    async getStockOutSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'so',
          field: 'name',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getStockOutPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 获取人员（工人姓名）的下拉
    async getPersonSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: '',
          field: 'name',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getPersonAll(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 项目管理列表
    async getProjectSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'p',
          field: 'name',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getProjectPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 全部项目管理列表（可查询到维保项目）
    async getProjectAllSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'p',
          field: 'name',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getProjectAllPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 维保项目管理列表
    async getMaintenanceProjectSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'mp',
          field: 'name',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getMaintenanceProjectPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 车辆加油站列表
    async getGasStationSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'm',
          field: 'code',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getGasStationPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 用户合同管理
    async getCommonSupplierSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 's',
          field: 'name',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getCommonSupplierPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 合同管理
    async getExpenditureContractList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'm',
          field: 'code',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getExpenditureContractPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 维保合同列表
    async getMaintenanceContractSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'm',
          field: 'code',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getMaintenanceContractPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 发票列表
    async getInvoiceSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'ip',
          field: 'code',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getInvoiceApplyPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 获取车辆列表（车辆中心的车辆信息列表）
    async getVehicleSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'v',
          field: 'plateNum',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getVehiclePage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 获取油卡号码
    async getFuelCardSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'v',
          field: 'plateNum',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getFuelCardPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 获取进项发票
    async getInputInvoiceSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'i',
          field: 'invoiceNo',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getInputInvoicePage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 科目管理列表
    async getSubjectSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 's',
          field: 'name',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getSubjectPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    async getVehicleProjectSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'v',
          field: 'plateNum',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getVehiclePage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    async getCourseStudyLogSummarySelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      // if (val) {
      //   params.wrapper.conditions.push({
      //     alias: "a",
      //     field: "creator",
      //     operator: "like",
      //     params: [val]
      //   });
      // }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getCourseStudyLogSummaryPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    async getCourseStudyLogSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'a',
          field: 'creator',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getCourseStudyLogPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    async getCheckReportSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'cr',
          field: 'code',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getCheckReportPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },

    async getSalaryStandardSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 's',
          field: 'title',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getSalaryStandardPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    async getDatabaseSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'a',
          field: 'name',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getDatabasePage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    async getApartmentSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'a',
          field: 'roomNumber',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getApartmentPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 员工档案的人员选择
    async getPersonnelSelectionList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: '',
          field: 'name',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getEmployeesPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 工作申请的人员选择
    async getJobApplicationSelectionList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'p',
          field: 'name',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getJobApplicationPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 开票单位选择
    async getInvoiceCompanySelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'ic',
          field: 'name',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getInvoiceCompanyPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 会议室选择
    async getMeetingRoomSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'm',
          field: 'name',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getMeetingRoomPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 三方检测报告
    async getThirdCheckReportSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'cr',
          field: 'code',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getThirdCheckReportPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 员工档案
    async getEmployeesSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'p',
          field: 'name',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getEmployeesPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    async getResignationApplicationSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'p',
          field: 'name',
          operator: 'like',
          params: [val]
        },{
          alias: 'p',
          field: 'jobNumber',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getResignationApplicationPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    async getSalarySelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'p',
          field: 'name',
          operator: 'like',
          params: [val]
        },{
          alias: 'p',
          field: 'jobNumber',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getSalaryPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 开票申请下拉列表
    async getInvoiceApplySelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'ip',
          field: 'code',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getInvoiceApplyPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    async getFundsClaimSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'f',
          field: 'code',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getFundsClaimPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    async getQuestionSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'q',
          field: 'title',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getQuestionPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    //用车申请列表
    async getVehicleUserRecordSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'f',
          field: 'code',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getVehicleUserRecordPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 行政中心物品管理
    async getGoodsSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'm',
          field: 'code',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getGoodsPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 采暖报价
    async getHeatingQuotationSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'p',
          field: 'code',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getHeatingQuotationPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 考试管理
    async getExamSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      // if (val) {
      //   params.wrapper.conditions.push({
      //     alias: 'e',
      //     field: 'title',
      //     operator: 'like',
      //     params: [val]
      //   })
      // }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getExamPage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    //分包供应商管理
    async getOutsourcingSupplierSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      // if (val) {
      //   params.wrapper.conditions.push({
      //     alias: '',
      //     field: 'name',
      //     operator: 'like',
      //     params: [val]
      //   })
      // }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getOutsourcingSupplierPage({ key: ' ' })
      if (res) {
        return res.data
      } else {
        return []
      }
    },
    // 考核模板
    async getPerformanceEvaluationTemplateSelectList(val, conditions, all = false) {
      let params = {
        pageSize: all ? this.maxSize : this.selectSize,
        pageNum: 1,
        wrapper: {
          conditions: [],
          operator: 'and'
        }
      }
      if (val) {
        params.wrapper.conditions.push({
          alias: 'p',
          field: 'name',
          operator: 'like',
          params: [val]
        })
      }
      if (conditions && Array.isArray(conditions)) {
        params.wrapper.conditions.push(...conditions)
      }
      let res = await getPerformanceEvaluationTemplatePage(params)
      if (res) {
        return res.data
      } else {
        return []
      }
    }
  }
}
