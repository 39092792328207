import axios from './axios.config'

const o2oaConfig = {
  // baseURL: import.meta.env.VITE_BASEURL_OA
  baseURL: '/'
}

// 发起流程
export const startProcess = (data) => {
  return axios
    .post(
      `/x_processplatform_assemble_surface/jaxrs/work/application/${data.application}/process/${data.process}`,
      data,
      o2oaConfig
    )
    .then(async (res) => {
      if (res && res.data && res.data[0]) {
        let process = res.data[0]
        let task = process.taskList[0]
        const { id, identity, routeNameList } = task
        if (id) {
          let submitData = {
            id,
            identity,
            routeName: routeNameList[0],
            opinion: ''
          }
          await processSubmit(submitData)
        }
      }
      return res
    })
}
export const processWork = (id) =>
  axios.get(`x_processplatform_assemble_surface/jaxrs/work/v2/workorworkcompleted/${id}`, o2oaConfig);
export const processSubmit = (data) =>
  axios.post(`x_processplatform_assemble_surface/jaxrs/task/${data.id}/processing`, data, o2oaConfig);
// axios.post('/x_processplatform_service_processing/jaxrs/work', data, o2oaConfig)

export const getUnitTop = (data) =>
  axios.get("/x_organization_assemble_control/jaxrs/unit/list/top", o2oaConfig);

export const getUserListByUnit = (data) =>
  axios.get(`/x_organization_assemble_control/jaxrs/identity/list/unit/${data.flag}`, o2oaConfig);

export const getUnitList = (data) =>
  axios.post(
    `/x_organization_assemble_control/jaxrs/unit/list/unit/sub/direct/object`,
    data,
    o2oaConfig
  )

export const getPersonAll = () =>
  axios.get(`/x_organization_assemble_express/jaxrs/person/list/all/object`, o2oaConfig);

export const getUserIdentityListLike = (data) =>
  axios.put(`/x_organization_assemble_control/jaxrs/identity/list/like`, data, o2oaConfig);

export const getUserIdentityDetail = (data) =>
  axios.get(`/x_organization_assemble_control/jaxrs/identity/${data.id}`, o2oaConfig);

export const getPersonListWithUnitSubDirectObject = (data) =>
  axios.post(`/x_organization_assemble_express/jaxrs/person/list/unit/sub/direct/object`, data, o2oaConfig);

/**
 * 根据用户表示获取主身份
 * @param personId
 */
export const listIdentityByPersonId = (personId) => axios.get(`/x_organization_assemble_control/jaxrs/identity/list/person/${personId}`, o2oaConfig);

export const getWorkLog = (data,source) =>
  axios.get(`/x_processplatform_assemble_surface/jaxrs/worklog/list/workorworkcompleted/${data.id}`, {
    ...o2oaConfig,
    cancelToken: source ? source.token : null
  });

export const getJobLog = (data,source) =>
  axios.get(`/x_processplatform_assemble_surface/jaxrs/worklog/list/job/${data.id}`, {
    ...o2oaConfig,
    cancelToken: source ? source.token : null
  });

// 角色权限
//获取角色字段
export const getRole = (data) =>
  axios.get(`/x_organization_assemble_control/jaxrs/role/${data}`, o2oaConfig);
// 角色模糊查询
export const getRoleListLikePage = (data) =>
  axios.put(`/x_organization_assemble_control/jaxrs/role/list/like`, data, o2oaConfig);
//删除角色
// export const deleteRoleList = (data) =>axios.delete(`/x_organization_assemble_control/jaxrs/role/{flag}`)
// 更新指定用户指定属性
export const updatePersonCustom = (data) =>
  axios.put(`/x_organization_assemble_personal/jaxrs/custom/${data.key}`, data.params, o2oaConfig);

// 管理员更新指定用户指定属性
export const managerUpdatePersonCustom = (data) =>
  axios.put(`/x_organization_assemble_personal/jaxrs/custom/manager/person/${data.person}/name/${data.name}`, data.params, o2oaConfig);

// 获取指定用户指定属性
export const getPersonCustom = (data) =>
  axios.get(`/x_organization_assemble_personal/jaxrs/custom/${data.key}`, o2oaConfig);

// 待办接口
// 按条件过滤的当前用户待办.（带分页）
export const getMyTaskList = (data) =>
  axios.post(
    `/x_processplatform_assemble_surface/jaxrs/task/v2/list/paging/${data.pageNumber}/size/${data.pageSize}`, data.params, o2oaConfig);
//按条件过滤当前用户代办 （不带分页）
// export const getTaskList = (data) =>
//     axios.post(
//         `/x_processplatform_assemble_surface/jaxrs/task/v2/list`,
//         data,
//         o2oaConfig
//     )
// 当前用户代办数量
export const getTaskNum = (data) =>
  axios.post(
    `/x_processplatform_assemble_surface/jaxrs/task/v2/count`,
    data,
    o2oaConfig
  );
//已办事项（带分页）
export const getMyCompletedTask = (data) =>
  axios.post(
    `/x_processplatform_assemble_surface/jaxrs/taskcompleted/v2/list/paging/${data.pageNumber}/size/${data.pageSize}`,
    data.params,
    o2oaConfig
  )
// 已办事项（不带分页）
// export const getCompletedTask = (data) =>
//     axios.post(
//         `/x_processplatform_assemble_surface/jaxrs/taskcompleted/v2/list`,
//         data,
//         o2oaConfig
//     )

// 待阅接口
export const getOnAllocationPage = (data) =>
  axios.post(
    `/x_processplatform_assemble_surface/jaxrs/read/v2/list/paging/${data.pageNumber}/size/${data.pageSize}`,
    data.params,
    o2oaConfig
  )
// 已阅接口
export const getReadCompletedPage = (data) =>
  axios.post(
    `/x_processplatform_assemble_surface/jaxrs/readcompleted/v2/list/paging/${data.pageNumber}/size/${data.pageSize}`,
    data.params,
    o2oaConfig
  )

// 阅读接口
export const readProcessing = (data) =>
  axios.put(
    `/x_processplatform_service_processing/jaxrs/read/${data.id}/processing`,
    data.params,
    o2oaConfig
  )
// 阅读接口2
export const readProcessing2 = (data) =>
  axios.post(
    `/x_processplatform_assemble_surface/jaxrs/read/${data.id}/processing`,
    data.params,
    o2oaConfig
  )
export const getViewDetail = (id) =>
  axios.get(`/x_query_assemble_surface/jaxrs/view/${id}`, o2oaConfig);
export const getViewBundle = (id, data) =>
  axios.put(`/x_query_assemble_surface/jaxrs/view/${id}/bundle`, data, o2oaConfig);

export const getViewExecute = (id, data) =>
  axios.put(`/x_query_assemble_surface/jaxrs/view/${id}/execute`, data, o2oaConfig);

export const updateWithWorkPath0 = (id, path, data) =>
  axios.put(`/x_processplatform_assemble_surface/jaxrs/data/work/${id}/${path}`, data, o2oaConfig)

export const processControl = (id) =>
  axios.get(`x_processplatform_assemble_surface/jaxrs/control/workorworkcompleted/${id}`, o2oaConfig);
export const getPersonByRole = (id) =>
  axios.get(`/x_organization_assemble_control/jaxrs/person/list/role/${id}`, o2oaConfig);


// 客服首页接口数据
export const serviceData = (data) =>
  axios.put(`/x_query_assemble_surface/jaxrs/stat/flag/${data.flag}/query/${data.queryFlag}/execute`, data.params, o2oaConfig);


export const getApplicationDictByProcess = (data) =>
  axios.get(
    `/x_processplatform_assemble_surface/jaxrs/applicationdict/${data.flag}/application/${data.id}/data`,
    o2oaConfig
  )

export const updateApplicationDictByProcess = (data) =>
  axios.put(
    `/x_processplatform_assemble_surface/jaxrs/applicationdict/${data.flag}/application/${data.id}/${data.path}/data`, data.params,
    o2oaConfig
  )

export const createApplicationDictPath = (data) =>
  axios.post(
    `/x_processplatform_assemble_surface/jaxrs/applicationdict/${data.flag}/application/${data.id}/${data.path}/data`, data.params,
    o2oaConfig
  )

export const downloadAttachment = (data) =>
  axios.get(
    `/x_processplatform_assemble_surface/jaxrs/attachment/download/${data.id}/stream`,
    {
      ...o2oaConfig,
      responseType: "blob"
    }
  );

export const getExecuteModePage = (data) =>
  axios.post(
    `/x_query_assemble_surface/jaxrs/statement/${data.viewId}/execute/mode/all/page/${data.pageNumber}/size/${data.pageSize}`, data.params,
    o2oaConfig
  )

export const getUnitListByTypeAndList = (data) =>
  axios.put(`/x_organization_assemble_control/jaxrs/unit/list/unit/type`, data, o2oaConfig);

export const getUnitListLike = (data) =>
  axios.put(`/x_organization_assemble_control/jaxrs/unit/list/like`, data, o2oaConfig).then((res) => {
    if (res && res.data) {
      res.data = res.data.filter(item=>item.level !== 1)
    }
    return res;
  });

export const getUnitListLikeAll = (data) =>
  axios.put(`/x_organization_assemble_control/jaxrs/unit/list/like`, data, o2oaConfig);

export const getComponentListAll = () =>
  axios.get(`/x_component_assemble_control/jaxrs/component/list/all`, o2oaConfig)
export const getProcessDetailByFlag = (data) =>
  axios.get(`/x_processplatform_assemble_surface/jaxrs/process/${data.flag}`, o2oaConfig);
export const getUnitDetail = (data) =>
  axios.get(`/x_organization_assemble_control/jaxrs/unit/${data.flag}`, o2oaConfig);
export const getLoginCaptcha = (width = 100, height = 45) => {
  return axios.get(
    `/x_organization_authentication/jaxrs/authentication/captcha/width/${width}/height/${height}`,
    o2oaConfig
  )
}

export const getLoginCode = (phone) => {
  return axios.get(
    `x_organization_authentication/jaxrs/authentication/code/credential/${phone}/key/loginsms`,
    o2oaConfig
  )
}

export const loginByCaptcha = (params) => {
  return axios.post(
    `x_organization_authentication/jaxrs/authentication/captcha`,
    params,
    o2oaConfig
  )
}

export const loginByCode = (params) => {
  return axios.post(`x_organization_authentication/jaxrs/authentication/code`, params, o2oaConfig)
}

export const loginByUKey = (params) => {
  return axios.post(`x_organization_authentication/jaxrs/authentication/ukey`, params, o2oaConfig)
}

export const sso = (code) => {
  return axios.get(`x_organization_authentication/jaxrs/qiyeweixin/code/${code}`, o2oaConfig)
}

export const ythbgptsso = (code, state) => {
  return axios.get(`x_organization_authentication/jaxrs/ythbgpt/code/${code}/${state}`, o2oaConfig)
}

export const execute = (flag) => {
  return axios.post(`x_center/jaxrs/invoke/${flag}/execute`, {}, o2oaConfig)
}

export const changePassword = (params) => {
  return axios.put(`x_organization_assemble_personal/jaxrs/password`, params, o2oaConfig)
}

export const login = (data) => {
  return axios.post(`x_organization_assemble_authentication/jaxrs/authentication`, data, o2oaConfig)
}

export const getLoginUserInfo = () => {
  return axios.get(`x_organization_assemble_authentication/jaxrs/authentication`, o2oaConfig)
}

export const logout = () => {
  return axios.delete(`x_organization_assemble_authentication/jaxrs/authentication`, o2oaConfig)
}
export const getLoginBind = () => {
  return axios.get(`x_organization_assemble_authentication/jaxrs/authentication/bind`, o2oaConfig)
}

export const getQywxConfig = () => {
  return axios.get(
    `x_organization_assemble_authentication/jaxrs/authentication/oauth/qywx/config`,
    o2oaConfig
  )
}

export const getConsumeList = (count, consume = 'ws') => {
  return axios.get(
    `x_message_assemble_communicate/jaxrs/consume/list/${consume}/currentperson/count/${count}`,
    o2oaConfig
  )
}

export const listAvailableIdentityWithProcess = (data) => {
  return axios.get(
    `x_processplatform_assemble_surface/jaxrs/process/list/available/identity/process/${data.id}`,
    o2oaConfig
  )
}
export const createProcessWork = (data) => {
  return axios.post(`x_processplatform_assemble_surface/jaxrs/work/process/${data.id}`, data, o2oaConfig)
}

export const getApplicationListComplex = () =>
  axios.get(`/x_processplatform_assemble_surface/jaxrs/application/list/complex`, o2oaConfig)
export const addUnitDuty = (data) => {
  return axios.post(`/x_organization_assemble_control/jaxrs/unitduty`, data, o2oaConfig)
}

export const editUnitDuty = (data) => {
  return axios.put(`/x_organization_assemble_control/jaxrs/unitduty/${data.id}`, data, o2oaConfig)
}

export const deleteUnitDuty = (data) => {
  return axios.delete(`/x_organization_assemble_control/jaxrs/unitduty/${data.id}`, o2oaConfig)
}

export const getProcessDetail = (data) => {
  return axios.get(`/x_processplatform_assemble_surface/jaxrs/work/v2/workorworkcompleted/${data.id}`, o2oaConfig)
}

export const workCloseCheck = (data) => {
  return axios.get(`/x_processplatform_assemble_surface/jaxrs/work/${data.id}/close/check`, o2oaConfig)
}
// export const deleteWorkDraft = (data) => {
//   return axios.delete(`/x_processplatform_service_processing/jaxrs/work/${data.id}/draft`, o2oaConfig)
// }
export const deleteWorkDraft = (data) => {
  return axios.delete(`/x_processplatform_assemble_surface/jaxrs/work/${data.id}`, o2oaConfig)
}

export const setPersonIcon = (data) => {
  return axios.put(`/x_organization_assemble_personal/jaxrs/person/icon`,data, {
    ...o2oaConfig,
    headers:{
      'Content-Type': 'multipart/form-data;'
    }
  })
}

export const getMyWorkPage = (data) => {
  return axios.post(`/x_processplatform_assemble_surface/jaxrs/work/list/my/paging/${data.pageNumber}/size/${data.pageSize}`, data.params, o2oaConfig)
}
export const getFormV2 = (data) => {
  return axios.get(`/x_processplatform_assemble_surface/jaxrs/form/v2/90f92a9a-6a4c-4f07-a0d9-674314c2a766`, o2oaConfig)
}

export const getAuthenticationMode = () => {
  return axios.get(`/x_organization_assemble_authentication/jaxrs/authentication/mode`, o2oaConfig)
}

export const getEventListFilter = (data) => {
  return axios.put(`/x_calendar_assemble_control/jaxrs/event/list/filter`, data, o2oaConfig)
}

export const getEventListFilterSample = (data) => {
  return axios.put(`/x_calendar_assemble_control/jaxrs/event/list/filter/sample`, data, o2oaConfig)
}

export const addCalendarSchedule = (data) => {
  return axios.post(`/x_calendar_assemble_control/jaxrs/event`, data, o2oaConfig)
}

export const getCalendarListMy = () => {
  return axios.get(`/x_calendar_assemble_control/jaxrs/calendar/list/my`, o2oaConfig)
}

export const getCalendarScheduleDetail = (data) => {
  return axios.get(`x_calendar_assemble_control/jaxrs/event/${data.id}`, o2oaConfig)
}
export const editCalendarSchedule = (data) => {
  return axios.put(`/x_calendar_assemble_control/jaxrs/event/update/single/${data.id}`, data, o2oaConfig)
}
export const deleteCalendarSchedule = (data) => {
  return axios.delete(`x_calendar_assemble_control/jaxrs/event/single/${data.id}`, o2oaConfig)
}



