<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ColorPicker",
  emits: ["update:modelValue"],
  props: {
    modelValue: {},
    size: {
      default() {
        return 26;
      }
    }
  },
  computed: {
    color: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    },
    colorSize() {
      return this.size - 8;
    },
    style() {
      let style = {
        "width": `${this.colorSize}px`,
        "height": `${this.colorSize}px`
      };
      if (!this.modelValue) {
        return style;
      }
      return {
        ...style,
        "background-color": this.modelValue
      };
    }
  },
  methods: {
    checkColor(item) {
      this.color = item.background;
    }
  }
});
</script>

<template>

  <el-popover
    placement="bottom-start"
    trigger="click"
    width="300"
  >
    <template #reference>
      <div class="color-picker-wrapper">
        <div class="color-picker" :style="style"></div>
      </div>
    </template>
    <template #default>
      <div class="preset-colors">
        <div class="preset-colors-item" v-for="(item,index) in dictStore.getDictByType('presetColors')" :key="index"
             @click="checkColor(item)"
             >
          <div class="preset-colors-item-content" :style="{'background-color':`${item.background}`}"></div>
        </div>
      </div>
      <input type="color" v-model="color" />
    </template>
  </el-popover>
</template>

<style scoped lang="scss">
.color-picker-wrapper {
  height: 26px;
  width: 26px;
  padding: 3px;
  border: 1px solid var(--dubhe-border-color);
  border-radius: 2px;
  cursor: pointer;

  .color-picker {
    height: 100%;
    width: 100%;
  }
}

.preset-colors {
  display: flex;
  flex-wrap: wrap;

  .preset-colors-item {
    height: 26px;
    width: 26px;
    padding: 3px;
    border: 1px solid var(--dubhe-border-color);
    border-radius: 2px;
    margin-right: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    .preset-colors-item-content{
      height: 100%;
      width: 100%;
    }
  }
}
</style>