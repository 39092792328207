<script>
import { defineComponent } from 'vue'
import _ from 'lodash'
export default defineComponent({
  name: 'DictTag',
  props: {
    type: {
      type: String,
      required: true
    },
    value: {
      default() {
        return ''
      }
    }
  },
  computed: {
    tagList() {
      if(_.isBoolean(this.value)){
        return [this.dictStore.getDictItemByTypeValue(this.type, this.value)]
      }
      if (!this.value) {
        return []
      }
      let list = this.value
        .split(',')
        .map((item) => this.dictStore.getDictItemByTypeValue(this.type, item))
      return list
    }
  },
  methods: {
    colorToRgba(color, opacity) {
      let reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
      // 把颜色值变成小写
      if (reg.test(color)) {
        // 如果只有三位的值，需变成六位，如：#fff => #ffffff
        if (color.length === 4) {
          let colorNew = '#'
          for (let i = 1; i < 4; i += 1) {
            colorNew += color.slice(i, i + 1).concat(color.slice(i, i + 1))
          }
          color = colorNew
        }
        // 处理六位的颜色值，转为RGB
        let colorChange = []
        for (let i = 1; i < 7; i += 2) {
          colorChange.push(parseInt('0x' + color.slice(i, i + 2)))
        }
        return 'rgba(' + colorChange.join(',') + ',' + opacity + ')'
      } else {
        return color
      }
    },
    filterTagStyle(item) {
      if (!item.background) {
        return {}
      }
      let backgroundColor = this.colorToRgba(item.background,0.05)
      let borderColor = this.colorToRgba(item.background,0.3)
      return {
        color: item.background,
        background: backgroundColor,
        borderColor: borderColor
      }
    }
  }
})
</script>

<template>
  <el-tag
    v-for="(item, index) in tagList"
    :key="index"
    :style="filterTagStyle(item)"
    v-bind="$attrs"
    >{{ item.label }}
  </el-tag>
</template>

<style scoped lang="scss">
.dubhe-tag {
  & + .dubhe-tag {
    margin-left: 5px;
  }
}
</style>