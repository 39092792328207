<script>
import { defineComponent } from 'vue'
import { designatedPerson, getPersonAll, getPersonListWithUnitSubDirectObject } from '@/axios'

export default defineComponent({
  name: 'ProjectLeader',
  emits: ['update:modelValue', 'callback'],
  data() {
    return {
      loading: true,
      form: {
        // id: null,
        // leaderId: '',
        // leaderName: '',
        projectLeaderList: [],
        projectLeaderIdList: [],
        projectWorkers: [],
        projectWorkersId: [],
        userList: []
      },
      rules: {
        projectLeaderIdList: [
          {
            validator: (rule, value, callback) => {
              if (!value || value.length === 0) {
                callback(new Error('请选择负责人'))
              }
              callback()
            }
          }
        ]
      }
    }
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    project: {},
    showWorks: {
      default() {
        return false
      }
    }
  },
  computed: {
    show: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    }
  },
  mounted() {
    this.initUserList()
  },
  methods: {
    open() {
      let projectLeaderList = this.project.projectLeaderList || []
      let projectWorkers = this.project.projectWorkers || []
      this.form.projectLeaderList = projectLeaderList
      this.form.projectLeaderIdList = projectLeaderList.map((item) => item.personId)
      this.form.projectWorkers = projectWorkers
      this.form.projectWorkersId = projectWorkers.map((item) => item.personId)
      this.loading = false
    },
    initUserList() {
      getPersonAll().then((res) => {
        this.userList = res.data
      })
      // getPersonListWithUnitSubDirectObject({
      //   // 组织唯一编码
      //   unitList:[6]
      // }).then(res=>{
      //   this.userList = res.data
      // })
    },
    leaderChange(val) {
      this.form.projectLeaderList = val.map((item) => {
        return {
          personId: item.id,
          personName: item.name,
          personDistinguishedName: item.distinguishedName
        }
      })
      // this.form.leaderName = val.name
    },
    workersChange(val) {
      this.form.projectWorkers = val.map((item) => {
        return {
          personId: item.id,
          personName: item.name,
          personDistinguishedName: item.distinguishedName
        }
      })
    },
    saveLeader() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.loading = true
          let reqData = {
            id: this.project.id,
            projectLeaderList: this.form.projectLeaderList
          }
          if (this.showWorks) {
            reqData.projectWorkers = this.form.projectWorkers
          }
          designatedPerson(reqData)
            .then((res) => {
              if (res) {
                this.$emit('callback', false, res.data)
                this.$message.success('指定负责人成功')
                this.show = false
              }
            })
            .catch(() => {
              this.loading = false
            })
        }
      })
    }
  }
})
</script>

<template>
  <el-dialog v-model="show" title="指定负责人" @open="open">
    <el-form ref="form" :model="form" :rules="rules" v-loading="loading">
      <el-form-item label="负责人" prop="projectLeaderIdList">
        <SelectUser
          multiple
          v-model="form.projectLeaderIdList"
          @change="leaderChange"
          :list="userList"
          :user="true"
        ></SelectUser>
      </el-form-item>
      <el-form-item label="施工人员" prop="projectWorkersId" v-if="showWorks">
        <SelectUser
          multiple
          v-model="form.projectWorkersId"
          @change="workersChange"
          :list="userList"
          :user="true"
        ></SelectUser>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="show = false" :disabled="loading">取消</el-button>
        <el-button type="primary" @click="saveLeader" :disabled="loading">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<style scoped lang="scss"></style>