import { defineStore } from 'pinia'
import _ from 'lodash'
import { getAuthenticationMode, getSystemInfo } from '@/axios'

export const useLoginStore = defineStore({
  id: 'login',
  persist: {
    enabled: true
  },
  state: () => {
    return {
      loginConfig: {},
      newLoginConfig: {},
      modeConfig: {}
    }
  },
  getters: {},
  actions: {
    initLoginConfig() {
      getSystemInfo().then((res) => {
        let newLoginConfig = res.data
        if (!_.isEqual(this.loginConfig, newLoginConfig)) {
          this.newLoginConfig = newLoginConfig
          this.loginConfig = newLoginConfig
          this.setTheme()
          setTimeout(() => {
            this.newLoginConfig = {}
          }, 1000)
        } else {
          this.setTheme()
        }
      })
      getAuthenticationMode().then((res) => {
        this.modeConfig = res.data
      })
    },
    colourBlend(c1, c2, ratio) {
      ratio = Math.max(Math.min(Number(ratio), 1), 0)
      let r1 = parseInt(c1.substring(1, 3), 16)
      let g1 = parseInt(c1.substring(3, 5), 16)
      let b1 = parseInt(c1.substring(5, 7), 16)
      let r2 = parseInt(c2.substring(1, 3), 16)
      let g2 = parseInt(c2.substring(3, 5), 16)
      let b2 = parseInt(c2.substring(5, 7), 16)
      let r = Math.round(r1 * (1 - ratio) + r2 * ratio)
      let g = Math.round(g1 * (1 - ratio) + g2 * ratio)
      let b = Math.round(b1 * (1 - ratio) + b2 * ratio)
      r = ('0' + (r || 0).toString(16)).slice(-2)
      g = ('0' + (g || 0).toString(16)).slice(-2)
      b = ('0' + (b || 0).toString(16)).slice(-2)
      return '#' + r + g + b
    },
    setTheme() {
      let el = document.documentElement
      let color = getComputedStyle(el).getPropertyValue(`--dubhe-color-primary`)
      if (this.loginConfig.theme) {
        color = this.loginConfig.theme
      }
      el.style.setProperty('--dubhe-color-primary', color)
      for (let i = 1; i <= 9; i++) {
        // 使用color-mix
        // el.style.setProperty(`--dubhe-color-primary-light-${i}`, `color-mix(in srgb, ${color} ${10-i}0%, #ffffff ${i}0%)`)
        // el.style.setProperty(`--dubhe-color-primary-dark-${i}`, `color-mix(in srgb, ${color} ${10-i}0%, #000000 ${i}0%)`)
        // 手动混合颜色
        el.style.setProperty(
          `--dubhe-color-primary-light-${i}`,
          this.colourBlend(color, '#ffffff', i / 10)
        )
        el.style.setProperty(
          `--dubhe-color-primary-dark-${i}`,
          this.colourBlend(color, '#000000', i / 10)
        )
      }
    }
  }
})

