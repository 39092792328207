import axios from './axios.config'
// 油卡充值FuelCardRechargeRecord
// 获取显示字段
export const getFuelCardRechargeRecordFields = (tableId='FuelCardRechargeRecord') =>axios.get(`/jaxrs/fuel_card_recharge_record/fields?tableId=${tableId}`)
// 获取查询条件
export const getFuelCardRechargeRecordConditions = ()=>axios.get('/jaxrs/fuel_card_recharge_record/conditions')
// 查询
export const getFuelCardRechargeRecordPage = (data) =>axios.post('/jaxrs/fuel_card_recharge_record/page',data)
// 详情
export const getFuelCardRechargeRecordDetail = (data) =>axios.get(`/jaxrs/fuel_card_recharge_record/${data.id}`)
// 保存
export const addFuelCardRechargeRecord = (data) =>axios.post(`/jaxrs/fuel_card_recharge_record`,data)
// 修改
export const editFuelCardRechargeRecord = (data) =>axios.put(`/jaxrs/fuel_card_recharge_record`,data)
// 删除
export const deleteFuelCardRechargeRecord = (data) =>axios.delete(`/jaxrs/fuel_card_recharge_record/${data.id}`)
// 导出
export const exportFuelCardRechargeRecord = (data) => axios.post(`/jaxrs/fuel_card_recharge_record/export`,data,{
    responseType:'blob'
})