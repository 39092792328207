<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ProcessTransfer",
  mounted() {
    this.init()
  },
  methods:{
    init(){
      // let a = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww32d46b6e53664730&redirect_uri=http%3A%2F%2Fyuanan.dubhe-tech.com%2Fx_desktop%2Fqiyeweixinsso.html%3Fredirect%3D%2Fmobile%2F%26response_type%3Dcode%26scope%3Dsnsapi_base%26agentid%3D1000004%26%23wechat_redirecthttps://open.weixin.qq.com/connect/oauth2/authorize?appid=ww32d46b6e53664730&redirect_uri=http%3A%2F%2Fyuanan.dubhe-tech.com%2Fx_desktop%2Fqiyeweixinsso.html%3Fredirect%3D%2Fmobile%2F%26response_type%3Dcode%26scope%3Dsnsapi_base%26agentid%3D1000004%26%23wechat_redirect'
      // let b = decodeURIComponent(a)
      // console.log(b);
      // console.log(this.$route);
      console.log('ProcessTransfer')
      let url = this.$route.query.url
      console.log(url)
      // // TODO:测试是否可关闭
      window.open(url,"_self")
      // location.href = url
    },
    close(){
      window.close()
    }
  }
});
</script>

<template>
  <el-button type="primary" @click="close">测试关闭</el-button>
</template>

<style scoped lang="scss"></style>