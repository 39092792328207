import axios from './axios.config'
// 员工技术职称
// 显示字段
export const getEmployeesTechnicalTitlesFields = (tableId='employeesTechnicalTitles') =>axios.get(`/jaxrs/hr/employees_technical_titles/fields?tableId=${tableId}`)
// 查询条件
export const getEmployeesTechnicalTitlesConditions = () =>axios.get('/jaxrs/hr/employees_technical_titles/conditions')
// 查询
export const getEmployeesTechnicalTitlesPage = (data) =>axios.post('/jaxrs/hr/employees_technical_titles/page',data)
// 详情
export const getEmployeesTechnicalTitlesDetail = (data) =>axios.get(`/jaxrs/hr/employees_technical_titles/${data.id}`)
// 归档
export const archivedEmployeesTechnicalTitles = (data) =>axios.put(`/jaxrs/hr/employees_technical_titles/archived/${data.id}`,data)
