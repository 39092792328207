import axios from './axios.config'
// 员工薪资 EmployeesSalary
// 获取显示字段
export const getEmployeesSalaryFields = (tableId='EmployeesSalary')=>axios.get(`/jaxrs/hr/employees/salary/fields?tableId=${tableId}`)
// 查询
export const getEmployeesSalaryConditions = ()=>axios.get('/jaxrs/hr/employees/salary/conditions')
// 分页
export const getEmployeesSalaryPage = (data)=>axios.post('/jaxrs/hr/employees/salary/page',data)
// 详情
export const getEmployeesSalaryDetail = (data)=>axios.get(`/jaxrs/hr/employees/salary/${data.id}`)
// 保存
export const addEmployeesSalary = (data) =>axios.post(`/jaxrs/hr/employees/salary`,data)
// 修改
export const editEmployeesSalary= (data)=>axios.put(`/jaxrs/hr/employees/salary`,data)
// 删除
export const deleteEmployeesSalary = (data)=>axios.delete(`/jaxrs/hr/employees/salary/${data.id}`)