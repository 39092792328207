<script>
import { defineComponent } from 'vue'
import { detailMixins } from '@/common/detailMixins'
import BaseInfoEdit from '@/views/WorkOrder/BaseInfoEdit.vue'
import { workOrderEditMixin } from "@/views/WorkOrder/workOrderEditMixins";

export default defineComponent({
  name: 'InspectionAcceptanceWorkOrderEdit',
  components: { BaseInfoEdit },
  mixins: [detailMixins,workOrderEditMixin],
  data() {
    return {}
  },
  computed: {
    rules() {
      let rules = {
        consumerId: [{ required: true, message: '请选择客户', trigger: 'change' }],
        district: [{ required: true, trigger: 'change', message: '请选择区' }],
        address: [{ required: true, trigger: 'change', message: '请输入详细地址' }],
        workOrderSource: [{ required: true, message: '请选择信息来源', trigger: 'change' }],
        bizType: [{ required: true, message: '请选择业务类型', trigger: 'change' }],
        workNum: [{ required: true, trigger: 'change', message: '请输入工单数' }],
        receptionDeptId: [{ required: true, message: '请选择接单部门', trigger: 'change' }],
        takeDate: [{ required: true,trigger: 'change',message: '请选择接件日期'}],
        visitDate: [{ required: true, message: '请选择上门日期', trigger: 'change' }],
        visitPeriod: [{ required: true, message: '请选择上门时段', trigger: 'change' }],
        checkReportId: [{ required: true, message: '请选择检测申请', trigger: 'change' }]
      }
      if (this.batch) {
        delete rules.district
        delete rules.address
        delete rules.consumerId
      }
      return rules
    }
  },
  watch: {},
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.baseForm.workOrderType = 'internal_acceptance'
      this.initProjectList()
      this.initMaterialList()
    }
  }
})
</script>
<template>
  <el-drawer
    v-model="showDrawer"
    size="95%"
    direction="btt"
    destroy-on-close
    @open="getDetail"
  >
    <template #header>
      <div>{{ title }}</div>
    </template>
    <template #default>
      <div class="detail-wrapper" v-loading="loading">
        <el-form :model="editForm" :label-position="labelPosition" :rules="rules" ref="form">
          <el-row :gutter="20">
            <BaseInfoEdit v-model:form="editForm" :batch="batch" :show-out-code="false">
              <template #header>
                <el-col :span="24">
                  <Divider title="检测信息"></Divider>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="检测申请" prop="checkReportId">
                    <SelectWithButton
                        :conditions="[
                        {
                          alias: 'cr',
                          field: 'auditStatus',
                          operator: 'eq',
                          params: ['audited']
                        }
                      ]"
                        :remote-method="getThirdCheckReportSelectList"
                        v-model="editForm.checkReportId"
                        :default-label="editForm.checkReportCode"
                        labelKey="code"
                        valueKey="id"
                        dialogName="ThirdCheckReport"
                        @select-change="thirdCheckReportChange"
                    >
                    </SelectWithButton>
                  </el-form-item>
                </el-col>
              </template>
              <template #default>
                <el-col :span="8">
                  <el-form-item label="项目名称" prop="projectId">
                    <SelectWithButton
                      :clearable="true"
                      :conditions="[
                        {
                          alias: 'p',
                          field: 'projectType',
                          operator: 'in',
                          params: ['maintenance']
                        }
                      ]"
                      :remote-method="getProjectAllSelectList"
                      v-model="editForm.projectId"
                      :default-label="editForm.projectName"
                      labelKey="name"
                      valueKey="id"
                      dialogName="ProjectAll"
                      @select-change="projectChange"
                    >
                    </SelectWithButton>
                  </el-form-item>
                </el-col>
              </template>
              <template #other>
                <el-col :span="24">
                  <Divider title="材料信息"></Divider>
                  <el-form-item prop="usedMaterials">
                    <TableNoEdit
                      title="材料明细"
                      :table-option="materialNoEditOption"
                      :list="editForm.usedMaterials"
                    ></TableNoEdit>
                  </el-form-item>
                </el-col>
              </template>
            </BaseInfoEdit>
          </el-row>
        </el-form>
      </div>
    </template>
    <template #footer>
      <div class="drawer-footer">
        <el-button
          @click="handleResetClick"
          :disabled="loading"
          v-if="showByAuditStatus(['pending_submission'])"
          >重置
        </el-button>
        <el-button type="primary" @click="handleSaveClick(false)" :disabled="loading"
          >保存</el-button
        >
        <el-button
          type="primary"
          @click="handleSaveClick(true)"
          icon="Promotion"
          :disabled="loading"
          v-if="showByAuditStatus(['pending_submission'])"
          >提交
        </el-button>
      </div>
    </template>
  </el-drawer>
</template>
<style scoped lang="scss"></style>