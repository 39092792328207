<script>
import { defineComponent } from 'vue'
import { getWorkLogV2 } from '@/axios'
import Divider from '../Divider/Divider.vue'
import axios from 'axios'
import _ from "lodash";

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'ProcessV2',
  components: { Divider },
  data() {
    return {
      processList: [],
      open: true,
      source: null
    }
  },
  props: {
    workId: {},
    module: {
      default() {
        return 'work_order'
      }
    },
    type: {
      default() {
        return 'log'
      }
    }
  },
  computed: {
    requestId() {
      return this.workId || null
    },
    triggerStyle() {
      if (this.open) {
        return {
          transform: 'translateX(-50%) rotate(0)'
        }
      } else {
        return {
          transform: 'translateX(-50%) rotate(180deg)'
        }
      }
    }
  },
  watch: {
    requestId: {
      handler(newVal) {
        if (newVal) {
          this.getLog()
        } else {
          this.closeGetLog()
          this.processList = []
        }
      },
      immediate: true
    }
  },
  mounted() {},
  methods: {
    getLog:_.debounce(function(){
        this.processList = []
        this.closeGetLog()
        if(!this.requestId){
          return
        }
        this.source = axios.CancelToken.source()
        getWorkLogV2({ id: this.requestId, module: this.module, type: this.type }, this.source)
          .then((res) => {
            if (res) {
              this.processList = res.data
            }
          })
          .catch(() => {})
          .finally(() => {
            this.source = null
          })
      },300,
      {
        leading: false,
        trailing: true
      }
    ),
    filterName(string) {
      return string.split('@')[0]
    },
    closeGetLog(){
      if(this.source){
        this.source.cancel()
        this.source = null
      }
    },
    handleTriggerClick() {
      this.open = !this.open
    }
  }
})
</script>

<template>
  <div class="progress-wrapper" :class="{ close: !open }">
    <div class="progress">
      <Divider title="工作流程"></Divider>
      <div class="work-wrapper">
        <div class="work">
          <el-timeline>
            <el-timeline-item v-for="item in processList" :key="item.id" type="success">
              <el-card>
                <div>{{ filterName(item.identity) }}（{{ filterName(item.unit) }}）</div>
                <div>
                  <el-text type="primary">{{ item.operate }}</el-text>
                  <el-text type="info">{{ item.createTime }}</el-text>
                </div>
                <div v-if="item.remark">
                  <el-text type="info">意见：{{ item.remark }}</el-text>
                </div>
              </el-card>
            </el-timeline-item>
          </el-timeline>
          <!--          <div class="work-item">-->
          <!--            <div class="content">-->
          <!--              <div v-for="item in processList" :key="item.id" class="content-item">-->
          <!--                <div>-->
          <!--                  <span class="person">{{ filterName(item.identity) }}</span>-->
          <!--                  （{{ filterName(item.unit) }}）【{{ item.operate }}】-->
          <!--                </div>-->
          <!--                <div>提交于：{{ item.createTime }}</div>-->
          <!--                <div v-if="item.remark">-->
          <!--                  <span class="opinion">{{ item.remark }}</span>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
      <div class="trigger" @click="handleTriggerClick" :style="triggerStyle">
        <el-icon>
          <DArrowRight />
        </el-icon>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.progress-wrapper {
  width: 350px;
  flex-grow: 0;
  flex-shrink: 0;
  padding-left: 30px;
  height: 100%;
  transition: all linear 0.2s;
  margin: 0 !important;

  &.close {
    width: 60px;

    .progress {
      width: 30px;

      & > * {
        display: none;
      }
    }
  }

  .trigger {
    position: absolute;
    display: flex !important;
    align-items: center;
    justify-content: center;
    top: 36px;
    left: 0;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: var(--dubhe-fill-color);
    border: 1px solid var(--dubhe-border-color);
    transition: all linear 0.2s;
    cursor: pointer;
    box-shadow: var(--dubhe-box-shadow-light);
  }

  .progress {
    position: relative;
    padding-left: 30px;
    height: 100%;
    width: 100%;
    border-left: 1px solid var(--dubhe-border-color);
    display: flex;
    flex-direction: column;

    .work-wrapper {
      position: relative;
      flex: 1;

      .work {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        overflow-y: auto;
        overflow-x: clip;

        :deep(.dubhe-timeline) {
          padding-left: 10px;

          .dubhe-timeline-item__wrapper {
            padding-left: 20px;
          }
        }

        :deep(.dubhe-card) {
          & + .dubhe-card {
            margin-top: 10px;
          }

          .dubhe-card__body {
            padding: 10px;
          }
        }
      }
    }
  }

  .content {
    padding: 5px 0;

    .content-item {
      padding: 5px 10px;
      font-size: 12px;
      line-height: 1.5;

      & + .content-item {
        border-top: 1px solid var(--dubhe-border-color);
      }

      .person {
        color: var(--dubhe-color-danger);
      }

      .opinion {
        color: var(--dubhe-color-primary);
        word-break: break-all;
      }

      & > div {
        padding: 3px 0;
      }
    }
  }
}
</style>