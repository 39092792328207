import dayjs from "dayjs";
import _ from "lodash";
import { selectListMixins } from "./selectListMixins";
import { commonMixins } from "./commonMixins";

export const tableDataMixins = {
  mixins: [selectListMixins, commonMixins],
  data() {
    return {
      //分页数据
      page: {
        total: 0,
        pageSize: 20,
        pageNumber: 1
      },
      //弃用（模糊检索）
      searchContent: "",
      //模糊搜索的key
      tableSearchKey: "",
      //检索条件列表
      conditionOptions: [],
      // 检索条件需特殊处理部分
      conditionCustom: {
        material: {
          type: "list",
          method: this.getMaterialSelectList,
          methodParams: ["", [], true]
        },
        material_type: {
          type: "list",
          method: this.getMaterialTypeSelectList,
          methodParams: ["", [], true]
        },
        store: {
          type: "list",
          method: this.getStoreSelectList,
          methodParams: ["", [], true]
        },
        supplier: {
          type: "list",
          method: this.getSupplierSelectList,
          methodParams: ["", [], true]
        },
        project: {
          type: "list",
          method: this.getProjectSelectList,
          methodParams: ["", [], true]
        },
        maintenance_project: {
          type: "list",
          method: this.getMaintenanceProjectSelectList,
          methodParams: ["", [], true]
        },
        commercial_project: {
          type: "list",
          method: this.getProjectSelectList,
          methodParams: ["", [{
            alias: 'p',
            field: 'projectType',
            operator: 'eq',
            params: ['commercial']
          }], true]
        },
        vehicle: {
          type: "list",
          method: this.getVehicleProjectSelectList,
          methodParams: ["", [], true]
        },
        consumer: {
          type: "list",
          method: this.getConsumerSelectList,
          methodParams: ["", [], true]
        },
        unit: {
          type: "list",
          method: this.getStatementDepartmentSelectList,
          methodParams: ["", [], true]
        },
        company: {
          type: "list",
          method: this.getInvoiceCompanySelectList,
          methodParams: ["", [], true]
        },
        dict: {
          type: "dict"
        },
        radio: {
          type: "dict"
        }
      },
      //检索条件
      conditions: [],
      //所有查询条件
      wrapper: {
        and: [],
        ors: [],
        conditions: [],
        operator: "and"
      },
      //排序条件
      sortList: [],
      //sort element与后端对应
      sortValueMap: {
        ascending: "asc",
        descending: "desc"
      },
      //table展示列
      tableColList: [],
      //table数据
      tableDataList: [],
      // table loading
      tableLoading: true,
      // 选中id，弹出选择中使用
      checkValue: "",
      checkList: [],
      colFilterMap: {
        money: { filter: this.filterMoney },
        boole: {
          filter: (obj) => {
            let value = obj.value;
            if (_.isNumber(value)) {
              return value === 1 ? "是" : "否";
            }
            return {
              name: value ? "是" : "否"
            };
          }
        }
      },
      mergeName: null,
      exportKey: 'id',
      exportTableKey: 'id'
    };
  },
  props: {
    customConditions: {
      type: Array
    }
  },
  watch: {},
  mounted() {
    // this.initDefaultConditions();
  },
  computed: {
    // 选择返回该条数据
    checkItem() {
      let item = this.tableDataList.find((item) => {
        return item.id === this.checkValue;
      });
      if (item) {
        return item;
      }
      return null;
    }
  },
  methods: {
    initDefaultConditions() {
      if (this.customConditions) {
        this.wrapper.and.push(...this.customConditions);
      }
    },
    // 根据展示列数据过滤返回展示列list
    getColList(list) {
      let colList = list.map((item) => {
        let colItem = {
          name: item.property,
          label: item.desc,
          order: item.order,
          columnMerge: item.columnMerge,
          richText: item.richText,
          alias: item.alias,
          fieldType: item.fieldType,
          iconKey: item.iconKey,
          sortAppendAlias: item.sortAppendAlias,
          total: item.total
        };

        if (item.sort) {
          colItem.sortable = "custom";
        }
        if (item.fieldType && this.colFilterMap[item.fieldType]) {
          colItem = {
            ...colItem,
            ...this.colFilterMap[item.fieldType]
          };
        } else if (this.colFilterMap[item.property]) {
          colItem = {
            ...colItem,
            ...this.colFilterMap[item.property]
          };
        }
        if ("show" in item) {
          colItem.show = item.show;
        } else {
          colItem.show = true;
        }
        if (item.width && item.width > 0) {
          colItem.width = item.width;
        }
        if (item.minWidth && item.minWidth > 0) {
          colItem.minWidth = item.minWidth;
        }
        return colItem;
      });
      return colList;
    },
    // 排序方法 需外部定义getPage获取列表
    sortChange(val) {
      if (!val.order) {
        this.sortList = [];
      } else {
        this.sortList = [
          {
            alias: val.alias,
            sortAppendAlias: val.sortAppendAlias,
            field: val.prop,
            sort: this.sortValueMap[val.order]
          }
        ];
      }
      this.getPage();
    },
    // 设置过滤参数及模糊检索字段
    async setConditionOptions(list, searchKey, options = {}) {
      // list = list.filter((item,index)=>index <= 7)
      let exportList = [];
      // 处理 field alias
      if (options.exportList && options.exportList.length > 0) {
        exportList = options.exportList.map(item => {
          let exportArray = item.split("&");
          return {
            alias: exportArray[1] || '',
            field: exportArray[0]
          };
        });
      }
      this.tableSearchKey = searchKey;
      let fieldsKeyMap = {};
      list.forEach((item) => {
        if (item.fields && item.fields.length > 0) {
          item.fields.forEach((fItem, fIndex) => {
            if (fIndex === 0) {
              item.field = fItem.field;
            }
            if (this.conditionCustom[fItem.type]) {
              if (fItem.type === "dict") {
                item.type = fItem.field;
                fieldsKeyMap[item.type] = {};
              } else if (fItem.type === "radio") {
                // 将radio转化为dict，简单select
                fItem.type = "dict";
                item.type = fItem.field;
                fieldsKeyMap[item.type] = {};
                if (fItem.items) {
                  fieldsKeyMap[item.type].list = fItem.items.map(lItem => {
                    return {
                      id: lItem,
                      name: lItem
                    };
                  });
                }
              } else {
                item.type = fItem.type;
                fieldsKeyMap[item.type] = {};
                if (item.listConditions) {
                  fieldsKeyMap[item.type].listConditions = item.listConditions;
                }
              }
            }
          });
        }
      });
      for (let key in fieldsKeyMap) {
        if (this.conditionCustom[key]) {
          let custom = this.conditionCustom[key];
          if (custom.type === "list" && custom.method) {
            // let methodParams = _.cloneDeep(custom.methodParams);
            // if (fieldsKeyMap[key].listConditions) {
            //   methodParams[1] = fieldsKeyMap[key].listConditions;
            // }
            // fieldsKeyMap[key].list = await custom.method(...methodParams);
            fieldsKeyMap[key] = {
              ...fieldsKeyMap[key],
              ...custom
            }
          } else if (custom.type === "list" && custom.list) {
            fieldsKeyMap[key].list = custom.list;
          }
        } else if (fieldsKeyMap[key].list) {
        } else {
          fieldsKeyMap[key].list = this.getDictSelectList(key);
        }
      }
      list.forEach((item) => {
        item.props = fieldsKeyMap[item.type];
      });
      list = list.filter((item) => {
        if (item.alias) {
          return exportList.every(eItem => {
            return eItem.alias !== item.alias || eItem.field !== item.field;
          });
        } else {
          return exportList.every(eItem => {
            if (eItem.alias) {
              return true;
            } else {
              return eItem.field !== item.field;
            }
          });
        }
      });
      this.conditionOptions = list;
    },
    // 获取字典下拉列表
    getDictSelectList(key) {
      let checkItem = this.dictStore.dictList.find((item) => item.type === key);
      let list = [];
      if (checkItem) {
        list = checkItem.children.map((item) => {
          return {
            id: item.val,
            name: item.label
          };
        });
      }
      return list;
    },
    setWrapper(wrapper) {
      this.wrapper = { ...this.wrapper, ...wrapper };
    },
    // 获取导出参数
    getExportParams(type, alias) {
      let params = {
        wrapper: {
          and: []
        },
        sorts: this.sortList,
        tableId: this.tableId
      };
      let idCondition = {
        alias: alias || "",
        field: this.exportKey,
        operator: "in",
        params: []
      };
      switch (type) {
        case "check":
          if (this.checkList.length > 0) {
            idCondition.params = this.checkList.map((item) => {
              return item[this.exportTableKey];
            });
            params.wrapper.and = [idCondition];
          } else {
            this.$message.warning("未选择数据，无法导出");
            return;
          }
          break;
        case "page":
          if (this.tableDataList.length > 0) {
            idCondition.params = this.tableDataList.map((item) => {
              return item[this.exportTableKey];
            });
            params.wrapper.and = [idCondition];
          } else {
            this.$message.warning("当前页无数据，无法导出");
            return;
          }
          break;
        case "all":
          params.wrapper = this.wrapper;
          break;
        default:
          break;
      }
      return params;
    },
    // 导出文件
    exportFile(type, alias, method) {
      let exportParams = this.getExportParams(type, alias);
      if (exportParams) {
        this.tableLoading = true;
        method(exportParams,this.tableId).then((res) => {
          if (res) {
            this.downLoadFile(res);
          }
        }).finally(() => {
          this.tableLoading = false;
        });
      }
    }
  }
};
