<script>
import { defineComponent } from "vue";
import SignCanvas from "./SignCanvas.vue";
import { validateImageSize } from "./signCanvas";
import SignDrawer from "./SignDrawer.vue";

export default defineComponent({
  name: "Sign",
  components: {
    SignDrawer,
    SignCanvas
  },
  emits:['callback'],
  data() {
    return {
      showDrawer: false
    };
  },
  props:{
    title:{},
    img:{}
  },
  computed: {
    buttonText() {
      return this.img ? "重新签字" : "签字";
    }
  },
  methods: {
    handleShowClick() {
      this.showDrawer = true;
    },
    signDrawerCallback(imgList){
      if(imgList&&imgList.length > 0){
        let img = imgList[0]
        this.$emit('callback',img)
      }
    }
  }
});
</script>

<template>
  <div class="wrap">
    <div>
      <el-button type="primary" plain @click="handleShowClick">{{buttonText}}</el-button>
      <!--      <el-button @click="$refs.canvasRef.clear()">清空</el-button>-->
      <!--      <el-button @click="$refs.canvasRef.undo()">撤销</el-button>-->
      <!--      <el-button @click="$refs.canvasRef.redo()">恢复</el-button>-->
      <!--      <el-button type="primary" @click="getImgSrc">获取签字图片</el-button>-->
      <!--      <el-button type="primary" @click="$refs.canvasRef.downLoadSignPNGImg()">下载签字图片</el-button>-->
    </div>
    <div class="imgBox" v-if="img">
      <img :src="img" />
    </div>
  </div>
  <SignDrawer v-model:show="showDrawer" @callback="signDrawerCallback" :title="title"></SignDrawer>
</template>

<style scoped lang="scss">
p {
  padding: 20px;
}

.wrap {
  width: 100%;
  //text-align: center;
}

.canvasBox {
  // margin-top: 820px;
  margin-top: 10px;
  border: 1px solid #dddddd;
  width: 100%;
  height: 300px;
  box-sizing: border-box;
}

.imgBox {
  padding: 10px 0 0 0;

  img {
    height: 200px;
  }
}
</style>