<template>
  <div class="full-wrapper">
    <SystemMenu></SystemMenu>
    <div class="full-main">
      <PageHeader></PageHeader>
      <div class="full-body">
        <el-watermark :font="font" class="full-wrapper" :zIndex="0" :content="watermarkContent">
          <PageMenu></PageMenu>
          <div class="customer-service-wrapper">
            <router-view v-slot="{ Component, route }">
              <!--            <transition name="router-animate">-->
              <!--              <component :is="Component" :key="route.fullPath"></component>-->
              <keep-alive :max="10">
                <component :is="Component" :key="route.fullPath"></component>
              </keep-alive>
              <!--            </transition>-->
              <!--                          <transition  class="animate__tada" enter-active-class="animate__animated animate__tada"-->
              <!--                                      leave-active-class="animate__animated animate__bounce">-->
              <!--                          </transition>-->
              <!--              <component :is="Component" :key="route.fullPath"></component>-->
            </router-view>
            <RecordingSound v-model:phone="phone" v-if="isCustomerService"></RecordingSound>
          </div>
          <!--          <router-view />-->

          <ProcessDrawer
            v-model="componentStore.processDrawer.show"
            :work-id="componentStore.processDrawer.workId"
            :task-id="componentStore.processDrawer.taskId"
            :priority-work="componentStore.processDrawer.priorityWork"
            :callback="componentStore.processDrawer.callback"
          ></ProcessDrawer>
          <component
            :is="componentStore.processDrawer.detailName"
            v-model:show="componentStore.processDrawer.showDetailDrawer"
            :edit-id="componentStore.processDrawer.editId"
            :showEditButton="true"
            :formPageLayout="true"
          ></component>
          <component
            :is="componentStore.processDrawer.editName"
            v-model:show="componentStore.processDrawer.showEditDrawer"
            :edit-id="componentStore.processDrawer.editId"
            @callback="componentStore.processDrawer.callback"
            :formPageLayout="true"
          ></component>
        </el-watermark>
      </div>
    </div>
  </div>
</template>

<script>
import ProcessDrawer from '@/components/ProcessDrawer/ProcessDrawer.vue'
import { useComponentStore } from '@/store/component'
import { processMixins } from '@/common/processMixins'
import { computed } from 'vue'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'PageLayout',
  mixins: [processMixins],
  components: { ProcessDrawer },
  provide() {
    return {
      phone: computed(() => this.phone)
    };
  },
  data() {
    return {
      phone: null,
      font: {
        color: '#e2e2e2',
        fontSize: '14'
      }
    }
  },
  computed: {
    watermarkContent() {
      let { name, mobile } = layout.user
      return [`${name}_${mobile.slice(-4)}`]
    },
    isCustomerService(){
      let routeName = this.$route.name
      return routeName.includes('CustomerService')
    }
  },
  async mounted() {
    this.init()
  },
  beforeUnmount() {
    if (layout.desktop && layout.desktop.closeWebSocket) {
      layout.desktop.closeWebSocket()
    }
  },
  methods: {
    useComponentStore,
    async init() {
      if (layout.desktop && layout.desktop.openWebSocket) {
        layout.desktop.openWebSocket()
      } else {
        const { init } = await import('@/utils/oa/oa-init')
        let node = document.getElementById('app')
        init(node)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.full-wrapper {
  @include max-size;
  @include flex-row;
  background: #ffffff;
}

.full-main {
  flex: 1;
  @include flex-column;
}

.full-body {
  @include flex-row;
  flex: 1;
  height: 100%;
  overflow: hidden;
  transform: translate(0, 0);
}

.customer-service-wrapper{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  &>*{
    width: 100%;
    &:first-child{
      flex:1;
    }
  }
}
</style>