import axios  from './axios.config'
// 获取显示字段 GoodsStore
export const getGoodsStoreFields = (tableId='goodsStore')=>axios.get(`/jaxrs/goods_store/fields?tableId=${tableId}`)
// 获取查询条件
export  const getGoodsStoreConditions = () =>axios.get('/jaxrs/goods_store/conditions')
// 查询
export const getGoodsStorePage = (data) =>axios.post(`/jaxrs/goods_store/page`,data)
// 查询（根据可见人员过滤）
export  const getGoodsStorePermission = (data) =>axios.post(`/jaxrs/goods_store/page_permission`,data)
// 详情
export const getGoodsStoreDetail = (data) =>axios.get(`/jaxrs/goods_store/${data.id}`)
// 保存
export const addGoodsStore = (data)=>axios.post(`/jaxrs/goods_store`,data)
// 编辑
export const editGoodsStore = (data)=>axios.put(`/jaxrs/goods_store`,data)
// 删除
export const deleteGoodsStore = (data)=>axios.delete(`/jaxrs/goods_store/${data.id}`)