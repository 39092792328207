<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ExportData',
  emits:['export'],
  data() {
    return {
      typeList: [
        {
          label: '选中的数据',
          type: 'check'
        },
        {
          label: '当页的数据',
          type: 'page'
        },
        {
          label: '全部数据',
          type: 'all'
        }
      ]
    }
  },
  methods: {
    handleExportClick(item) {
      this.$emit('export',item.type)
    }
  }
})
</script>

<template>
  <el-dropdown>
    <el-button type="" link icon="Download">导出</el-button>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item
          v-for="(item, index) in typeList"
          :key="index"
          @click="handleExportClick(item)"
          >{{ item.label }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<style scoped lang="scss"></style>