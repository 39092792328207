import axios from './axios.config'
//获取库存预警
export const getWarningNum = () => axios.get('/jaxrs/home/count_warning_num')
//获取今天的简报数据
export const getTodayPresentation = (params) => axios.get('/jaxrs/home/count_by_today', { params })
//获取昨天的简报数据
export const getYesterdayPresentation = (params) => axios.get('/jaxrs/home/count_by_yesterday', { params })
//获取本周的简报数据
export const getWeekPresentation = (params) => axios.get('/jaxrs/home/count_by_this_week', { params })
//获取本月的简报数据
export const getMonthPresentation = (params) => axios.get('/jaxrs/home/count_by_this_month', { params })
//获取本年的简报数据
export const getYearPresentation = (params) => axios.get('/jaxrs/home/count_by_this_year', { params })

