import axios from './axios.config'
// 在职员工状态 统计
export const getHrDatabaseEmployeesStatistics = () => axios.get('/jaxrs/hr/database/employees_statistics')
// 入职登记状态统计
export const getHrDatabaseEntryRegistrationStatistics = () => axios.get('/jaxrs/hr/database/entry_registration_statistics')
// 技能证书统计
export const getHrDatabaseSkillCertificatesStatistics = () => axios.get('/jaxrs/hr/database/skill_certificates_statistics')
// 年龄分布
export const getHrDatabaseAgeStatistics = () => axios.get('/jaxrs/hr/database/age_statistics')
// 性别占比
export const getHrDatabaseGenderStatistics = () => axios.get('/jaxrs/hr/database/gender_statistics')
// 学历分布
export const getHrDatabaseEducationStatistics = () => axios.get('/jaxrs/hr/database/education_statistics')
// 技能证书占比
export const getHrDatabaseCertificateStatistics = () => axios.get('/jaxrs/hr/database/certificate_statistics')
