<script>
import { defineComponent } from 'vue'
import _ from 'lodash'
import { changePassword, logout, setPersonIcon } from "@/axios";
import { layout } from '@o2oa/component'
import Cookies from 'js-cookie'

export default defineComponent({
  name: 'ChangePasswordDialog',
  emits: ['update:modelValue'],
  data() {
    return {
      loading: false,
      user: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      defaultUser: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      rules: {
        oldPassword: [{ required: true, message: '请输入原密码', trigger: 'change' }],
        newPassword: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请输入新密码'))
              } else if (this.checkPasswordError(value)) {
                callback(
                  new Error('密码需包含大写字母小写字母数字及特殊字符并至少8位，如：Aa12345@')
                )
              } else if (this.user.newPassword !== this.user.confirmPassword) {
                callback(new Error('请确认新密码与确认密码相同'))
              }
              callback()
            }
          }
        ],
        confirmPassword: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请输入确认密码'))
              } else if (this.checkPasswordError(value)) {
                callback(
                  new Error('密码需包含大写字母小写字母数字及特殊字符并至少8位，如：Aa12345@')
                )
              } else if (this.user.newPassword !== this.user.confirmPassword) {
                callback(new Error('请确认新密码与确认密码相同'))
              }
              callback()
            }
          }
        ]
      }
    }
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    show: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    },
    password() {
      let { newPassword, confirmPassword } = this.user
      return { newPassword, confirmPassword }
    }
  },
  watch: {
    password: {
      handler(val) {
        this.$nextTick(() => {
          if (val.newPassword) {
            this.$refs.form.validateField('newPassword', () => {})
          }
          if (val.confirmPassword) {
            this.$refs.form.validateField('confirmPassword', () => {})
          }
        })
      },
      deep: true
    }
  },
  methods: {
    open() {
      this.user = _.cloneDeep(this.defaultUser)
      this.$refs.form.resetFields()
    },
    checkPasswordError(value) {
      let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/
      let check = reg.test(value)
      return !check
    },
    handleConfirmClick() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          changePassword(this.user)
            .then((res) => {
              if (res) {
                logout().then(() => {
                  layout.session.user.token = ''
                  Cookies.remove('x-token')
                  this.$message.success('修改成功，请重新登录')
                  this.$router.push({
                    name: 'Login'
                  })
                })
              }
            })
            .catch(() => {
              this.loading = false
            })
          // .finally(() => {
          //   this.loading = false;
          // });
        }
      })
    },
    userAvatarChange(uploadFile, uploadFiles) {
      if (uploadFiles.length > 0) {
        this.loading = true
        let formData = new FormData();
        uploadFiles.forEach((item) => {
          formData.append("file", item.raw);
          formData.append("fileName", item.raw);
        });
        setPersonIcon(formData).then(()=>{
          this.userStore.initUserInfo()
        }).finally(()=>{
          this.loading = false
          this.clearFiles();
        })
      }
    },
    clearFiles() {
      if(this.$refs.upload){
        this.$refs.upload.clearFiles();
      }
    }
  }
})
</script>

<template>
  <el-dialog
    v-model="show"
    v-bind="$attrs"
    title="个人设置"
    top="5%"
    width="500"
    destroy-on-close
    @opened="open"
  >
    <div v-loading="loading">
      <div class="user-avatar-wrapper">
        <el-image :src="`data:image/png;base64,${userStore.userInfo.iconMdpi}`" />
        <el-upload
          ref="upload"
          multiple
          :limit="1"
          accept="image/*"
          :auto-upload="false"
          :show-file-list="false"
          :on-change="userAvatarChange"
        >
          <el-button type="primary" plain>更换头像</el-button>
        </el-upload>
      </div>
      <el-form ref="form" :model="user" :rules="rules" label-width="100" label-align="left">
        <el-form-item label="原密码" prop="oldPassword">
          <el-input type="password" placeholder="原密码" v-model="user.oldPassword"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input type="password" placeholder="新密码" v-model="user.newPassword"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassword">
          <el-input type="password" placeholder="确认密码" v-model="user.confirmPassword"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button type="primary" @click="handleConfirmClick">确认</el-button>
    </template>
  </el-dialog>
</template>

<style scoped lang="scss">
  .user-avatar-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    .dubhe-image{
      width: 100px;
      height: 100px;
      margin-bottom: 20px;
      border-radius: 50%;
      box-shadow: var(--dubhe-box-shadow-light);
    }
  }
</style>