import axios from './axios.config'
// 获取显示字段
export const getCheckReportFields = (tableId = 'checkReport') =>axios.get(`/jaxrs/check_report/fields?tableId=${tableId}`)
// 获取查询条件
export const getCheckReportConditions = ()=>axios.get('/jaxrs/check_report/conditions')
// 获取查询
export const getCheckReportPage = (data) =>axios.post('/jaxrs/check_report/page',data)
// 详情页
export const getCheckReportDetail = (data) =>{
    let url
    if (data.oper) {
        url = `/jaxrs/check_report/${data.id}?oper=${data.oper}`
    } else {
        url = `/jaxrs/check_report/${data.id}`
    }
    return axios.get(url)
}
//保存
export const addCheckReport = (data) =>axios.post(`/jaxrs/check_report`,data)
// 编辑
export const editCheckReport = (data) =>axios.put(`/jaxrs/check_report`,data)
// 上传验收报告
export const uploadInspectionReport = (data) =>axios.put(`/jaxrs/check_report/upload-inspection-report`,data)

// 上传检测报告
export const uploadCheckReport = (data) =>axios.put(`/jaxrs/check_report/upload-check-report`,data)

// 删除
export const deleteCheckReport = (data) =>axios.delete(`/jaxrs/check_report/${data.id}`)
// 获取检测根据项目ID
export const getCheckReportByProject = (data) =>axios.get(`/jaxrs/check_report/project_id/${data.id}`)
// 退回检测报告
export const returnCheckReport = (data) =>axios.put(`/jaxrs/check_report/returned`,data)
