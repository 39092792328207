import axios from './axios.config'
// 合同记录（ContractManagement）
// 显示字段
export const getContractManagementFields = (tableId='contractManagement') =>axios.get(`/jaxrs/hr/contract_management/fields?tableId=${tableId}`)
// 查询条件
export const getContractManagementConditions = () =>axios.get('/jaxrs/hr/contract_management/conditions')
// 查询
export const getContractManagementPage = (data) =>axios.post('/jaxrs/hr/contract_management/page',data)
// 详情
export const getContractManagementDetail = (data) =>axios.get(`/jaxrs/hr/contract_management/${data.id}`)
// 添加(保存)
export const addContractManagement = (data) =>axios.post(`/jaxrs/hr/contract_management`,data)
// 编辑
export const editContractManagement = (data) =>axios.put(`/jaxrs/hr/contract_management`,data)
// 删除
export const deleteContractManagement = (data) =>axios.delete(`/jaxrs/hr/contract_management/${data.id}`)
