import axios from './axios.config'
//保存
export const addProjectClock = (data) => axios.post('/jaxrs/project_clock', data)
//获取项目当天项目打卡
export const getProjectClockByProject = (data) =>
  axios.get(`/jaxrs/project_clock/list_project_id/${data.id}`)
//获取用户打卡记录
export const getProjectClockListUser = (data) => axios.post(`/jaxrs/project_clock/list_user`, data)
//获取项目打卡时长（按天）
export const getProjectClockTime = (data) => axios.post(`/jaxrs/project_clock/get_person_list`, data)
