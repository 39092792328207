<script>
import { defineComponent } from "vue";
import DispatchDialog from "../DispatchDialog/DispatchDialog.vue";

export default defineComponent({
  name: "MapDispatchDialog",
  components: { DispatchDialog },
  emits: ["update:modelValue", "delete"],
  inject: ["unit", "combineDispatch", "confirm", "combineConfirm"],
  data() {
    return {
      showDispatchDialog: false,
      dateKeyMap: {
        "工程部": "visitDate",
        "维保售后本部": "maintenanceDate"
      },
      addressKeyMap: {
        "工程部": "address",
        "维保售后本部": "consumerAddress"
      }
    };
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    list: {}
  },
  computed: {
    show: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    },
    dateKey() {
      return this.dateKeyMap[this.unit];
    },
    addressKey() {
      return this.addressKeyMap[this.unit];
    }
  },
  methods: {
    handleDispatchClick() {
      this.showDispatchDialog = true;
    },
    handleDeleteClick(id) {
      this.$emit("delete", id);
    }
  }
});
</script>

<template>
  <div class="order-dialog" v-if="show">
    <div class="detail-title">
      <div class="detail-title-content">已选择工单</div>
    </div>
    <div class="detail-body">
      <div class="work-order-item" v-for="item in list" :key="item.id">
        <div class="work-order-item-content">
          <div class="work-order-item-content-item">
            <ri-map-pin-line :size="18"></ri-map-pin-line>
            <span>{{ item.district }}</span><span
            v-if="item[addressKey]">{{ item[addressKey] }}</span></div>
          <div class="work-order-item-content-item-row">
            <div class="work-order-item-content-item work-order-item-content-item-date">
              <ri-calendar-line :size="18"></ri-calendar-line>
              <span>
              {{ item[dateKey] }}
              </span>
            </div>
            <div class="work-order-item-content-item">
              <ri-user-line :size="18"></ri-user-line>
              <span>
              {{ item.consumerName }}
              </span>
            </div>
            <div class="work-order-item-content-item" v-if="item.materialName">
              <ri-box-2-fill :size="18"></ri-box-2-fill>
              <span>
              {{ item.materialName }}
              </span>
            </div>
          </div>
        </div>
        <div class="work-order-item-button">
          <el-button type="danger" link icon="Delete" @click="handleDeleteClick(item.id)"></el-button>
        </div>
      </div>
    </div>
    <div class="detail-foot">
      <el-button type="primary" @click="handleDispatchClick">
        派工
      </el-button>
    </div>
  </div>
  <DispatchDialog
    v-model="showDispatchDialog"
    @confirm="confirm"
    @combineConfirm="combineConfirm"
    :combineDispatch="combineDispatch"
    :unit="unit"
    :check-list="list"
  ></DispatchDialog>
</template>

<style scoped lang="scss">
.order-dialog {
  position: absolute;
  top: 175px;
  right: 30px;
  width: 350px;
  background: #ffffff;
  box-shadow: 0 0 7px 0 rgba(182, 182, 182, 0.5);
  border-radius: 11px;
  padding: 20px;
  z-index: 1;

  & > * {
    width: 100%;
  }

  .detail-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    & > * {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .detail-title-content {
      font-size: 18px;

      &:before {
        content: ' ';
        width: 6px;
        height: 16px;
        background: #3b76ee;
        margin-right: 10px;
      }
    }

    .detail-title-close {
      font-size: 16px;
      cursor: pointer;
    }
  }

  .detail-body {
    height: 400px;
    overflow: auto;

    .work-order-item {
      display: flex;
      align-items: center;
      padding: 10px 0;

      & + .work-order-item {
        border-top: 1px solid var(--dubhe-border-color);
      }

      .work-order-item-content {
        flex: 1;

        .work-order-item-content-item-row {
          display: flex;
          align-items: flex-start;
          flex-wrap: wrap;

          .work-order-item-content-item {
            margin-right: 15px;
            margin-top: 10px;

            //& + .work-order-item-content-item {
            //  margin-left: 15px;
            //}
          }
        }

        .work-order-item-content-item {
          display: flex;
          align-items: flex-start;
          line-height: 18px;
          flex-shrink: 0;
          width: 100%;
          //&.work-order-item-content-item-date{
          //  flex-shrink: 0;
          //}

          & > *:first-child {
            height: 18px;
            width: 18px;
            margin-right: 5px;
            flex-shrink: 0;
            color: #3b76ee;
          }
        }
      }

      .work-order-item-button {
        opacity: 0;
        transition: all linear .2s;
      }

      &:hover {
        .work-order-item-button {
          opacity: 1;
        }
      }
    }
  }

  .detail-foot {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
</style>