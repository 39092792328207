<script>
import { defineComponent,computed } from "vue";
import FilePreviewDrawer from "./FilePreviewDrawer.vue";
import ImageItem from "./ImageItem.vue";
import { downloadFileByFilename } from "@/axios";
import _ from "lodash";

/**
 * type: image图片，file文件
 */
export default defineComponent({
  name: "FileList",
  components: {
    FilePreviewDrawer,
    ImageItem
  },
  emits: ["update:list","update:fileNames"],
  provide() {
    return {
      useItem: computed(() => this.useItem),
      urlKey: computed(() => this.urlKey),
      urlList: computed(() => this.urlList),
      disabled: computed(() => this.disabled),
      downloadItem: computed(() => this.downloadItem),
      deleteItem: computed(() => this.deleteItem)
    }
  },
  data(){
    return {
      showFilePreviewDrawer:false,
      showImageDrawer:false,
      showIndex:0,
      previewFile:{}
    }
  },
  props: {
    list: {
      type: Array,
      default() {
        return [];
      }
    },
    fileNames:{
    },
    urlKey: {
      type: String,
      default() {
        return "url";
      }
    },
    nameKey: {
      type: String,
      default() {
        return "label";
      }
    },
    filenameKey: {
      type: String,
      default() {
        return "filename";
      }
    },
    type: {
      type: String,
      default() {
        return "image";
      }
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      }
    },
    deleteMethod: {
      type: Function
    }
  },
  computed: {
    urlList() {
      if (this.useItem) {
        return this.list;
      }
      return this.list.map((item) => item[this.urlKey]);
    },
    showList() {
      return this.list && this.list.length > 0;
    },
    showImageList() {
      return this.showList && this.type === "image";
    },
    showFileList() {
      return this.showList && this.type === "file";
    },
    useItem() {
      return this.urlKey === "$";
    },
    fileNameList: {
      get() {
        if (_.isString(this.fileNames) && this.fileNames.length > 0) {
          let list = this.fileNames.split(',').filter(item=>!!item)
          return list
        }
        return []
      },
      set(val) {
        let str = val.join(',')
        this.$emit('update:fileNames', str)
      }
    },
    listLength(){
      return this.urlList.length
    },
    showMore(){
      return this.listLength > 1
    },
    firstImageItem(){
      return this.list[0]
    },
    firstImageUrl(){
      return this.urlList[0]
    }
  },
  methods: {
    downloadItem(item,index) {
      let url, name, filename;
      if (this.useItem) {
        url = item;
        name = this.fileNameList[index];
        filename = this.fileNameList[index];
      } else {
        url = item[this.urlKey];
        name = item[this.nameKey];
        filename = item[this.filenameKey];
      }
      downloadFileByFilename({filename}).then(res=>{
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = URL.createObjectURL(res);
        a.download = name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
    },
    viewItem(index) {
      // this.previewFile = item
      this.showIndex = index
      this.showFilePreviewDrawer = true
    },
    deleteItem(item,index) {
      if (this.deleteMethod) {
        this.deleteMethod(item,index);
      } else {
        let list = this.list.filter((lItem,lIndex) => lIndex !== index);
        if (this.useItem) {
          this.fileNameList = this.fileNameList.filter((lItem,lIndex) => lIndex !== index);
        }
        this.$emit("update:list", list);
      }
    },
    showMoreClick(){
      this.showImageDrawer = true
      // console.log(this.$refs)
      // console.log(this.$refs.image.$el.getElementsByTagName("img"));
      // this.$refs.image.$el.getElementsByTagName("img")[0].click()
    }
  }
});
</script>
<template>
  <div class="file-list" v-if="showImageList">
    <template v-if="!disabled">
      <template v-for="(item, index) in list" :key="index">
        <ImageItem :item="item" :index="index"></ImageItem>
      </template>
    </template>
    <template v-else>
      <ImageItem :item="firstImageItem" :index="0"></ImageItem>
      <el-button link type="primary" v-if="showMore" @click="showMoreClick">共{{listLength}}张，查看更多</el-button>
    </template>
  </div>
  <div class="file-list" v-if="showFileList">
    <div class="file-list-item" v-for="(item, index) in list" :key="index">
      <div class="file-content">
        <el-icon>
          <Document />
        </el-icon>
        <div class="file-content-text">
          <el-tooltip
            effect="dark"
            :content="item[nameKey]"
          >
            <el-text truncated>{{ item[nameKey] }}</el-text>
          </el-tooltip>
        </div>
      </div>
      <div class="file-icons">
        <el-icon v-if="!useItem" @click="viewItem(index)">
          <View />
        </el-icon>
        <el-icon @click="downloadItem(item,index)">
          <Download />
        </el-icon>
        <el-icon @click="deleteItem(item,index)" v-if="!disabled">
          <Delete />
        </el-icon>
      </div>
    </div>
  </div>
  <FilePreviewDrawer v-model:show="showFilePreviewDrawer" :show-index="showIndex" :fileList="list"></FilePreviewDrawer>
  <el-drawer v-model="showImageDrawer" size="95%" direction="btt" destroy-on-close @open="open" :append-to-body="true">
    <template #header>
      <div>图片列表</div>
    </template>
    <template #default>
      <div class="detail-wrapper" v-if="showImageDrawer">
        <div class="file-list">
          <template v-for="(item, index) in list" :key="index">
            <ImageItem :item="item" :index="index"></ImageItem>
          </template>
        </div>
      </div>
    </template>
  </el-drawer>
</template>

<style scoped lang="scss">
.file-list {
  display: block;
  width: 100%;

  .image-list-item {
    display: inline-flex;
    position: relative;
    //height: 200px;
    height: 123.6px;
    width: 200px;
    margin: 10px;
    border-radius: 10px;
    border: 1px solid var(--dubhe-border-color);
    box-shadow: var(--dubhe-box-shadow);
    overflow: hidden;
    cursor: pointer;

    & > img,
    & > .dubhe-image {
      width: 100%;
      height: 100%;
    }

    &:hover {
      .image-icons {
        opacity: 1;
      }
    }

    .image-icons {
      position: absolute;
      top: 10px;
      right: 10px;
      color: #ffffff;
      font-size: 15px;
      opacity: 0;
      transition: all linear 0.2s;
      display: flex;

      .dubhe-icon {
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.2);

        & + .dubhe-icon {
          margin-left: 10px;
        }
      }
    }
  }

  .file-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    border-radius: 5px;
    background: var(--dubhe-fill-color-light);
    max-width: 700px;
    margin-top: 10px;
    //box-shadow: var(--dubhe-box-shadow);
    //&+.file-list-item{
    //  margin-top: 10px;
    //}
    &:hover > .file-icons {
      opacity: 1;
    }

    .file-content {
      display: flex;
      align-items: center;
      flex: 1;

      .dubhe-icon {
        color: var(--dubhe-color-primary);
        font-size: 18px;
        margin-right: 5px;
      }

      .file-content-text {
        flex: 1;
        position: relative;
        height: 22px;

        .dubhe-text {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
        }

        //&>div{
        //  position: absolute;
        //  top: 0;
        //  left: 0;
        //  width: 100%;
        //  overflow: hidden;
        //}
      }
    }

    .file-icons {
      display: flex;
      align-items: center;
      //opacity: 0;
      transition: all linear 0.2s;
      //padding-left: 5px;
      .dubhe-icon {
        cursor: pointer;
        font-size: 16px;
        color: var(--dubhe-color-primary);
        margin-left: 10px;

        & + .dubhe-icon {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>