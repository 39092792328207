import axios from './axios.config'
// 获取工单显示字段
export const getWorkOrderFields = (tableId='workOrder') => axios.get(`/jaxrs/v2/work_order/fields?tableId=${tableId}`)
// 获取工单导入字段
export const getWorkOrderImportFields = (tableId='workOrderImport') => axios.get(`/jaxrs/v2/work_order/import/fields?tableId=${tableId}`)
// 获取工单查询条件
export const getWorkOrderConditions = () => axios.get('/jaxrs/v2/work_order/conditions')
// 获取工单分页数据
export const getWorkOrderPage = (data) => axios.post('/jaxrs/v2/work_order/page',data)
// 获取基础工单显示字段
export const getBaseWorkOrderFields = (tableId='workOrder') => axios.get(`/jaxrs/v2/base/work_order/fields?tableId=${tableId}`)
// 获取工单查询条件
export const getBaseWorkOrderConditions = () => axios.get('/jaxrs/v2/base/work_order/conditions')
// 获取基础工单分页数据
export const getBaseWorkOrderPage = (data) => axios.post('/jaxrs/v2/base/work_order/page',data)
// 获取工单详情
export const getWorkOrderDetail = (data) => axios.get(`/jaxrs/v2/work_order/${data.id}`)
// 修改工单
export const editWorkOrder = (data) => axios.put(`/jaxrs/v2/work_order`,data)
// 添加工单
export const addWorkOrder = (data) => axios.post(`/jaxrs/v2/work_order`,data)
// 删除工单
export const deleteWorkOrder = (data) => axios.delete(`/jaxrs/v2/work_order/${data.id}`)
// 导出工单
export const exportWorkOrder = (data) => axios.post(`/jaxrs/v2/work_order/export?tableId=${data.tableId}`,data,{
  responseType:'blob'
})
// 导入工单
export const importWorkOrder = (data) => axios.post(`/jaxrs/v2/work_order/import`,data)
// 提交工单
export const submitWorkOrder = (data) => axios.post(`/jaxrs/v2/work_order/submit`,data)
// 工单派工
export const assignWorkOrder = (data) => axios.post(`/jaxrs/v2/work_order/assign`,data)
// 工单回访
export const returnVisitWorkOrder = (data) => axios.post(`/jaxrs/v2/work_order/return_visit`,data)
// 工单完工
export const completedWorkOrder = (data) => axios.post(`/jaxrs/v2/work_order/completed`,data)
// 工单结单
export const closedWorkOrder = (data) => axios.post(`/jaxrs/v2/work_order/closed`,data)
// 客服工单结单
export const serviceClosedWorkOrder = (data) => axios.post(`/jaxrs/v2/work_order/service_closed`,data)
// 工单签到
export const clockInWorkOrder = (data) => axios.post(`/jaxrs/v2/work_order/clockIn`,data)
// 工单重派
export const reassignWorkOrder = (data) => axios.post(`/jaxrs/v2/work_order/reassign/${data.id}`)
// 获取流转记录
export const getWorkLogV2 = (data,source) => axios.post(`/jaxrs/common/worklog`,data,{
  cancelToken: source ? source.token : null
})
// 创建检测工单
export const addCheckWorkOrder = (data) => axios.post(`/jaxrs/v2/work_order/check_assign`,data)
// 批量提交工单
export const submitWorkOrderBatch = (data) => axios.post(`/jaxrs/v2/work_order/batch_submit`,data)
// 工单退回
export const drawBackWorkOrder = (data) => axios.put(`/jaxrs/simple_work_order/draw_back`,data)
// 创建下载任务
export const downloadTask = (data) =>axios.post(`/jaxrs/v2/work_order/download_task`,data)
// 下载列表
export const downloadList = () =>axios.get(`/jaxrs/v2/work_order/download_list`)
// 下载压缩包
export const downloadPackage = (data) =>axios.get(`/jaxrs/v2/work_order/download/${data.filename}`)
// 删除下载任务
export const deletePackage = (data)=>axios.post(`/jaxrs/v2/work_order/del_download_task/${data.filename}`)
// 更新工单来源及图片
export const editWorkOrderSourceImages = (data) => axios.post(`/jaxrs/v2/work_order/update_source_images`,data)
// 项目绑定工单
export const bindWorkOrderToProject = (data) => axios.put(`/jaxrs/v2/work_order/bind_project/${data.projectId}`,data.ids)
// 根据refId查询
export const getWorkOrderListByRef = (data) =>axios.get(`/jaxrs/v2/work_order/ref/${data.refId}`)
// 工单撤回（将未派工、已派工、服务中撤回到待提交状态）
export const withdrawWorkOrder = (data) => axios.put(`/jaxrs/v2/work_order/withdraw/${data.id}`)
// 工单材料统计
export const getWorkOrderStatisticsMaterial = (data) => axios.post(`/jaxrs/v2/work_order/statistics_material`,data)
// 工单预览
export const previewWorkOrder = (data) => axios.post(`/jaxrs/v2/work_order/preview?id=${data.id}&oper=${data.oper}`,data.params)
// 完善工单信息
export const perfectWorkOrder = (data) => axios.post(`/jaxrs/v2/work_order/perfect`,data)
// 重置绩效
export const resetWageWorkOrder = (data) => axios.post(`/jaxrs/v2/work_order/reset_wage/${data.id}`,data)
// 工单抽检通过
export const workOrderAudited = (data) => axios.put(`/jaxrs/simple_work_order/pass/${data.ids}`,data)
// 工单抢单
export const grabWorkOrderPool = (data) => axios.put(`/jaxrs/work_order_pool/grab_order/${data.id}`,data)
// 加入工单池
export const addWorkOrderPool = (data) => axios.put(`/jaxrs/work_order_pool/add/${data.ids}`,data)

// 获取验收工单显示字段
export const getCheckWorkOrderFields = (tableId='checkWorkOrder') => axios.get(`/jaxrs/check_work_order/fields?tableId=${tableId}`)
// 获取验收工单查询条件
export const getCheckWorkOrderConditions = () => axios.get('/jaxrs/check_work_order/conditions')
// 获取验收工单分页数据
export const getCheckWorkOrderPage = (data) => axios.post('/jaxrs/check_work_order/page',data)

// 获取检测工单显示字段
export const getInternalAcceptanceWorkOrderFields = (tableId='checkWorkOrder') => axios.get(`/jaxrs/internal_acceptance_work_order/fields?tableId=${tableId}`)
// 获取检测工单查询条件
export const getInternalAcceptanceWorkOrderConditions = () => axios.get('/jaxrs/internal_acceptance_work_order/conditions')
// 获取检测工单分页数据
export const getInternalAcceptanceWorkOrderPage = (data) => axios.post('/jaxrs/internal_acceptance_work_order/page',data)
// 回退
export const rollbackWorkOrder = (data) => axios.post('/jaxrs/v2/work_order/rollback',data)
// 获取工单销售订单等页面用（可传较多查询条件）
export const getOrderSettlementWorkOrderPage = (data) => axios.post('/jaxrs/v2/work_order/order_settlement_work_order',data)
