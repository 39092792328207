import axios from './axios.config'
// 获取工单对账显示字段
export const getWorkOrderSettlementFields = (tableId='workOrderSettlement') => axios.get(`/jaxrs/work_order/settlement/fields?tableId=${tableId}`)
// 获取工单对账查询条件
export const getWorkOrderSettlementConditions = () => axios.get('/jaxrs/work_order/settlement/conditions')
// 获取工单对账分页数据
export const getWorkOrderSettlementPage = (data) => axios.post(`/jaxrs/work_order/settlement/page`,data)
// 工单对账批量对账
export const execWorkOrderSettlement = (data) => axios.put(`/jaxrs/work_order/settlement/exec`,data)
// export const execWorkOrderSettlement = (data) => axios.post(`/jaxrs/work_order/settlement/exec/${data.ids}`,data)
// 工单对账批量异常
export const issueWorkOrderSettlement = (data) => axios.put(`/jaxrs/work_order/settlement/issue`,data)
// 工单对账批量复原
export const resetWorkOrderSettlement = (data) => axios.put(`/jaxrs/work_order/settlement/reset`,data)
// 导出
export const exportWorkOrderSettlement = (data)=>axios.post(`/jaxrs/work_order/settlement/export`,data,{
    responseType:'blob'
  })
// 获取工单对账统计
export const getWorkOrderSettlementStatic = (data) => axios.post(`/jaxrs/work_order/settlement/stat`,data)
// 获取工单对账详情
export const getWorkOrderSettlementDetail = (data) => axios.get(`/jaxrs/work_order/settlement/${data.id}`)
