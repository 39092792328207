import axios from './axios.config'
// 题目（question）
// 获取显示字段
export const getQuestionFields = (tableId='question')=>axios.get(`/jaxrs/question/fields?tableId=${tableId}`)
// 获取查询条件
export const getQuestionConditions = () =>axios.get('/jaxrs/question/conditions')
// 获取分页数据
export const getQuestionPage = (data) =>axios.post('/jaxrs/question/page',data)
// 详情
export const getQuestionDetail = (data) =>{
  let url
  if(data.oper){
    url=`/jaxrs/question/${data.id}?oper=${data.oper}`
  }else{
    url=`/jaxrs/question/${data.id}`
  }
  return axios.get(url)
}
// 保存
export const addQuestion = (data) =>axios.post(`/jaxrs/question`,data)
// 编辑
export const editQuestion = (data)=>axios.put(`/jaxrs/question`,data)
// 删除
export const deleteQuestion = (data) =>axios.delete(`/jaxrs/question/${data.id}`)
//导出excel模板
export const exportQuestionExcelTemplate = (data) =>
  axios.get(`/jaxrs/question/download/excel/template`, {
    responseType: 'blob'
  })
//导出word模板
export const exportQuestionWordTemplate = (data) =>
  axios.get(`/jaxrs/question/download/word/template`, {
    responseType: 'blob'
  })
//导入excel
export const importQuestionExcel = (data) =>
  axios.post(`/jaxrs/question/import/excel`, data, {
    headers:{
      'Content-Type': 'multipart/form-data;'
    }
  })
// 批量保存
export const batchSaveQuestion = (data) =>axios.post(`/jaxrs/question/batch_save`,data)


