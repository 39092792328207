import axios from './axios.config'
//获取显示字段
export const getPaymentApplicationFields = (tableId='paymentApplication') =>axios.get(`/jaxrs/payment_application/fields?tableId=${tableId}`)
// 获取查询字段
export const getPaymentApplicationConditions = () =>axios.get('/jaxrs/payment_application/conditions')
//获取分页数据（查询）
export const getPaymentApplicationPage = (data) =>axios.post('/jaxrs/payment_application/page',data)
// 详情
export const getPaymentApplicationDetail = (data) =>{
    let url
    if(data.oper){
        url=`/jaxrs/payment_application/${data.id}?oper=${data.oper}`
    }else{
        url=`/jaxrs/payment_application/${data.id}`
    }
    return axios.get(url)
}
// 保存
export const addPaymentApplication = (data) =>axios.post(`/jaxrs/payment_application`,data)
// 编辑
export const editPaymentApplication = (data) =>axios.put(`/jaxrs/payment_application`,data)
// 删除
export const deletePaymentApplication = (data) =>axios.delete(`/jaxrs/payment_application/${data.id}`)
// 付款状态
export const isPayment = (data) =>axios.put(`/jaxrs/payment_application/payment_status`,data)
// 导出
export const exportPayRequestion = (data) =>axios.post(`/jaxrs/payment_application/export`,data,{
    responseType: 'blob'
})
// 发票上传接口（上传发票）
export const uploadPaymentApplication = (data) =>axios.post(`/jaxrs/payment_application/upload/invoices`,data)
// 根据业务id和付款类型获取付款申请记录
export const getPaymentApplicationPageByBizIdAndPaymentApplicationType = (data) =>axios.post(`/jaxrs/payment_application/listByBizIdPaymentApplicationType`,data)
// 发票上传接口（上传发票）V2
export const uploadPaymentApplicationV2 = (data) =>axios.post(`/jaxrs/payment_application/v2/upload/invoices`,data)


