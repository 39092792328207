<script>
import { defineComponent, computed } from "vue";
import MapDispatchHome from "./MapDispatchHome.vue";

export default defineComponent({
  name: "MapDispatch",
  components: { MapDispatchHome },
  emits: ["update:modelValue", "confirm", "combineConfirm"],
  provide() {
    return {
      unit: computed(() => this.unit),
      combineDispatch: computed(() => this.combineDispatch),
      confirm: this.confirm,
      combineConfirm: this.combineConfirm
    };
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    wrapper: {
      default() {
        return {};
      }
    },
    method: {
      required: true
    },
    unit: {
      type: String
    },
    combineDispatch: {}
  },
  computed: {
    show: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    }
  },
  mounted() {
  },
  methods: {
    init() {
    },
    confirm(val) {
      this.$emit("confirm", val);
      this.show = false
    },
    combineConfirm(val) {
      this.$emit("combineConfirm", val);
      this.show = false
    }
  }
});
</script>

<template>
  <el-drawer v-model="show" size="95%" direction="btt" destroy-on-close @open="init">
    <template #header>
      <div>地图派工</div>
    </template>
    <template #default>
      <div class="detail-wrapper">
        <MapDispatchHome :wrapper="wrapper" :method="method"></MapDispatchHome>
      </div>
    </template>
  </el-drawer>
</template>

<style scoped lang="scss">
.detail-wrapper{
  overflow-y: clip;
}
</style>