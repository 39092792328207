<script>
import { defineComponent } from "vue";
import * as userService from "../../assets/image/map/user_service.png";
import * as userDispatch from "../../assets/image/map/user_dispatch.png";
import * as userDefault from "../../assets/image/map/user_default.png";
import * as orderDefault from "../../assets/image/map/order_default.png";
import * as orderDispatch from "../../assets/image/map/order_dispatch.png";
import * as orderService from "../../assets/image/map/order_service.png";
import MapDispatchDialog from "./MapDispatchDialog.vue";
import SelectV2 from "../SelectMultiple/SelectV2.vue";
import DatePicker from "../DatePicker/DatePicker.vue";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import _ from 'lodash'

dayjs.extend(isBetween);

// 不允许proxy代理
let map = null;
let markerLayer = null;
let zoom = 12;

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: "MapDispatchHome",
  components: { DatePicker, SelectV2, MapDispatchDialog },
  inject: ["unit"],
  data() {
    return {
      workOrderList: [],
      checkWorkOrderIdList: [],

      date: ["", ""],
      selectWorkOrder: null,
      dateKeyMap: {
        "工程部": "visitDate",
        "维保售后本部": "maintenanceDate"
      },
      latKeyMap: {
        "工程部": "lat",
        "维保售后本部": "latitude"
      },
      lngKeyMap: {
        "工程部": "lng",
        "维保售后本部": "longitude"
      }
    };
  },
  props: {
    wrapper: {},
    method: {}
  },
  computed: {
    checkWorkOrderList() {
      let list = this.workOrderList.filter(item => this.checkWorkOrderIdList.includes(item.id));
      return list;
    },
    orderShow() {
      return this.checkWorkOrderIdList.length > 0;
    },
    latKey() {
      return this.latKeyMap[this.unit];
    },
    lngKey() {
      return this.lngKeyMap[this.unit];
    },
    dateKey() {
      return this.dateKeyMap[this.unit];
    },
    dateFilterWorkOrderList() {
      if (!this.date || !this.date[0]) {
        return this.workOrderList;
      }
      let list = this.workOrderList.filter(item => {
        if (item[this.dateKey] === this.date[0] || item[this.dateKey] === this.date[1]) {
          return true;
        }
        if (dayjs(item[this.dateKey]).isBetween(dayjs(this.date[0]), dayjs(this.date[1]))) {
          return true;
        }
        return false
      });
      return list;
    }
  },
  watch: {
    dateFilterWorkOrderList: {
      handler() {
        this.setWorkOrderMarkerData();
      },
      deep: true
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.mapStore.loadMapScript();
      this.initMap();
    },
    initDate() {
      let baseDate = dayjs();
      let startDate = baseDate.date(1);
      let start = startDate.format("YYYY-MM-DD");
      let endDay = baseDate.daysInMonth();
      let endDate = baseDate.date(endDay);
      let end = endDate.format("YYYY-MM-DD");
      this.date = [start, end];
    },
    initMap() {
      //定义地图中心点坐标
      let latitude = this.mapStore.latitude;
      let longitude = this.mapStore.longitude;
      let TMap = window.TMap;
      let defaultMarkStyle = {
        width: 30,
        height: 30,
        //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
        anchor: { x: 15, y: 15 },
        direction: "bottom",
        offset: { x: 0, y: 8 }, // 标注点文本文字基于direction方位的偏移属性
        strokeColor: "#fff", // 标注点文本描边颜色
        strokeWidth: 2 // 标注点文本描边宽度
      };
      let center = new TMap.LatLng(latitude, longitude);
      //定义map变量，调用 TMap.Map() 构造函数创建地图
      map = new TMap.Map(this.$refs.container, {
        center: center, //设置地图中心点坐标
        zoom: zoom, //设置地图缩放级别
        pitch: 0, //设置俯仰角
        viewMode: "3D",
        // mapStyleId: "style1",
        // showControl: false,
        baseMap: {			//底图设置（参数为：VectorBaseMap对象）
          type: "vector",	//类型：失量底图
          // type: 'traffic',	//类型：失量底图
          features: ["base", "building2d", "building3d", "label", "point"]
          //仅渲染：道路及底面(base) + 2d建筑物(building2d)，以达到隐藏文字的效果
        }
        // rotation: 45 //设置地图旋转角度
      });
      //创建并初始化MultiMarker
      markerLayer = new TMap.MultiMarker({
        map: map, //指定地图容器
        //样式定义
        styles: {
          //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
          userServiceStyle: new TMap.MarkerStyle({
            src: userService.default,
            ...defaultMarkStyle
          }),
          userDispatchStyle: new TMap.MarkerStyle({
            src: userDispatch.default,
            ...defaultMarkStyle
          }),
          userDefaultStyle: new TMap.MarkerStyle({
            src: userDefault.default,
            ...defaultMarkStyle
          }),
          orderDefaultStyle: new TMap.MarkerStyle({
            src: orderDefault.default,
            ...defaultMarkStyle
          }),
          orderDispatchStyle: new TMap.MarkerStyle({
            src: orderDispatch.default,
            ...defaultMarkStyle
          }),
          orderServiceStyle: new TMap.MarkerStyle({
            src: orderService.default,
            ...defaultMarkStyle
          })
        },
        // enableCollision: true,
        // collisionOptions: {
        //   sameSource: true,
        //   crossSource: true,
        //   vectorBaseMapSource: true
        // },
        //点标记数据数组
        geometries: []
      });
      markerLayer.on("click", this.labelClick);
      this.initWorkOrderList();
      this.initDate();
    },
    initWorkOrderList() {
      let params = {
        pageSize: 999999,
        pageNum: 1,
        wrapper: this.wrapper
      };
      this.method(params)
        .then((res) => {
          this.workOrderList = res.data;
          this.setDefaultCenter();
        })
        .catch(() => {
        });
    },
    setWorkOrderMarkerData() {
      this.checkWorkOrderIdList = [];
      this.clearMarker();
      this.setDefaultCenter();
      let TMap = window.TMap;
      let styleMap = {
        未派工: "orderDefaultStyle",
        已派工: "orderDispatchStyle",
        服务中: "orderServiceStyle"
      };
      let list = this.dateFilterWorkOrderList
        .filter((item) => item[this.latKey])
        .map((item) => {
          let latitude = item[this.latKey];
          let longitude = item[this.lngKey];
          return {
            id: item.id,
            styleId: styleMap["未派工"], //指定样式id
            position: new TMap.LatLng(latitude, longitude),
            content: item.consumerName,
            rank: 10,
            orderData: item
          };
        });
      markerLayer.add(list);
    },
    labelClick(res) {
      let { geometry } = res;
      this.setWorkOrder(geometry.id);
    },
    setWorkOrder(id) {
      let geometry = markerLayer.geometries.find(item => item.id === id);
      if (this.workOrderHasCheck(id)) {
        this.checkWorkOrderIdList = this.checkWorkOrderIdList.filter(item => item !== id);
        geometry.styleId = "orderDefaultStyle";
      } else {
        this.checkWorkOrderIdList.push(id);
        geometry.styleId = "orderDispatchStyle";
      }
      markerLayer.updateGeometries([
        geometry
      ]);
    },
    workOrderHasCheck(id) {
      return this.checkWorkOrderIdList.includes(id);
    },
    clearMarker() {
      if (markerLayer) {
        markerLayer.setGeometries([]);
      }
    },
    setDefaultCenter() {
      let defaultWorkOrder = this.dateFilterWorkOrderList.find(item => {
        if (item[this.latKey] && item[this.lngKey]) {
          return true;
        }
      });
      if (defaultWorkOrder) {
        this.workOrderChange(defaultWorkOrder);
      }
    },
    workOrderLabelFilter(val) {
      let keyList = ["consumerName", "district", "consumerAddress","address"];
      let str = "";
      keyList.forEach((item, index) => {
        if (val[item]) {
          if (index !== 0) {
            str += " ";
          }
          str += val[item];
        }
      });
      return str;
    },
    workOrderFilter(list, searchVal) {
      if(_.isArray(list)){
        let keyList = ["consumerName", "district", "consumerAddress","address"];
        let returnList = list.filter(item => {
          return keyList.some(kItem => {
            if(!item[kItem]){
              return false
            }
            return item[kItem].includes(searchVal)
          });
        });
        return returnList;
      }else{
        return []
      }
    },
    workOrderChange(val) {
      if (!val) {
        return;
      }
      let latitude = val[this.latKey];
      let longitude = val[this.lngKey];
      if (latitude && longitude) {
        let center = new TMap.LatLng(latitude, longitude);
        // map.panTo(position);
        map.easeTo(
          {
            center: center,
            zoom: 16
          },
          {
            duration: 500
          }
        )
      } else {
        this.$message.warning("该客户无地理位置坐标，请修改该客户地理位置坐标");
      }
    }
  }
});
</script>

<template>
  <div class="map-wrapper">
    <div class="content-filter">
      <DatePicker v-model="date" type="daterange" start-placeholder="开始日期"
                  end-placeholder="结束日期" :clearable="false"></DatePicker>
      <SelectV2 class="content-filter-select" :list="dateFilterWorkOrderList" v-model="selectWorkOrder" value-key="id"
                placeholder="请输入客户名称、区、详细地址搜索"
                label-key="consumerName" :light-label-key="dateKey" :filter="workOrderFilter"
                :change="workOrderChange"
                :label-filter="workOrderLabelFilter"></SelectV2>
    </div>
    <div ref="container" class="map-container"></div>
    <MapDispatchDialog v-model="orderShow" :list="checkWorkOrderList" @delete="setWorkOrder"></MapDispatchDialog>
  </div>
</template>

<style scoped lang="scss">
.map-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;


  & > * {
    z-index: 2;
  }

  .map-container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .content-filter {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;

    & > * {
      & + * {
        margin-left: 12px;
      }
    }

    .content-filter-select {
      width: 600px;
    }

    :deep(button) {
      height: auto !important;
    }
  }
}
</style>
