import axios from './axios.config'
// 获取供应商准入显示字段
export const getSupplierAccessFields = (tableId='supplierAccess') => axios.get(`/jaxrs/supplier_access/fields?tableId=${tableId}`)
// 获取供应商准入查询条件
export const getSupplierAccessConditions = () => axios.get('/jaxrs/supplier_access/conditions')
// 获取供应商准入分页数据
export const getSupplierAccessPage = (data) => axios.post('/jaxrs/supplier_access/page',data)
// 获取供应商准入详情
export const getSupplierAccessDetail = (data) => axios.get(`/jaxrs/supplier_access/${data.id}`)
// 修改供应商准入
export const editSupplierAccess = (data) => axios.put(`/jaxrs/supplier_access`,data)
// 添加供应商准入
export const addSupplierAccess = (data) => axios.post(`/jaxrs/supplier_access`,data)
// 删除供应商准入
export const deleteSupplierAccess = (data) => axios.delete(`/jaxrs/supplier_access/${data.id}`)

