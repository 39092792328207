import axios from './axios.config'
// 获取显示字段   TrainingPlan
export const getTrainingPlanFields = (tableId = 'trainingPlan')=>axios.get(`/jaxrs/training_plan/fields?tableId=${tableId}`)
// 获取查询条件
export const getTrainingPlanConditions = ()=>axios.get('/jaxrs/training_plan/conditions')
// 查询
export const getTrainingPlanPage = (data)=>axios.post('/jaxrs/training_plan/page',data)
// 供应商详情页
export const getTrainingPlanDetail = (data)=>axios.get(`/jaxrs/training_plan/${data.id}`)
// 保存
export const addTrainingPlan = (data)=>axios.post(`/jaxrs/training_plan`,data)
// 修改
export const editTrainingPlan = (data)=>axios.put(`/jaxrs/training_plan`,data)
// 删除
export const deleteTrainingPlan = (data)=>axios.delete(`/jaxrs/training_plan/${data.id}`)