<script>
import { defineComponent } from 'vue'
import ProcessWork from '@/utils/ProcessWork/Main'
// import '@/utils/ProcessWork/StartProcess'
import { o2, component } from '@o2oa/component'

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'ProcessDrawerBody',
  data() {
    return {
      loading: false,
      processWork: null,
      showIframe: false,
      iframeUrl: null,
      // workId: null,
      processDetail: null
    }
  },
  props: {
    workId: {},
    taskId: {},
    priorityWork: {},
    closeCallback:{}
  },
  mounted() {
    this.init()
  },
  beforeUnmount() {},
  methods: {
    async init() {
      this.showIframe = false
      this.loading = true
      // this.workId = this.$route.query.id
      // let application = this.$route.query.applicationId
      // let process = this.$route.query.processId
      if (this.workId) {
        this.loadProcess()
      }
      // else if (application && process) {
      //   this.startProcess(application, process)
      // }

      // let params = this.$route.params
      // let query = this.$route.query
      // let appName = params.appName || 'Process'
      // let node = this.$refs.wrapper
      // node.innerHTML = ''
      // let options = {}
      // let status = null
      // try {
      //   let params = JSON.parse(query.params)
      //   console.log('params', params)
      //   // 会议室发起流程 params 中没有 options 字段，这里加了个判断
      //   if (params.options) {
      //     options = params.options
      //     status = params.status
      //   } else {
      //     options = params
      //   }
      // } catch (e) {
      //   // fail to parse JSON
      // }
      // console.log('OA init options', options)
      // console.log('init status', status)
      //
      // let moduleName = AppModuleMap[appName] || appName
      // return
      // console.log(this.$route);
      // let params = this.$route.params
      // let appName = params.params
      // let { id } = this.$route.query
      // let node = this.$refs.wrapper
      // this.processWork = new ProcessWork(node)
      // if (appName === 'Process') {
      //   this.loading = true
      //   this.processWork.loadWork(id)
      //   this.processWork.loadApplication(() => {
      //     this.loading = false
      //   })
      // } else if (appName === 'cms') {
      //   // this.app = await layout.createApp(moduleName, options, node)
      //   // if (status) {
      //   //   console.log('setStatus', status)
      //   //   this.app.status = status
      //   // }
      //   // let beforeLoad =
      //   //   AppBeforeLoadMap[appName] ||
      //   //   `${appName.replace(/^./, (match) => {
      //   //     return match.toLowerCase()
      //   //   })}BeforeLoad`
      //   // try {
      //   //   this[beforeLoad] && this[beforeLoad]()
      //   // } catch (error) {
      //   //   console.log('fail to exec beforeLoad')
      //   //   // do nothing
      //   // }
      //   // this.app.load()
      //   // this.isLoading = false
      //   // let afterLoad =
      //   //   AppAfterLoadMap[appName] ||
      //   //   `${appName.replace(/^./, (match) => {
      //   //     return match.toLowerCase()
      //   //   })}AfterLoad`
      //   // try {
      //   //   this[afterLoad] && this[afterLoad]()
      //   // } catch (error) {
      //   //   console.log('fail to exec AfterLoad')
      //   //   // do nothing
      //   // }
      //   // this.app.close = () => {
      //   //   this.$router.go(-1)
      //   // }
      //
      // }
      // let workId = this.$route.query.id
      // let type = this.$route.query.id

      // 修改base可直接打开
      // o2.api.form.openWork(this.workId)
    },
    loadProcess() {
      let node = this.$refs.wrapper
      let options = {
        workId:this.workId,
        taskId:this.taskId,
        priorityWork:this.priorityWork,
      }
      this.processWork = new ProcessWork(node, options, this.closeEvent)
      this.processWork.loadWork()
      // this.processWork.onQueryLoad()
      // this.processWork.loadWorkApplication()
      this.processWork.loadApplication(() => {
        this.loading = false
      })
    },
    closeEvent(){
      if(this.closeCallback){
        this.closeCallback()
      }
    },
    startProcess(
      app,
      process,
      node,
      data,
      identity,
      callback,
      target,
      latest,
      afterCreated,
      skipDraftCheck
    ) {
      let formApp = o2.api.form.getApp()
      console.log(1)
      let that = this
      o2.Actions.get('x_processplatform_assemble_surface').getProcessByName(
        process,
        app,
        function (json) {
          console.log(json)
          console.log(o2)
          if (json.data) {
            o2.xDesktop.requireApp('process.TaskCenter', 'ProcessStarter', () => {
              let starter = new o2.xApplication.process.TaskCenter.ProcessStarter(
                json.data,
                formApp,
                {
                  workData: data,
                  identity: identity,
                  latest: latest,
                  skipDraftCheck: skipDraftCheck,
                  onStarted: (data, title, processName) => {
                    console.log(that)
                    console.log(data)
                    console.log(title)
                    console.log(processName)
                    that.workId = data[0].work
                    that.loadProcess()
                  }
                }
              )
              starter.load()
            })
          }
        }
      )
    }
  }
})
</script>

<template>
  <!--  <div>{{showIframe}}</div>-->
  <div
    ref="wrapper"
    id="oa-process-wrapper-layout"
    class="oa-process-wrapper"
    v-loading="loading"
  ></div>
  <!--  <iframe v-if="showIframe" ref="finishIframe" :src="iframeUrl" scrolling="auto" frameborder="0" @load="load"></iframe>-->
</template>

<style scoped lang="scss">
.oa-process-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: auto;
  background: #eeeeee;
}

iframe {
  position: relative;
  height: 100%;
  width: 100%;
}
</style>