import axios from './axios.config'
// 获取显示字段 OilCoupon
export const getOilCouponFields = (tableId='OilCoupon')=>axios.get(`/jaxrs/oil_coupon/fields?tableId=${tableId}`)
// 获取查询条件
export const getOilCouponConditions = ()=>axios.get('/jaxrs/oil_coupon/conditions')
// 查询
export const getOilCouponPage = (data)=>axios.post('/jaxrs/oil_coupon/page',data)
// 详情页
export const getOilCouponDetail = (data)=>axios.get(`/jaxrs/oil_coupon/${data.id}`)
// 保存
export const addOilCoupon = (data)=>axios.post(`/jaxrs/oil_coupon`,data)
// 编辑
export const editOilCoupon = (data)=>axios.put(`/jaxrs/oil_coupon`,data)
// 删除
export const deleteOilCoupon = (data)=>axios.delete(`/jaxrs/oil_coupon/${data.id}`)