<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "LoadingIcon",
  props:{
    title:{
      default(){
        return '正在加载'
      }
    }
  }
});
</script>

<template>
  <div class="waitting"><span>{{title}}</span> <div class="loading">
    <span></span>
    <span></span>
    <span></span>
  </div></div>
</template>

<style scoped lang="scss">
.waitting{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  &>span{
    margin-top: 14px;
    margin-right: 10px;
    color:#999
  }
  .loading{
    width: 40px;
    height: 4px;
    span{
      display: inline-block;
      width: 4px;
      height: 100%;
      margin-right: 5px;
      border-radius: 50%;
      background: #999;
      -webkit-animation: load 1.6s ease infinite;
      &:last-child{
        margin-right: 0px;
      }
    }
  }
}

@-webkit-keyframes load{
  0%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}
.loading span:nth-child(1){
  -webkit-animation-delay:0.2s;
}
.loading span:nth-child(2){
  -webkit-animation-delay:0.4s;
}
.loading span:nth-child(3){
  -webkit-animation-delay:0.6s;
}
</style>