<script>
import { defineComponent } from "vue";
import { getConsumerByPhone, getConsumerDetail } from "@/axios";
import WorkOrderEdit from "../../views/WorkOrder/WorkOrderEdit.vue";
import WorkOrderComplainEdit from "../../views/WorkOrderComplain/WorkOrderComplainEdit.vue";
import qnvfunc from "./js/qnvfunc";
let {
  TV_HangUpCtrl,
  TV_RefuseCallIn,
  TV_StartFlash
} = qnvfunc

export default defineComponent({
  name: "RecordingSoundDialog",
  components: { WorkOrderComplainEdit, WorkOrderEdit },
  emits: ["update:modelValue"],
  data() {
    return {
      consumer: null,
      loading: false,
      showWorkOrderEditDrawer: false,
      workOrderType: null,
      showWorkOrderComplainEditDrawer: false
    };
  },
  props: {
    phone: {},
    modelValue: {
      type: Boolean
    }
  },
  computed: {
    show: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    }
  },
  methods: {
    open() {
      this.loading = true;
      getConsumerByPhone({ phone: this.phone }).then(res => {
        if (res.data.length > 0) {
          this.consumer = res.data[0];
        } else {
          this.consumer = null;
        }
      }).catch(() => {
      }).finally(() => {
        this.loading = false;
      });
    },
    editWorkOrderClick(type){
      this.workOrderType = type
      this.showWorkOrderEditDrawer = true
      this.show = false
    },
    editWorkOrderComplainClick(){
      this.showWorkOrderComplainEditDrawer = true
      this.show = false
    },
    handleIgnoreClick(){
      // TV_HangUpCtrl(0)
      // TV_RefuseCallIn(0)
      // TV_StartFlash(0)
      this.show = false
    }
  }
});
</script>

<template>
  <el-dialog v-model="show" v-bind="$attrs" title="客户来电" width="50%" destroy-on-close :show-close="false"
             :close-on-click-modal="false" @open="open">
    <!--    <div v-loading="loading">-->
    <template #header>
      <div class="recording-sound-dialog-header">
        <span>客户来电</span>
        <span class="color-success margin-left-10">{{ phone }}</span>
        <span class="color-danger margin-left-10" v-if="!consumer && !loading">新客户</span>
      </div>
    </template>
    <div v-loading="loading">
      <el-form ref="form" label-suffix="：" v-if="consumer">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="联系方式">
              <span class="color-success">{{ consumer.contactPhone }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="客户名称">
              {{ consumer.name }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系人">
              {{ consumer.contact }}
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="联系地址">
              {{ `${consumer.district}${consumer.address}` }}
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!--      <el-form ref="form" label-suffix="：" v-else>-->
      <!--        <el-form-item label="联系方式">-->
      <!--          <span class="color-success">{{phone}}</span>-->

      <!--        </el-form-item>-->
      <!--      </el-form>-->
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleIgnoreClick">忽略</el-button>
        <el-button type="primary" @click="editWorkOrderClick('install')">安装</el-button>
        <el-button type="warning" @click="editWorkOrderClick('repair')">维修</el-button>
        <el-button type="danger" @click="editWorkOrderComplainClick">反馈</el-button>
      </span>
    </template>
  </el-dialog>
  <WorkOrderEdit
    v-model:show="showWorkOrderEditDrawer"
    :component-type="workOrderType"
  ></WorkOrderEdit>
  <WorkOrderComplainEdit
    v-model:show="showWorkOrderComplainEditDrawer"
  ></WorkOrderComplainEdit>
</template>

<style scoped lang="scss">
.recording-sound-dialog-header {
  font-size: 18px;
  font-weight: bold;
}
</style>