export const conditionTypeMap = {
  string: {
    eq: {
      name: "等于",
      component: "el-input",
      params: ""
    },
    is_null_or_eq: {
      name: "等于",
      component: "el-input",
      params: ""
    },
    ne: {
      name: "不等于",
      component: "el-input",
      params: ""
    },
    gt: {
      name: "大于",
      component: "el-input",
      params: ""
    },
    gte: {
      name: "大于等于",
      component: "el-input",
      params: ""
    },
    lt: {
      name: "小于",
      component: "el-input",
      params: ""
    },
    lte: {
      name: "小于等于",
      component: "el-input",
      params: ""
    },
    between: {
      name: "选择范围",
      component: "el-input",
      params: ""
    },
    is_null: {
      name: "为空"
    },
    is_not_null: {
      name: "不为空"
    },
    in: {
      name: "等于任意一个",
      component: "el-input",
      params: ""
    },
    not_in: {
      name: "不等于任意一个",
      component: "el-input",
      params: ""
    },
    like: {
      name: "包含(关键字搜索)",
      component: "el-input",
      params: ""
    },
    not_like: {
      name: "不包含(关键字搜索)",
      component: "el-input",
      params: ""
    }
  },
  number: {
    eq: {
      name: "等于",
      component: "el-input",
      props: {
        type: "number",
        min: 0
      },
      params: 0
    },
    is_null_or_eq: {
      name: "等于",
      component: "el-input",
      props: {
        type: "number",
        min: 0
      },
      params: 0
    },
    ne: {
      name: "不等于",
      component: "el-input",
      props: {
        type: "number",
        min: 0
      },
      params: 0
    },
    gt: {
      name: "大于",
      component: "el-input",
      props: {
        type: "number",
        min: 0
      },
      params: 0
    },
    gte: {
      name: "大于等于",
      component: "el-input",
      props: {
        type: "number",
        min: 0
      },
      params: 0
    },
    lt: {
      name: "小于",
      component: "el-input",
      props: {
        type: "number",
        min: 0
      },
      params: 0
    },
    lte: {
      name: "小于等于",
      component: "el-input",
      props: {
        type: "number",
        min: 0
      },
      params: 0
    },
    between: {
      name: "选择范围",
      component: "InputNumberBetween",
      params: [0, 0]
    },
    is_null: {
      name: "为空"
    },
    is_not_null: {
      name: "不为空"
    },
    in: {
      name: "等于任意一个",
      component: "el-input",
      props: {
        type: "number",
        min: 0
      },
      params: 0
    },
    not_in: {
      name: "不等于任意一个",
      component: "el-input",
      props: {
        type: "number",
        min: 0
      },
      params: 0
    },
    like: {
      name: "包含(关键字搜索)",
      component: "el-input",
      props: {
        type: "number",
        min: 0
      },
      params: 0
    },
    not_like: {
      name: "不包含(关键字搜索)",
      component: "el-input",
      props: {
        type: "number",
        min: 0
      },
      params: 0
    }
  },
  date: {
    eq: {
      name: "等于",
      component: "DatePicker",
      props: {
        valueFormat: "YYYY-MM-DD"
      },
      params: ""
    },
    is_null_or_eq: {
      name: "等于",
      component: "DatePicker",
      props: {
        valueFormat: "YYYY-MM-DD"
      },
      params: ""
    },
    ne: {
      name: "不等于",
      component: "DatePicker",
      props: {
        valueFormat: "YYYY-MM-DD"
      },
      params: ""
    },
    gt: {
      name: "大于",
      component: "DatePicker",
      props: {
        valueFormat: "YYYY-MM-DD"
      },
      params: ""
    },
    gte: {
      name: "大于等于",
      component: "DatePicker",
      props: {
        valueFormat: "YYYY-MM-DD"
      },
      params: ""
    },
    lt: {
      name: "小于",
      component: "DatePicker",
      props: {
        valueFormat: "YYYY-MM-DD"
      },
      params: ""
    },
    lte: {
      name: "小于等于",
      component: "DatePicker",
      props: {
        valueFormat: "YYYY-MM-DD"
      },
      params: ""
    },
    between: {
      name: "选择范围",
      component: "DatePicker",
      props: {
        valueFormat: "YYYY-MM-DD",
        type: "daterange"
      },
      params: ["", ""]
    },
    is_null: {
      name: "为空"
    },
    is_not_null: {
      name: "不为空"
    },
    in: {
      name: "等于任意一个",
      component: "DatePicker",
      props: {
        valueFormat: "YYYY-MM-DD"
      },
      params: ""
    },
    not_in: {
      name: "不等于任意一个",
      component: "DatePicker",
      props: {
        valueFormat: "YYYY-MM-DD"
      },
      params: ""
    },
    like: {
      name: "包含(关键字搜索)",
      component: "DatePicker",
      props: {
        valueFormat: "YYYY-MM-DD"
      },
      params: ""
    },
    not_like: {
      name: "不包含(关键字搜索)",
      component: "DatePicker",
      props: {
        valueFormat: "YYYY-MM-DD"
      },
      params: ""
    }
  },
  datetime: {
    eq: {
      name: "等于",
      component: "DatePicker",
      props: {
        type: "datetime",
        valueFormat: "YYYY-MM-DD HH:mm:ss"
      },
      params: ""
    },
    is_null_or_eq: {
      name: "等于",
      component: "DatePicker",
      props: {
        type: "datetime",
        valueFormat: "YYYY-MM-DD HH:mm:ss"
      },
      params: ""
    },
    ne: {
      name: "不等于",
      component: "DatePicker",
      props: {
        type: "datetime",
        valueFormat: "YYYY-MM-DD HH:mm:ss"
      },
      params: ""
    },
    gt: {
      name: "大于",
      component: "DatePicker",
      props: {
        type: "datetime",
        valueFormat: "YYYY-MM-DD HH:mm:ss",
        defaultTime: new Date(2000, 1, 1, 0, 0, 0)
      },
      params: ""
    },
    gte: {
      name: "大于等于",
      component: "DatePicker",
      props: {
        type: "datetime",
        valueFormat: "YYYY-MM-DD HH:mm:ss",
        defaultTime: new Date(2000, 1, 1, 0, 0, 0)
      },
      params: ""
    },
    lt: {
      name: "小于",
      component: "DatePicker",
      props: {
        type: "datetime",
        valueFormat: "YYYY-MM-DD HH:mm:ss",
        defaultTime: new Date(2000, 1, 1, 23, 59, 59)
      },
      params: ""
    },
    lte: {
      name: "小于等于",
      component: "DatePicker",
      props: {
        type: "datetime",
        valueFormat: "YYYY-MM-DD HH:mm:ss",
        defaultTime: new Date(2000, 1, 1, 23, 59, 59)
      },
      params: ""
    },
    between: {
      name: "选择范围",
      component: "DatePicker",
      props: {
        type: "datetimerange",
        valueFormat: "YYYY-MM-DD HH:mm:ss",
        defaultTime: [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 1, 1, 23, 59, 59)]
      },
      params: ["", ""]
    },
    is_null: {
      name: "为空"
    },
    is_not_null: {
      name: "不为空"
    },
    in: {
      name: "等于任意一个",
      component: "DatePicker",
      props: {
        type: "datetime",
        valueFormat: "YYYY-MM-DD HH:mm:ss"
      },
      params: ""
    },
    not_in: {
      name: "不等于任意一个",
      component: "DatePicker",
      props: {
        type: "datetime",
        valueFormat: "YYYY-MM-DD HH:mm:ss"
      },
      params: ""
    },
    like: {
      name: "包含(关键字搜索)",
      component: "DatePicker",
      props: {
        type: "datetime",
        valueFormat: "YYYY-MM-DD HH:mm:ss"
      },
      params: ""
    },
    not_like: {
      name: "不包含(关键字搜索)",
      component: "DatePicker",
      props: {
        type: "datetime",
        valueFormat: "YYYY-MM-DD HH:mm:ss"
      },
      params: ""
    }
  },
  month: {
    eq: {
      name: "等于",
      component: "DatePicker",
      props: {
        type: "month",
        valueFormat: "YYYY-MM"
      },
      params: ""
    },
    is_null_or_eq: {
      name: "等于",
      component: "DatePicker",
      props: {
        type: "month",
        valueFormat: "YYYY-MM"
      },
      params: ""
    },
    ne: {
      name: "不等于",
      component: "DatePicker",
      props: {
        type: "month",
        valueFormat: "YYYY-MM"
      },
      params: ""
    },
    gt: {
      name: "大于",
      component: "DatePicker",
      props: {
        type: "month",
        valueFormat: "YYYY-MM"
      },
      params: ""
    },
    gte: {
      name: "大于等于",
      component: "DatePicker",
      props: {
        type: "month",
        valueFormat: "YYYY-MM"
      },
      params: ""
    },
    lt: {
      name: "小于",
      component: "DatePicker",
      props: {
        type: "month",
        valueFormat: "YYYY-MM"
      },
      params: ""
    },
    lte: {
      name: "小于等于",
      component: "DatePicker",
      props: {
        type: "month",
        valueFormat: "YYYY-MM"
      },
      params: ""
    },
    between: {
      name: "选择范围",
      component: "DatePicker",
      props: {
        type: "monthrange",
        valueFormat: "YYYY-MM"
      },
      params: ["", ""]
    },
    is_null: {
      name: "为空"
    },
    is_not_null: {
      name: "不为空"
    },
    in: {
      name: "等于任意一个",
      component: "DatePicker",
      props: {
        type: "month",
        valueFormat: "YYYY-MM"
      },
      params: ""
    },
    not_in: {
      name: "不等于任意一个",
      component: "DatePicker",
      props: {
        type: "month",
        valueFormat: "YYYY-MM"
      },
      params: ""
    },
    like: {
      name: "包含(关键字搜索)",
      component: "DatePicker",
      props: {
        type: "month",
        valueFormat: "YYYY-MM"
      },
      params: ""
    },
    not_like: {
      name: "不包含(关键字搜索)",
      component: "DatePicker",
      props: {
        type: "month",
        valueFormat: "YYYY-MM"
      },
      params: ""
    }
  },
  boole: {
    eq: {
      name: "等于",
      component: "el-switch",
      props: {
        activeValue: 1,
        inactiveValue: 0
      },
      params: 0
    },
    is_null_or_eq: {
      name: "等于",
      component: "el-switch",
      props: {
        activeValue: 1,
        inactiveValue: 0
      },
      params: 0
    },
    ne: {
      name: "不等于",
      component: "el-switch",
      props: {
        activeValue: 1,
        inactiveValue: 0
      },
      params: 0
    }
  },

  material: {
    eq: {
      name: "等于",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true
      },
      params: ""
    },
    is_null_or_eq: {
      name: "等于",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true
      },
      params: ""
    },
    ne: {
      name: "不等于",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true
      },
      params: ""
    },
    gt: {
      name: "大于",
      component: "el-input",
      params: ""
    },
    gte: {
      name: "大于等于",
      component: "el-input",
      params: ""
    },
    lt: {
      name: "小于",
      component: "el-input",
      params: ""
    },
    lte: {
      name: "小于等于",
      component: "el-input",
      params: ""
    },
    between: {
      name: "选择范围",
      component: "el-input",
      params: ""
    },
    is_null: {
      name: "为空"
    },
    is_not_null: {
      name: "不为空"
    },
    in: {
      name: "等于任意一个",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true,
        multiple: true,
        collapseTags: true
      },
      params: []
    },
    not_in: {
      name: "不等于任意一个",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true,
        multiple: true,
        collapseTags: true
      },
      params: []
    },
    like: {
      name: "包含(关键字搜索)",
      component: "el-input",
      params: ""
    },
    not_like: {
      name: "不包含(关键字搜索)",
      component: "el-input",
      params: ""
    }
  },
  material_type: {
    eq: {
      name: "等于",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true
      },
      params: ""
    },
    is_null_or_eq: {
      name: "等于",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true
      },
      params: ""
    },
    ne: {
      name: "不等于",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true
      },
      params: ""
    },
    gt: {
      name: "大于",
      component: "el-input",
      params: ""
    },
    gte: {
      name: "大于等于",
      component: "el-input",
      params: ""
    },
    lt: {
      name: "小于",
      component: "el-input",
      params: ""
    },
    lte: {
      name: "小于等于",
      component: "el-input",
      params: ""
    },
    between: {
      name: "选择范围",
      component: "el-input",
      params: ""
    },
    is_null: {
      name: "为空"
    },
    is_not_null: {
      name: "不为空"
    },
    in: {
      name: "等于任意一个",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true,
        multiple: true,
        collapseTags: true
      },
      params: []
    },
    not_in: {
      name: "不等于任意一个",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true,
        multiple: true,
        collapseTags: true
      },
      params: []
    },
    like: {
      name: "包含(关键字搜索)",
      component: "el-input",
      params: ""
    },
    not_like: {
      name: "不包含(关键字搜索)",
      component: "el-input",
      params: ""
    }
  },
  store: {
    eq: {
      name: "等于",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true
      },
      params: ""
    },
    is_null_or_eq: {
      name: "等于",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true
      },
      params: ""
    },
    ne: {
      name: "不等于",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true
      },
      params: ""
    },
    gt: {
      name: "大于",
      component: "el-input",
      params: ""
    },
    gte: {
      name: "大于等于",
      component: "el-input",
      params: ""
    },
    lt: {
      name: "小于",
      component: "el-input",
      params: ""
    },
    lte: {
      name: "小于等于",
      component: "el-input",
      params: ""
    },
    between: {
      name: "选择范围",
      component: "el-input",
      params: ""
    },
    is_null: {
      name: "为空"
    },
    is_not_null: {
      name: "不为空"
    },
    in: {
      name: "等于任意一个",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true,
        multiple: true,
        collapseTags: true
      },
      params: []
    },
    not_in: {
      name: "不等于任意一个",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true,
        multiple: true,
        collapseTags: true
      },
      params: []
    },
    like: {
      name: "包含(关键字搜索)",
      component: "el-input",
      params: ""
    },
    not_like: {
      name: "不包含(关键字搜索)",
      component: "el-input",
      params: ""
    }
  },
  supplier: {
    eq: {
      name: "等于",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true
      },
      params: ""
    },
    is_null_or_eq: {
      name: "等于",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true
      },
      params: ""
    },
    ne: {
      name: "不等于",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true
      },
      params: ""
    },
    gt: {
      name: "大于",
      component: "el-input",
      params: ""
    },
    gte: {
      name: "大于等于",
      component: "el-input",
      params: ""
    },
    lt: {
      name: "小于",
      component: "el-input",
      params: ""
    },
    lte: {
      name: "小于等于",
      component: "el-input",
      params: ""
    },
    between: {
      name: "选择范围",
      component: "el-input",
      params: ""
    },
    is_null: {
      name: "为空"
    },
    is_not_null: {
      name: "不为空"
    },
    in: {
      name: "等于任意一个",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true,
        multiple: true,
        collapseTags: true
      },
      params: []
    },
    not_in: {
      name: "不等于任意一个",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true,
        multiple: true,
        collapseTags: true
      },
      params: []
    },
    like: {
      name: "包含(关键字搜索)",
      component: "el-input",
      params: ""
    },
    not_like: {
      name: "不包含(关键字搜索)",
      component: "el-input",
      params: ""
    }
  },
  project: {
    eq: {
      name: "等于",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true
      },
      params: ""
    },
    is_null_or_eq: {
      name: "等于",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true
      },
      params: ""
    },
    ne: {
      name: "不等于",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true
      },
      params: ""
    },
    gt: {
      name: "大于",
      component: "el-input",
      params: ""
    },
    gte: {
      name: "大于等于",
      component: "el-input",
      params: ""
    },
    lt: {
      name: "小于",
      component: "el-input",
      params: ""
    },
    lte: {
      name: "小于等于",
      component: "el-input",
      params: ""
    },
    between: {
      name: "选择范围",
      component: "el-input",
      params: ""
    },
    is_null: {
      name: "为空"
    },
    is_not_null: {
      name: "不为空"
    },
    in: {
      name: "等于任意一个",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true,
        multiple: true,
        collapseTags: true
      },
      params: []
    },
    not_in: {
      name: "不等于任意一个",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true,
        multiple: true,
        collapseTags: true
      },
      params: []
    },
    like: {
      name: "包含(关键字搜索)",
      component: "el-input",
      params: ""
    },
    not_like: {
      name: "不包含(关键字搜索)",
      component: "el-input",
      params: ""
    }
  },
  maintenance_project: {
    eq: {
      name: "等于",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true
      },
      params: ""
    },
    is_null_or_eq: {
      name: "等于",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true
      },
      params: ""
    },
    ne: {
      name: "不等于",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true
      },
      params: ""
    },
    gt: {
      name: "大于",
      component: "el-input",
      params: ""
    },
    gte: {
      name: "大于等于",
      component: "el-input",
      params: ""
    },
    lt: {
      name: "小于",
      component: "el-input",
      params: ""
    },
    lte: {
      name: "小于等于",
      component: "el-input",
      params: ""
    },
    between: {
      name: "选择范围",
      component: "el-input",
      params: ""
    },
    is_null: {
      name: "为空"
    },
    is_not_null: {
      name: "不为空"
    },
    in: {
      name: "等于任意一个",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true,
        multiple: true,
        collapseTags: true
      },
      params: []
    },
    not_in: {
      name: "不等于任意一个",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true,
        multiple: true,
        collapseTags: true
      },
      params: []
    },
    like: {
      name: "包含(关键字搜索)",
      component: "el-input",
      params: ""
    },
    not_like: {
      name: "不包含(关键字搜索)",
      component: "el-input",
      params: ""
    }
  },
  commercial_project: {
    eq: {
      name: "等于",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true
      },
      params: ""
    },
    is_null_or_eq: {
      name: "等于",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true
      },
      params: ""
    },
    ne: {
      name: "不等于",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true
      },
      params: ""
    },
    gt: {
      name: "大于",
      component: "el-input",
      params: ""
    },
    gte: {
      name: "大于等于",
      component: "el-input",
      params: ""
    },
    lt: {
      name: "小于",
      component: "el-input",
      params: ""
    },
    lte: {
      name: "小于等于",
      component: "el-input",
      params: ""
    },
    between: {
      name: "选择范围",
      component: "el-input",
      params: ""
    },
    is_null: {
      name: "为空"
    },
    is_not_null: {
      name: "不为空"
    },
    in: {
      name: "等于任意一个",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true,
        multiple: true,
        collapseTags: true
      },
      params: []
    },
    not_in: {
      name: "不等于任意一个",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true,
        multiple: true,
        collapseTags: true
      },
      params: []
    },
    like: {
      name: "包含(关键字搜索)",
      component: "el-input",
      params: ""
    },
    not_like: {
      name: "不包含(关键字搜索)",
      component: "el-input",
      params: ""
    }
  },
  vehicle: {
    eq: {
      name: "等于",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "plateNum",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true
      },
      params: ""
    },
    is_null_or_eq: {
      name: "等于",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "plateNum",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true
      },
      params: ""
    },
    ne: {
      name: "不等于",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "plateNum",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true
      },
      params: ""
    },
    gt: {
      name: "大于",
      component: "el-input",
      params: ""
    },
    gte: {
      name: "大于等于",
      component: "el-input",
      params: ""
    },
    lt: {
      name: "小于",
      component: "el-input",
      params: ""
    },
    lte: {
      name: "小于等于",
      component: "el-input",
      params: ""
    },
    between: {
      name: "选择范围",
      component: "el-input",
      params: ""
    },
    is_null: {
      name: "为空"
    },
    is_not_null: {
      name: "不为空"
    },
    in: {
      name: "等于任意一个",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "plateNum",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true,
        multiple: true,
        collapseTags: true
      },
      params: []
    },
    not_in: {
      name: "不等于任意一个",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "plateNum",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true,
        multiple: true,
        collapseTags: true
      },
      params: []
    },
    like: {
      name: "包含(关键字搜索)",
      component: "el-input",
      params: ""
    },
    not_like: {
      name: "不包含(关键字搜索)",
      component: "el-input",
      params: ""
    }
  },
  consumer: {
    eq: {
      name: "等于",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true
      },
      params: ""
    },
    is_null_or_eq: {
      name: "等于",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true
      },
      params: ""
    },
    ne: {
      name: "不等于",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true
      },
      params: ""
    },
    gt: {
      name: "大于",
      component: "el-input",
      params: ""
    },
    gte: {
      name: "大于等于",
      component: "el-input",
      params: ""
    },
    lt: {
      name: "小于",
      component: "el-input",
      params: ""
    },
    lte: {
      name: "小于等于",
      component: "el-input",
      params: ""
    },
    between: {
      name: "选择范围",
      component: "el-input",
      params: ""
    },
    is_null: {
      name: "为空"
    },
    is_not_null: {
      name: "不为空"
    },
    in: {
      name: "等于任意一个",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true,
        multiple: true,
        collapseTags: true
      },
      params: []
    },
    not_in: {
      name: "不等于任意一个",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true,
        multiple: true,
        collapseTags: true
      },
      params: []
    },
    like: {
      name: "包含(关键字搜索)",
      component: "el-input",
      params: ""
    },
    not_like: {
      name: "不包含(关键字搜索)",
      component: "el-input",
      params: ""
    }
  },
  unit: {
    eq: {
      name: "等于",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true
      },
      params: ""
    },
    is_null_or_eq: {
      name: "等于",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true
      },
      params: ""
    },
    ne: {
      name: "不等于",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true
      },
      params: ""
    },
    gt: {
      name: "大于",
      component: "el-input",
      params: ""
    },
    gte: {
      name: "大于等于",
      component: "el-input",
      params: ""
    },
    lt: {
      name: "小于",
      component: "el-input",
      params: ""
    },
    lte: {
      name: "小于等于",
      component: "el-input",
      params: ""
    },
    between: {
      name: "选择范围",
      component: "el-input",
      params: ""
    },
    is_null: {
      name: "为空"
    },
    is_not_null: {
      name: "不为空"
    },
    in: {
      name: "等于任意一个",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true,
        multiple: true,
        collapseTags: true
      },
      params: []
    },
    not_in: {
      name: "不等于任意一个",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true,
        multiple: true,
        collapseTags: true
      },
      params: []
    },
    like: {
      name: "包含(关键字搜索)",
      component: "el-input",
      params: ""
    },
    not_like: {
      name: "不包含(关键字搜索)",
      component: "el-input",
      params: ""
    }
  },
  company: {
    eq: {
      name: "等于",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        filterable: true
      },
      params: ""
    },
    is_null_or_eq: {
      name: "等于",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        filterable: true
      },
      params: ""
    },
    ne: {
      name: "不等于",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        filterable: true
      },
      params: ""
    },
    gt: {
      name: "大于",
      component: "el-input",
      params: ""
    },
    gte: {
      name: "大于等于",
      component: "el-input",
      params: ""
    },
    lt: {
      name: "小于",
      component: "el-input",
      params: ""
    },
    lte: {
      name: "小于等于",
      component: "el-input",
      params: ""
    },
    between: {
      name: "选择范围",
      component: "el-input",
      params: ""
    },
    is_null: {
      name: "为空"
    },
    is_not_null: {
      name: "不为空"
    },
    in: {
      name: "等于任意一个",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        filterable: true,
        multiple: true,
        collapseTags: true
      },
      params: []
    },
    not_in: {
      name: "不等于任意一个",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        filterable: true,
        multiple: true,
        collapseTags: true
      },
      params: []
    },
    like: {
      name: "包含(关键字搜索)",
      component: "el-input",
      params: ""
    },
    not_like: {
      name: "不包含(关键字搜索)",
      component: "el-input",
      params: ""
    }
  },

  user: {
    eq: {
      name: "等于",
      component: "SelectUser",
      params: ""
    },
    is_null_or_eq: {
      name: "等于",
      component: "SelectUser",
      params: ""
    },
    ne: {
      name: "不等于",
      component: "SelectUser",
      params: ""
    },
    is_null: {
      name: "为空"
    },
    is_not_null: {
      name: "不为空"
    },
    in: {
      name: "等于任意一个",
      component: "SelectUser",
      props: {
        multiple: true
      },
      params: []
    },
    not_in: {
      name: "不等于任意一个",
      component: "SelectUser",
      props: {
        multiple: true
      },
      params: []
    }
  },
  person: {
    eq: {
      name: "等于",
      component: "SelectUser",
      props: {
        user: true
      },
      params: ""
    },
    is_null_or_eq: {
      name: "等于",
      component: "SelectUser",
      props: {
        user: true
      },
      params: ""
    },
    ne: {
      name: "不等于",
      component: "SelectUser",
      props: {
        user: true
      },
      params: ""
    },
    is_null: {
      name: "为空"
    },
    is_not_null: {
      name: "不为空"
    },
    in: {
      name: "等于任意一个",
      component: "SelectUser",
      props: {
        multiple: true,
        user: true
      },
      params: []
    },
    not_in: {
      name: "不等于任意一个",
      component: "SelectUser",
      props: {
        multiple: true,
        user: true
      },
      params: []
    }
  },

  dict: {
    eq: {
      name: "等于",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true
      },
      params: ""
    },
    is_null_or_eq: {
      name: "等于",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true
      },
      params: ""
    },
    ne: {
      name: "不等于",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true
      },
      params: ""
    },
    gt: {
      name: "大于",
      component: "el-input",
      params: ""
    },
    gte: {
      name: "大于等于",
      component: "el-input",
      params: ""
    },
    lt: {
      name: "小于",
      component: "el-input",
      params: ""
    },
    lte: {
      name: "小于等于",
      component: "el-input",
      params: ""
    },
    between: {
      name: "选择范围",
      component: "el-input",
      params: ""
    },
    is_null: {
      name: "为空"
    },
    is_not_null: {
      name: "不为空"
    },
    in: {
      name: "等于任意一个",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true,
        multiple: true,
        collapseTags: true
      },
      params: []
    },
    not_in: {
      name: "不等于任意一个",
      component: "SelectV3",
      props: {
        list: [],
        labelKey: "name",
        valueKey: "id",
        lightLabelKey: "spec",
        filterable: true,
        multiple: true,
        collapseTags: true
      },
      params: []
    },
    like: {
      name: "包含(关键字搜索)",
      component: "el-input",
      params: ""
    },
    not_like: {
      name: "不包含(关键字搜索)",
      component: "el-input",
      params: ""
    }
  }
};

export const listFilterMap = {
  textValue: {
    equals: {
      name: "等于",
      component: "el-input",
      params: ""
    },
    notEquals: {
      name: "不等于",
      component: "el-input",
      params: ""
    },
    like: {
      name: "匹配",
      component: "el-input",
      params: ""
    },
    notLike: {
      name: "不匹配",
      component: "el-input",
      params: ""
    }
    // "in": {
    //   "name": "等于任意一个",
    //   "component": "el-input",
    //   "params": ""
    // }
  },
  numberValue: {
    equals: {
      name: "等于",
      component: "el-input",
      props: {
        type: "number",
        min: 0
      },
      params: ""
    },
    notEquals: {
      name: "不等于",
      component: "el-input",
      props: {
        type: "number",
        min: 0
      },
      params: ""
    },
    greaterThan: {
      name: "大于",
      component: "el-input",
      props: {
        type: "number",
        min: 0
      },
      params: ""
    },
    greaterThanOrEqualTo: {
      name: "大于等于",
      component: "el-input",
      props: {
        type: "number",
        min: 0
      },
      params: ""
    },
    lessThan: {
      name: "小于",
      component: "el-input",
      props: {
        type: "number",
        min: 0
      },
      params: ""
    },
    lessThanOrEqualTo: {
      name: "小于等于",
      component: "el-input",
      props: {
        type: "number",
        min: 0
      },
      params: ""
    },
    range: {
      name: "选择范围",
      component: "InputNumberBetween",
      params: [0, 0]
    }
  },
  dateTimeValue: {
    equals: {
      name: "等于",
      component: "DatePicker",
      props: {
        type: "datetime",
        valueFormat: "YYYY-MM-DD HH:mm:ss"
      },
      params: ""
    },
    notEquals: {
      name: "不等于",
      component: "DatePicker",
      props: {
        type: "datetime",
        valueFormat: "YYYY-MM-DD HH:mm:ss"
      },
      params: ""
    },
    greaterThan: {
      name: "大于",
      component: "DatePicker",
      props: {
        type: "datetime",
        valueFormat: "YYYY-MM-DD HH:mm:ss"
      },
      params: ""
    },
    greaterThanOrEqualTo: {
      name: "大于等于",
      component: "DatePicker",
      props: {
        type: "datetime",
        valueFormat: "YYYY-MM-DD HH:mm:ss"
      },
      params: ""
    },
    lessThan: {
      name: "小于",
      component: "DatePicker",
      props: {
        type: "datetime",
        valueFormat: "YYYY-MM-DD HH:mm:ss"
      },
      params: ""
    },
    lessThanOrEqualTo: {
      name: "小于等于",
      component: "DatePicker",
      props: {
        type: "datetime",
        valueFormat: "YYYY-MM-DD HH:mm:ss"
      },
      params: ""
    },
    range: {
      name: "选择范围",
      component: "DatePicker",
      props: {
        type: "datetimerange",
        valueFormat: "YYYY-MM-DD HH:mm:ss"
      },
      params: ["", ""]
    }
  },
  dateValue: {
    equals: {
      name: "等于",
      component: "DatePicker",
      props: {
        valueFormat: "YYYY-MM-DD"
      },
      params: ""
    },
    notEquals: {
      name: "不等于",
      component: "DatePicker",
      props: {
        valueFormat: "YYYY-MM-DD"
      },
      params: ""
    },
    greaterThan: {
      name: "大于",
      component: "DatePicker",
      props: {
        valueFormat: "YYYY-MM-DD"
      },
      params: ""
    },
    greaterThanOrEqualTo: {
      name: "大于等于",
      component: "DatePicker",
      props: {
        valueFormat: "YYYY-MM-DD"
      },
      params: ""
    },
    lessThan: {
      name: "小于",
      component: "DatePicker",
      props: {
        valueFormat: "YYYY-MM-DD"
      },
      params: ""
    },
    lessThanOrEqualTo: {
      name: "小于等于",
      component: "DatePicker",
      props: {
        valueFormat: "YYYY-MM-DD"
      },
      params: ""
    },
    range: {
      name: "选择范围",
      component: "DatePicker",
      props: {
        type: "daterange",
        valueFormat: "YYYY-MM-DD"
      },
      params: ""
    }
  },
  timeValue: {
    equals: {
      name: "等于",
      component: "el-time-picker",
      props: {
        valueFormat: "HH:mm:ss"
      },
      params: ""
    },
    notEquals: {
      name: "不等于",
      component: "el-time-picker",
      props: {
        valueFormat: "HH:mm:ss"
      },
      params: ""
    },
    greaterThan: {
      name: "大于",
      component: "el-time-picker",
      props: {
        valueFormat: "HH:mm:ss"
      },
      params: ""
    },
    greaterThanOrEqualTo: {
      name: "大于等于",
      component: "el-time-picker",
      props: {
        valueFormat: "HH:mm:ss"
      },
      params: ""
    },
    lessThan: {
      name: "小于",
      component: "el-time-picker",
      props: {
        valueFormat: "HH:mm:ss"
      },
      params: ""
    },
    lessThanOrEqualTo: {
      name: "小于等于",
      component: "el-time-picker",
      props: {
        valueFormat: "HH:mm:ss"
      },
      params: ""
    },
    range: {
      name: "选择范围",
      component: "el-time-picker",
      props: {
        type: "timerange",
        valueFormat: "HH:mm:ss"
      },
      params: []
    }
  },
  booleanValue: {
    equals: {
      name: "等于",
      component: "el-switch",
      params: true
    },
    notEquals: {
      name: "不等于",
      component: "el-switch",
      params: true
    }
  }
};
const listMap = {};
for (let key in listFilterMap) {

  listMap[key] = [];
  for (let i in listFilterMap[key]) {
    let item = {
      ...listFilterMap[key][i],
      value: i
    };
    listMap[key].push(item);
  }
}

export const listFilterListMap = listMap;
