<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ConditionTags",
  emits:['callback'],
  data() {
    return {
      visible: false,
      popoverPlacement: "bottom-start"
    };
  },
  props: {
    tableId:{}
  },
  computed:{
    searchParamsHistory() {
      return this.searchParamsStore.getSearchParamsHistoryByTableId(this.tableId);
    },
    showSearchParamsHistory(){
      return this.searchParamsHistory.length > 0
    },
    firstTag(){
      return this.searchParamsHistory[0]
    }
  },
  methods:{
    tagClick(item){
      this.visible = false
      this.$emit('callback',item)
    }
  }
});
</script>

<template>
  <el-popover
    v-if="showSearchParamsHistory"
    :placement="popoverPlacement"
    :enterable="true"
    :teleported="false"
    v-model:visible="visible"
    width="400"
  >
    <template #reference>
      <el-tag class="tag-button" @click.stop="tagClick(firstTag)">{{firstTag.name}}</el-tag>
    </template>
    <template #default>
      <el-tag class="tag-button tag-margin" v-for="item in searchParamsHistory" :key="item.id" @click.stop="tagClick(item)">{{item.name}}</el-tag>
    </template>
  </el-popover>
</template>

<style scoped lang="scss">
  .tag-button{
    cursor: pointer;
  }
  .tag-margin{
    margin: 5px;
  }
</style>