<script>
import { defineComponent } from "vue";
import { getExecuteModePage, getUnitDetail } from "@/axios";
import _ from "lodash";

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: "SelectWorkerDialog",
  emits: ["update:modelValue", "confirm", "combineConfirm", "update:value"],
  data() {
    return {
      dataViewId: "DD_STAT_WORKLOAD_02",
      searchContent: "",
      leaderId: "",
      orderList: [],
      serverList: [],
      workerList: [],
      unitId: "",
      unitList: [],
      remark: "",
      showUnitPersonList:['工程部','维保售后本部']
    };
  },
  props: {
    modelValue: {
      type: Boolean
    },
    value: {
      type: Array
    },
    title: {
      type: String,
      default() {
        return "工人选择";
      }
    },
    labelKey: {
      type: String,
      default() {
        return "workerName";
      }
    },
    valueKey: {
      type: String,
      default() {
        return "workerId";
      }
    },
    checkList: {
      type: Array
    },
    combineDispatch: {
      type: Boolean,
      default() {
        return false;
      }
    },
    unit: {
      type: String
    }
  },
  computed: {
    show: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    },
    filterList() {
      let list = this.workerList.filter(item => {
        if (this.searchContent) {
          return item.name.includes(this.searchContent) && this.unitId === item.unit;
        }
        return this.unitId === item.unit;
      });
      return list;
    },
    canCombineDispatch() {
      return this.orderList && this.orderList.length > 1;
    },
    isEngineering() {
      return this.unit === "工程部";
    },
    isMaintenance() {
      return this.unit === "维保售后本部";
    }
  },
  mounted() {
  },
  methods: {
    initList() {
      if (this.isEngineering) {
        this.dataViewId = "DD_STAT_WORKLOAD_02";
      }
      if (this.isMaintenance) {
        this.dataViewId = "DD_STAT_WORKLOAD_03";
      }
      let reqData = {
        pageSize: 99999,
        pageNumber: 1,
        viewId: this.dataViewId,
        params: { unitId: "" }
      };
      getExecuteModePage(reqData).then(res => {
        this.workerList = res.data;
        this.initUnitList();
      });
    },
    async initUnitList() {
      if (this.workerList.length > 0) {
        let unitMap = {};
        this.workerList.forEach((item) => {
          if (!unitMap[item.unit]) {
            unitMap[item.unit] = item.unitName;
          }
        });
        let unitList = [];
        let baseUnitList = [];
        for (let key in unitMap) {
          let unitItem = {
            id: key,
            name: unitMap[key]
          };
          baseUnitList.push(unitItem);
        }
        for (const item of this.showUnitPersonList) {
          let unitDetail = await getUnitDetail({ flag: this.dictStore.getDictItemByTypeLabel("unit", item).val });
          let unitId = unitDetail.data.id;
          unitList.push(...baseUnitList.filter(item => {
            return item.id === unitId;
          }))
        }

        // if (this.unit) {
        //   let unitDetail = await getUnitDetail({ flag: this.dictStore.getDictItemByTypeLabel("unit", this.unit).val });
        //   let unitId = unitDetail.data.id;
        //   unitList = unitList.filter(item => {
        //     return item.id === unitId;
        //   });
        // }
        this.unitList = unitList;
        if (this.unitList.length > 0) {
          this.unitId = this.unitList[0].id;
          this.$nextTick(() => {
            this.$refs.tree.setCurrentKey(this.unitId);
          });
        }
      }
    },
    handleCancelClick() {
      this.$emit("update:modelValue", false);
    },
    getDispatchData() {
      if (!this.leaderId) {
        if (this.serverList.length === 0) {
          this.$message.warning("请选择服务人员");
          return;
        } else if (this.serverList.length === 1) {
          this.setLeader(this.serverList[0]);
        } else {
          this.$message.warning("请选择负责人");
          return;
        }
      }
      let hasCheckLeader = false;
      let leader = _.cloneDeep(this.workerList.find(item => item.person === this.leaderId));
      let totalList = _.cloneDeep(this.serverList).map(item => {
        let server = {
          [this.valueKey]: item.person,
          [this.labelKey]: item.name,
          isLeader: false
        };
        if (item.person === this.leaderId) {
          hasCheckLeader = true;
          server.isLeader = true;
        }
        return server;
      });
      if (!hasCheckLeader) {
        totalList.push({
          [this.valueKey]: leader.person,
          [this.labelKey]: leader.name,
          isLeader: true
        });
      }
      let resData = {
        workOrderIds: this.orderList.map(item => item.id),
        workers: totalList,
        remark: this.remark
      };
      return resData;
    },
    handleDispatchClick() {
      let resData = this.getDispatchData();
      if (resData) {
        this.$emit("confirm", resData);
        this.$emit("update:modelValue", false);
      }
    },
    handleCombineDispatchClick() {
      let resData = this.getDispatchData();
      if (resData) {
        this.$emit("combineConfirm", resData);
        this.$emit("update:modelValue", false);
      }
    },
    open() {
      this.reset();
      this.orderList = _.cloneDeep(this.checkList);
      this.initList();
    },
    reset() {
      this.leaderId = "";
      this.serverList = [];
      this.remark = "";
    },
    handleCurrentChange(val) {
      if (val) {
        this.unitId = val.id;
      } else {
        this.unitId = "";
      }
    },
    addItem(item) {
      if (this.serverList.find(cItem => cItem.person === item.person)) {
        return;
      }
      this.serverList.push(_.cloneDeep(item));
    },
    deleteItem(item) {
      if (this.leaderId === item.person) {
        this.leaderId = "";
      }
      this.serverList = this.serverList.filter((cItem, index) => cItem.person !== item.person);
    },
    setLeader(item) {
      this.leaderId = item.person;
    },
    isLeader(item) {
      return item.person === this.leaderId;
    },
    deleteOrderItem(item) {
      if (this.orderList.length === 1) {
        this.$message.warning("无法删除，至少选择一条工单指派");
        return;
      }
      this.orderList = this.orderList.filter((cItem, index) => cItem.id !== item.row.id);
    },
    getLabel(val, list) {
      let item = list.find((item) => item.val === val);
      if (item) {
        return item.label;
      } else {
        return val;
      }
    }
  }
});
</script>

<template>
  <el-dialog v-model="show" v-bind="$attrs" :title="title" top="5%" width="80%" destroy-on-close @opened="open">
    <div class="worker-dialog">
      <Divider title="工单信息"></Divider>
      <el-table :data="orderList" height="200">
        <el-table-column label="序号" width="80" align="center">
          <template #default="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <!--        <el-table-column label="工单类型" prop="workOrderType">-->
        <!--          <template #default="scope">-->
        <!--            {{ getLabel(scope.row.workOrderType, dictStore.getDictByType("workOrderType")) }}-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <!--        <el-table-column label="业务类型" prop="bizType">-->
        <!--          <template #default="scope">-->
        <!--            {{ getLabel(scope.row.bizType, dictStore.getDictByType("bizType")) }}-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <el-table-column label="工单编号" prop="code" />
        <el-table-column label="客户名称" prop="consumerName" />
        <el-table-column label="联系电话" prop="contactPhone" />
        <el-table-column label="区" prop="district" />
        <el-table-column v-if="isEngineering" label="联系地址" prop="address" />
        <el-table-column v-if="isMaintenance" label="联系地址" prop="consumerAddress" />
        <el-table-column label="操作" width="100" align="center">
          <template #default="scope">
            <el-button
              link
              type="danger"
              size="small"
              @click.stop="deleteOrderItem(scope)"
            >移除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <Divider title="服务人员"></Divider>
      <div class="worker-body">
        <div class="worker-unit">
          <el-tree
            ref="tree"
            :data="unitList"
            empty-text="暂无数据"
            nodeKey="id"
            highlight-current
            default-expand-all
            @current-change="handleCurrentChange"
          >
            <template #default="item">
              <div>{{ item.data.name }}</div>
            </template>
          </el-tree>
        </div>
        <div class="worker-table-wrapper">
          <div class="worker-search-wrapper">
            <el-input placeholder="输入员工名称搜索" v-model="searchContent"></el-input>
          </div>
          <div class="worker-tables">
            <el-table class="worker-table" ref="table" :data="filterList" height="250">
              <el-table-column label="序号" width="80" align="center" fixed>
                <template #default="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>
              <el-table-column label="员工名称" prop="name" />
              <el-table-column label="今日派单数" prop="today" />
              <el-table-column label="未完成工单" prop="undone" />
              <el-table-column label="操作" width="80" align="center">
                <template #default="scope">
                  <el-button link type="primary" size="small" @click.stop="addItem(scope.row)"
                  >添加
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-table class="worker-table-checked" ref="table" :data="serverList" height="250">
              <el-table-column label="序号" width="80" align="center" fixed>
                <template #default="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>
              <el-table-column label="员工名称" prop="name">
                <template #default="scope">
                  <div>
                    {{ scope.row.name }}
                    <el-tag v-if="isLeader(scope.row)">负责人</el-tag>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="部门名称" prop="unitName" />
              <el-table-column label="操作" width="150" align="center">
                <template #default="scope">
                  <el-button link type="primary" size="small" @click.stop="setLeader(scope.row)"
                             v-if="!isLeader(scope.row)"
                  >设为负责人
                  </el-button>
                  <el-button link type="danger" size="small" @click.stop="deleteItem(scope.row)"
                  >删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <Divider title="备注信息"></Divider>
      <el-input type="textarea" v-model="remark"></el-input>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleCancelClick">取消</el-button>
        <el-button type="primary" @click="handleDispatchClick">派工</el-button>
        <el-button type="primary" @click="handleCombineDispatchClick"
                   v-if="combineDispatch && canCombineDispatch">合并派工</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<style scoped lang="scss">
.worker-dialog {
  & > :deep(*) {
    width: 100%;
  }

  .radio {
    :deep(.dubhe-radio__label) {
      display: none;
    }
  }

  .worker-header {
    padding-bottom: 10px;

    .dubhe-input {
      width: 200px;
    }
  }

  .worker-body {
    display: flex;
    //height: 300px;

    & > * {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    .worker-unit {
      height: 100%;
      width: 200px;
      overflow: auto;
    }

    .worker-tables {
      display: flex;
    }

    .worker-table-wrapper {
      flex: 1;
    }

    .worker-table {
      flex: 1;
      height: 100%;
    }

    .worker-table-checked {
      flex: 1;
      height: 100%;
      margin-left: 10px;
    }
  }

  .worker-search-wrapper {
    margin-bottom: 10px;

    .dubhe-input {
      width: 200px;
    }
  }

  //.worker-wrapper {
  //  display: flex;
  //  .worker-table {
  //    flex: 1;
  //  }
  //}
}
</style>