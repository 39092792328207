<script>
import { defineComponent } from 'vue'
import { detailMixins } from '@/common/detailMixins'
import BaseInfoEdit from '@/views/WorkOrder/BaseInfoEdit.vue'
import { workOrderEditMixin } from "@/views/WorkOrder/workOrderEditMixins";

export default defineComponent({
  // 退货工单
  name: 'returnGoodsWorkOrderEdit',
  components: { BaseInfoEdit },
  mixins: [detailMixins,workOrderEditMixin],
  data() {
    return {}
  },
  computed: {
    rules() {
      let rules = {
        consumerId: [{ required: true, message: '请选择客户', trigger: 'change' }],
        district: [{ required: true, trigger: 'change', message: '请选择区' }],
        address: [{ required: true, trigger: 'change', message: '请输入详细地址' }],
        workOrderSource: [{ required: true, message: '请选择信息来源', trigger: 'change' }],
        bizType: [{ required: true, message: '请选择业务类型', trigger: 'change' }],
        workNum: [{ required: true, trigger: 'change', message: '请输入工单数' }],
        receptionDeptId: [{ required: true, message: '请选择接单部门', trigger: 'change' }],
        usedMaterials: [
          {
            validator: (rule, value, callback) => {
              if (value.length > 0) {
                if (value.some((item) => !item.materialId)) {
                  callback(new Error('请选择材料'))
                }
                if (value.some((item) => !item.num)) {
                  callback(new Error('请输入数量'))
                }
              }
              callback();
            }
          }
        ]
      }
      if (this.batch) {
        delete rules.district
        delete rules.address
        delete rules.consumerId
      } else {
        delete rules.receptionDeptId
      }
      return rules
    }
  },
  watch: {},
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.baseForm.workOrderType = 'return_goods'
      this.initMaterialList()
    }
  }
})
</script>
<template>
  <el-drawer
    v-model="showDrawer"
    size="95%"
    direction="btt"
    destroy-on-close
    @open="getDetail"
  >
    <template #header>
      <div>{{ title }}</div>
    </template>
    <template #default>
      <div class="detail-wrapper" v-loading="loading">
        <el-form :model="editForm" :label-position="labelPosition" :rules="rules" ref="form">
          <el-row :gutter="20">
            <BaseInfoEdit v-model:form="editForm" :batch="batch">
              <template #other>
                <el-col :span="24">
                  <Divider title="材料信息"></Divider>
                  <el-form-item prop="usedMaterials">
                    <TableEdit
                      title="材料明细"
                      :table-option="materialOption"
                      :list="editForm.usedMaterials"
                    ></TableEdit>
                  </el-form-item>
                </el-col>
              </template>
            </BaseInfoEdit>
          </el-row>
        </el-form>
      </div>
    </template>
    <template #footer>
      <div class="drawer-footer">
        <el-button
          @click="handleResetClick"
          :disabled="loading"
          v-if="showByAuditStatus(['pending_submission'])"
          >重置
        </el-button>
        <el-button type="primary" @click="handleSaveClick(false)" :disabled="loading"
          >保存</el-button
        >
        <el-button
          type="primary"
          @click="handleSaveClick(true)"
          icon="Promotion"
          :disabled="loading"
          v-if="showByAuditStatus(['pending_submission'])"
          >提交
        </el-button>
      </div>
    </template>
  </el-drawer>
</template>
<style scoped lang="scss"></style>