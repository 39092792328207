<script>
import { defineComponent } from 'vue'
import _ from 'lodash'

export default defineComponent({
  name: 'SearchTree',
  emits: ['currentChange'],
  data() {
    return {
      open: true,
      searchContent: ''
    }
  },
  props: {
    list: {
      required: true,
      type: Array
    },
    width: {
      default() {
        return '25%'
      }
    },
    border: {
      type: Boolean,
      default() {
        return false
      }
    },
    clickNode: {
      type: Boolean,
      default() {
        return false
      }
    },
    placeholder: {
      type: String,
      default() {
        return '请输入内容'
      }
    },
    defaultProps: {
      default() {
        return {
          label: 'name',
          children: 'children'
        }
      }
    },
    nodeKey: {
      default() {
        return 'id'
      }
    },
    currentNodeKey: {}
  },
  computed: {
    wrapperStyle() {
      if (this.open) {
        return {
          width: _.isNumber(this.width) ? `${this.width}px` : this.width
        }
      } else {
        return {
          width: `0`,
          padding: '0'
        }
      }
    }
  },
  watch: {
    currentNodeKey: {
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            this.$refs.tree.setCurrentKey(val)
          })
        }
      },
      immediate: true
    }
  },
  methods: {
    handleToggleClick() {
      this.open = !this.open
    },
    handleCurrentChange(data) {
      this.$emit('currentChange', data)
    },
    filterNode(value, data, node) {
      if (!value) return true
      if (node.level === 1) {
        return node.label.includes(value)
      } else {
        return node.label.includes(value) || node.parent.visible
      }
    },
    filterChange() {
      this.$refs.tree.filter(this.searchContent)
    }
  }
})
</script>

<template>
  <div class="search-tree" :style="wrapperStyle" :class="{ open: open, border: border }">
    <div class="tree-wrapper">
      <slot name="header"></slot>
      <el-input
        class="tree-search"
        v-model="searchContent"
        :placeholder="placeholder"
        prefix-icon="Search"
        @change="filterChange"
      />
      <slot name="header-bottom"></slot>
      <el-tree
        ref="tree"
        class="tree"
        :data="list"
        empty-text="暂无数据"
        :props="defaultProps"
        :nodeKey="nodeKey"
        highlight-current
        :expand-on-click-node="clickNode"
        :filter-node-method="filterNode"
        default-expand-all
        @current-change="handleCurrentChange"
        v-bind="$attrs"
      >
        <template #default="item">
          <slot v-bind="item">
<!--            <div>{{ item.data[defaultProps.label] }}</div>-->
            <el-tooltip :content="item.data[defaultProps.label]">
              <el-text truncated>{{ item.data[defaultProps.label] }}</el-text>
            </el-tooltip>
          </slot>
        </template>
      </el-tree>
    </div>
    <div class="switch" @click="handleToggleClick">
      <el-icon>
        <ArrowRight />
      </el-icon>
    </div>
  </div>
</template>

<style scoped lang="scss">
.search-tree {
  position: relative;
  flex-shrink: 0;
  border-right: 1px solid var(--dubhe-border-color);
  padding: 0 10px 0 0;
  z-index: 10;

  &.border {
    border: 1px solid var(--dubhe-border-color);
  }

  &.open {
    .dubhe-icon {
      transform: rotate(180deg);
    }
  }

  :deep(.dubhe-tree-node) {
    cursor: pointer;
  }

  :deep(.is-current) {
    .dubhe-tree-node__content {
      color: #55b76d;
    }
  }

  .tree-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;

    & > * {
      width: 100%;
    }

    .tree-search {
      margin-bottom: 10px;
    }

    .tree {
      //margin-top: 10px;
      flex: 1;
      overflow: auto;
    }
  }

  .switch {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(100%, -50%);
    height: 80px;
    width: 20px;
    background: var(--dubhe-fill-color-darker);
    border-radius: 0 40px 40px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
