<script>
import { defineComponent } from 'vue'
import * as userService from '@/assets/image/map/user_service.png'
import * as userDispatch from '@/assets/image/map/user_dispatch.png'
import * as userDefault from '@/assets/image/map/user_default.png'
import * as orderDefault from '@/assets/image/map/order_default.png'
import * as orderDispatch from '@/assets/image/map/order_dispatch.png'
import * as orderService from '@/assets/image/map/order_service.png'
import { getPersonByRole, getViewBundle, getViewExecute } from '@/axios'

import UserDialog from './UserDialog.vue'
import OrderDialog from './OrderDialog.vue'

// 不允许proxy代理
let map = null
let markerLayer = null
let zoom = 14

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'MapHome',
  components: { UserDialog, OrderDialog },
  data() {
    return {
      workOrderHandlerId: 'WorkOrderHandlerSystemRole',
      workOrderViewId: 'DD_WORK_ORDER_CIVIL_02',
      workOrderCount: 99999,
      workOrderKey: '',
      workOrderValueList: [],
      workOrderList: [],
      userList: [],
      trajectoryShow: false,
      userShow: false,
      orderShow: false,

      positionList: [
        {
          label: '全部定位',
          value: 'all'
        },
        {
          label: '人员定位',
          value: 'user'
        },
        {
          label: '工单定位',
          value: 'order'
        }
      ],
      positionActive: 'user',
      date: null,

      userData: {},
      orderData: {}
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      await this.mapStore.loadMapScript()
      this.initMap()
    },
    initMap() {
      //定义地图中心点坐标
      let latitude = this.mapStore.latitude
      let longitude = this.mapStore.longitude
      let TMap = window.TMap
      let defaultMarkStyle = {
        width: 30,
        height: 30,
        //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
        anchor: { x: 15, y: 15 },
        direction: 'bottom',
        offset: { x: 0, y: 8 }, // 标注点文本文字基于direction方位的偏移属性
        strokeColor: '#fff', // 标注点文本描边颜色
        strokeWidth: 2 // 标注点文本描边宽度
      }
      let center = new TMap.LatLng(latitude, longitude)
      //定义map变量，调用 TMap.Map() 构造函数创建地图
      map = new TMap.Map(this.$refs.container, {
        center: center, //设置地图中心点坐标
        zoom: zoom, //设置地图缩放级别
        pitch: 0, //设置俯仰角
        viewMode: '3D',
        // mapStyleId:'style1',
        // showControl: false,
        baseMap: {			//底图设置（参数为：VectorBaseMap对象）
          type: 'vector',	//类型：失量底图
          // type: 'traffic',	//类型：失量底图
          features: ['base', 'building2d','building3d','label','point']
          //仅渲染：道路及底面(base) + 2d建筑物(building2d)，以达到隐藏文字的效果
        }
        // rotation: 45 //设置地图旋转角度
      })
      //创建并初始化MultiMarker
      markerLayer = new TMap.MultiMarker({
        map: map, //指定地图容器
        //样式定义
        styles: {
          //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
          userServiceStyle: new TMap.MarkerStyle({
            src: userService.default,
            ...defaultMarkStyle
          }),
          userDispatchStyle: new TMap.MarkerStyle({
            src: userDispatch.default,
            ...defaultMarkStyle
          }),
          userDefaultStyle: new TMap.MarkerStyle({
            src: userDefault.default,
            ...defaultMarkStyle
          }),
          orderDefaultStyle: new TMap.MarkerStyle({
            src: orderDefault.default,
            ...defaultMarkStyle
          }),
          orderDispatchStyle: new TMap.MarkerStyle({
            src: orderDispatch.default,
            ...defaultMarkStyle
          }),
          orderServiceStyle: new TMap.MarkerStyle({
            src: orderService.default,
            ...defaultMarkStyle
          })
        },
        //点标记数据数组
        geometries: []
      })
      markerLayer.on('click', this.labelClick)
      this.initPersonList()
      // this.initWorkOrderList()
    },
    async initPersonList() {
      await getPersonByRole(this.workOrderHandlerId).then((res) => {
        if (res) {
          this.userList = res.data
          console.log(this.userList)
          this.setUserMarkerData()
        }
      })
    },
    initWorkOrderList() {
      // TODO:如需重新启用，接自建表工单
      // let reqData = {
      //   count: this.workOrderCount,
      //   filterList: [
      //     {
      //       logic: 'or',
      //       path: 'status',
      //       title: '工单状态',
      //       comparison: 'equals',
      //       comparisonTitle: '等于',
      //       value: '未派工',
      //       formatType: 'textValue'
      //     },
      //     {
      //       logic: 'or',
      //       path: 'status',
      //       title: '工单状态',
      //       comparison: 'equals',
      //       comparisonTitle: '等于',
      //       value: '已派工',
      //       formatType: 'textValue'
      //     },
      //     {
      //       logic: 'or',
      //       path: 'status',
      //       title: '工单状态',
      //       comparison: 'equals',
      //       comparisonTitle: '等于',
      //       value: '服务中',
      //       formatType: 'textValue'
      //     }
      //   ]
      // }
      // if (this.date) {
      //   let dateFilter = [
      //     {
      //       logic: 'and',
      //       path: 'appointmentDate',
      //       title: '上门时间',
      //       comparison: 'greaterThanOrEqualTo',
      //       comparisonTitle: '大于',
      //       formatType: 'dateValue',
      //       value: this.date[0]
      //     },
      //     {
      //       logic: 'and',
      //       path: 'appointmentDate',
      //       title: '上门时间',
      //       comparison: 'lessThanOrEqualTo',
      //       comparisonTitle: '小于',
      //       formatType: 'dateValue',
      //       value: this.date[1]
      //     }
      //   ]
      //   reqData.filterList.push(...dateFilter)
      // }
      // getViewBundle(this.workOrderViewId, reqData)
      //   .then((res) => {
      //     if (res) {
      //       this.workOrderKey = res.data.key
      //       this.workOrderValueList = res.data.valueList
      //     }
      //   })
      //   .catch(()=>{})
      //   .finally(() => {
      //     if (this.workOrderValueList.length === 0) {
      //       return
      //     }
      //     let reqDataExecute = {
      //       key: this.workOrderKey,
      //       bundleList: this.workOrderValueList
      //     }
      //     getViewExecute(this.workOrderViewId, reqDataExecute)
      //       .then((res) => {
      //         if (res) {
      //           this.workOrderList = res.data.grid.map((item) => {
      //             return item.data
      //           })
      //           this.setWorkOrderMarkerData()
      //         }
      //       })
      //       .catch(()=>{})
      //       .finally(() => {})
      //   })
    },
    setUserMarkerData() {
      let TMap = window.TMap
      let filterList = this.userList.filter((item) => {
        if (!item.attributes) {
          return false
        }
        return item.attributes.some((item) => {
          return (item.name === 'location' && item.attributeList.length > 0)
        })
      })
      let list = filterList.map((item, index) => {
        let userLocation = item.attributes.find((item) => item.name === 'location').attributeList
        userLocation = userLocation[0].split(',')
        let latitude = Number(userLocation[0])
        let longitude = Number(userLocation[1])
        return {
          id: item.id,
          styleId: 'userServiceStyle', //指定样式id
          position: new TMap.LatLng(latitude, longitude), //点标记坐标位置
          content: item.name,
          rank: 10,
          userData: item
        }
      })
      markerLayer.add(list)
    },
    setWorkOrderMarkerData() {
      let TMap = window.TMap
      let styleMap = {
        未派工: 'orderDefaultStyle',
        已派工: 'orderDispatchStyle',
        服务中: 'orderServiceStyle'
      }
      let list = this.workOrderList
        .filter((item) => item.location)
        .map((item) => {
          let location = item.location.split(',')
          let latitude = Number(location[0])
          let longitude = Number(location[1])
          return {
            id: item.id,
            styleId: styleMap[item.status], //指定样式id
            position: new TMap.LatLng(latitude, longitude),
            content: item.customerName,
            rank: 10,
            orderData: item
          }
        })
      markerLayer.add(list)
    },
    labelClick(res) {
      let { geometry } = res
      if (geometry.userData) {
        this.userData = geometry.userData
        this.orderShow = false
        this.userShow = true
      } else {
        this.orderData = geometry.orderData
        this.userShow = false
        this.orderShow = true
        // this.userShow = true
      }
    },
    positionClick(item) {
      if (item) {
        this.positionActive = item.value
      }
      this.clearMarker()
      switch (this.positionActive) {
        case 'user':
          this.initPersonList()
          break
        case 'order':
          this.initWorkOrderList()
          break
        case 'all':
          this.initPersonList()
          this.initWorkOrderList()
          break
        default:
          break
      }
    },
    positionColor(item) {
      if (item.value === this.positionActive) {
        return '#3B76EE'
      }
    },
    clearMarker() {
      markerLayer.setGeometries([])
    },
    dateChange() {
      console.log(this.date)
      this.positionClick()
    }
  }
})
</script>

<template>
  <div class="map-wrapper">
    <div ref="container" class="map-container"></div>
<!--    <div class="content-filter">-->
<!--      <el-button-->
<!--        :color="positionColor(item)"-->
<!--        v-for="(item, index) in positionList"-->
<!--        size="large"-->
<!--        :key="index"-->
<!--        @click="positionClick(item)"-->
<!--      >-->
<!--        {{ item.label }}-->
<!--      </el-button>-->
<!--      <DatePicker-->
<!--        type="daterange"-->
<!--        v-model="date"-->
<!--        size="large"-->
<!--        clearable-->
<!--        range-separator="至"-->
<!--        start-placeholder="开始日期"-->
<!--        end-placeholder="结束日期"-->
<!--        @change="dateChange"-->
<!--      ></DatePicker>-->
<!--    </div>-->
<!--    <UserDialog v-model:show="userShow" :user-data="userData"></UserDialog>-->
<!--    <OrderDialog v-model:show="orderShow" :order-data="orderData"></OrderDialog>-->
  </div>
</template>

<style scoped lang="scss">
.map-wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  & > * {
    z-index: 2;
  }

  .map-container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .content-filter {
    position: absolute;
    top: 20px;
    left: 20px;
    display: flex;
    align-items: center;

    & > * {
      margin-left: 12px;

      & + * {
      }
    }
    :deep(button){
      height: auto!important;
    }

    .check-button {
      color: #4b4949;
      width: 115px;
      height: 40px;
      background: #ffffff;
      border-radius: 7px;
      margin-left: 14px;
      border: 1px;
      border-color: #e3e3e3;

      &.active {
        color: #ffffff;
        background: #3b76ee;
        border-radius: 7px;
        border: none;
      }
    }

    :deep(.dubhe-date-editor) {
      margin-left: 12px;
    }
  }
}
</style>
