<script>
import { defineComponent } from 'vue'

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Drawer',
  props: {
    title: {
      type: String
    },
    show: {
      type: Boolean,
      required: true
    },
    direction:{
      type: String,
      default() {
        return 'btt'
      }
    },
    size: {
      type: String,
      default() {
        return '90%'
      }
    },
    hiddenFooter:{
      type: Boolean,
      default() {
        return false
      }
    }
  },
  computed: {
    showDrawer: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    }
  },
  methods:{
    handleCancelClick(){
      this.showDrawer = false
    },
    handleConfirmClick(){
      this.$emit('callback')
      this.showDrawer = false
    }
  }
})
</script>

<template>
  <el-drawer v-model="showDrawer" :size="size" :direction="direction" v-bind="$attrs">
    <template #header>
      <div>{{ title }}</div>
    </template>
    <template #default>
      <slot></slot>
    </template>
    <template #footer v-if="!hiddenFooter">
      <div style="flex: auto">
        <el-button @click="handleCancelClick">取消</el-button>
        <el-button type="primary" @click="handleConfirmClick">确认</el-button>
      </div>
    </template>
  </el-drawer>
</template>

<style scoped lang="scss"></style>