<script>
import { defineComponent } from 'vue'
import _ from "lodash";

export default defineComponent({
  name: 'MessageBox',
  emits: ['update:modelValue'],
  data() {
    return {}
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    show: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    }
  },
  mounted() {},
  beforeUnmount() {},
  methods: {
    deleteItem(index) {
      this.messageStore.deleteMessage(index)
    },
    clear(){
      this.messageStore.clearMessage()
    },
    hasBody(item){
      return item.body && _.isObject(item.body)
    },
    itemClick(item) {
      if(this.hasBody(item)){
        this.componentStore.showProcessDetail(item.body)
        this.show = false
      }
    }
  }
})
</script>

<template>
  <el-drawer
    class="message-box-class"
    v-model="show"
    size="400"
    title="消息列表"
    direction="rtl"
    destroy-on-close
  >
    <div class="message-box-wrapper">
      <div class="message-box">
        <div
          class="message-box-item"
          v-for="(item, index) in messageStore.messageList"
          :key="index"
          @click.stop="itemClick(item)"
        >
          <div class="message-box-title">
            <span class="title-content" v-if="hasBody(item)">待办提醒</span>
            <span class="title-content" v-else>消息提醒</span>
            <el-icon @click.stop="deleteItem(index)">
              <Close />
            </el-icon>
          </div>
          <div class="message-box-content">{{ item.title }}</div>
          <div v-if="hasBody(item)">
            <span class="message-box-label">应用：</span>
            <span class="message-box-text-danger">{{ item.body.applicationName }}；</span>
            <span class="message-box-label">流程：</span>
            <span class="message-box-text-danger">{{ item.body.processName }}</span>
          </div>
          <div>{{ item.time }}</div>
        </div>
      </div>
      <div class="message-box-buttons">
        <el-button type="primary" @click="clear">清除消息</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<style scoped lang="scss">
.message-box-wrapper {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.message-box-buttons {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message-box {
  height: calc(100% - 50px);
  width: 100%;
  overflow: auto;

  .message-box-item {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    line-height: 1.5;
    border-bottom: 1px solid #cccccc;
    cursor: pointer;

    .message-box-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;

      .title-content {
        color: #4387cd;
      }
    }

    .message-box-label {
      font-weight: bold;
    }

    .message-box-text-danger {
      color: #ff0000;
    }
  }
}
</style>