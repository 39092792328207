<script>
import { defineComponent } from "vue";
import SelectV2 from "../SelectMultiple/SelectV2.vue";
import { getExtField, saveExtField } from "@/axios";
import EditListDialog from "./EditListDialog.vue";

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: "ExtFieldDialog",
  components: { SelectV2, EditListDialog },
  emits: ["update:modelValue", "confirm"],
  data() {
    return {
      fieldList: [],
      showEditListDialog: false,
      editItem: { items: "" }
    };
  },
  props: {
    modelValue: {
      type: Boolean
    },
    formId: {
      type: String,
      required: true
    }
  },
  computed: {
    show: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    }
  },
  methods: {
    open() {
      this.getExtField();
    },
    getExtField() {
      getExtField(this.formId).then((res) => {
        this.fieldList = res.data;
        console.log(this.fieldList);
      }).catch(() => {
      });
    },
    handleCancelClick() {
      this.show = false;
    },
    handleConfirmClick() {
      if (!this.checkFieldList()) {
        return;
      }
      let reqData = {
        formId: this.formId,
        definitions: this.fieldList
      };
      saveExtField(reqData).then(res => {
        this.$emit("confirm");
        this.show = false;
      }).catch(() => {
      });
    },
    checkFieldList() {
      let nameMap = {};
      let nameRepeat = false
      let errorList = [];
      let reg = /^[a-zA-Z_\u4e00-\u9fa5][a-zA-Z0-9_\u4e00-\u9fa5]*/;
      this.fieldList.forEach((item, index) => {
        if(nameMap[item.fieldName]){
          nameRepeat = true
        }
        nameMap[item.fieldName] = true
        if (!item.fieldName) {
          errorList.push(`第${index + 1}条未填写名称`);
        } else if (!reg.test(item.fieldName)) {
          errorList.push(`第${index + 1}条名称不符合规则`);
        }
      });
      if(nameRepeat){
        this.$message.warning('名称重复，请编辑')
        return false
      }
      if (errorList.length > 0) {
        let errorMessage = errorList.join("<br>");
        this.$message({
          dangerouslyUseHTMLString: true,
          type: "warning",
          message: errorMessage
        });
      }
      return errorList.length <= 0;
      // let check = this.fieldList.every(item => {
      //   if(!item.fieldName){
      //     return false
      //   }
      //   let reg = /^[a-zA-Z_\u4e00-\u9fa5][a-zA-Z0-9_\u4e00-\u9fa5]*/
      //   return reg.test(item.fieldName)
      // });
      // if (!check) {
      //   this.$message.warning("请填写完整名称，并确认名称符合规则");
      // }
      // return check;
    },
    handleAddClick() {
      let newItem = {
        fieldName: "",
        fieldType: "string",
        items: "",
        sequence: this.fieldList.length > 0 ? this.fieldList[this.fieldList.length - 1].sequence + 1 : 0
      };
      this.fieldList.push(newItem);
    },
    handleDeleteClick(scope) {
      this.fieldList = this.fieldList.filter((item, index) => index !== scope.$index);
    },
    handleEditListClick(scope) {
      this.editItem = scope.row;
      this.showEditListDialog = true;
    },
    editListCallback(val) {
      this.editItem.items = val;
    }
  }
});
</script>

<template>
  <el-dialog v-model="show" v-bind="$attrs" title="扩展字段" width="80%" destroy-on-close @open="open">
    <div>
      <el-alert title="名称只能包含字母、数字、下划线、中文，并且不能以数字开头。" type="warning" :closable="false" />
      <el-table class="table" :data="fieldList" stripe border empty-text="暂无数据" v-bind="$attrs">
        <el-table-column label="名称" align="center" min-width="100">
          <template #default="scope">
            <el-input v-model="scope.row.fieldName" :disabled="scope.row.id"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="字段类型" align="center" min-width="100">
          <template #default="scope">
            <SelectV2 :list="dictStore.getDictByType('extFieldType')" v-model="scope.row.fieldType" value-key="val"
                      label-key="label" :clearable="false"></SelectV2>
          </template>
        </el-table-column>
        <el-table-column label="子项" align="center" min-width="200">
          <template #default="scope">
            <div class="flex-center" v-if="scope.row.fieldType === 'radio'">
              <div>{{ scope.row.items }}</div>
              <el-button type="primary" link icon="Edit" @click="handleEditListClick(scope)"></el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="80">
          <template #header="scope">
            <el-button type="primary" link icon="Plus" @click="handleAddClick"></el-button>
          </template>
          <template #default="scope">
            <el-button type="danger" link icon="Delete" @click="handleDeleteClick(scope)"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleCancelClick">取消</el-button>
        <el-button type="primary" @click="handleConfirmClick">确认</el-button>
      </span>
    </template>
  </el-dialog>
  <EditListDialog v-model="showEditListDialog" :items="editItem.items" @confirm="editListCallback"></EditListDialog>
</template>

<style scoped lang="scss">
.dubhe-alert {
  margin-bottom: 10px;
}

.table {
  :deep(td.dubhe-table__cell) {
    padding: 0;

    &:first-child {
      .cell {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .cell {
      padding: 0;

      & > .text {
        display: flex;
        align-items: center;
        min-height: 30px;
      }

      &:has(.dubhe-textarea) {
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;

        .dubhe-textarea {
          position: absolute;
          top: 0;
          bottom: 0;
          height: 100%;
          width: 100%;
          border: 1px solid transparent;
          transition: 0.3s;

          &:hover {
            border: 1px solid #4a90e2;
          }

          textarea {
            height: 100%;
            box-shadow: none;
            border-radius: 0;
          }
        }
      }

      .cell-content, .total {
        font-weight: bold;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .dubhe-input {
        width: 100%;
        border-width: 0;

        .dubhe-input__wrapper {
          border-radius: 0;
          box-shadow: 0 0 0 0;
          border: 1px solid transparent;
          transition: 0.3s;

          &:hover {
            border: 1px solid #4a90e2;
          }
        }
      }

      .dubhe-input-group__append {
        border-radius: 0;
        box-shadow: 0 0 0 0;
      }

      .dubhe-select {
        width: 100%;
      }

      .dubhe-button {
        border-radius: 0;
        border-width: 0;
      }

      .center-component {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .custom {
        height: 100%;
        display: flex;
        align-items: center;
        width: 100%;

        &:has(.dubhe-tag) {
          padding: 0 10px;
        }

        &:has(.dubhe-select) {
          & > * {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>