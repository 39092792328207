<script>
import { defineComponent } from 'vue'
import { detailMixins } from '@/common/detailMixins'
import {
  editWorkOrderComplain,
  getWorkOrderComplainDetail,
  addWorkOrderComplain,
  getWorkOrderPage
} from '@/axios'
import _ from 'lodash'

export default defineComponent({
  name: 'WorkOrderComplainEdit',
  mixins: [detailMixins],
  components: {},
  inject: ['phone'],
  props: {
    editId: {}
  },
  data() {
    return {
      labelPosition: 'top',
      editForm: {
        id: null,
        workOrderId: null,
        workOrderCode: '',
        materialName: '',
        consumerName: '',
        date: '',
        complaintType: '',
        content: '',
        result: '',
        // resultTime: "",
        complaintWay: '',
        opinion: '',
        createAt: '',
        createBy: '',
        creator: '',
        updateAt: '',
        updateBy: '',
        updater: ''
      },
      baseForm: {
        id: null,
        workOrderId: null,
        workOrderCode: '',
        materialName: '',
        consumerName: '',
        date: this.getTime(),
        complaintType: '',
        content: '',
        result: '',
        // resultTime: "",
        complaintWay: '',
        opinion: '',
        createAt: '',
        createBy: '',
        creator: '',
        updateAt: '',
        updateBy: '',
        updater: ''
      },
      rules: {
        // consumerId: [
        //   { required: true, message: "请选择客户", trigger: "change" }
        // ],
        // workOrderId: [
        //   { required: true, message: "请选择反馈工单", trigger: "change" }
        // ],
        date: [
          { required: true, message: '请选择反馈时间', trigger: 'change' }
        ],
        complaintWay: [
          { required: true, message: '请选择反馈渠道', trigger: 'change' }
        ],
        // complaintContentSelect: [
        //   { required: true, message: "请选择反馈内容", trigger: "change" }
        // ],
        result: [
          { required: true, message: '请选择反馈类型', trigger: 'change' }
        ],
        complaintType: [
          { required: true, message: '请输入反馈类型', trigger: 'change' }
        ]
      },
      workOrderConditions: [{
        operator: 'in',
        params: ['completed', 'pending_return_visit', 'closed'],
        field: 'workOrderStatus',
        alias: ''
      }]
    }
  },
  computed: {
    isAdd() {
      return !this.editForm.id
    },
    title() {
      if (this.isAdd) {
        return '客户反馈记录（新增）'
      } else {
        return '客户反馈记录（编辑）'
      }
    }
  },
  watch: {},
  methods: {
    close() {
      this.$message.success('保存成功')
      this.showDrawer = false
      this.$emit('callback', this.isAdd)
    },
    editConfirm() {
      this.$emit('confirm', this.editForm)
    },
    getDetail() {
      this.loading = true
      if (this.editId) {
        this.workOrderConditions[1] = {}
        getWorkOrderComplainDetail({ id: this.editId }).then((res) => {
          this.editForm = res.data
          this.workOrderConditions[1] = {
            alias: 'c',
            field: 'id',
            operator: 'eq',
            params: [
              this.editForm.consumerId
            ]
          }
          this.editForm.leaderName = res.data.leaderName
          this.editForm.resultTime = res.data.resultTime
          this.editForm.materialName = res.data.materialName
        })
          .catch(() => {
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.editForm = _.cloneDeep(this.baseForm)
        // TODO:根据电话客户，自动赋值
        // if (this.phone) {
        //   this.workOrderConditions[1] = {
        //     alias: "c",
        //     field: "contactPhone",
        //     operator: "eq",
        //     params: [
        //       this.phone
        //     ]
        //   };
        // }
        this.loading = false
      }
    },
    async handleSaveClick() {
      let saveMethod
      if (this.isAdd) {
        saveMethod = addWorkOrderComplain
      } else {
        saveMethod = editWorkOrderComplain
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          saveMethod(this.editForm)
            .then((res) => {
              if (res) {
                this.loading = false
                this.close()
              }
            })
            .catch(() => {
              this.loading = false
            })
        }
      })
    },
    // 选择工单下拉
    selectWorkOrderChange(val) {
      console.log('输出打印客户反馈工单val', val)
      if (val) {
        this.editForm.workOrderCode = val.code
        // 机器型号
        this.editForm.materialName = val.materialName
        // 服务人员
        this.editForm.leaderName = val.leaderName
        // 完工时间
        this.editForm.resultTime = val.visitDate
        // 反馈日期
        this.editForm.returnVisitDate = val.returnVisitDate
      }
    },
    consumerChange(val) {
      console.log('输出打印客户名称的val', val)
      if (val) {
        this.editForm.consumerId = val.id
        this.editForm.district = val.district
        this.editForm.address = val.address
      }
      if (this.editForm.consumerId) {
        this.workOrderConditions[1] = {
          alias: 'c',
          field: 'id',
          operator: 'eq',
          params: [
            this.editForm.consumerId
          ]
        }
      } else {
        this.workOrderConditions[1] = {}
      }
      this.editForm.workOrderId = null
      this.editForm.workOrderCode = null
    },
    handelInputAddress(val) {
      console.log('输出打印模糊地址查询的输入的val', val)
    },
    // 添加地址模糊查询
    async getWorkOrderSelectListChange(searchValue) {
      let newConditions = []
      if (searchValue) {
        newConditions.push(
          {
            alias: "c",
            field: "name",
            operator: "like",
            params: [searchValue]
          },
          {
            alias: "w",
            field: "code",
            operator: "like",
            params: [searchValue]
          },
          {
            alias: 'w',
            field: 'address',
            operator: 'like',
            params: [searchValue]
          })
      }
      let reqData = {
        pageSize: 100,
        pageNum: 1,
        wrapper: {
          conditions: newConditions,
          and:this.workOrderConditions,
          operator: 'or'
        }
      }
      let res = await getWorkOrderPage(reqData)
      if (res) {
        return res.data
      } else {
        return []
      }
    }

  }
})
</script>
<template>
  <el-drawer v-model="showDrawer" size="95%" direction="btt" destroy-on-close @callback="editConfirm" @open="getDetail">
    <template #header>
      <div>{{ title }}</div>
    </template>
    <template #default>
      <div class="detail-wrapper">
        <Divider title="基本信息"></Divider>
        <el-form :model="editForm" :label-position="labelPosition" :rules="rules" ref="form" v-loading="loading">
          <el-row :gutter="20">
            <SelectConsumer
              :span="8"
              v-model="editForm"
              :keyMap="{
                    id: 'consumerId',
                    name: 'consumerName',
                  }"
              @change="consumerChange"
            ></SelectConsumer>
            <el-col :span="8">
              <el-form-item label="反馈工单">
                <SelectWithButton
                  v-model="editForm.workOrderId"
                  :remote-method="getWorkOrderSelectListChange"
                  labelKey="code"
                  :conditions="workOrderConditions"
                  light-label-key="contactPhone"
                  valueKey="id"
                  :default-label="editForm.workOrderCode"
                  dialogName="WorkOrder"
                  placeholder="请选择反馈工单"
                  :customFilterMethod="handelInputAddress"
                  @selectChange="selectWorkOrderChange"
                >
                </SelectWithButton>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="反馈渠道" prop="complaintWay">
                <SelectV2
                  v-model='editForm.complaintWay'
                  :list="dictStore.getDictByType('complaintWay')"
                  labelKey='label'
                  valueKey='val'
                ></SelectV2>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="反馈类型" prop="complaintType">
                <SelectV2
                  v-model="editForm.complaintType"
                  :list="dictStore.getDictByType('complaintType')"
                  labelKey="label"
                  valueKey="val"
                  placeholder="请选择反馈类型"
                ></SelectV2>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="反馈内容">
                <SelectV2
                  v-model="editForm.complaintContentSelect"
                  :list="dictStore.getDictByType('complaintContentSelect')"
                  labelKey="label"
                  valueKey="val"
                  placeholder="请选择反馈内容"
                ></SelectV2>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="反馈时间" prop="date">
                <DatePicker v-model="editForm.date" placeholder="请选择反馈时间"></DatePicker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="机器型号" prop="materialName">
                <el-input v-model="editForm.materialName" disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="原服务人员" prop="leaderName">
                <el-input v-model="editForm.leaderName" disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="完工时间">
                <el-input v-model="editForm.resultTime" disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="客户地址">
                <SelectAddress
                  v-model:district="editForm.district"
                  v-model:address="editForm.address"
                  placeholder="系统自动生成"
                  disabled
                ></SelectAddress>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="回访日期">
                <el-input v-model="editForm.returnVisitDate" disabled="true" placeholder="系统自动生成" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="客户意见">
                <el-input type="textarea" v-model="editForm.opinion" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="反馈详细信息">
                <el-input type="textarea" v-model="editForm.content" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注">
                <el-input type="textarea" v-model="editForm.remark" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </template>
    <template #footer>
      <div class="drawer-footer">
        <el-button type="primary" @click="handleSaveClick" icon="Document" :disabled="loading">保存</el-button>
      </div>
    </template>
  </el-drawer>
</template>
<style scoped lang="scss">
</style>