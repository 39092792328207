import axios from './axios.config'
// （维保工单绩效配置）
//获取结算方案显示字段
export const getSettlementSchemeMaintenanceFields = (tableId='settlementSchemeMaintenance') =>axios.get(`/jaxrs/settlement_strategy/maintenance/fields?tableId=${tableId}`)
//获取查询结算方案
export const getSettlementSchemeMaintenanceConditions = () =>axios.get('/jaxrs/settlement_strategy/maintenance/conditions')
// 查询
export const getSettlementSchemeMaintenancePage = (data) =>axios.post('/jaxrs/settlement_strategy/maintenance/page',data)
//详情
export const getSettlementSchemeMaintenanceDetail = (data) =>axios.get(`/jaxrs/settlement_strategy/${data.id}`)
//保存
export const addSettlementSchemeMaintenance = (data) =>axios.post(`/jaxrs/settlement_strategy`,data)
//修改
export const editSettlementSchemeMaintenance = (data) =>axios.put(`/jaxrs/settlement_strategy`,data)
// 删除
export const deleteSettlementSchemeMaintenance = (data) =>axios.delete(`/jaxrs/settlement_strategy/${data.id}`)
