<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ScreenCard",
  props:{
    icon:{},
    title:{}
  }
});
</script>

<template>
  <div class="screen-card">
    <div class="screen-card-header" v-if="title">
      <div class="flex-center">
<!--        <component class="screen-card-header-icon" size="22" :is="icon"></component>-->
        {{title}}
      </div>
    </div>
    <div class="screen-card-body">
      <div class="screen-card-body-absolute">
        <slot name="default"></slot>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.screen-card {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  margin-top: 20px;
  .screen-card-header {
    display: flex;
    align-items: center;
    height: 40px;
    //padding-left: 30px;
    padding-left: 9%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("../../assets/image/bigScreen/card_header3.png");
    //font-size: 22px;
    font-size: 18px;
    color: #fff;
    font-family: 'fangsong';
    &>*{
      font-family: 'fangsong';
    }
    .screen-card-header-icon{
      color: rgb(85, 185, 240);
      margin-right: 5px;
    }
  }
  .screen-card-body{
    flex: 1;
    position: relative;
    .screen-card-body-absolute{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      //background: #d8bb8c;
    }
  }
}
</style>
