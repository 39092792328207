import axios from './axios.config'
// 获取显示字段 invoiceCompany
export const getInvoiceCompanyFields = (tableId = 'invoiceCompany')=>axios.get(`/jaxrs/invoice_company/fields?tableId=${tableId}`)
// 获取查询条件
export const getInvoiceCompanyConditions = () =>axios.get('/jaxrs/invoice_company/conditions')
// 查询
export const getInvoiceCompanyPage = (data) =>axios.post('/jaxrs/invoice_company/page',data)
// 详情
export const getInvoiceCompanyDetail = (data) =>axios.get(`/jaxrs/invoice_company/${data.id}`)
// 保存
export const addInvoiceCompany = (data) =>axios.post(`/jaxrs/invoice_company`,data)
// 编辑
export const editInvoiceCompany = (data) =>axios.put(`/jaxrs/invoice_company`,data)
// 删除
export const deleteInvoiceCompany = (data) =>axios.delete(`/jaxrs/invoice_company/${data.id}`)

