import axios from './axios.config'
// 获取显示字段
export const getMaintenanceWorkOrderFields = (tableId='maintenanceWorkOrder')=>axios.get(`/jaxrs/maintenance/work_order/fields?tableId=${tableId}`)
// 获取查询条件
export const getMaintenanceWorkOrderConditions = ()=>axios.get('/jaxrs/maintenance/work_order/conditions')
// 查询
export const getMaintenanceWorkOrderPage = (data) =>axios.post('/jaxrs/maintenance/work_order/page',data)
// 详情
export const getMaintenanceWorkOrderDetail = (data) => {
  let url
  if (data.oper) {
    url = `/jaxrs/maintenance/work_order/${data.id}?oper=${data.oper}`
  } else {
    url = `/jaxrs/maintenance/work_order/${data.id}`
  }
  return axios.get(url)
}
// 编辑
export const editMaintenanceWorkOrder = (data) =>axios.put(`/jaxrs/maintenance/work_order`,data)
// 派工
export const assignMaintenanceWorkOrder = (data) =>axios.post('/jaxrs/maintenance/work_order/assign',data)
// 完工
export const completedMaintenanceWorkOrder = (data) =>axios.post('/jaxrs/maintenance/work_order/completed',data)
// 编辑维保日期
export const editMaintenanceWorkOrderMaintenanceDate = (data) =>axios.put('/jaxrs/maintenance/work_order/maintenance_date',data)
//导出
export const exportMaintenanceWorkOrder = (data) =>
  axios.post(`/jaxrs/maintenance/work_order/export`, data, {
    responseType: 'blob'
  })
// 维保工单合并派工
export const combineAssignMaintenanceWorkOrder = (data) =>axios.post('/jaxrs/maintenance/work_order/combine_assign',data)
//导出工单详情
export const exportMaintenanceWorkOrderDetail = (data) =>
  axios.get(`/jaxrs/maintenance/work_order/exportMaintenanceWorkOrder/${data.id}`, {
    responseType: 'blob'
  })
// 导入工单详情
export const importMaintenanceWorkOrderDetail = (data) => axios.post(`/jaxrs/maintenance/work_order/importMaintenanceWorkOrder`,data)
// 删除
export const deleteMaintenanceWorkOrder = (data) =>axios.delete(`/jaxrs/maintenance/work_order/${data.id}`)
// 撤回
export const withdrawMaintenanceWorkOrder = (data) =>axios.put(`/jaxrs/maintenance/work_order/withdraw/${data.id}`,data)
// 重新计算绩效
export const resetWageMaintenanceWorkOrder = (data) => axios.post(`/jaxrs/maintenance/work_order/reset_wage/${data.id}`,data)
// 查询维保图片
export const getMaintenanceWorkOrderImagesPage = (data) =>axios.post(`/jaxrs/maintenance/work_order/images/page/${data.id}`,data)
// 退回维保工单
export const drawbackMaintenanceWorkOrder = (data) =>axios.put(`/jaxrs/maintenance/work_order/drawback`,data)

