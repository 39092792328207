import axios from './axios.config'
// 工资条
// 获取展示列
export const getSalarySlipFields = (tableId='salarySlip')=>axios.get(`/jaxrs/salary_slip/fields?tableId=${tableId}`)
// 获取查询条件
export const getSalarySlipConditions = ()=>axios.get('/jaxrs/salary_slip/conditions')
// 查询
export const getSalarySlipPage = (data) =>axios.post('/jaxrs/salary_slip/page',data)
// 详情
export const getSalarySlipDetail = (data) =>axios.get(`/jaxrs/salary_slip/${data.id}`)
// 删除
export const deleteSalarySlip = (data) =>axios.delete(`/jaxrs/salary_slip/${data.id}`)
// 发送
export const sentSalarySlip = (data) =>axios.get(`/jaxrs/salary_slip/sent-salary/${data.id}`)
// 撤回发送
export const retractSalarySlip = (data) =>axios.get(`/jaxrs/salary_slip/retract-salary/${data.id}`)
// 预约发送
export const appointmentSalarySlip = (data) =>axios.post(`/jaxrs/salary_slip/appointment-salary`,data)
// 获取导入字段列表
export const getSalarySlipImportFields = ()=>axios.get('/jaxrs/salary_slip/import/fields')
// 导入工资条
export const importSalarySlip = (data) =>axios.post(`/jaxrs/salary_slip/import`,data)
