import axios from './axios.config'
// 获取材料显示字段
export const getMaterialRequisitionFields = (tableId='materialRequisition') => axios.get(`/jaxrs/material_requisition/fields?tableId=${tableId}`)
// 获取查询字段
export const getMaterialRequisitionConditions = () => axios.get('/jaxrs/material_requisition/conditions')
//获取列表分页数据
export const getMaterialRequisitionPage = (data) => axios.post('/jaxrs/material_requisition/page', data)
// 获取详情
export const getMaterialRequisitionDetail = (data) => axios.get(`/jaxrs/material_requisition/${data.id}`)
//添加（保存）
export const addMaterialRequisition = (data) =>axios.post(`/jaxrs/material_requisition`,data)
//编辑
export const editMaterialRequisition = (data) =>axios.put(`/jaxrs/material_requisition`,data)
//删除
export const deleteMaterialRequisition = (data) =>axios.delete(`/jaxrs/material_requisition/${data.id}`)
