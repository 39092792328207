import axios from './axios.config'
// 获取显示字段
export const getMaintenanceContractFields = (tableId='maintenanceContract')=>axios.get(`/jaxrs/maintenance/contract/fields?tableId=${tableId}`)
// 获取查询条件
export const getMaintenanceContractConditions = ()=>axios.get('/jaxrs/maintenance/contract/conditions')
// 查询
export const getMaintenanceContractPage = (data) =>axios.post('/jaxrs/maintenance/contract/page',data)
// 详情
export const getMaintenanceContractDetail = (data) => {
  let url
  if (data.oper) {
    url = `/jaxrs/maintenance/contract/${data.id}?oper=${data.oper}`
  } else {
    url = `/jaxrs/maintenance/contract/${data.id}`
  }
  return axios.get(url)
}
// 保存
export const addMaintenanceContract = (data) =>axios.post(`/jaxrs/maintenance/contract`,data)
// 编辑
export const editMaintenanceContract = (data) =>axios.put(`/jaxrs/maintenance/contract`,data)
// 删除
export const deleteMaintenanceContract = (data) =>axios.delete(`/jaxrs/maintenance/contract/${data.id}`)
// 获取维保合同根据商用项目ID
export const getMaintenanceContractByCommercialProject = (data)=>axios.get(`/jaxrs/maintenance/contract/project_id/${data.id}`)
// 下载维保合同
export const downloadMaintenanceContract = (data) => axios.post('/jaxrs/maintenance/contract/download',data,{
    responseType: 'blob'
  })
// 上传维保合同
export const uploadMaintenanceContract = (data) =>axios.post(`/jaxrs/maintenance/contract/attachment`,data)
// 修改收款计划
export const updateCollectionPlan = (data) =>axios.put(`/jaxrs/maintenance/contract/update_collection_plan`,data)
// 撤回
export const contractRetract = (data) =>axios.get(`/jaxrs/maintenance/contract/retract/${data.id}`)
// 修改合同金额
export const updateAmountMaintenanceContract = (data) =>axios.put(`/jaxrs/maintenance/contract/update_amount`,data)

