import axios from './axios.config'
//获取显示字段
export const getPurchaseReturnFields = (tableId='purchaseReturn') =>axios.get(`/jaxrs/purchase_return/fields?tableId=${tableId}`)
//获取查询事字段
export const getPurchaseReturnConditions = () =>axios.get('/jaxrs/purchase_return/conditions')
//获取分页查询数据（查询）
export const getPurchaseReturnPage = (data) =>axios.post('/jaxrs/purchase_return/page',data)
// 详情
export const getPurchaseReturnDetail = (data) =>{
    let url
    if(data.oper){
        url=`/jaxrs/purchase_return/${data.id}?oper=${data.oper}`
    }else{
        url=`/jaxrs/purchase_return/${data.id}`
    }
    return axios.get(url)
}
// 保存
export const addPurchaseReturn = (data) =>axios.post(`/jaxrs/purchase_return`,data)
// 修改
export const editPurchaseReturn = (data) =>axios.put(`/jaxrs/purchase_return`,data)
// 删除
export const deletePurchaseReturn = (data) =>axios.delete(`/jaxrs/purchase_return/${data.id}`)
// 导出
export const exportPurchaseReturn = (data) =>
    axios.post(`/jaxrs/purchase_return/export`,data,{
        responseType:'blob'
    })