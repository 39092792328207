<script>
import { defineComponent } from "vue";
import qnvfunc from "./js/qnvfunc";
import _ from "lodash";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import RecordingSoundDialog from "./RecordingSoundDialog.vue";
import { addCallRecords } from "@/axios";

dayjs.extend(duration);

let {
  TV_Initialize,
  TV_Disable,
  getEvent,
  I_CheckActiveX,
  OpenDevice,
  ODT_LBRIDGE,
  AppendStatus,
  AppendStatusEx,
  CloseDevice,
  TV_StartRecordFile,
  TV_StopRecordFile,
  BriEvent_PhoneHook,
  BriEvent_PhoneHang,
  BriEvent_CallIn,
  BriEvent_GetCallID,
  BriEvent_StopCallIn,
  BriEvent_EndID,
  BriEvent_RemoteHook,
  BriEvent_PhoneDial
} = qnvfunc;
/**
 * 响铃事件3 有来电，添加延迟事件延迟5s清除有来电
 * 来电号码4
 * 停止呼叫5 清除有来电及延迟事件
 * 摘机事件1
 * 开始录音
 * 挂机事件2
 * 停止录音
 * 清除有来电及延迟事件
 */
export default defineComponent({
  name: "RecordingSound",
  components: { RecordingSoundDialog },
  emits: ["update:phone"],
  props: {
    phone: {}
  },
  data() {
    return {
      date: this.getTime(),
      status: "no",
      hasCall: false,
      clearCallTimer: null,
      callNumber: null,
      lastCallNumber: null,
      statusMap: {
        no: "无来电",
        calling: "正在响铃",
        recording: "正在通话"
      },
      errorMessage: null,
      startTime: null,
      endTime: null,
      endTimeInterval: null,
      showRecordingSoundDialog: false,
      callType: "",
      callOutNumber: ""
    };
  },
  computed: {
    isNo() {
      return this.status === "no";
    },
    isCalling() {
      return this.status === "calling";
    },
    isRecording() {
      return this.status === "recording";
    },
    getDiffTime() {
      if (!this.startTime || !this.endTime) {
        return "00:00:00";
      }
      let end = dayjs(this.endTime);
      let diff = end.diff(this.startTime);
      let seconds = _.round(diff / 1000);
      let formatSeconds = (seconds) => {
        let minute = 60;
        let hour = 60 * minute;
        let hourStr = filterTimeNumber(_.floor(seconds / hour));
        let minuteStr = filterTimeNumber(_.floor((seconds % hour) / minute));
        let secondStr = filterTimeNumber(seconds % minute);
        return `${hourStr}:${minuteStr}:${secondStr}`;
      };
      let filterTimeNumber = (number) => {
        if (number < 10) {
          return `0${number}`;
        }
        return number;
      };
      return formatSeconds(seconds);
    },
    backCallNumber() {
      if (this.callNumber) {
        return this.callNumber;
      }
      if (this.lastCallNumber) {
        return this.lastCallNumber;
      }
      return null;
    }
  },
  watch: {
    callNumber(val) {
      this.$emit("update:phone", val);
    }
  },
  mounted() {
    this.init();
    // this.startTime = new Date();
    // this.initEndTimeInterval();
  },
  unmounted() {
    this.clearTimer();
    this.clearEndTimeInterval();
    TV_Disable();
  },
  methods: {
    init() {
      TV_Initialize(this.messageCallback);
      I_CheckActiveX(this.messageCallback);
      getEvent(this.T_GetEvent);
    },
    messageCallback(message) {
      this.errorMessage = message;
    },
    T_GetEvent(uID, uEventType, uHandle, uResult, szdata) {
      //var vValueArray=qnviccub.QNV_Event(0,2,0,"","",1024);
      if (uEventType === -1)
        return;
      var vValue = " type=" + uEventType + " Handle=" + uHandle + " Result=" + uResult + " szdata=" + szdata;
      switch (uEventType) {
        case BriEvent_PhoneHook:// 本地电话机摘机事件
          this.phoneHook();
          AppendStatusEx(uID, "本地电话机摘机" + vValue);
          break;
        case BriEvent_PhoneDial:// 只有在本地话机摘机，没有调用软摘机时，检测到DTMF拨号
          this.setCallOutNumber(szdata);
          AppendStatusEx(uID, "本地话机拨号" + vValue);
          break;
        case BriEvent_PhoneHang:// 本地电话机挂机事件
          // TV_HangUpCtrl(uID);
          this.closeDialog();
          this.stopRecord();
          AppendStatusEx(uID, "本地电话机挂机" + vValue);
          break;
        case BriEvent_CallIn:// 外线通道来电响铃事件
          this.initHasCall();
          AppendStatusEx(uID, "外线通道来电响铃事件" + vValue);
          break;
        case BriEvent_GetCallID://得到来电号码
          this.setCallNumber(szdata);
          AppendStatusEx(uID, "得到来电号码" + vValue);
          break;
        case BriEvent_StopCallIn:// 对方停止呼叫(产生一个未接电话)
          this.clearTimer();
          this.clearCall();
          AppendStatusEx(uID, "对方停止呼叫(产生一个未接电话)" + vValue);
          break;
        // case BriEvent_DialEnd:// 调用开始拨号后，全部号码拨号结束
        //   AppendStatusEx(uID, "调用开始拨号后，全部号码拨号结束" + vValue);
        //   break;
        // case BriEvent_PlayFileEnd:// 播放文件结束事件
        //   AppendStatusEx(uID, "播放文件结束事件" + vValue);
        //   break;
        // case BriEvent_PlayMultiFileEnd:// 多文件连播结束事件
        //   AppendStatusEx(uID, "多文件连播结束事件" + vValue);
        //   break;
        // case BriEvent_PlayStringEnd://播放字符结束
        //   AppendStatusEx(uID, "播放字符结束" + vValue);
        //   break;
        // case BriEvent_RepeatPlayFile:// 播放文件结束准备重复播放
        //   AppendStatusEx(uID, "播放文件结束准备重复播放" + vValue);
        //   break;
        // case BriEvent_SendCallIDEnd:// 给本地设备发送震铃信号时发送号码结束
        //   AppendStatusEx(uID, "给本地设备发送震铃信号时发送号码结束" + vValue);
        //   break;
        // case BriEvent_RingTimeOut://给本地设备发送震铃信号时超时
        //   AppendStatusEx(uID, "给本地设备发送震铃信号时超时" + vValue);
        //   break;
        // case BriEvent_Ringing://正在内线震铃
        //   AppendStatusEx(uID, "正在内线震铃" + vValue);
        //   break;
        // case BriEvent_Silence:// 通话时检测到一定时间的静音.默认为5秒
        //   AppendStatusEx(uID, "通话时检测到一定时间的静音" + vValue);
        //   break;
        // case BriEvent_GetDTMFChar:// 线路接通时收到DTMF码事件
        //   AppendStatusEx(uID, "线路接通时收到DTMF码事件" + vValue);
        //   break;
        case BriEvent_RemoteHook:// 拨号后,被叫方摘机事件
          this.remoteHook();
          AppendStatusEx(uID, "拨号后,被叫方摘机事件" + vValue);
          break;
        // case BriEvent_RemoteHang://对方挂机事件
        //   TV_HangUpCtrl(uID);
        //   AppendStatusEx(uID, "对方挂机事件" + vValue);
        //   break;
        // case BriEvent_Busy:// 检测到忙音事件,表示PSTN线路已经被断开
        //   AppendStatusEx(uID, "检测到忙音事件,表示PSTN线路已经被断开" + vValue);
        //   break;
        // case BriEvent_DialTone:// 本地摘机后检测到拨号音
        //   AppendStatusEx(uID, "本地摘机后检测到拨号音" + vValue);
        //   break;
        // case BriEvent_RingBack:// 电话机拨号结束呼出事件。
        //   AppendStatusEx(uID, "电话机拨号结束呼出事件" + vValue);
        //   break;
        // case BriEvent_MicIn:// MIC插入状态
        //   AppendStatusEx(uID, "MIC插入状态" + vValue);
        //   break;
        // case BriEvent_MicOut:// MIC拔出状态
        //   AppendStatusEx(uID, "MIC拔出状态" + vValue);
        //   break;
        // case BriEvent_FlashEnd:// 拍插簧(Flash)完成事件，拍插簧完成后可以检测拨号音后进行二次拨号
        //   AppendStatusEx(uID, "拍插簧(Flash)完成事件，拍插簧完成后可以检测拨号音后进行二次拨号" + vValue);
        //   break;
        // case BriEvent_RefuseEnd:// 拒接完成
        //   AppendStatusEx(uID, "拒接完成" + vValue);
        //   break;
        // case BriEvent_SpeechResult:// 语音识别完成
        //   AppendStatusEx(uID, "语音识别完成" + vValue);
        //   break;
        // case BriEvent_FaxRecvFinished:// 接收传真完成
        //   AppendStatusEx(uID, "接收传真完成" + vValue);
        //   break;
        // case BriEvent_FaxRecvFailed:// 接收传真失败
        //   AppendStatusEx(uID, "接收传真失败" + vValue);
        //   break;
        // case BriEvent_FaxSendFinished:// 发送传真完成
        //   AppendStatusEx(uID, "发送传真完成" + vValue);
        //   break;
        // case BriEvent_FaxSendFailed:// 发送传真失败
        //   AppendStatusEx(uID, "发送传真失败" + vValue);
        //   break;
        // case BriEvent_OpenSoundFailed:// 启动声卡失败
        //   AppendStatusEx(uID, "启动声卡失败" + vValue);
        //   break;
        // case BriEvent_UploadSuccess://远程上传成功
        //   AppendStatusEx(uID, "远程上传成功" + vValue);
        //   break;
        // case BriEvent_UploadFailed://远程上传失败
        //   AppendStatusEx(uID, "远程上传失败" + vValue);
        //   break;
        // case BriEvent_EnableHook:// 应用层调用软摘机/软挂机成功事件
        //   AppendStatusEx(uID, "应用层调用软摘机/软挂机成功事件" + vValue);
        //   break;
        // case BriEvent_EnablePlay:// 喇叭被打开或者/关闭
        //   AppendStatusEx(uID, "喇叭被打开或者/关闭" + vValue);
        //   break;
        // case BriEvent_EnableMic:// MIC被打开或者关闭
        //   AppendStatusEx(uID, "MIC被打开或者关闭" + vValue);
        //   break;
        // case BriEvent_EnableSpk:// 耳机被打开或者关闭
        //   AppendStatusEx(uID, "耳机被打开或者关闭" + vValue);
        //   break;
        // case BriEvent_EnableRing:// 电话机跟电话线(PSTN)断开/接通
        //   AppendStatusEx(uID, "电话机跟电话线(PSTN)断开/接通" + vValue);
        //   break;
        // case BriEvent_DoRecSource:// 修改录音源
        //   AppendStatusEx(uID, "修改录音源" + vValue);
        //   break;
        // case BriEvent_DoStartDial:// 开始软件拨号
        //   AppendStatusEx(uID, "开始软件拨号" + vValue);
        //   break;
        // case BriEvent_RecvedFSK:// 接收到FSK信号，包括通话中FSK/来电号码的FSK
        //   AppendStatusEx(uID, "接收到FSK信号，包括通话中FSK/来电号码的FSK" + vValue);
        //   break;
        // case BriEvent_PlugOut:
        //   AppendStatusEx(uID, "设备移除");
        //   break;
        // case BriEvent_DevErr://设备错误
        //   AppendStatusEx(uID, "设备错误" + decodeURIComponent(vValue));
        //   break;
        default:
          if (uEventType < BriEvent_EndID)
            AppendStatusEx(uID, "忽略其它事件发生:ID=" + uEventType + vValue);
          break;
      }
    },
    initHasCall() {
      this.status = "calling";
      this.clearTimer();
      this.clearCallTimer = setTimeout(() => {
        this.clearCall();
      }, 5000);
    },
    clearTimer() {
      if (this.clearCallTimer) {
        clearTimeout(this.clearCallTimer);
        this.clearCallTimer = null;
      }
    },
    clearCall() {
      if (this.isCalling) {
        this.status = "no";
        this.callNumber = null;
      }
    },
    setCallNumber(number,open=true) {
      this.callNumber = number;
      if (this.callNumber && open) {
        this.showRecordingSoundDialog = true;
      }
    },
    setCallOutNumber(number) {
      this.callOutNumber = number;
    },
    phoneHook() {
      if (this.isCalling) {
        this.callType = "call_in";
        this.startRecord();
      }
    },
    remoteHook() {
      this.callType = "call_out";
      this.setCallNumber(this.callOutNumber,false);
      this.callOutNumber = null;
      this.startRecord();
    },
    startRecord() {
      this.startTime = new Date();
      this.initEndTimeInterval();
      this.status = "recording";
      let filePath = this.getFilePath()
      TV_StartRecordFile(0, filePath);
      this.clearTimer();
    },
    getFilePath() {
      let number = this.callNumber
      let basePath = this.dictStore.getDictItemByTypeValue("systemParams", "recordFilePath").label;
      let time = dayjs(new Date()).format("YYYYMMDDHHmmss");
      let fileName = `${number}_${time}.wav`;
      let fullFilePath = basePath + fileName;
      return fullFilePath;
    },
    async stopRecord() {
      if (this.isRecording) {
        this.clearEndTimeInterval();
        this.status = "no";
        TV_StopRecordFile(0);
        await this.saveCallRecords();
        this.setLastCallNumber();
      }
    },
    saveCallRecords() {
      let user = this.userStore.userInfo
      let reqData = {
        creator: user.name,
        phone: this.callNumber,
        callAt: this.getTime(this.startTime,'YYYY-MM-DD HH:mm:ss'),
        callDuration: this.getDiffSeconds(),
        callType: this.callType
      }
      addCallRecords(reqData)
    },
    getDiffSeconds(){
      let end = dayjs(this.endTime);
      let diff = end.diff(this.startTime);
      let seconds = _.round(diff / 1000);
      return seconds
    },
    closeDialog() {
      this.showRecordingSoundDialog = false;
    },
    initEndTimeInterval() {
      this.endTime = new Date();
      this.endTimeInterval = setInterval(() => {
        this.endTime = new Date();
      }, 1000);
    },
    clearEndTimeInterval() {
      if (this.endTimeInterval) {
        clearInterval(this.endTimeInterval);
        this.endTimeInterval = null;
      }
    },
    setLastCallNumber() {
      this.lastCallNumber = this.callNumber;
      this.callNumber = null;
    }
  }
});
</script>

<template>
  <div class="recording-sound">
    <div>系统日期：{{ date }}</div>
    <div v-if="errorMessage" class="error">{{ errorMessage }}</div>
    <div v-else class="success">来电显示/录音：USB电话录音正常运行</div>
    <div class="recording-sound-info" v-if="isNo && lastCallNumber">
      <span>
        <span>上次通话：</span>
        <span class="success">{{ lastCallNumber }}</span>
      </span>
    </div>
    <div class="recording-sound-info" v-else>
      <span>
        <span>{{ statusMap[status] }}：</span>
        <span class="success" v-if="callNumber">
          {{ callNumber }}
        </span>
        <span class="success" v-else>--</span>
      </span>
      <span v-if="isRecording">正在录音：<span class="success">{{ getDiffTime }}</span></span>
    </div>
  </div>
  <RecordingSoundDialog v-model="showRecordingSoundDialog" :phone="callNumber"></RecordingSoundDialog>
</template>

<style scoped lang="scss">
.recording-sound {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background:#f1f3f7 !important;

  .recording-sound-info {
    & > * {
      margin-left: 10px;
    }
  }
}

.error {
  color: var(--dubhe-color-danger)
}

.success {
  color: var(--dubhe-color-success)
}
</style>