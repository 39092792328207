import axios from './axios.config'
// 获取显示字段
export const getExamPaperAnswerFields = (tableId='examPaperAnswer')=>axios.get(`/jaxrs/exam_paper_answer/fields?tableId=${tableId}`)
// 获取查询条件
export const getExamPaperAnswerConditions = () =>axios.get('/jaxrs/exam_paper_answer/conditions')
// 查询
export const getExamPaperAnswerPage = (data) =>axios.post('/jaxrs/exam_paper_answer/page',data)
// 详情
export const getExamPaperAnswerDetail = (data) =>axios.get(`/jaxrs/exam_paper_answer/${data.id}`)
//导出
export const exportExamPaperAnswer = (data) =>
  axios.post(`/jaxrs/exam_paper_answer/export`, data, {
    responseType: 'blob'
  })

