<script>
import { defineComponent } from "vue";
import { detailMixins } from "@/common/detailMixins";
import { addConsumer, editConsumer, getConsumerDetail } from "@/axios/consumer";
import _ from "lodash";
import SelectFullAddress from "@/components/SelectFullAddress/SelectFullAddress.vue";

export default defineComponent({
  name: "ConsumerManageEdit",
  components: { SelectFullAddress },
  mixins: [detailMixins],
  inject: ["phone"],
  props: {
    consumerType: {
      required: true
    },
    editId: {},
    maintenanceProjectId: null,
    maintenanceProjectCode: {}
  },
  data() {
    return {
      dynamicTags: [],
      inputVisible: false,
      inputValue: null,
      labelPosition: "top",
      tableEditOption: {
        items: [
          {
            type: "input",
            name: "contactName",
            label: "联系人",
            width: 300,
            fixed: "left"
          },
          {
            type: "input",
            name: "contactPhone",
            label: "联系电话",
            width: 300,
            params: {
              maxlength: "11",
              showWordLimit: true
            }
          },
          {
            type: "input",
            name: "contactAddress",
            label: "联系地址",
            width: 300,
          },
          {
            type: "input",
            name: "remark",
            label: "备注"
          }
        ]
      },
      editForm: {
        id: null,
        contacts: [],
        remark: null,
        createBy: "",
        creator: "",
        updateBy: "",
        updater: "",
        code: null,
        name: null,
        householdNumber: "",
        consumerType: null,
        contact: null,
        contactPhone: null,
        district: null,
        street: null,
        community: null,
        address: null,
        email: null,
        tag: null,
        taxNumber: null,
        unitAddress: null,
        unitPhone: null,
        bankName: null,
        bankAccount: null,
        invoiceTitle: null,
        longitude: null,
        latitude: null
      },
      baseForm: {
        id: null,
        contacts: [],
        remark: null,
        createBy: "",
        creator: "",
        updateBy: "",
        updater: "",
        code: null,
        name: null,
        householdNumber: "",
        consumerType: "civil",
        contact: null,
        contactPhone: null,
        district: null,
        street: null,
        community: null,
        address: null,
        email: null,
        tag: null,
        taxNumber: null,
        unitAddress: null,
        unitPhone: null,
        bankName: null,
        bankAccount: null,
        longitude: null,
        latitude: null
      },
      rules: {
        name: [
          {
            required: true,
            trigger: "change",
            message: "请输入客户名称"
          }
        ],
        contactPhone: [
          {
            required: true,
            trigger: "change",
            message: "请输入联系电话"
          },
          { max: 11, message: "最大长度为11", trigger: "change" }
        ],
        latitude: [
          {
            required: true,
            trigger: "change",
            message: "请选择地理位置"
          }
        ],
        unitPhone: [{ max: 11, message: "最大长度为11", trigger: "change" }],
        contacts: [
          {
            validator: (rule, value, callback) => {
              if (value.length > 0) {
                if (value.some((item) => !item.contactName)) {
                  callback(new Error("请输入联系人"));
                }
                if (value.some((item) => !item.contactPhone)) {
                  callback(new Error("请输入联系电话"));
                }
              }
              callback();
            }
          }
        ],
        district: [
          {
            required: true,
            trigger: "change",
            message: "请选择区"
          }
        ],
        address: [
          {
            required: true,
            trigger: "change",
            message: "请输入详细地址"
          }
        ]
      },
    };
  },
  computed: {
    isAdd() {
      return !this.editForm.id;
    },
    title() {
      if (this.isAdd) {
        return "客户管理（新增）";
      } else {
        return "客户管理（编辑）";
      }
    },
    hasDefaultConsumerType() {
      return !!this.consumerType;
    }
  },
  mounted() {
  },
  methods: {
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },

    close(data) {
      this.$message.success("保存成功");
      this.showDrawer = false;
      this.$emit("callback", this.isAdd, data);
    },
    async formValidate() {
      let validate;
      await this.$refs.form.validate((valid) => {
        validate = valid;
      });
      return validate;
    },
    async handleSaveClick() {
      if (!(await this.formValidate())) {
        return;
      }
      this.loading = true;
      // 获取当前用户的信息
      let user = this.getMyIdentityOrUser();
      let userId = user.id;
      let userName = user.name;

      let saveMethod;
      if (this.isAdd) {
        saveMethod = addConsumer;
        this.editForm.createBy = userId;
        this.editForm.creator = userName;
      } else {
        saveMethod = editConsumer;
        this.editForm.updateBy = userId;
        this.editForm.updater = userName;
      }
      let contacts = this.editForm.contacts;

      this.editForm.contacts = this.editForm.contacts.map((item) => {
        return {
          ...item,
          id: this.isAdd ? "" : item.id,
        };
      });
      this.editForm.tag = this.dynamicTags.join(",");
      saveMethod(this.editForm).then(res=>{
        this.close(res.data)
      }).catch(()=>{
        this.loading = false;
        this.editForm.contacts = contacts;
      })
    },
    /**
     * 获取详情
     */
    async getDetail() {
      this.loading = true;
      if (this.editId) {
        let res = await getConsumerDetail({ id: this.editId });
        this.editForm = res.data;
        if (this.editForm.tag) {
          this.dynamicTags = this.editForm.tag.split(",");
        } else {
          this.dynamicTags = [];
        }
        this.loading = false;
      } else {
        // 清空表单
        this.editForm = _.cloneDeep(this.baseForm);
        if (this.consumerType) {
          this.editForm.consumerType = this.consumerType;
        }
        if (this.phone) {
          this.editForm.contactPhone = this.phone;
        }
        this.$refs.form.resetFields();
        this.dynamicTags = [];
        this.loading = false;
      }
    },
    districtChange() {
      this.editForm.street = null;
      this.editForm.community = null;
    },
    streetChange() {
      this.editForm.community = null;
    }
  }
});
</script>

<template>
  <el-drawer v-model="showDrawer" size="95%" direction="btt" destroy-on-close @opened="getDetail">
    <template #header>
      <div>{{ title }}</div>
    </template>
    <template #default>
      <div class="detail-wrapper detail-wrapper-flex" v-loading="loading">
        <div class="detail-form">
          <el-form
            ref="form"
            :model="editForm"
            :rules="rules"
            scroll-to-error
            :label-position="labelPosition"
          >
            <Divider title="基本信息"></Divider>
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item label="客户编号">
                  <el-input v-model="editForm.code" placeholder="系统自动生成" disabled />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="客户类型">
                  <el-radio-group v-model="editForm.consumerType" :disabled="hasDefaultConsumerType">
                    <el-radio v-for="(item,index) in dictStore.getDictByType('consumerType') " :label="item.val" :key="index">{{ item.label
                      }}
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="客户名称" prop="name">
                  <el-input v-model="editForm.name" />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="客户等级" prop="consumerLevel">
                  <SelectV2
                    :list="dictStore.getDictByType('consumerLevel')"
                    v-model="editForm.consumerLevel"
                    value-key="val"
                    label-key="label"
                    placeholder="请选择"
                  ></SelectV2>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="联系人" prop="contact">
                  <el-input v-model="editForm.contact" />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="联系电话" prop="contactPhone">
                  <el-input v-model="editForm.contactPhone" maxlength="11" show-word-limit />
                </el-form-item>
              </el-col>
              <SelectFullAddress :span="6" v-model="editForm" :key-map="{
                district: 'district',
                street: 'street',
                community: 'community',
                address: 'address',
                lat: 'latitude',
                lng: 'longitude'
              }"></SelectFullAddress>
              <el-col :span="6">
                <el-form-item label="邮箱" prop="email">
                  <el-input v-model="editForm.email" />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="用户燃气编号" prop="householdNumber">
                  <el-input v-model="editForm.householdNumber" />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="标签" prop="tag">
                  <div class="tag-group">
                    <el-tag
                      :key="tag"
                      v-for="tag in dynamicTags"
                      closable
                      :disable-transitions="false"
                      @close="handleClose(tag)"
                    >
                      {{ tag }}
                    </el-tag>
                    <el-input
                      v-if="inputVisible"
                      v-model="inputValue"
                      ref="saveTagInput"
                      v-on:keyup.enter.native="handleInputConfirm"
                      @blur="handleInputConfirm"
                    >
                    </el-input>
                    <el-button v-else class="button-new-tag" size="small" @click="showInput"
                    >+ 新标签
                    </el-button
                    >
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :gutter="24">
                <el-form-item>
                  <TableEdit
                    title="联系人列表"
                    v-model:list="editForm.contacts"
                    :tableOption="tableEditOption.items"
                    :totalOption="tableEditOption.total"
                  ></TableEdit>
                </el-form-item>
              </el-col>
            </el-row>
            <Divider title="开票信息"></Divider>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="发票抬头" prop="invoiceTitle">
                  <el-input v-model="editForm.invoiceTitle" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="税号" prop="taxNumber">
                  <el-input v-model="editForm.taxNumber" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="单位地址" prop="unitAddress">
                  <el-input v-model="editForm.unitAddress" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="单位电话" prop="unitPhone">
                  <el-input v-model="editForm.unitPhone" maxlength="11" show-word-limit />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="开户行" prop="bankName">
                  <el-input v-model="editForm.bankName" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="银行账号" prop="bankAccount">
                  <el-input v-model="editForm.bankAccount" />
                </el-form-item>
              </el-col>
            </el-row>
            <Divider title="其他信息"></Divider>
            <el-row :gutter="24">
              <el-col :span="24">
                <el-form-item label="备注">
                  <el-input  type="textarea" v-model="editForm.remark"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="drawer-footer">
        <el-button
          type="primary"
          @click="handleSaveClick(false)"
          icon="Document"
          :disabled="loading"
        >保存
        </el-button
        >
      </div>
    </template>
  </el-drawer>
</template>
<style scoped lang="scss">
.table-edit .footer .dubhe-button {
  margin-top: 20px !important;
}

.tag-group {
  * + * {
    margin-left: 10px;
  }

  :deep(.dubhe-input) {
    display: inline;
    background: #d8bb8c;
  }
}

:deep(.charts-cont) {
  & + .input-number {
    .dubhe-input {
      .dubhe-input__wrapper {
        padding-left: 26px !important;
      }
    }
  }

  & + .input {
    padding-left: 26px !important;
  }
}
</style>