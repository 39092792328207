<script>
import { defineComponent } from 'vue'
import SelectMultipleDialog from '../SelectMultiple/SelectMultipleDialog.vue'

export default defineComponent({
  name: 'SelectMultiple',
  components: {
    SelectMultipleDialog
  },
  props: {
    modelValue: {
      required: true
    },
    multiple: {
      type: Boolean,
      default() {
        return true
      }
    },
    list: {
      type: Array,
      required: true
    },
    filterOption: {
      type: Array,
      required: true
    },
    labelKey: {
      type: String,
      default() {
        return 'label'
      }
    },
    valueKey: {
      type: String,
      default() {
        return 'id'
      }
    }
  },
  data() {
    return {
      showDialog: false
    }
  },
  computed: {
    tagList() {
      return this.list.filter((item) => this.value.includes(item[this.valueKey]))
    },
    firstTag() {
      if (this.tagList.length > 0) {
        return this.tagList[0]
      } else {
        return false
      }
    },
    otherTagLength() {
      if (this.tagList.length > 1) {
        return this.tagList.length - 1
      } else {
        return false
      }
    },
    value: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    }
  },
  methods: {
    handleCloseClick(tag) {
      this.value = this.value.filter((item) => item !== tag[this.valueKey])
    },
    openDialog() {
      this.showDialog = true
    }
  }
})
</script>

<template>
  <div class="el-select" @click="openDialog">
    <div class="select-trigger el-tooltip__trigger el-tooltip__trigger">
      <div class="el-select__tags">
        <span class="el-select-tags-wrapper has-prefix">
          <el-tag closable v-if="firstTag" @close="handleCloseClick(firstTag)">
            {{ firstTag[labelKey] }}
          </el-tag>
          <el-tag v-if="otherTagLength"> +{{ otherTagLength }} </el-tag>
        </span>
      </div>
      <div class="el-input el-input--suffix">
        <div class="el-input__wrapper">
          <div class="input-dom"></div>
          <span class="el-input__suffix">
            <span class="el-input__suffix-inner">
              <el-icon><User /></el-icon>
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
  <SelectMultipleDialog
    v-model="showDialog"
    :list="list"
    v-model:value="value"
    :filterOption="filterOption"
  ></SelectMultipleDialog>
</template>

<style scoped lang="scss">
.dubhe-select__tags {
  width: 100%;
}

.input-dom {
  flex: 1;
  //height: 30px;
  height: calc(var(--dubhe-component-size) - 2px);
}
</style>