import axios  from './axios.config'
// 获取显示字段  Notification
export const getNotificationFields = (tableId = 'notification')=>axios.get(`/jaxrs/notification/fields?tableId=${tableId}`)
// 获取查询条件
export const getNotificationConditions = ()=>axios.get('/jaxrs/notification/conditions')
// 查询
export const getNotificationPage = (data)=>axios.post('/jaxrs/notification/page',data)
// 查询我的
export const getNotificationMy = (data)=>axios.post('/jaxrs/notification/my',data)
// 详情
export const getNotificationDetail = (data)=>axios.get(`/jaxrs/notification/${data.id}`)
// 保存
export const addNotification = (data)=>axios.post(`/jaxrs/notification`,data)
// 编辑
export const editNotification = (data) =>axios.put(`/jaxrs/notification`,data)
// 删除
export const deleteNotification = (data)=>axios.delete(`/jaxrs/notification/${data.id}`)
// 撤回
export const returnNotification = (data)=>axios.get(`/jaxrs/notification/recall/${data.id}`)
// 发布
export const issureNotification = (data) =>axios.get(`/jaxrs/notification/publish/${data.id}`)
// 置顶
export const topNotification = (data) =>axios.get(`/jaxrs/notification/top/${data.id}`)
// 阅读
export const readNotification = (data) =>axios.get(`/jaxrs/notification/read/${data.id}`)