import axios from './axios.config'
// 获取显示字段 SalaryAdjustment
export const getSalaryAdjustmentFields = (tableId='salaryAdjustment')=>axios.get(`/jaxrs/hr/salary_adjustment/fields?tableId=${tableId}`)
// 查询字段
export const getSalaryAdjustmentConditions = ()=>axios.get('/jaxrs/hr/salary_adjustment/conditions')
// 分页查询
export const getSalaryAdjustmentPage = (data)=>axios.post('/jaxrs/hr/salary_adjustment/page',data)
// 详情
export const getSalaryAdjustmentDetail = (data)=>axios.get(`/jaxrs/hr/salary_adjustment/${data.id}`)
// 保存
export const addSalaryAdjustment = (data)=>axios.post(`/jaxrs/hr/salary_adjustment`,data)
// 修改
export const editSalaryAdjustment = (data)=>axios.put(`/jaxrs/hr/salary_adjustment`,data)
// 删除
export const deleteSalaryAdjustment = (data)=>axios.delete(`/jaxrs/hr/salary_adjustment/${data.id}`)