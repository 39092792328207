import axios from './axios.config'
// 分包项目管理 OutsourcingProject
// 获取项目显示字段
export const getOutsourcingProjectFields = (tableId = 'outsourcingProject') =>axios.get(`/jaxrs/outsourcing_project/fields?tableId=${tableId}`)
// 获取项目查询条件
export const getOutsourcingProjectConditions = () =>axios.get('/jaxrs/outsourcing_project/conditions')
// 查询
export const getOutsourcingProjectPage = (data)=>axios.post('/jaxrs/outsourcing_project/page',data)
// 详情
export const getOutsourcingProjectDetail = (data) =>axios.get(`/jaxrs/outsourcing_project/${data.id}`)
// 保存
export const addOutsourcingProject = (data) =>axios.post(`/jaxrs/outsourcing_project`,data)
// 修改
export const editOutsourcingProject = (data) =>axios.put(`/jaxrs/outsourcing_project`,data)
// 删除
export const deleteOutsourcingProject = (data) =>axios.delete(`/jaxrs/outsourcing_project/${data.id}`)
// 分包管理的导出
export const exportOutsourcingProject = (data) =>axios.post(`/jaxrs/outsourcing_project/export`,data,{
  responseType:'blob'
})
// 项目开工
export const startProjectProcess = (data) =>axios.put(`/jaxrs/outsourcing_project/start/${data.id}`)
// 项目竣工
export const endProjectProcess = (data) =>axios.put(`/jaxrs/outsourcing_project/finish/${data.id}`)