import axios  from './axios.config'
// 获取查询条件  BusinessTargets
export const getBusinessTargetsFields = (tableId = 'businessTargets')=>axios.get(`/jaxrs/business_targets/fields?tableId=${tableId}`)
// 查询条件
export const getBusinessTargetsConditions = ()=>axios.get('/jaxrs/business_targets/conditions')
// 查询
export const getBusinessTargetsPage = (data)=>axios.post('/jaxrs/business_targets/page',data)
// 详情
export const getBusinessTargetsDetail = (data)=>axios.get(`/jaxrs/business_targets/${data.id}`)
// 保存
export const addBusinessTargets = (data)=>axios.post(`/jaxrs/business_targets`,data)
// 编辑
export const editBusinessTargets = (data)=>axios.put(`/jaxrs/business_targets`,data)
// 删除
export const deleteBusinessTargets = (data)=>axios.delete(`/jaxrs/business_targets/${data.id}`)

