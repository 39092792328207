<script>
import { defineComponent } from "vue";
import _ from "lodash";
import { openApiUpload, uploadFile, uploadImage } from "@/axios";

export default defineComponent({
  name: "UploadFile",
  emits: ["checkChange"],
  data() {
    return {
      loading: false,
      uploadMethod: null,
      selectFileList: []
    };
  },
  props: {
    type: {
      type: String,
      default() {
        return "image";
      }
    },
    maxSize: {
      type: Number,
      default() {
        return 10;
      }
    },
    upload: {
      type: Function
    },
    fileList: {
      type: Array
    }
  },
  computed: {
    accept() {
      if (this.type === "image") {
        return "image/*";
      } else {
        return "";
      }
    }
  },
  mounted() {
    this.initUploadMethod();
    // console.log(this);
    // console.log(this.validateField());

  },
  methods: {
    fileChange(uploadFile, uploadFiles) {
      if (uploadFiles.length > 0) {
        if(!this.checkFilesSize(uploadFiles)){
          return;
        }
        if (this.type === "image") {
          if (!this.checkImageType(uploadFiles)) {
            return;
          }
        }
        this.uploadMethod(uploadFiles);
      }
    },
    checkFilesSize(list){
      let checkOk = true
      list.forEach(item=>{
        if(item.size === 0){
          checkOk = false
        }
      })
      if(!checkOk){
        this.clearFiles();
        this.$message.warning('请选择文件大小大于0的文件上传')
      }
      return checkOk
    },
    checkImageType(list) {
      let checkFail = list.some(item => {
        return item.raw.type.indexOf("image/") === -1;
      });
      if (checkFail) {
        this.clearFiles();
        this.$message.warning("请选择图片格式文件");
      }
      return !checkFail;
    },
    initUploadMethod() {
      this.uploadMethod = _.debounce(
        async (uploadFiles) => {
          let formData = new FormData();
          uploadFiles.forEach((item) => {
            formData.append("files", item.raw);
          });
          let uploadMethod;
          let {matched} = this.$route
          if(matched && matched[0]?.meta.noUser){
            uploadMethod = openApiUpload
          }else{
            if (this.type === "image") {
              uploadMethod = uploadImage;
            } else {
              uploadMethod = uploadFile;
            }
          }
          this.loading = true;
          uploadMethod(formData)
            .then(async (res) => {
              if (res) {
                this.$emit("checkChange", res.data, uploadFiles, this.uploadCallback);
                if (this.upload) {
                  await this.upload(res.data, uploadFiles, this.uploadCallback);
                }
                this.uploadCallback()
              }
            })
            .catch(() => {
            })
            .finally(() => {
              this.loading = false;
              this.clearFiles();
            });
        },
        300,
        {
          leading: false,
          trailing: true
        }
      );
    },
    uploadCallback() {
      if (this?.$parent?.validate) {
        this.$parent.validate("change", (trigger, callback) => {
          return trigger;
        });
      }
    },
    clearFiles() {
      if(this.$refs.upload){
        this.$refs.upload.clearFiles();
      }
    }
  }
});
</script>

<template>
  <el-upload
    class="upload"
    ref="upload"
    drag
    multiple
    v-loading="loading"
    :limit="maxSize"
    :accept="accept"
    :auto-upload="false"
    :show-file-list="false"
    :on-change="fileChange"
  >
    <div class="upload-dragger">将文件拖到此处，或点击上传</div>
    <!--    <template #tip>-->
    <!--      <div class="el-upload__tip">-->
    <!--        jpg/png files with a size less than 500kb-->
    <!--      </div>-->
    <!--    </template>-->
  </el-upload>
</template>

<style scoped lang="scss">
.upload {
  max-width: 300px;
}

.upload-dragger {
  width: 100%;
  padding: 10px 0;
}
</style>