import axios from './axios.config'
// 获取显示字段
export const getAccountingVoucherFields = (tableId='accountingVoucher') =>axios.get(`/jaxrs/accounting_voucher/fields?tableId=${tableId}`)
// 获取查询字段
export const getAccountingVoucherConditions = () =>axios.get('/jaxrs/accounting_voucher/conditions')
// 获取分页查询数据
export const getAccountingVoucherPage = (data) =>axios.post('/jaxrs/accounting_voucher/page',data)
// 详情
export const getAccountingVoucherDetail = (data) =>{
    let url
    if(data.oper){
        url=`/jaxrs/accounting_voucher/${data.id}?oper=${data.oper}`
    }else{
        url=`/jaxrs/accounting_voucher/${data.id}`
    }
    return axios.get(url)
}
// 保存
export const addAccountingVoucher = (data) =>axios.post(`/jaxrs/accounting_voucher`,data)
// 编辑
export const editAccountingVoucher = (data) =>axios.put(`/jaxrs/accounting_voucher`,data)
// 删除
export const deleteAccountingVoucher = (data) =>axios.delete(`/jaxrs/accounting_voucher/${data.id}`)
// 导出
export const exportAccountingVoucher = (data) =>
    axios.post(`/jaxrs/accounting_voucher/export`, data, {
        responseType: 'blob'
    })