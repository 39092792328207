import axios from './axios.config'
// 车辆保险
// 获取显示字段
export const getVehicleInsuranceFields = (tableId="vehicleInsurance") =>axios.get(`/jaxrs/vehicle_insurance/fields?tableId=${tableId}`)
// 获取查询条件
export const getVehicleInsuranceConditions = () =>axios.get('/jaxrs/vehicle_insurance/conditions')
// 查询
export const getVehicleInsurancePage = (data) =>axios.post('/jaxrs/vehicle_insurance/page',data)
// 详情
export const getVehicleInsuranceDetail = (data) =>{
    let url
    if(data.oper){
        url=`/jaxrs/vehicle_insurance/${data.id}?oper=${data.oper}`
    }else{
        url=`/jaxrs/vehicle_insurance/${data.id}`
    }
    return axios.get(url)
}
// 保存（新增）
export const addVehicleInsurance = (data) =>axios.post(`/jaxrs/vehicle_insurance`,data)
// 修改
export const editVehicleInsurance = (data) =>axios.put(`/jaxrs/vehicle_insurance`,data)
// 删除
export const deleteVehicleInsurance = (data) =>axios.delete(`/jaxrs/vehicle_insurance/${data.id}`)
// 导出
export const exportVehicleInsurance = (data) =>
    axios.post(`/jaxrs/vehicle_insurance/export`,data,{
        responseType:'blob'
    })
// 查询保险过期和将要过期
export const getExpirationInsurance = () =>axios.get('/jaxrs/vehicle_insurance/expireVehicleList')