import axios from './axios.config'
// 物品领用申请 GoodsRequisition
export const getGoodsRequisitionFields = (tableId = 'goodsRequisition')=>axios.get(`/jaxrs/goods_requisition/fields?tableId=${tableId}`)
// 获取查询条件
export const getGoodsRequisitionConditions = ()=>axios.get('/jaxrs/goods_requisition/conditions')
// 查询
export const getGoodsRequisitionPage = (data)=>axios.post(`/jaxrs/goods_requisition/page`,data)
// 详情
export const getGoodsRequisitionDetail = (data)=>{
  let url
  if (data.oper){
    url = `/jaxrs/goods_requisition/${data.id}?oper=${data.oper}`
  }else{
    url= `/jaxrs/goods_requisition/${data.id}`
  }
  return axios.get(url)
}
// 保存
export const addGoodsRequisition = (data)=>axios.post(`/jaxrs/goods_requisition`,data)
// 编辑
export const editGoodsRequisition = (data)=>axios.put(`/jaxrs/goods_requisition`,data)
// 删除
export const deleteGoodsRequisition = (data)=>axios.delete(`/jaxrs/goods_requisition/${data.id}`)
// 修改流程状态
export const changeGoodsRequisitionProcess = (data)=>axios.put('/jaxrs/goods_requisition/status',data)
// 出库
export const exportGoodsRequisition = (data)=>axios.put('/jaxrs/goods_requisition/stock_out',data)