import axios from './axios.config'
// 获取显示字段
export const getProjectApprovalFields = (tableId='projectApproval')=>axios.get(`/jaxrs/project_approval/fields?tableId=${tableId}`)
// 获取查询条件
export const getProjectApprovalConditions = ()=>axios.get('/jaxrs/project_approval/conditions')
// 查询
export const getProjectApprovalPage = (data) =>axios.post('/jaxrs/project_approval/page',data)

// 根据项目ID获取立项详情
export const getProjectApprovalByProjectId = (data) =>axios.get(`/jaxrs/project_approval/project_id/${data.projectId}`)
// 详情
export const getProjectApprovalDetail = (data) => {
  let url
  if (data.oper) {
    url = `/jaxrs/project_approval/${data.id}?oper=${data.oper}`
  } else {
    url = `/jaxrs/project_approval/${data.id}`
  }
  return axios.get(url)
}
// 保存
export const addProjectApproval = (data) =>axios.post(`/jaxrs/project_approval`,data)
// 编辑
export const editProjectApproval = (data) =>axios.put(`/jaxrs/project_approval`,data)
// 删除
export const deleteProjectApproval = (data) =>axios.delete(`/jaxrs/project_approval/${data.id}`)
// 获取详情页地址
export const getProjectIdDetail = (data) =>axios.get(`/jaxrs/project_approval/project_id/${data.id}`)

// 根据项目id获取详情
export const getProjectApprovalCallBackDetail = (data)=>axios.get(`/jaxrs/project_approval/project_id/${data.projectId}`)

// 更新项目单位信息
export const getProjectMessage = (data)=>axios.post(`/jaxrs/project/project_unit`,data)
// 项目立项撤回审核
export const returnProjectApproval = (data)=>axios.get(`/jaxrs/project_approval/retract/${data.id}`)
// 导出
export const exportProjectApproval = (data) => axios.post(`/jaxrs/project_approval/export`,data,{
  responseType:'blob'
})