<script>
import { defineComponent } from "vue";
import { getExecuteModePage } from "@/axios";
import _ from "lodash";

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: "SelectWorkerDialog",
  emits: ["update:modelValue", "confirm", "update:value"],
  data() {
    return {
      // dataViewId: "DD_STAT_WORKLOAD_01",
      dataViewId: "DD_STAT_WORKLOAD_02",
      searchContent: "",
      leaderId: "",
      serverList: [],
      workerList: [],
      unitId: "",
      unitList: []
    };
  },
  props: {
    modelValue: {
      type: Boolean
    },
    value: {
      type: Array
    },
    title: {
      type: String,
      default() {
        return "工人选择";
      }
    },
    labelKey: {
      type: String,
      default() {
        return "workerName";
      }
    },
    valueKey: {
      type: String,
      default() {
        return "workerId";
      }
    }
  },
  computed: {
    show: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    },
    filterList() {
      let list = this.workerList.filter(item => {
        return item.name.includes(this.searchContent) && this.unitId === item.unit;
      });
      return list;
    }
  },
  mounted() {
  },
  methods: {
    initList() {
      let reqData = {
        pageSize: 99999,
        pageNumber: 1,
        viewId: this.dataViewId,
        params: { unitId: "" }
      };
      getExecuteModePage(reqData).then(res => {
        this.workerList = res.data;
        this.initUnitList();
      });
    },
    initUnitList() {
      if (this.workerList.length > 0) {
        let unitMap = {};
        this.workerList.forEach((item) => {
          if (!unitMap[item.unit]) {
            unitMap[item.unit] = item.unitName;
          }
        });
        let unitList = [];
        for (let key in unitMap) {
          let unitItem = {
            id: key,
            name: unitMap[key]
          };
          unitList.push(unitItem);
        }
        this.unitList = unitList;
        if (this.unitList.length > 0) {
          this.unitId = this.unitList[0].id;
          this.$nextTick(() => {
            this.$refs.tree.setCurrentKey(this.unitId);
            this.setChecks();
          });
        }
      }
    },
    handleCancelClick() {
      this.$emit("update:modelValue", false);
    },
    handleConfirmClick() {
      if (!this.leaderId) {
        this.$message.warning("请选择负责人");
        return;
      }
      let hasCheckLeader = false;
      let leader = _.cloneDeep(this.workerList.find(item => item.person === this.leaderId));
      let totalList = _.cloneDeep(this.serverList).map(item => {
        let server = {
          [this.valueKey]: item.person,
          [this.labelKey]: item.name,
          isLeader: false
        };
        if (item.person === this.leaderId) {
          hasCheckLeader = true;
          server.isLeader = true;
        }
        return server;
      });
      if (!hasCheckLeader) {
        totalList.push({
          [this.valueKey]: leader.person,
          [this.labelKey]: leader.name,
          isLeader: true
        });
      }
      this.$emit("confirm", totalList);
      this.$emit("update:modelValue", false);
    },
    open() {
      this.initList();
    },
    setChecks() {
      this.value.forEach(item => {
        if (item.isLeader) {
          this.leaderId = item[this.valueKey];
        }
      });
      this.$nextTick(() => {
        this.toggleSelection(this.value);
      });
    },
    handleSelectionChange(selection) {
      this.serverList = selection;
    },
    toggleSelection(rows) {
      if (rows && rows.length > 0) {
        let idMap = rows.reduce((map,item ) => {
          map[item[this.valueKey]] = true;
          return map;
        }, {});
        this.workerList.forEach(item=>{
          if(idMap[item.person]){
            this.$refs.table.toggleRowSelection(item, true);
          }
        })
      } else {
        this.$refs.table.clearSelection();
      }
    },
    handleCurrentChange(val) {
      if (val) {
        this.unitId = val.id;
      } else {
        this.unitId = "";
      }
    }
  }
});
</script>

<template>
  <el-dialog v-model="show" v-bind="$attrs" :title="title" top="5%" width="80%" destroy-on-close @opened="open">
    <div class="worker-dialog">
      <div class="worker-header">
        <el-input placeholder="请输入名称" v-model="searchContent" highlight-current />
      </div>
      <div class="worker-body">
        <div class="worker-unit">
          <el-tree
            ref="tree"
            :data="unitList"
            empty-text="暂无数据"
            nodeKey="id"
            highlight-current
            default-expand-all
            @current-change="handleCurrentChange"
          >
            <template #default="item">
              <div>{{ item.data.name }}</div>
            </template>
          </el-tree>
        </div>
        <el-table class="worker-table" ref="table" :data="filterList" height="100%" row-key="person"
                  @selection-change="handleSelectionChange">
          <el-table-column
            type="selection"
            width="55"
            align="center"
            :reserve-selection="true"
            fixed
          />
          <el-table-column label="负责人" align="center" width="80" fixed>
            <template #default="scope">
              <div class="radio">
                <el-radio v-model="leaderId" :label="scope.row.person"></el-radio>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="序号" width="80" align="center" fixed>
            <template #default="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="员工名称" prop="name" />
          <el-table-column label="今日派单数" prop="today" />
          <el-table-column label="未完成工单" prop="undone" />
        </el-table>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleCancelClick">取消</el-button>
        <el-button type="primary" @click="handleConfirmClick">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<style scoped lang="scss">
.worker-dialog {
  & > :deep(*) {
    width: 100%;
  }

  .radio {
    :deep(.dubhe-radio__label) {
      display: none;
    }
  }

  .worker-header {
    padding-bottom: 10px;

    .dubhe-input {
      width: 200px;
    }
  }

  .worker-body {
    display: flex;
    height: 600px;

    .worker-unit {
      height: 100%;
      width: 200px;
      //background: #d8bb8c;
      overflow: auto;
      margin-right: 10px;
    }

    .worker-table {
      flex: 1;
      height: 100%;
    }
  }

  //.worker-wrapper {
  //  display: flex;
  //  .worker-table {
  //    flex: 1;
  //  }
  //}
}
</style>