<script>
import { defineComponent } from 'vue'
// import { layout, o2 } from "@o2oa/component";

export default defineComponent({
  name: 'ProcessDrawerBodyNew',
  props: {
    workId: {},
    closeCallback: {}
  },
  mounted() {
    this.init()
  },
  methods:{
    init(){
      console.log('init')
      layout.addReady(()=>{
        ((layout)=>{
          var appNames = 'process.Work'
          var options = null;
          var statusObj = {"workId":this.workId,"taskId":"","workCompletedId":"","jobId":"","draftId":"","priorityWork":""}

          var _load = ()=>{
            //o2.require("MWF.xDesktop.MessageMobile", function(){
            // layout.message = new MWF.xDesktop.MessageMobile();
            // layout.message.load();
            //}.bind(this));
            layout.apps = [];
            //layout.node = $("layout");
            // layout.node = $("layout") || $("appContent") || document.body;
            // layout.node = this.$refs.body;
            layout.node = $("processDrawerBody");
            // layout.node = document.body;
            console.log(layout.node);
            var appName=appNames, m_status=statusObj, option=options;

            var topWindow = window.opener;
            if (topWindow){
              try{
                if (!appName) appName = topWindow.layout.desktop.openBrowserApp;
                if (!m_status) m_status = topWindow.layout.desktop.openBrowserStatus;
                if (!option)  option = topWindow.layout.desktop.openBrowserOption;
              }catch(e){}
            }
            layout.openApplication(null, appName, option||{}, m_status);

            if (layout.session.user.name === "anonymous"){
              o2.loadCss("../o2_core/o2/xDesktop/$Default/blue/style-skin.css");
            }else{
              o2.xDesktop.getUserLayout(()=>{
                var style = layout.userLayout.flatStyle;
                o2.loadCss("../o2_core/o2/xDesktop/$Default/"+style+"/style-skin.css");
              });
            }
          };

          if (layout.session && layout.session.user){
            _load();
          }else{
            if (layout.sessionPromise){
              layout.sessionPromise.then(()=>{
                _load();
              },()=>{});
            }
          }
          //_load();
        })(layout);
      });
    }
  }
})
</script>

<template>
  <div class="process-drawer-body processDrawerBody" ref="body" id="processDrawerBody"></div>
</template>

<style scoped lang="scss">
.process-drawer-body {
  height: 100%;
  width: 100%;
  background: #d8bb8c;
}
</style>