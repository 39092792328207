import axios from './axios.config'
// 获取显示字段
export const getProjectMaterialPlanFields = (tableId='projectMaterialPlan')=>axios.get(`/jaxrs/project_material_plan/fields?tableId=${tableId}`)
// 获取查询条件
export const getProjectMaterialPlanConditions = ()=>axios.get('/jaxrs/project_material_plan/conditions')
// 查询
export const getProjectMaterialPlanPage = (data) =>axios.post('/jaxrs/project_material_plan/page',data)
// 详情
export const getProjectMaterialPlanDetail = (data) =>axios.get(`/jaxrs/project_material_plan/${data.id}`)
// 保存
export const addProjectMaterialPlan = (data) =>axios.post(`/jaxrs/project_material_plan`,data)
// 修改
export const editProjectMaterialPlan = (data) =>axios.put(`/jaxrs/project_material_plan`,data)
// 删除
export const deleteProjectMaterialPlan = (data) =>axios.delete(`/jaxrs/project_material_plan/${data.id}`)
// 根据项目id获取详情
export const getProjectMaterialPlanDetailByProjectId = (data) =>axios.get(`/jaxrs/project_material_plan/project/${data.id}`)
// 根据项目用料统计
export const getProjectMaterialPlanDetailStatistics = (data) =>axios.post(`/jaxrs/project_material_plan/material_statistics`,data)
