import axios from "./axios.config"
//安检日报 SecurityCheckDaily
// 显示字段
export const getSecurityCheckDailyFields = (tableId ="securityCheckDaily") =>axios.get(`/jaxrs/security_check_daily/fields?tableId=${tableId}`)
// 获取查询条件
export const getSecurityCheckDailyConditions = ()=>axios.get('/jaxrs/security_check_daily/conditions')
// 查询
export const getSecurityCheckDailyPage = (data)=>axios.post('/jaxrs/security_check_daily/page',data)
// 详情
export const getSecurityCheckDailyDetail = (data)=>axios.get(`/jaxrs/security_check_daily/${data.id}`)
// 保存
export const addSecurityCheckDaily = (data)=>axios.post(`/jaxrs/security_check_daily`,data)
// 编辑
export const editSecurityCheckDaily = (data)=>axios.put(`/jaxrs/security_check_daily`,data)
// 导出
export const exportSecurityCheckDaily = (data)=>axios.post(`/jaxrs/security_check_daily/export`,data,{
  responseType:'blob'
})
