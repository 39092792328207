<script>
import { defineComponent,createElementVNode } from "vue";
import { detailMixins } from "@/common/detailMixins";
import { getPrintTemplatePage } from "@/axios/print";
import { htmlToPDF } from "@/utils/pdf/html2pdf";
// import { hiprint } from "@/utils/vue-plugin-hiprint";
export default defineComponent({
  name: "PrintPreview",
  mixins: [detailMixins],
  data() {
    return {
      hiprintTemplate: null,
      loading: false,
      exportLoading: false,
      content: ""
    };
  },
  props: {
    name: {
      type: String,
      required: true
    },
    data: {},
    watermark:[]
  },
  mounted() {
  },
  methods: {
    async init() {
      await this.getPrintTemplate();
      let dom = jQuery("#preview_content_design");
      dom.empty();
      let templateParams = {};
      if (this.content) {
        templateParams.template = JSON.parse(this.content);
      }
      this.hiprintTemplate = new hiprint.PrintTemplate(templateParams);
      let data = this.data || {};
      dom.html(this.hiprintTemplate.getHtml(data));
      // this.$nextTick(()=>{
      //   this.setWatermark()
      // })
    },
    setWatermark() {
      // console.log(this.$refs.print);
      if(!this.watermark) {
        return
      }
      let nodes = Array.from(jQuery('.hiprint-printPaper'))
      // console.log(nodes);
      nodes.forEach(node => {
        this.watermark.forEach((wItem)=>{
          let wNode = jQuery('<img/>')
          for(let key in wItem.props) {
            if(key === 'style'){
              wNode.css(wItem.props[key])
            }else{
              wNode.attr(key, wItem.props[key])
            }
          }
          node.appendChild(wNode[0])
        })
      })
    },
    async getHtml() {
      await this.getPrintTemplate();
      let templateParams = {};
      if (this.content) {
        templateParams.template = JSON.parse(this.content);
      }
      let hiprintTemplate = new hiprint.PrintTemplate(templateParams);
      let data = this.data || {};
      let html = hiprintTemplate.getHtml(data)
      return html
    },
    async getPrintTemplate() {
      this.loading = true;
      let params = {
        pageSize: 10,
        pageNum: 1,
        wrapper: {
          "and": [],
          "ors": [],
          "conditions": [
            {
              "alias": "",
              "field": "name",
              "operator": "eq",
              "params": [
                this.name
              ]
            }
          ],
          "operator": "and"
        },
        sorts: []
      };
      await getPrintTemplatePage(params)
        .then((res) => {
          if (res && res.data.length > 0) {
            this.content = res.data[0].content;
          }
        })
        .catch(() => {
        })
        .finally(() => {
          this.loading = false;
        });
    },
    print() {


      // let printElement = (elem) => {
      //   var printWindow = window.open('', '_blank', 'height=500,width=800');
      //   printWindow.document.write('<html><head><title></title>');
      //   printWindow.document.write('</head><body >');
      //   printWindow.document.write(elem);
      //   printWindow.document.write('</body></html>');
      //   printWindow.document.close();
      //   printWindow.print();
      // }
      // let html = this.$refs.print
      //
      // console.log({html});
      // // window.print(html);
      // printElement(html)
      // return

      // 打印数据，key 对应 元素的 字段名
      let printData = this.data || {};
      // 参数: 打印时设置 左偏移量，上偏移量
      let options = { leftOffset: -1, topOffset: -1 };
      // 扩展
      let ext = {
        callback: () => {
          console.log("浏览器打印窗口已打开");
        },
        styleHandler: () => {
          let css = '<link href="./css/print-lock.css" rel="stylesheet">';
          return css;
        }
      };

      // let html = this.hiprintTemplate.getHtml(printData, options);
      // console.log(html);
      // , ext
      // 调用浏览器打印
      this.hiprintTemplate.print(printData, options, ext);
    },
    print2() {
      let data = this.data || {};
      if (hiprint.hiwebSocket.opened) {
        this.hiprintTemplate.print2(data);
      } else {
        alert("请先连接客户端(刷新网页), 然后再点击「直接打印」");
      }
    },
    async exportPDF() {
      // let html = await this.$refs.print.getHtml()
      this.exportLoading = true
      let html = this.$refs.print
      let title = this.getTime(new Date(),'YYYYMMDDHHmmss')
      await htmlToPDF(html,title)
      this.exportLoading = false
    }
  }
});
</script>

<template>
  <el-drawer v-model="showDrawer" size="95%" direction="btt" destroy-on-close @opened="init">
    <template #header>
      <div>打印预览</div>
    </template>
    <template #default>
      <div v-loading="loading">
        <div class="button-group">
          <el-button type="primary" @click.stop="exportPDF" :loading="exportLoading" :disabled="exportLoading">导出PDF</el-button>
          <el-button type="primary" @click.stop="print">打印</el-button>
          <!--        <el-button type="primary" @click.stop="print">浏览器打印</el-button>-->
          <!--        <el-button type="primary" @click.stop="print2">直接打印(需要连接客户端)</el-button>-->
        </div>
        <div class="print-preview-wrapper">
          <div id="preview_content_design" class="content" ref="print"></div>
        </div>
      </div>
    </template>
  </el-drawer>
</template>

<style scoped lang="scss">
.print-preview-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;

  .content {
    //padding-top: 2.5px;
    padding-top: 4px;
    box-shadow: var(--dubhe-box-shadow-light);
    //border: 1px solid #999999;
  }
}
.button-group{
  @include flex-row-end-col-center;
  padding: 10px 0;
}
</style>