<script>
import { defineComponent } from "vue";
import { o2 } from "@o2oa/component";

export default defineComponent({
  name: "ImportExcelO2oa",
  emits: ["callback"],
  props: {
    name: {
      type: String
    },
    params: {}
  },
  computed: {
    title() {
      return `${this.name}导入`;
    }
  },
  methods: {
    downloadTemp() {
      let fileName = `${this.name}导入模版`;
      let options = this.params;
      o2.api.importer.downloadTemplate(options, fileName);
    },
    importExcel() {
      o2.api.importer.upload(this.params, (res) => {
        this.$emit("update:modelValue", false);
        this.$emit("callback");
      });
    }
  }
});
</script>

<template>
  <el-dropdown>
    <el-button type="" link icon="Upload">导入</el-button>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item
          @click="downloadTemp"
        >{{ this.name }}模板下载
        </el-dropdown-item>
        <el-dropdown-item
          @click="importExcel"
        >导入{{ this.name }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
<!--  <el-dialog-->
<!--    v-bind="$attrs"-->
<!--    :title="title"-->
<!--    width="70%"-->
<!--    append-to-body-->
<!--    destroy-on-close-->
<!--    @closed="close"-->
<!--  >-->
<!--    <div class="import-excel">-->
<!--      <el-button @click="downloadTemp">{{ this.name }}模板下载</el-button>-->
<!--      <el-button @click="importExcel">导入{{ this.name }}</el-button>-->
<!--    </div>-->
<!--  </el-dialog>-->
</template>

<style scoped lang="scss">

</style>