<script>
import { defineComponent } from 'vue'
import { processWork } from '@/axios/o2oa'
import { o2 } from "@o2oa/component";

export default defineComponent({
  name: 'OrderDialog',
  emits: ['update:show'],
  data() {
    return {
    }
  },
  props: {
    orderData: {
      type: Object
    },
    show: {
      type: Boolean
    }
  },
  computed: {
    showDialog: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    }
  },
  watch: {
  },
  methods: {
    close(){
      this.showDialog = false
    },
    openDetail(){
      o2.api.page.openWork(this.orderData.workId)
    },
    statusType(status){
      let typeMap = {
        未派工: 'danger',
        已派工: 'success',
        服务中: 'primary'
      }
      return typeMap[status]
    }
  }
})
</script>

<template>
  <div class="order-dialog" v-if="show">
    <div class="detail-title">
      <div class="detail-title-content">工单详情</div>
      <div class="detail-title-close" @click="close">
        <el-icon>
          <Close />
        </el-icon>
      </div>
    </div>
    <div class="detail-header">
      <el-form>
        <el-form-item label="接件单号">
          <div>{{ orderData.code }}</div>
        </el-form-item>
        <el-form-item label="客户名称">
          <div>{{ orderData.customerName }}</div>
        </el-form-item>
        <el-form-item label="联系地址">
          <div>{{ orderData.address }}</div>
        </el-form-item>
        <el-form-item label="联系电话">
          <div>{{ orderData.phone }}</div>
        </el-form-item>
        <el-form-item label="预约时间">
          <div>{{ orderData.appointmentTime }}</div>
        </el-form-item>
        <div class="detail-status">
          <el-tag :type="statusType(orderData.status)">{{orderData.status}}</el-tag>
        </div>
      </el-form>
    </div>
    <el-button type="primary" size="large" block @click="openDetail">查看详情</el-button>
  </div>
</template>

<style scoped lang="scss">
.order-dialog {
  position: absolute;
  top: 120px;
  right: 50px;
  width: 363px;
  background: #ffffff;
  box-shadow: 0 0 7px 0 rgba(182, 182, 182, 0.5);
  border-radius: 11px;
  padding: 25px;

  & > * {
    width: 100%;
  }

  .detail-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > * {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .detail-title-content {
      font-size: 18px;

      &:before {
        content: ' ';
        width: 6px;
        height: 16px;
        background: var(--dubhe-color-primary);
        margin-right: 10px;
      }
    }

    .detail-title-close {
      font-size: 16px;
      cursor: pointer;
    }
  }

  .detail-header {
    position: relative;
    padding: 18px 0;
    .detail-status{
      position: absolute;
      top: 0;
      right: 0;
    }
    .dubhe-form{
      position: relative;
    }
    .dubhe-form-item {
      margin-bottom: 0;
    }
  }
}

.transparent-form {
  max-height: 400px;
  overflow: auto;

  .order-form {
    max-height: none;
  }
}

.order-form {
  max-height: 400px;
  padding: 15px;
  overflow: auto;
  background: #f5f7fa;
  border-radius: 7px;

  .order-form-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 36px;

    & + .order-form-item {
      border-top: 1px solid #d9d9d9;
    }

    //&>div:nth-child(1){
    //
    //}
  }

  .order-button {
    position: absolute;
    right: 0;
    top: 0;

    :deep(.dubhe-button) {
      padding: 10px;
      font-size: 12px;
    }
  }
}
</style>