import axios  from "./axios.config";
// 获取显示字段 (projectGateway)
export const getProjectGatewayFields = (tableId='projectGateway')=>axios.get(`/jaxrs/project_gateway/fields?tableId=${tableId}`)
// 获取查询条件
export const getProjectGatewayConditions = ()=>axios.get('/jaxrs/project_gateway/conditions')
// 查询
export const getProjectGatewayPage = (data)=>axios.post('/jaxrs/project_gateway/page',data)
// 详情
export const getProjectGatewayDetail = (data)=>axios.get(`/jaxrs/project_gateway/${data.id}`)
// 保存
export const addProjectGateway = (data)=>axios.post(`/jaxrs/project_gateway`,data)
// 编辑
export const editProjectGateway = (data)=>axios.put(`/jaxrs/project_gateway`,data)
// 删除
export const deleteProjectGateway = (data)=>axios.delete(`/jaxrs/project_gateway/${data.id}`)