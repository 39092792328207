<script>
import { defineComponent } from 'vue'
import { detailMixins } from '@/common/detailMixins'
import { workOrderEditMixin } from '@/views/WorkOrder/workOrderEditMixins'
import BaseInfoEdit from '@/views/WorkOrder/BaseInfoEdit.vue'

export default defineComponent({
  name: 'RepairWorkOrderEdit',
  components: { BaseInfoEdit },
  mixins: [detailMixins, workOrderEditMixin],
  data() {
    return {}
  },
  computed: {
    rules() {
      let rules = {
        consumerId: [{ required: true, message: '请选择客户', trigger: 'change' }],
        district: [{ required: true, trigger: 'change', message: '请选择区' }],
        address: [{ required: true, trigger: 'change', message: '请输入详细地址' }],
        workOrderSource: [{ required: true, message: '请选择信息来源', trigger: 'change' }],
        bizType: [{ required: true, message: '请选择业务类型', trigger: 'change' }],
        serviceType: [{ required: true, message: '请选择服务性质', trigger: 'change' }],
        faultDesc: [{ required: true, message: '请输入故障描述及可能原因', trigger: 'change' }],
        workNum: [{ required: true, trigger: 'change', message: '请输入工单数' }],
        receptionDeptId: [{ required: true, message: '请选择接单部门', trigger: 'change' }]
      }
      if (this.batch) {
        delete rules.district
        delete rules.address
        delete rules.consumerId
      } else {
        delete rules.receptionDeptId
      }
      return rules
    }
  },
  watch: {},
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.baseForm.workOrderType = 'repair'
      this.initProjectList()
      this.initMaterialList()
      this.initUnitList()
    }
  }
})
</script>
<template>
  <el-drawer
    v-model="showDrawer"
    size="95%"
    direction="btt"
    destroy-on-close
    @callback="editConfirm"
    @open="getDetail"
  >
    <template #header>
      <div>{{ title }}</div>
    </template>
    <template #default>
      <div class="detail-wrapper" v-loading="loading">
        <el-form :model="editForm" :label-position="labelPosition" :rules="rules" ref="form">
          <el-row :gutter="20">
            <BaseInfoEdit v-model:form="editForm" :batch="batch">
              <template #default>
                <el-col :span="8">
                  <el-form-item label="所属项目" prop="projectId">
                    <SelectWithButton
                      :clearable="true"
                      :remote-method="getMaintenanceProjectSelectList"
                      v-model="editForm.projectId"
                      :default-label="editForm.projectName"
                      labelKey="name"
                      valueKey="id"
                      dialogName="MaintenanceProject"
                      @select-change="projectChange"
                    >
                    </SelectWithButton>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="服务性质" prop="serviceType">
                    <SelectV2
                      v-model="editForm.serviceType"
                      :list="dictStore.getDictByType('serviceType')"
                      labelKey="label"
                      valueKey="val"
                      placeholder="请选择"
                    ></SelectV2>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="机器型号">
<!--                    <el-input v-model="editForm.materialName"></el-input>-->
                    <el-autocomplete
                      class="max-width"
                      v-model="editForm.materialName"
                      :fetch-suggestions="materialSelectQuery"
                      clearable
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="订单收费">
                    <el-switch v-model="editForm.isOrderPay"></el-switch>
                  </el-form-item>
                </el-col>
              </template>
              <template #other>
                <el-col :span="24">
                  <el-form-item label="故障描述及可能原因" prop="faultDesc">
                    <el-input type="textarea" v-model="editForm.faultDesc"></el-input>
                  </el-form-item>
                </el-col>
              </template>
            </BaseInfoEdit>
          </el-row>
        </el-form>
      </div>
    </template>
    <template #footer>
      <div class="drawer-footer" v-if="isDefaultRepair">
        <el-button @click="handleResetClick" :disabled="loading">重置</el-button>
        <el-button type="primary" @click="handleSaveClick(false)" :disabled="loading"
          >暂存</el-button
        >
        <el-button type="primary" @click="handleSaveClick(true)" icon="Document" :disabled="loading"
          >保存</el-button
        >
      </div>
      <div class="drawer-footer" v-else>
        <el-button
          @click="handleResetClick"
          :disabled="loading"
          v-if="showByAuditStatus(['pending_submission'])"
          >重置
        </el-button>
        <el-button type="primary" @click="handleSaveClick(false)" :disabled="loading"
          >保存
        </el-button>
        <el-button
          type="primary"
          @click="handleSaveClick(true)"
          icon="Promotion"
          :disabled="loading"
          v-if="showByAuditStatus(['pending_submission'])"
          >提交
        </el-button>
      </div>
    </template>
  </el-drawer>
</template>
<style scoped lang="scss"></style>