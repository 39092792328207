import axios from './axios.config'
// 获取显示字段
export const getVehicleDepartureFields = (tableId='vehicleDeparture')=>axios.get(`/jaxrs/vehicle_departure/fields?tableId=${tableId}`)
// 获取查询条件
export const getVehicleDepartureConditions = ()=>axios.get('/jaxrs/vehicle_departure/conditions')
// 查询
export const getVehicleDeparturePage = (data) =>axios.post('/jaxrs/vehicle_departure/page',data)
// 详情
export const getVehicleDepartureDetail = (data) => {
  let url
  if (data.oper) {
    url = `/jaxrs/vehicle_departure/${data.id}?oper=${data.oper}`
  } else {
    url = `/jaxrs/vehicle_departure/${data.id}`
  }
  return axios.get(url)
}
// 保存
export const addVehicleDeparture = (data) =>axios.post(`/jaxrs/vehicle_departure`,data)
// 编辑
export const editVehicleDeparture = (data) =>axios.put(`/jaxrs/vehicle_departure`,data)
// 删除
export const deleteVehicleDeparture = (data) =>axios.delete(`/jaxrs/vehicle_departure/${data.id}`)
// 根据登记类型获取检查项
export const getVehicleDepartureByType = (data)=>axios.get(`/jaxrs/vehicle_departure/get_by_departure_type/${data.type}`)
