<script>
import { defineComponent } from "vue";
import IdentityDialog from "@/components/ProcessStart/IdentityDialog.vue";
import { createProcessWork, getProcessDetailByFlag, getViewDetail, listAvailableIdentityWithProcess } from "@/axios";

export default defineComponent({
  name: "ProcessStart",
  components:{IdentityDialog},
  data(){
    return {
      showIdentityDialog: false,
      identityList:[],
      processDetail:{}
    }
  },
  methods:{
    addProcessByDataView(dataViewId) {
      getViewDetail(dataViewId).then(res=>{
        let viewDetail = JSON.parse(res.data.data)
        this.processDetail = viewDetail.where.processList[0]
        this.getIdentityList()
      })
    },
    addProcessById(processId) {
      getProcessDetailByFlag({flag:processId}).then(res=>{
        this.processDetail = res.data
        this.getIdentityList()
      })
    },
    getIdentityList(){
      listAvailableIdentityWithProcess({ id: this.processDetail.id }).then((res) => {
        if (res.data) {
          let list = res.data
          if (list.length === 0) {
            this.$message.warning('您无权限做此操作')
          } else if (list.length === 1) {
            let identity = list[0]
            this.identityCallback(identity)
          } else {
            this.identityList = list
            this.showIdentityDialog = true
          }
        }
      })
    },
    identityCallback(identity) {
      let reqData = {
        id: this.processDetail.id,
        identity: identity.distinguishedName,
        title: this.processDetail.name
      }
      this.$refs.identityDialog.loading = true
      createProcessWork(reqData).then((res) => {
        if (res) {
          this.showIdentityDialog = false
          this.componentStore.showProcessDetail(res.data[0])
        }
      }).catch(()=>{
        this.$refs.identityDialog.loading = false
      })
    }
  }
});
</script>

<template>
  <IdentityDialog
    ref="identityDialog"
    v-model="showIdentityDialog"
    :list="identityList"
    :process-detail="processDetail"
    @callback="identityCallback"
  ></IdentityDialog>
</template>

<style scoped lang="scss">

</style>