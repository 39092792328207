<script>
import { defineComponent } from "vue";
import * as Excel from "exceljs";
import _ from 'lodash'
export default defineComponent({
  name: "ImportExcelOld",
  emits:['callback'],
  data() {
    return {
      loading: false,
      active: 1,
      stepList: [
        {
          title: "选择导入文件"
        },
        {
          title: "预览数据"
        },
        {
          title: "异常数据"
        }
      ],
      infoList: [
        {
          content: "为保证数据导入顺利，推荐您使用标准模板",
          download: true
        }
      ],
      file: null,
      excelData: [],
      activeSheet: null,
      repeatData: [],
      repeatPage: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      repeatInfoList: [
        {
          content: "您有异常数据，可下载查看",
          download: true
        }
      ]
    };
  },
  props: {
    colList: {
      type: Array,
      default() {
        return [];
      }
    },
    importMethod: {
      type: Function
    },
    importCallback: {
      type: Function
    }
  },
  computed: {
    tableNoEditOptions() {
      let list = this.colList.map(item => {
        return {
          name: item.value,
          label: item.label
        };
      });
      return list;
    },
    backDisabled() {
      return this.active === 1 || this.loading;
    },
    confirmDisabled() {
      return this.active !== 2 || this.loading;
    },
    activeTable() {
      let table = this.excelData.find((item) => item.id === this.activeSheet);
      return table || {};
    },
    tableList() {
      if (this.activeTable?.value) {
        return this.activeTable.value.map((item, index) => {
          let row = {
            index
          };
          for (let i = 0; i < item.length; i++) {
            row[`key${i}`] = item[i];
          }
          return row;
        });
      }
      return [];
    },
    tableOption() {
      if (this.activeTable?.value && this.activeTable?.value[0]) {
        let length = this.activeTable?.value[0].length;
        let list = [];
        for (let i = 0; i < length; i++) {
          let col = {
            prop: `key${i}`,
            width: this.colList[i].width
          };
          list.push(col);
        }
        return list;
      }
      return [];
    },
    requireList() {
      let list = [];
      this.colList.forEach((item, index) => {
        if (item.required) {
          list.push(index);
        }
      });
      return list;
    },
    requireMessage() {
      let list = [];
      this.colList.forEach((item, index) => {
        if (item.required) {
          list.push(item.label);
        }
      });
      let str = list.join(",");
      return `${str}为必填项，请重新填写`;
    },
    formatList() {
      let list = [];
      this.colList.forEach((item, index) => {
        if (item.dateFormat) {
          list.push(index);
        }
      });
      return list;
    },
    repeatPageData() {
      let { pageSize, pageNum } = this.repeatPage;
      let start = (pageNum - 1) * pageSize;
      let end = pageNum * pageSize;
      let list = this.repeatData.slice(start, end);
      return list;
    }
  },
  mounted() {
  },
  methods: {
    async readExcel(file) {
      const workbook = new Excel.Workbook();
      await workbook.xlsx.load(file);
      let sheetList = workbook.worksheets;

      let sheets = sheetList.map((sheet) => {
        let value = sheet.getSheetValues();
        value.shift(1);
        value.forEach((row) => {
          row.shift(1);
          row.forEach((rItem, rIndex) => {
            if (this.formatList.includes(rIndex)) {
              let format = this.colList[rIndex].dateFormat;
              if (_.isDate(rItem)) {
                row[rIndex] = this.getTime(rItem, format);
              }
            }
          });
        });
        value = value.filter(item=>item.length > 0)
        return {
          id: sheet.id,
          name: sheet.name,
          value
        };
      });
      if (this.validateSheet(sheets)) {
        this.$message.warning(this.requireMessage);
        return [];
      }
      return sheets;
    },
    validateSheet(sheetList) {
      if (sheetList.length > 0) {
        let value = _.cloneDeep(sheetList[0].value);
        value.shift(1);
        let checkError = value.some(item => {
          return this.requireList.some(requireIndex => {
            return !item[requireIndex];
          });
        });
        return checkError;
      } else {
        return true;
      }
    },
    async downloadExample() {
      if (!this.colList || this.colList.length === 0) {
        this.$message.error("无导入列，无法下载模板");
      }
      let defaultRow = {};
      let columns = this.colList.map((item) => {
        if (item.demo) {
          defaultRow[item.value] = item.demo;
        }
        return {
          header: item.label,
          key: item.value,
          width: item.width ? item.width / 10 : 20
        };
      });
      const workbook = new Excel.Workbook();
      const worksheet = workbook.addWorksheet("导入模板");
      worksheet.columns = columns;
      const headerRow = worksheet.getRow(1);
      headerRow.eachCell((cell) => {
        cell.style = {
          font: {
            bold: true
          },
          alignment: {
            vertical: "center",
            horizontal: "center"
          }
          // fill: {
          //   bgColor: { rgb: 'FF670000' }
          // }
        };
      });
      worksheet.addRows([defaultRow]);
      let excelBuffer = await workbook.xlsx.writeBuffer();
      let blob = new Blob([excelBuffer]);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = URL.createObjectURL(blob);
      a.download = `导入模板.xlsx`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    async downloadRepeat() {
      if (!this.colList || this.colList.length === 0) {
        this.$message.error("无导入列，无法下载");
      }
      let columns = this.colList.map((item) => {
        return {
          header: item.label,
          key: item.value,
          width: item.width ? item.width / 10 : 20
        };
      });
      const workbook = new Excel.Workbook();
      const worksheet = workbook.addWorksheet("异常数据");
      worksheet.columns = columns;
      const headerRow = worksheet.getRow(1);
      headerRow.eachCell((cell) => {
        cell.style = {
          font: {
            bold: true
          },
          alignment: {
            vertical: "center",
            horizontal: "center"
          }
          // fill: {
          //   bgColor: { rgb: 'FF670000' }
          // }
        };
      });
      worksheet.addRows(this.repeatData);
      let excelBuffer = await workbook.xlsx.writeBuffer();
      let blob = new Blob([excelBuffer]);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = URL.createObjectURL(blob);
      a.download = `异常数据.xlsx`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    handleBackClick() {
      if (this.active > 1) {
        this.active--;
      }
    },
    handleCancelClick() {
      this.$emit("update:modelValue", false);
    },
    async handleConfirmClick() {
      let tableData = this.getTableData();
      if (this.importCallback) {
        this.loading = true;
        await this.importCallback(tableData).finally(()=>{
          this.loading = false;
        })
      }else{
        this.loading = true;
        this.importMethod(tableData).then(res => {
          if (res) {
            if (res.data && res.data.length > 0) {
              this.showRepeatData(res.data);
            } else {
              // this.$message.success("导入完成，请关闭后刷新列表");
              this.handleCancelClick()
              this.$message.success("导入完成");
              this.$emit('callback')
            }
          }
        }).finally(() => {
          this.loading = false;
        });
      }
      // this.$emit('callback', tableData)
      // this.$emit('update:modelValue', false)
    },
    showRepeatData(list) {
      this.repeatData = list
      this.repeatPage.pageNum = 1;
      this.repeatPage.total = this.repeatData.length;
      this.active = 3;
      this.$message.success("导入完成，有异常数据，请查看");
    },
    getTableData() {
      if (this.excelData.length > 0) {
        let sheet = this.excelData[0];
        let keyMap = {};
        this.colList.forEach((item, index) => {
          keyMap[index] = item.value;
        });
        let excel = sheet.value.filter((item, index) => index > sheet.start);
        let tableData = excel.map(item => {
          let tableItem = {};
          item.forEach((iItem, iIndex) => {
            tableItem[keyMap[iIndex]] = iItem;
          });
          return tableItem;
        });
        return tableData;
      } else {
        return [];
      }
    },
    close() {
      this.active = 1;
      this.excelData = [];
      this.repeatData = [];
    },
    async fileChange(uploadFile, uploadFiles) {
      if (uploadFile) {
        this.file = uploadFile.raw;
        this.excelData = await this.readExcel(this.file);
        if (this.excelData.length === 0) {
          return;
        }
        this.excelData.forEach((item) => {
          item.start = 0;
        });
        if (this.excelData.length > 0) {
          this.activeSheet = this.excelData[0].id;
        }
        this.active = 2;
      }
    },
    tableCurrentChange(row) {
      // 设置标题行
      // let table = this.excelData.find((item) => item.id === this.activeSheet)
      // if (row) {
      //   table.start = row.index
      // }
    },
    hasCheck(id) {
      return id === this.activeTable.start;
    }
  }
});
</script>

<template>
  <el-dialog
    v-bind="$attrs"
    title="导入数据"
    width="70%"
    append-to-body
    destroy-on-close
    @closed="close"
  >
    <div class="import-excel" v-loading="loading">
      <el-steps :active="active" align-center>
        <el-step v-for="(item, index) in stepList" :title="item.title" :key="index" />
      </el-steps>
      <div v-if="active === 1">
        <ul class="info">
          <li v-for="(item, index) in infoList" :key="index">
            <span>{{ item.content }}</span>
            <el-button v-if="item.download" type="primary" text @click="downloadExample"
            >点击下载
            </el-button>
          </li>
        </ul>
        <el-upload
          drag
          accept=".xls,.xlsx"
          :auto-upload="false"
          :show-file-list="false"
          :on-change="fileChange"
        >
          <div class="upload-notice">
            <el-icon class="icon">
              <UploadFilled />
            </el-icon>
            <div>选择或拖拽文件上传</div>
          </div>
        </el-upload>
      </div>
      <div v-if="active === 2">
        <!--        <div class="sheet-select">-->
        <!--          &lt;!&ndash;          <el-select v-model="activeSheet">&ndash;&gt;-->
        <!--          &lt;!&ndash;            <el-option&ndash;&gt;-->
        <!--          &lt;!&ndash;              v-for="item in excelData"&ndash;&gt;-->
        <!--          &lt;!&ndash;              :key="item.id"&ndash;&gt;-->
        <!--          &lt;!&ndash;              :value="item.id"&ndash;&gt;-->
        <!--          &lt;!&ndash;              :label="item.name"&ndash;&gt;-->
        <!--          &lt;!&ndash;            ></el-option>&ndash;&gt;-->
        <!--          &lt;!&ndash;          </el-select>&ndash;&gt;-->
        <!--          <span>点击任意一行可将其设置为标题行，标题行之前的数据不导入。</span>-->
        <!--        </div>-->
        <slot name="preview-header"></slot>
        <div>
          <el-table
            :data="tableList"
            stripe
            border
            highlight-current-row
            empty-text="暂无数据"
            :show-header="false"
            height="400"
            @current-change="tableCurrentChange"
            style="width: 100%"
          >
            <el-table-column label="序号" align="center" width="80" fixed="left">
              <template #default="scope">
                <!--                :class="{ hasCheck: hasCheck(scope.$index) }"-->
                <div class="order-number">
                  <span class="number">
                    {{ scope.$index + 1 }}
                  </span>
                  <el-tag>标题行</el-tag>
                </div>
              </template>
            </el-table-column>
            <template v-for="item in tableOption" :key="item.props">
              <el-table-column :label="item.prop" :prop="item.prop"
                               :min-width="item.width&&`${item.width}px`"></el-table-column>
            </template>
          </el-table>
        </div>
      </div>
      <div v-if="active === 3">
        <ul class="info">
          <li v-for="(item, index) in repeatInfoList" :key="index">
            <span>{{ item.content }}</span>
            <el-button v-if="item.download" type="primary" text @click="downloadRepeat"
            >点击下载
            </el-button>
          </li>
        </ul>
        <el-table
          :data="repeatPageData"
          stripe
          border
          highlight-current-row
          empty-text="暂无数据"
          height="400"
          style="width: 100%"
        >
          <el-table-column label="序号" align="center" width="80" fixed="left">
            <template #default="scope">
              {{ ((repeatPage.pageNum - 1) * repeatPage.pageSize) + scope.$index + 1 }}
            </template>
          </el-table-column>
          <template v-for="item in tableNoEditOptions" :key="item.name">
            <el-table-column :label="item.label" :prop="item.name"
                             :min-width="item.width&&`${item.width}px`"></el-table-column>
          </template>
        </el-table>
        <div class="repeat-data-pagination">
          <el-pagination
            layout="total, prev, pager, next"
            background
            v-model:current-page="repeatPage.pageNum"
            :page-size="repeatPage.pageSize"
            :total="repeatPage.total"
          />
        </div>
      </div>
    </div>
    <template #footer>
      <el-button @click="handleBackClick" :disabled="backDisabled">上一步</el-button>
      <el-button @click="handleCancelClick" :disabled="loading">关闭</el-button>
      <el-button type="primary" @click="handleConfirmClick" :disabled="confirmDisabled"
      >确认
      </el-button>
    </template>
  </el-dialog>
</template>

<style scoped lang="scss">
.import-excel {
  .info {
    li {
      line-height: var(--dubhe-component-size);

      .dubhe-button {
        margin-left: 10px;
      }
    }
  }

  .upload-notice {
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon {
      color: var(--dubhe-text-color-secondary);
      font-size: 80px;
      margin-bottom: 10px;
    }
  }
}

.sheet-select {
  padding: 20px 0;

  & > span {
    margin-left: 10px;
  }
}

.order-number {
  //cursor: pointer;

  .number {
    line-height: 24px;
  }

  .dubhe-tag {
    display: none;
  }

  //&:hover {
  //  .number {
  //    display: none;
  //  }
  //
  //  .dubhe-tag {
  //    display: flex;
  //  }
  //}

  &.hasCheck {
    .number {
      display: none;
    }

    .dubhe-tag {
      display: flex;
    }
  }
}

.repeat-data-pagination {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
</style>
