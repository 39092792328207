import axios from './axios.config'
//获取显示字段
export const getSwapOrderFields = (tableId='exchangeNote') =>axios.get(`/jaxrs/exchange_note/fields?tableId=${tableId}`)
// 获取查询条件
export const getSwapOrderConditions = () =>axios.get('/jaxrs/exchange_note/conditions')
// 查询
export const getSwapOrderPage = (data) =>axios.post('/jaxrs/exchange_note/page',data)
// 详情
export const getSwapOrderDetail = (data) =>{
    let url
    if(data.oper){
        url=`/jaxrs/exchange_note/${data.id}?oper=${data.oper}`
    }else{
        url=`/jaxrs/exchange_note/${data.id}`
    }
    return axios.get(url)
}
// 保存（新增）
export const addSwapOrder = (data) =>axios.post(`/jaxrs/exchange_note`,data)
// 修改
export const editSwapOrder = (data) =>axios.put(`/jaxrs/exchange_note`,data)
// 删除
export const deleteSwapOrder = (data) =>axios.delete(`/jaxrs/exchange_note/${data.id}`)
// 换货原因（获取字典值）
export const getReplacementReason = () =>axios.get('/jaxrs/dict/get_by_type/exchangeReason')
// 导出接口
export const exportSwapOrder = (data) =>
    axios.post(`/jaxrs/exchange_note/export`,data,{
        responseType:'blob'
    })
