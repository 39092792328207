import axios from './axios.config'
//获取显示字段
export const getSalesPaymentFields = (tableId='salesCollection') =>axios.get(`/jaxrs/sales_collection/fields?tableId=${tableId}`)
//获取查询条件
export const getSalesPaymentConditions = () =>axios.get('/jaxrs/sales_collection/conditions')
//获取分页数据（查询）
export const getSalesPaymentPage = (data) =>axios.post('/jaxrs/sales_collection/page',data)
//详情
export const getSalesPaymentDetail = (data) =>axios.get(`/jaxrs/sales_collection/${data.id}`)
//保存
export const addSalesPayment = (data) =>axios.post(`/jaxrs/sales_collection` ,data)
//修改
export const editSalesPayment = (data) =>axios.put(`/jaxrs/sales_collection`,data)
//删除
export const deleteSalesPayment = (data) =>axios.delete(`/jaxrs/sales_collection/${data.id}`)
//导出
export const exportSalesPayment = (data) =>
    axios.post(`/jaxrs/sales_collection/export`,data,{
        responseType:'blob'
    })
