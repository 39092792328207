import axios from './axios.config'
// 获取显示字段 Salary
export const getSalaryFields = (tableId='salary')=>axios.get(`/jaxrs/hr/salary/fields?tableId=${tableId}`)
// 查询字段
export const getSalaryConditions = ()=>axios.get('/jaxrs/hr/salary/conditions')
// 分页查询
export const getSalaryPage = (data)=>axios.post('/jaxrs/hr/salary/page',data)
// 详情
export const getSalaryDetail = (data)=>axios.get(`/jaxrs/hr/salary/${data.id}`)
// 保存
export const addSalary = (data)=>axios.post(`/jaxrs/hr/salary`,data)
// 修改
export const editSalary = (data)=>axios.put(`/jaxrs/hr/salary`,data)
// 删除
export const deleteSalary = (data)=>axios.delete(`/jaxrs/hr/salary/${data.id}`)