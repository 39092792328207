import axios from './axios.config'
//领用退库申请 GoodsReturned
export const getGoodsReturnedFields = (tableId= 'goodsReturn')=>axios.get(`/jaxrs/goods_returned/fields?tableId=${tableId}`)
// 查询条件
export const getGoodsReturnedConditions = () =>axios.get('/jaxrs/goods_returned/conditions')
//查询
export const getGoodsReturnedPage = (data)=>axios.post(`/jaxrs/goods_returned/page`,data)
// 详情
export const getGoodsReturnedDetail = (data)=>{
  let url
  if(data.oper){
    url = `/jaxrs/goods_returned/${data.id}?oper=${data.oper}`
  }else{
    url = `/jaxrs/goods_returned/${data.id}`
  }
  return axios.get(url)
}
// 保存
export const addGoodsReturned = (data)=>axios.post(`/jaxrs/goods_returned`,data)
// 编辑
export const editGoodsReturned = (data) =>axios.put(`/jaxrs/goods_returned`,data)
// 删除
export const deleteGoodsReturned = (data) =>axios.delete(`/jaxrs/goods_returned/${data.id}`)
// 入库
export const inStoreGoodsReturned = (data) =>axios.put(`/jaxrs/goods_returned/stock_out`,data)
