import axios from './axios.config'

// 播放监控视频
export const vehicleVideoPlay = (data) =>axios.post(`/jaxrs/vehicle_video/play/${data.vehicleId}/${data.deviceId}/${data.channel}`,data)
// 停止监控视频
export const vehicleVideoStop = (data) =>axios.post(`/jaxrs/vehicle_video/stop/${data.vehicleId}/${data.deviceId}/${data.channel}`,data)
// 回放列表
export const getVehicleVideoMediaList = (data) =>axios.post(`/jaxrs/vehicle_video/media/list/${data.vehicleId}`,data)
// 播放回放
export const vehicleVideoMediaPlay = (data) =>axios.post(`/jaxrs/vehicle_video/media/play/${data.vehicleId}`,data)
// 停止回放
export const vehicleVideoMediaStop = (data) =>axios.post(`/jaxrs/vehicle_video/media/stop/${data.vehicleId}`,data)
