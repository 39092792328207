import axios from "./axios.config"
//安检计划 SecurityCheckPlan
// 显示字段
export const getSecurityCheckPlanFields = (tableId ="securityCheckPlan") =>axios.get(`/jaxrs/security_check_plan/fields?tableId=${tableId}`)
// 获取查询条件
export const getSecurityCheckPlanConditions = ()=>axios.get('/jaxrs/security_check_plan/conditions')
// 查询
export const getSecurityCheckPlanPage = (data)=>axios.post('/jaxrs/security_check_plan/page',data)
// 详情
export const getSecurityCheckPlanDetail = (data)=>axios.get(`/jaxrs/security_check_plan/${data.id}`)
// 保存
export const addSecurityCheckPlan = (data)=>axios.post(`/jaxrs/security_check_plan`,data)
// 编辑
export const editSecurityCheckPlan = (data)=>axios.put(`/jaxrs/security_check_plan`,data)
// 删除
export const deleteSecurityCheckPlan= (data)=>axios.delete(`/jaxrs/security_check_plan/${data.id}`)
// 导出
export const exportSecurityCheckPlan = (data)=>axios.post(`/jaxrs/security_check_plan/export`,data,{
  responseType:'blob'
})
// 获取剩余工单数
export const getSecurityCheckPlanResidueWorkOrderNum = (data)=>axios.post(`/jaxrs/security_check_plan/get_residue_work_order_num`,data)
