<script>
import { defineComponent } from "vue";
import SelectUserDialog from "./SelectUserDialog.vue";
import { getUserIdentityListLike, getPersonAll, getUserListByUnit } from "@/axios";
import _ from 'lodash'

/**
 * multiple 多选，使用时绑定值需要为数组 []
 * 验证trigger需指定change
 */
export default defineComponent({
  name: "SelectUser",
  components: {
    SelectUserDialog
  },
  emits: ["change", "update:modelValue"],
  data() {
    return {
      totalList: [],
      showDialog: false
    };
  },
  props: {
    modelValue: {
      required: true
    },
    multiple: {
      type: Boolean,
      default() {
        return false;
      }
    },
    list: {
      type: Array
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      }
    },
    placeholder: {
      type: String
    },
    user: {
      type: Boolean,
      default() {
        return false;
      }
    },
    change: {
      type: Function
    },
    conditions: {
      type: Array
    },
    valueKey:{
      type: String,
      default(){
        return 'id'
      }
    },
    unitName: {}
  },
  computed: {
    dialogList() {
      if (this.list) {
        return this.list;
      } else {
        return this.totalList;
      }
    },
    tagList() {
      if (this.value) {
        return this.dialogList.filter((item) => this.value.includes(item[this.valueKey]));
      }
      return [];
    },
    firstTag() {
      if (this.tagList.length > 0) {
        return this.tagList[0];
      } else {
        return false;
      }
    },
    otherTagLength() {
      if (this.tagList.length > 1) {
        return this.tagList.length - 1;
      } else {
        return false;
      }
    },
    value: {
      get() {
        if (this.multiple) {
          return this.modelValue;
        } else {
          return this.modelValue ? [this.modelValue] : [];
        }
      },
      set(val) {
        if (this.multiple) {
          this.$emit("update:modelValue", val);
          this.$emit("change", this.getTagList(val));
          if (this.change) {
            this.change(this.getTagList(val));
          }
          if (this?.$parent?.validate) {
            this.$parent.validate("change", (trigger, callback) => {
              return trigger;
            });
          }
        } else {
          this.$emit("update:modelValue", val[0]);
          this.$emit("change", this.getTagList(val)[0]);
          if (this.change) {
            this.change(this.getTagList(val)[0]);
          }
          if (this?.$parent?.validate) {
            this.$parent.validate("change", (trigger, callback) => {
              return trigger;
            });
          }
        }
      }
    },
    inputPlaceholder() {
      if (this.placeholder) {
        return this.placeholder;
      }
      if (this.multiple) {
        return "请选择（可多选）";
      }
      return "请选择";
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (!this.list) {
        this.initUserList();
      }
    },
    async initUserList() {
      if (this.unitName) {
        let unitNameList = _.isArray(this.unitName)? this.unitName : this.unitName.split(',')
        let totalList = []
        await Promise.all(unitNameList.map(async unitItem => {
          let unitFlag = this.dictStore.getDictItemByTypeLabel('unit', unitItem).val
          await getUserListByUnit({ flag: unitFlag }).then(res => {
            if (this.user) {
              totalList.push(...res.data.map(item => {
                return {
                  ...item,
                  id: item.person
                }
              }))
            } else {
              totalList.push(...res.data)
            }
          });
        }))
        if(this.user){
          totalList = _.uniqBy(totalList,'id')
        }
        this.totalList = totalList
        // let unitFlag = this.dictStore.getDictItemByTypeLabel('unit', this.unitName).val
        // getUserListByUnit({ flag: unitFlag }).then(res => {
        //   if (this.user) {
        //     this.totalList = res.data.map(item => {
        //       return {
        //         ...item,
        //         id: item.person
        //       }
        //     });
        //     console.log(this.totalList);
        //   } else {
        //     this.totalList = res.data;
        //   }
        // });
      } else {
        if (this.user) {
          getPersonAll().then(res => {
            this.totalList = res.data;
          });
        } else {
          getUserIdentityListLike({ key: " " }).then((res) => {
            this.totalList = res.data;
          });
        }
      }
    },
    // filterListByParams(list){
    //   if(this.conditions){
    //     console.log(list);
    //     console.log(this.conditions);
    //     return list
    //   }else{
    //     return list
    //   }
    // },
    handleCloseClick(tag) {
      this.value = this.value.filter((item) => item !== tag[this.valueKey]);
    },
    openDialog() {
      if (this.disabled) {
        return;
      }
      this.showDialog = true;
    },
    filterUserName(item) {
      if (this.user) {
        return item.name;
      }
      // return `${item.name}-${item.unitLevelName}`;
      return `${item.name}-${item.unitName}`;
    },
    getTagList(val) {
      return this.dialogList.filter((item) => val.includes(item[this.valueKey]));
    }
  }
});
</script>

<template>
  <div class="dubhe-select" @click="openDialog" :class="{ 'is-disabled': disabled }">
    <div class="select-trigger dubhe-tooltip__trigger dubhe-tooltip__trigger">
      <div class="dubhe-select__tags">
        <span class="dubhe-select-tags-wrapper has-prefix">
          <el-tag
            :closable="!disabled"
            v-if="firstTag"
            @close="handleCloseClick(firstTag)"
            :type="disabled ? 'info' : ''"
          >
            {{ filterUserName(firstTag) }}
          </el-tag>
          <el-tag v-if="otherTagLength"> +{{ otherTagLength }} </el-tag>
        </span>
      </div>
      <div class="dubhe-input dubhe-input--suffix" :class="{ 'is-disabled': disabled }">
        <div class="dubhe-input__wrapper">
          <div class="input-dom">
            <span v-if="!firstTag" class="select-placeholder">{{ inputPlaceholder }}</span>
          </div>
          <span class="dubhe-input__suffix">
            <span class="dubhe-input__suffix-inner">
              <el-icon><User /></el-icon>
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
  <SelectUserDialog
    v-model="showDialog"
    :list="dialogList"
    v-model:value="value"
    :multiple="multiple"
    :user="user"
    :valueKey="valueKey"
  ></SelectUserDialog>
</template>

<style scoped lang="scss">
.dubhe-select__tags {
  width: 100%;
}

.dubhe-select.is-disabled {
  cursor: not-allowed;

  & * {
    cursor: not-allowed;
  }
}

.select-placeholder {
  color: var(--dubhe-input-placeholder-color)
}

.input-dom {
  flex: 1;
  //height: 30px;
  height: calc(var(--dubhe-component-size) - 2px);
}
</style>
