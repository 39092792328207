import axios from './axios.config'
// 入职登记
// 显示字典类型显示字段
export const getEntryRegistrationFields = (tableId='entryRegistration') => axios.get(`/jaxrs/hr/contract_approve/fields?tableId=${tableId}`)
// 获取查询条件
export const getEntryRegistrationConditions = () =>axios.get('/jaxrs/hr/contract_approve/conditions')
// 分页数据（查询）
export const getEntryRegistrationPage = (data) =>axios.post('/jaxrs/hr/contract_approve/page',data)
// 编辑
export const editEntryRegistration = (data) =>axios.put(`/jaxrs/hr/contract_approve`,data)
// 添加
export const addEntryRegistration = (data) =>axios.post(`/jaxrs/hr/contract_approve`,data)
// 删除
export const deleteEntryRegistration = (data) =>axios.delete(`/jaxrs/hr/contract_approve/${data.id}`)
// 详情
export const getEntryRegistrationDetail = (data) =>axios.get(`/jaxrs/hr/contract_approve/${data.id}`)
// 编辑审核状态
export const editModifyAuditStatus = (data)=>axios.put(`/jaxrs/hr/contract_approve/status`,data)
// 确认入职
export const confirmOnboarding = (data)=>axios.put(`/jaxrs/hr/contract_approve/entry`,data)
// 下载合同
export const entryRegistrationContractDownload = (data) => axios.post(`/jaxrs/hr/contract_approve/contract/download/${data.id}`,data,{
  responseType:'blob'
})
// 终止
export const finishEntryRegistration = (data) =>axios.post(`/jaxrs/hr/contract_approve/finish`,data)
// 获取录取通知
export const getEntryRegistrationOffer = (data) =>axios.post(`/jaxrs/hr/contract_approve/get_offer`,data)
