<script>
import { defineComponent } from 'vue'
import MenuItem from './MenuItem.vue'

export default defineComponent({
  name: 'MenuTree',
  components: {
    MenuItem
  },
  props: {
    open: {
      type: Boolean,
      required: true
    },
    list: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      defaultActive: null
    }
  },
  watch: {
    '$route.path': {
      handler() {
        this.initActive()
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {},
  methods: {
    initActive() {
      let path = this.$route.path.replace('/','')
      let route = this.permissionStore.getRouteByPath(path)
      // this.defaultActive = this.$route.path.replace('/','')
      this.defaultActive = route.id
    }
  }
})
</script>

<template>
  <el-menu :collapse="!open" :default-active="defaultActive">
    <MenuItem v-for="(item, index) in list" :key="item.id" :data="item"></MenuItem>
  </el-menu>
</template>

<style scoped lang="scss"></style>