import axios from './axios.config'
// 获取调拨单显示字段
export const getStockTransferFields = (tableId='stockTransfer') => axios.get(`/jaxrs/stock_transfer/fields?tableId=${tableId}`)
// 获取调拨单查询条件
export const getStockTransferConditions = () => axios.get('/jaxrs/stock_transfer/conditions')
// 获取调拨单分页数据
export const getStockTransferPage = (data) => axios.post('/jaxrs/stock_transfer/page', data)
// 获取调拨单详情
export const getStockTransferDetail = (data) => {
  let url
  if (data.oper) {
    url = `/jaxrs/stock_transfer/${data.id}?oper=${data.oper}`
  } else {
    url = `/jaxrs/stock_transfer/${data.id}`
  }
  return axios.get(url)
}
// 修改调拨单
export const editStockTransfer = (data) => axios.put(`/jaxrs/stock_transfer`, data)
// 添加调拨单
export const addStockTransfer = (data) => axios.post(`/jaxrs/stock_transfer`, data)
// 删除调拨单
export const deleteStockTransfer = (data) => axios.delete(`/jaxrs/stock_transfer/${data.id}`)

export const exportStockTransfer = (data) =>
    axios.post(`/jaxrs/stock_transfer/export`,data,{
      responseType: 'blob'
    })
