import axios from './axios.config'
// 课程章节（courseChapter）
// 获取列表
export const getCourseChapterList = (data) =>axios.post('/jaxrs/course_chapter/list',data)
// 获取列表（移动端）
export const getCourseChapterListMobile = (data) =>axios.post('/jaxrs/course_chapter/list_mobile',data)
// 详情
export const getCourseChapterDetail = (data)=>axios.get(`/jaxrs/course_chapter/${data.id}`)
// 保存
export const addCourseChapter = (data) =>axios.post(`/jaxrs/course_chapter`,data)
// 编辑
export const editCourseChapter = (data)=>axios.put(`/jaxrs/course_chapter`,data)
// 删除
export const deleteCourseChapter = (data) =>axios.delete(`/jaxrs/course_chapter/${data.id}`)
// 获取列表
export const saveCourseChapterBatch = (data) =>axios.post('/jaxrs/course_chapter/batch_save_or_update',data)
