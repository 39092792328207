import axios from './axios.config'
// 油卡管理FuelCard
// 获取显示字段
export const getFuelCardFields = (tableId='FuelCard') =>axios.get(`/jaxrs/fuel_card/fields?tableId=${tableId}`)
// 获取查询条件
export const getFuelCardConditions = ()=>axios.get('/jaxrs/fuel_card/conditions')
// 查询
export const getFuelCardPage = (data) =>axios.post('/jaxrs/fuel_card/page',data)
// 详情
export const getFuelCardDetail = (data) =>axios.get(`/jaxrs/fuel_card/${data.id}`)
// 保存
export const addFuelCard = (data) =>axios.post(`/jaxrs/fuel_card`,data)
// 修改
export const editFuelCard = (data) =>axios.put(`/jaxrs/fuel_card`,data)
// 删除
export const deleteFuelCard = (data) =>axios.delete(`/jaxrs/fuel_card/${data.id}`)
// 导出
export const exportFuelCard = (data) => axios.post(`/jaxrs/fuel_card/export`,data,{
    responseType:'blob'
})