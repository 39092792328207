<script>
import { defineComponent } from "vue";
import { getRegionTree } from "@/axios";

export default defineComponent({
  name: "SelectFullAddress",
  emits: ["update:modelValue"],
  data() {
    return {
      regionTree: []
    };
  },
  props: {
    span: {
      type: Number,
      default() {
        return 6;
      }
    },
    modelValue: {
      type: Object,
      required: true
    },
    keyMap: {
      type: Object,
      default() {
        return {
          district: "district",
          street: "street",
          community: "community",
          address: "address",
          lat: "lat",
          lng: "lng"
        };
      }
    }
  },
  computed: {
    data: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    },
    checkStreet() {
      if (this.data[this.keyMap.district]) {
        let checkItem = this.regionTree.find(item => item.name === this.data[this.keyMap.district]);
        return checkItem;
      }
      return null;
    },
    streetSelectList() {
      if (this.checkStreet && this.checkStreet.children) {
        return this.checkStreet.children;
      }
      return [];
    },
    checkCommunity() {
      if (this.data[this.keyMap.street]) {
        let checkItem = this.streetSelectList.find(item => item.name === this.data[this.keyMap.street]);
        return checkItem;
      }
      return null;
    },
    communitySelectList() {
      if (this.checkCommunity && this.checkCommunity.children) {
        return this.checkCommunity.children;
      }
      return [];
    },
    address() {
      let str = "";
      let keyList = [this.keyMap.district, this.keyMap.street, this.keyMap.community, this.keyMap.address];
      keyList.forEach(item => {
        if (item && this.data[item]) {
          str += this.data[item];
        }
      });
      return str;
    }
  },
  mounted() {
    this.initRegionTree();
  },
  methods: {
    initRegionTree() {
      getRegionTree().then(res => {
        if (res) {
          this.regionTree = res.data;
        }
      });
    },
    districtChange() {
      if (this.keyMap.street) {
        this.data[this.keyMap.street] = null;
      }
      if (this.keyMap.community) {
        this.data[this.keyMap.community] = null;
      }
    },
    streetChange() {
      if (this.keyMap.community) {
        this.data[this.keyMap.community] = null;
      }
    }
  }
});
</script>

<template>
  <el-col :span="span" v-if="keyMap.district">
    <el-form-item label="区" :prop="keyMap.district">
      <SelectV2
        class="max-width"
        v-model="data[keyMap.district]"
        :list="regionTree"
        labelKey="name"
        valueKey="name"
        placeholder="请选择区"
        @select-change="districtChange"
      ></SelectV2>
    </el-form-item>
  </el-col>
  <el-col :span="span" v-if="keyMap.street">
    <el-form-item label="街道" :prop="keyMap.street">
      <SelectV2
        class="max-width"
        v-model="data[keyMap.street]"
        :list="streetSelectList"
        labelKey="name"
        valueKey="name"
        placeholder="请选择街道"
        @select-change="streetChange"
      ></SelectV2>
    </el-form-item>
  </el-col>
  <el-col :span="span" v-if="keyMap.community">
    <el-form-item label="社区" :prop="keyMap.community">
      <SelectV2
        class="max-width"
        v-model="data[keyMap.community]"
        :list="communitySelectList"
        labelKey="name"
        valueKey="name"
        placeholder="请选择社区"
      ></SelectV2>
    </el-form-item>
  </el-col>
  <el-col :span="span" v-if="keyMap.address">
    <el-form-item label="详细地址" :prop="keyMap.address">
      <el-input v-model="data[keyMap.address]" />
    </el-form-item>
  </el-col>
  <el-col :span="span" v-if="keyMap.lat">
    <el-form-item label="地理位置">
      <MapCoordinate :address="address" v-model:lat="data[keyMap.lat]"
                     v-model:lng="data[keyMap.lng]"></MapCoordinate>
    </el-form-item>
  </el-col>
</template>

<style scoped lang="scss">

</style>