import axios from './axios.config'

// 企业微信认证签名
export const signature = () => axios.get(`/jaxrs/wx/cp/js/v1/signature`)
// 逆向地理位置转换
export const getGoeEncoder = (data) => axios.get(`/jaxrs/wx/geo/encoder?location=${data.location}`)
// 人员轨迹上报
export const trajectory = (data) => axios.post('/jaxrs/wx/geo/trajectory', data)

// 查询人员轨迹
export const journey = (data) => axios.post('/jaxrs/wx/geo/journey', data)

// 逆向地理位置转换
export const getGoeParse = (data) => axios.get(`/jaxrs/wx/geo/parse?address=${data.address}`)

