// JobHandover
import axios from "./axios.config"
// 获取显示字段
export const getJobHandoverFields = (tableId="jobHandover")=>axios.get(`/jaxrs/hr/job_handover/fields?tableId=${tableId}`)
// 查询条件
export const getJobHandoverConditions = ()=>axios.get('/jaxrs/hr/job_handover/conditions')
// 查询
export const getJobHandoverPage = (data)=>axios.post('/jaxrs/hr/job_handover/page',data)
// 详情
export const getJobHandoverDetail = (data)=>axios.get(`/jaxrs/hr/job_handover/${data.id}`)
// 保存
export const addJobHandover = (data)=>axios.post(`/jaxrs/hr/job_handover`,data)
// 修改
export const editJobHandover = (data)=>axios.put(`/jaxrs/hr/job_handover`,data)
// 删除
export const deleteJobHandover = (data)=>axios.delete(`/jaxrs/hr/job_handover/${data.id}`)
// 获取任务交接待办
export const getTaskHandoverTask = (data)=>axios.get(`/jaxrs/task_handover/task/${data.id}`)
// 获取任务交接已办
export const getTaskHandoverTaskCompleted = (data)=>axios.get(`/jaxrs/task_handover/task_completed/${data.id}`)
// 获取任务交接发起的
export const getTaskHandoverWork = (data)=>axios.get(`/jaxrs/task_handover/work/${data.id}`)
// 流程交接
export const handoverTaskHandover = (data)=>axios.post(`/jaxrs/task_handover/handover`,data)
