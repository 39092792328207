<script>
import { defineComponent } from 'vue'
import { getWorkLog, getJobLog } from "@/axios";
import Divider from '@/components/Divider/Divider.vue'
import axios from "axios";
import _ from "lodash";

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Process',
  components: { Divider },
  data() {
    return {
      processList: [],
      open: true,
      source: null
    };
  },
  props: {
    workId: {},
    jobId: {}
  },
  computed: {
    requestId() {
      return this.workId || this.jobId || '';
    },
    isWorkId() {
      return !!this.workId;
    },
    triggerStyle(){
      if(this.open){
        return {
          transform:'translateX(-50%) rotate(0)'
        }
      }else{
        return {
          transform:'translateX(-50%) rotate(180deg)'
        }
      }
    }
  },
  watch: {
    requestId: {
      handler(newVal,oldVal) {
        if (newVal) {
          this.getLog();
        } else {
          this.closeGetLog()
          this.processList = [];
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    // this.init()
  },
  methods: {
    // init(){
    //   if(this.requestId){
    //     this.getLog()
    //   }else{
    //     this.processList = []
    //   }
    // },
    getLog:_.debounce(
      function () {
        this.processList = [];
        let getLogMethod;
        if (this.isWorkId) {
          getLogMethod = getWorkLog;
        } else {
          getLogMethod = getJobLog;
        }
        this.closeGetLog()
        if(!this.requestId){
          return
        }
        this.source = axios.CancelToken.source()
        getLogMethod({ id: this.requestId },this.source).then((res) => {
          if (res) {
            this.processList = res.data.filter((item) => item.fromActivityType === "manual");
          }
        }).catch(() => {
        }).finally(()=>{
          this.source = null
        })
        // getProcessDetail({id: this.requestId}).then((res)=>{
        //   // console.log(res);
        //   let recordList = res.data.recordList
        //   console.log(recordList);
        // })
        // getFormV2({}).then(res=>{
        //   console.log(res);
        //   let dataJson = res.data.form.data
        //   console.log(dataJson);
        //   let dataObj = JSON.parse(`"${dataJson}"`)
        //   dataObj = JSON.parse(dataObj)
        //   console.log(dataObj);
        // })
      },
      300,
      {
        leading: false,
        trailing: true
      }
    ),
    filterProcessType(item) {
      if (item.taskCompletedList.length) {
        return 'success'
      } else if (item.taskList.length) {
        return 'warning'
      }
      return 'success'
    },
    filterName(string){
      return string.split('@')[0]
    },
    closeGetLog(){
      if(this.source){
        this.source.cancel()
        this.source = null
      }
    },
    handleTriggerClick(){
      this.open = !this.open
    }
  }
})
</script>

<template>
  <div class="progress-wrapper" :class="{'close':!open}">
    <div class="progress">
      <Divider title="审批流程"></Divider>
      <div class="work-wrapper">
        <div class="work">
          <el-timeline>
            <el-timeline-item  v-for="item in processList" :key="item.id" :timestamp="item.fromActivityName" placement="top" :type="filterProcessType(item)">
<!--            <el-timeline-item  v-for="item in processList" :key="item.id" :timestamp="item.arrivedActivityName" placement="top" :type="filterProcessType(item)">-->
              <el-card v-for="i in item.taskCompletedList" :key="i.id">
                <div>
                  {{filterName(i.identity)}}（{{ filterName(i.unit) }}）
                </div>
                <div><el-text type="primary">{{ i.routeName }}</el-text> <el-text type="info">{{ i.completedTime }}</el-text></div>
                <div v-if="i.opinion">
                  <el-text type="info">意见：{{ i.opinion }}</el-text>
                </div>
              </el-card>
              <el-card v-for="i in item.taskList" :key="i.id">
                <div>{{ filterName(i.identity) }}（{{ filterName(i.unit) }}）正在处理</div>
                <div><el-text type="info">{{ i.startTime }}</el-text></div>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
      <div class="trigger" @click="handleTriggerClick" :style="triggerStyle">
        <el-icon>
          <DArrowRight />
        </el-icon>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.progress-wrapper {
  width: 350px;
  flex-grow: 0;
  flex-shrink: 0;
  padding-left: 30px;
  height: 100%;
  transition: all linear .2s;
  margin: 0 !important;

  &.close {
    width: 60px;

    .progress {
      width: 30px;

      & > * {
        display: none;
      }
    }
  }

  .trigger {
    position: absolute;
    display: flex !important;
    align-items: center;
    justify-content: center;
    top: 36px;
    left: 0;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: var(--dubhe-fill-color);
    border: 1px solid var(--dubhe-border-color);
    transition: all linear .2s;
    cursor: pointer;
    box-shadow: var(--dubhe-box-shadow-light);
  }

  .progress {
    position: relative;
    padding-left: 30px;
    height: 100%;
    width: 100%;
    border-left: 1px solid var(--dubhe-border-color);
    display: flex;
    flex-direction: column;
    .work-wrapper {
      position: relative;
      flex: 1;

      .work {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        overflow-y: auto;
        overflow-x: clip;
        :deep(.dubhe-timeline){
          padding-left: 10px;
          .dubhe-timeline-item__wrapper{
            padding-left: 20px;
          }
        }
        :deep(.dubhe-card){
          &+.dubhe-card{
            margin-top: 10px;
          }
          .dubhe-card__body{
            padding: 10px;
          }
        }
      }
    }
  }

  .content {
    padding: 5px 0;

    .content-item {
      padding: 5px 10px;
      font-size: 12px;
      line-height: 1.5;

      & + .content-item {
        border-top: 1px solid var(--dubhe-border-color);
      }

      .person {
        color: var(--dubhe-color-danger);
      }

      .opinion {
        color: var(--dubhe-color-primary);
        word-break: break-all;
      }

      & > div {
        padding: 3px 0;
      }
    }
  }
}
</style>