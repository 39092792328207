<script>

export default {
  name: 'TableOperateButtons',
  data(){
    return {
      showMore: true
    }
  },
  mounted() {
    let children = this.$refs.slot.children
    let childrenList = Array.from(children)
    let childrenLength = childrenList.length
    if(childrenLength > 2){
      let editIndex = childrenList.findIndex((item) => {
        return item.innerText === '编辑'
      })
      let firstIndex = editIndex < 0 ? 0 : editIndex
      childrenList.forEach((item,index)=>{
        if(index === firstIndex){
          this.$refs.first.append(item)
        }else{
          this.$refs.more.append(item)
        }
      })
    }else{
      this.showMore = false
    }
  },
  methods: {
  }
}
</script>

<template>
  <div class="table-operate-buttons">
    <span class="table-operate-buttons-first" ref="first" v-if="showMore"></span>
    <el-dropdown v-if="showMore">
      <el-button
        link
        type="primary"
        size="small"
      >更多
        <el-icon>
          <ArrowDown></ArrowDown>
        </el-icon>
      </el-button>
      <template #dropdown>
        <div class="more-buttons" ref="more"></div>
      </template>
    </el-dropdown>
    <div ref="slot">
      <slot name="default"></slot>
    </div>
    <!--    <el-popover trigger="hover" :offset="0" v-if="showMore">-->
    <!--      <template #reference>-->
    <!--        <el-button-->
    <!--          link-->
    <!--          type="primary"-->
    <!--          size="small"-->
    <!--        >更多-->
    <!--          <el-icon>-->
    <!--            <ArrowDown></ArrowDown>-->
    <!--          </el-icon>-->
    <!--        </el-button>-->
    <!--      </template>-->
    <!--      <template #default>-->
    <!--        <div class="more-buttons" ref="more"></div>-->
    <!--      </template>-->
    <!--    </el-popover>-->
  </div>
</template>

<style scoped lang="scss">
.table-operate-buttons{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  .table-operate-buttons-first{
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  :deep(.dubhe-button){
    &+.dubhe-button{
      margin-left: 5px;
    }
  }
}
.more-buttons {
  display: flex;
  flex-direction: column;
  //width: 200px;
  padding: 5px 10px;

  :deep(.dubhe-button) {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-left: 0 !important;
  }
}
</style>