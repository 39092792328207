import axios from './axios.config'
// 转正申请(regularizationApplication)
export const getRegularizationApplicationFields = (tableId='regularizationApplication')=>axios.get(`/jaxrs/hr/regularization_application/fields?tableId=${tableId}`)
// 获取查询条件
export const getRegularizationApplicationConditions = ()=>axios.get('/jaxrs/hr/regularization_application/conditions')
// 查询
export const getRegularizationApplicationPage = (data) =>axios.post('/jaxrs/hr/regularization_application/page',data)
// 详情
export const getRegularizationApplicationDetail = (data) =>axios.get(`/jaxrs/hr/regularization_application/${data.id}`)
// 保存
export const addRegularizationApplication = (data) =>axios.post(`/jaxrs/hr/regularization_application`,data)
// 修改
export const editRegularizationApplication = (data) =>axios.put('/jaxrs/hr/regularization_application',data)
// 删除
export const deleteRegularizationApplication = (data) =>axios.delete(`/jaxrs/hr/regularization_application/${data.id}`)
// 修改流程状态
export const changeModifyFlowState = (data) =>axios.put(`/jaxrs/hr/regularization_application/status`,data)
// 转正
export const regularizationRegularizationApplication = (data) =>axios.put(`/jaxrs/hr/regularization_application/regularization/${data.id}`)
// 延长
export const extendRegularizationApplication = (data) =>axios.put(`/jaxrs/hr/regularization_application/extend`,data)
// 终止
export const terminationRegularizationApplication = (data) =>axios.put(`/jaxrs/hr/regularization_application/termination`,data)
// 发送转正通知
export const sendRegularizationApplicationRegularizationNotice = (data) =>axios.post(`/jaxrs/hr/regularization_application/get_regularization_notice`,data)
// 发送延长试用通知
export const sendRegularizationApplicationExtendNotice = (data) =>axios.post(`/jaxrs/hr/regularization_application/get_extend_notice`,data)
// 发送终止试用通知
export const sendRegularizationApplicationTerminationNotice = (data) =>axios.post(`/jaxrs/hr/regularization_application/get_termination_notice`,data)
