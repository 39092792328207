<script>
import { defineComponent } from "vue";
import _ from "lodash";
import { sendEmailMessage } from "@/axios";

export default defineComponent({
  name: "SendNoticeDrawer",
  data() {
    return {
      loading: false,
      editForm:{
        email:'',
        title:'',
        content:''
      },
      rules:{
        title: [{ required: true, message: '请输入标题', trigger: 'change' }]
      }
    };
  },
  component:{
  },
  props: {
    show: {
      type: Boolean
    },
    content:{}
  },
  computed: {
    showDrawer: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("update:show", val);
      }
    }
  },
  methods: {
    open(){
      this.editForm = this.content
    },
    close(){
      this.editForm = {
        email:'',
        title:'',
        content:''
      }
    },
    confirm(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          sendEmailMessage(this.editForm).then((res) => {
            if (res) {
              this.$message.success('发送成功')
              this.showDrawer = false
            }
          })
            .finally(() => {
              this.loading = false
            })
        }
      })
    }
  }
});
</script>

<template>
  <el-drawer v-model="showDrawer" size="95%" direction="btt" destroy-on-close @open="open" @close="close">
    <template #header>
      <div>通知预览</div>
    </template>
    <template #default>
      <div class="detail-wrapper">
        <el-form :model="editForm" label-position="top"  :rules="rules" ref="form" v-loading="loading">
          <el-form-item label="标题" prop="title">
            <el-input v-model="editForm.title"/>
          </el-form-item>
          <RichEditor v-model="editForm.content" height="500"></RichEditor>
        </el-form>
      </div>
    </template>
    <template #footer>
      <div class="drawer-footer">
        <el-button type="primary" @click="confirm"
        >确认
        </el-button>
      </div>
    </template>
  </el-drawer>
</template>

<style scoped lang="scss">

.detail-wrapper {
  height: 100%;
  overflow: auto;
  iframe {
    height: 100%;
    width: 100%;
  }
}
</style>