import axios from './axios.config'
// 车辆管理
// 获取显示字段
export const getVehicleFields = (tableId='vehicle') =>axios.get(`/jaxrs/vehicle/fields?tableId=${tableId}`)
// 获取查询条件
export const getVehicleConditions = () =>axios.get('/jaxrs/vehicle/conditions')
// 查询
export const getVehiclePage = (data) =>axios.post('/jaxrs/vehicle/page',data)
// 详情
export const getVehicleDetail = (data) =>{
    let url
    if(data.oper){
        url=`/jaxrs/vehicle/${data.id}?oper=${data.oper}`
    }else{
        url=`/jaxrs/vehicle/${data.id}`
    }
    return axios.get(url)
}
// 保存（新增）
export const addVehicle = (data) =>axios.post(`/jaxrs/vehicle`,data)
// 修改
export const editVehicle = (data) =>axios.put(`/jaxrs/vehicle`,data)
// 删除
export const deleteVehicle = (data) =>axios.delete(`/jaxrs/vehicle/${data.id}`)
// 导出
export const exportVehicle = (data) =>
    axios.post(`/jaxrs/vehicle/export`,data,{
        responseType:'blob'
    })
// 按时间查询车辆轨迹
export const getVehicleTrajectoryRecords = (data) =>axios.post(`/jaxrs/vehicle_trajectory/records`,data)
// 重启OBD
export const reloadObdControl = (data) =>axios.get(`/jaxrs/obd_control/reload/${data.sn}`)


