import axios from "./axios.config";
// 课程学习记录明细（courseStudyLog）
// 获取显示字段
export const getCourseStudyLogFields = (tableId = "courseStudyLog") => axios.get(`/jaxrs/course_study_log/fields?tableId=${tableId}`);
// 获取查询条件
export const getCourseStudyLogConditions = () => axios.get("/jaxrs/course_study_log/conditions");
// 获取分页数据
export const getCourseStudyLogPage = (data) => axios.post("/jaxrs/course_study_log/page", data);
// 详情
export const getCourseStudyLogDetail = (data) => axios.get(`/jaxrs/course_study_log/${data.id}`);
// 保存
export const addCourseStudyLog = (data) => axios.post(`/jaxrs/course_study_log`, data);
// 修改
export const editCourseStudyLog = (data) => axios.put(`/jaxrs/course_study_log`, data);
// 删除
export const deleteCourseStudyLog = (data) => axios.delete(`/jaxrs/course_study_log/${data.id}`);
// 获取汇总显示字段
export const getCourseStudyLogSummaryFields = (tableId = "courseStudyLogSummary") => axios.get(`/jaxrs/course_study_log/summary_fields?tableId=${tableId}`);
// 获取汇总分页数据
export const getCourseStudyLogSummaryPage = (data) => axios.post("/jaxrs/course_study_log/summary_page", data);
