import axios from './axios.config'
// ecode码
export const getEcodeFields = (tableId='ecode')=>axios.get(`/jaxrs/ecode/fields?tableId=${tableId}`)
// 查询
export const getEcodeConditions = ()=>axios.get('/jaxrs/ecode/conditions')
// 查询
export const getEcodePage = (data)=>axios.post('/jaxrs/ecode/page',data)
// 保存
export const addEcode = (data)=>axios.post(`/jaxrs/ecode`,data)
// 删除
export const deleteEcode = (data) =>axios.delete(`/jaxrs/ecode/${data.id}`)