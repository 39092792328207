import axios from './axios.config'
// 合同管理   ExpenditureContract
export const getExpenditureContractFields = (tableId='expenditureContract')=>axios.get(`/jaxrs/expenditure_contract/fields?tableId=${tableId}`)
// 获取查询条件
export const getExpenditureContractConditions = ()=>axios.get('/jaxrs/expenditure_contract/conditions')
// 查询
export const getExpenditureContractPage = (data)=>axios.post('/jaxrs/expenditure_contract/page',data)
// 根据类型查询字典列表
export const exportExpenditureContract = (data)=>axios.post(`/jaxrs/expenditure_contract/export`,data,{
  responseType:'blob'
})
// 详情
export const getExpenditureContractDetail = (data)=>axios.get(`/jaxrs/expenditure_contract/${data.id}`)
// 保存
export const addExpenditureContract = (data)=>axios.post(`/jaxrs/expenditure_contract`,data)
// 修改
export const editExpenditureContract = (data)=>axios.put(`/jaxrs/expenditure_contract`,data)
// 删除
export const deleteExpenditureContract = (data)=>axios.delete(`/jaxrs/expenditure_contract/${data.id}`)

// 作废
export const voidExpenditureContract = (data)=>axios.put(`/jaxrs/expenditure_contract/cancel/${data.id}`)
// 撤回
export const withdrawExpenditureContract = (data)=>axios.get(`/jaxrs/expenditure_contract/retract/${data.id}`)