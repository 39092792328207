<script>
import { defineComponent } from 'vue'

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Divider',
  props: {
    title: {
      type: String,
      required: true
    },
    top: {
      type: Number,
      default() {
        return 10
      }
    }
  },
  computed: {
    dividerStyle() {
      return {
        'padding-top': `${this.top}px`
      }
    }
  }
})
</script>

<template>
  <div class="divider" :style="dividerStyle">
    <div class="divider-title">
      <slot name="default">
        {{ title }}
      </slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.divider {
  padding-bottom: 10px;
  .divider-title{
    border-bottom: 1px solid var(--dubhe-color-primary);
    padding-bottom: 5px;
  }
}
</style>