<script>
import { defineComponent } from "vue";
import { getExtField } from "@/axios";
import ExtFieldDialog from "./ExtFieldDialog.vue";
import _ from 'lodash'

export default defineComponent({
  name: "ExtField",
  emits: ["update:data"],
  components: { ExtFieldDialog },
  data() {
    return {
      fieldList: [],
      extData: [],
      showExtFieldDialog: false
    };
  },
  props: {
    data: {
      required: true,
      type: Array,
      default() {
        return [];
      }
    },
    formId: {
      required: true,
      type: String
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      }
    },
    addLabelSuffix: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  watch: {
    data: {
      handler() {
        this.setExtData();
      },
      immediate: true,
      deep: true
    }
  },
  computed: {},
  mounted() {
    this.getExtField();
  },
  methods: {
    getExtField() {
      getExtField(this.formId).then((res) => {
        this.fieldList = res.data;
        this.setExtData();
      }).catch(() => {
      });
    },
    setExtData() {
      if (!this.fieldList || this.fieldList.length === 0) {
        return;
      }
      let extDataMap = {};
      this.data.forEach(item => {
        extDataMap[item.extDefName] = item.value;
      });
      this.extData = this.fieldList.map(item => {
        return {
          ...item,
          value: _.has(extDataMap, item.fieldName) ? extDataMap[item.fieldName] : null
        };
      });
    },
    setData() {
      let list = this.extData.map(item => {
        return {
          extDefName: item.fieldName,
          formId: item.formId,
          value: item.value
        };
      });
      this.$emit("update:data", list);
    },
    showByFieldType(item, index, type) {
      if (!this.extData[index]) {
        return false;
      }
      return item.fieldType === type;
    },
    getSelectList(item) {
      let list = item.items.split(";").map(lItem => {
        return {
          id: lItem,
          label: lItem
        };
      });
      return list;
    },
    dialogConfirm() {
      this.getExtField();
    }
  }
});
</script>

<template>
  <Divider title="扩展字段">
    <template #default>
      <el-button v-if="!disabled" type="primary" link icon="Edit" @click="showExtFieldDialog = true"></el-button>
    </template>
  </Divider>
  <el-row :gutter="20" v-if="disabled">
    <el-col :span="6" v-for="(item,index) in extData" :key="index">
      <el-form-item :label="addLabelSuffix?`${item.fieldName}：`:item.fieldName">
        {{ item.value }}
      </el-form-item>
    </el-col>
  </el-row>
  <el-row :gutter="20" v-else>
    <el-col :span="6" v-for="(item,index) in extData" :key="index">
      <el-form-item :label="addLabelSuffix?`${item.fieldName}：`:item.fieldName">
        <template v-if="showByFieldType(item,index,'radio')">
          <SelectV2 :list="getSelectList(item)" v-model="item.value" @change="setData"></SelectV2>
        </template>
        <template v-if="showByFieldType(item,index,'string')">
          <el-input v-model="item.value" @input="setData"></el-input>
        </template>
        <template v-if="showByFieldType(item,index,'date')">
          <DatePicker v-model="item.value" @change="setData"></DatePicker>
        </template>
      </el-form-item>
    </el-col>
  </el-row>
  <ExtFieldDialog v-model="showExtFieldDialog" v-model:fieldList="fieldList" :formId="formId"
                  @confirm="dialogConfirm"></ExtFieldDialog>
</template>

<style scoped lang="scss">

</style>