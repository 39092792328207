<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "SelectAddress",
  emits: ["update:district","update:address"],
  data() {
    return {
    };
  },
  props: {
    district: {
    },
    address: {
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  computed: {
    districtData: {
      get() {
        return this.district;
      },
      set(val) {
        this.$emit("update:district", val);
      }
    },
    addressData: {
      get() {
        return this.address;
      },
      set(val) {
        this.$emit("update:address", val);
      }
    },
  },
  methods: {
  }
});
</script>

<template>
  <el-input v-model="addressData" :disabled="disabled" class="input-with-select">
    <template #prepend>
      <SelectV2
        v-model="districtData"
        :list="dictStore.getDictByType('district')"
        labelKey="label"
        valueKey="val"
        :disabled="disabled"
        placeholder="请选择区"
      ></SelectV2>
    </template>
  </el-input>
</template>

<style scoped lang="scss">
.input-with-select {
:deep(.dubhe-input-group__prepend){
    background-color: #ffffff
  }
}
</style>