<script>
import { defineComponent } from 'vue'

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'RemarkDialog',
  emits: ['update:modelValue','confirm'],
  data() {
    return {
      form:{
        remark:''
      }
    }
  },
  props:{
    modelValue:{
      type:Boolean
    },
    title:{
      type:String
    },
    required:{
    }
  },
  computed:{
    show:{
      get(){
        return this.modelValue
      },
      set(val){
        this.$emit('update:modelValue',val)
      }
    },
    rules(){
      let rules = {}
      if(this.required){
        rules = {
          remark: [{ required: true, message: '请输入备注', trigger: 'change' }]
        }
      }
      return rules
    },
  },
  methods: {
    open(){
      this.form.remark = ''
    },
    handleCancelClick() {
      this.show = false
    },
    handleConfirmClick() {
      this.$refs.form.validate((valid)=>{
        if(valid){
          this.$emit('confirm', {
            remark: this.form.remark
          })
          this.show = false
        }
      })
    }
  }
})
</script>

<template>
  <el-dialog v-model="show" v-bind="$attrs" :title="title" width="50%" destroy-on-close @open="open">
    <div class="remark-dialog">
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item label="备注信息" prop="remark">
          <el-input type="textarea" v-model="form.remark"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleCancelClick">取消</el-button>
        <el-button type="primary" @click="handleConfirmClick">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<style scoped lang="scss">
.remark-dialog {
  & > :deep(*) {
    width: 100%;
  }
}
</style>