<script>
import { defineComponent } from "vue";
import { uploadImage } from "@/axios";
import vueEsign from "vue-esign";

export default defineComponent({
  name: "SignDrawerV2",
  components: { vueEsign },
  emits: ["update:show", "callback"],
  data() {
    return {
      loading: false,
      isCrop: true,
      lineWidth: 4,
      lineColor: "#000000",
      bgColor: "#ffffff",
      width: 0,
      height: 0,
      esignKey: Math.random()
    };
  },
  props: {
    show: {
      type: Boolean
    },
    title: {
      default() {
        return "签字";
      }
    },
    showNotice:{}
  },
  computed: {
    showDrawer: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("update:show", val);
      }
    }
  },
  methods: {
    open() {
      this.initSize();
      // this.clearCanvas();
    },
    initSize() {
      this.$nextTick(() => {
        let wrapper = this.$refs.wrapper;
        let { offsetWidth, offsetHeight } = wrapper;
        this.width = offsetWidth;
        this.height = offsetHeight;
        this.esignKey = Math.random();
      });
    },
    base642File(base64Str, fileName) {
      let arr = base64Str.split(",");
      let mime = arr[0].match(/:(.*?);/)[1]; // base64解析出来的图片类型
// 对base64串进行操作，去掉url头，并转换为byte   atob为window内置方法
      let bstr = atob(arr[1]);
      let len = bstr.length;
      let ab = new ArrayBuffer(len); // 将ASCII码小于0的转换为大于0
      let u8arr = new Uint8Array(ab); //
      while (len--) {
        u8arr[len] = bstr.charCodeAt(len);
      }
      ;
// 创建新的 File 对象实例[utf-8内容，文件名称或者路径，[可选参数，type：文件中的内容mime类型]]
      return new File([u8arr], fileName, {
        type: mime
      });
    },
    base64toFile(data, fileName) {
      const dataArr = data.split(",");
      const byteString = atob(dataArr[1]);
      const options = {
        type: "image/png",
        endings: "native"
      };
      const u8Arr = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        u8Arr[i] = 255 - byteString.charCodeAt(i);
      }
      return new File([u8Arr], fileName + ".png", options); //返回文件流
    },
    async confirm() {
      this.$refs.esign.generate().then(res => {
        this.loading = true;
        let file = this.base642File(res, `${this.getTime(new Date(), "YYYYMMDDHHmmss")}.png`);
        let formData = new FormData();
        formData.append("files", file);
        uploadImage(formData)
          .then((res) => {
            if (res) {
              let filesData = res.data;
              this.$emit("callback", filesData);
              this.showDrawer = false;
            }
          })
          .catch(() => {
          }).finally(() => {
          this.loading = false;
        });
      }).catch(err => {
        // console.log(err)
        this.$message.warning("请签字");
      });
    },
    clearCanvas() {
      this.$refs.esign.reset();
    }
  }
});
</script>

<template>
  <el-drawer
    v-model="showDrawer"
    size="95%"
    direction="btt"
    destroy-on-close
    @open="open"
  >
    <template #header>
      <div>{{ title }}</div>
    </template>
    <template #default>
      <div class="detail-wrapper" v-loading="loading">
        <el-alert v-if="showNotice" :title="dictStore.getDictItemByTypeValue('systemParams', 'signNotice').label" type="warning" :closable="false"/>
        <div class="sign-body" ref="wrapper">
          <vue-esign :key="esignKey" ref="esign" :width="width" :height="height" :isCrop="isCrop" :lineWidth="lineWidth"
                     :lineColor="lineColor" v-model:bgColor="bgColor" />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="drawer-footer">
        <el-button @click="clearCanvas"
        >清空
        </el-button>
        <el-button type="primary" @click="confirm"
        >确认
        </el-button>
      </div>
    </template>
  </el-drawer>
</template>

<style scoped lang="scss">
.detail-wrapper {
  overflow: hidden !important;
  display: flex;
  flex-direction: column;
  .sign-body{
    width: 100%;
    flex: 1;
  }
}
</style>