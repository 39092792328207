import axios  from './axios.config'
// 获取显示字段
export const getEquipmentRepairSupplierFields = (tableId='equipmentRepairSupplier')=>axios.get(`/jaxrs/equipment_repair_supplier/fields?tableId=${tableId}`)
// 获取查询条件
export  const getEquipmentRepairSupplierConditions = () =>axios.get('/jaxrs/equipment_repair_supplier/conditions')
// 查询
export const getEquipmentRepairSupplierPage = (data) =>axios.post(`/jaxrs/equipment_repair_supplier/page`,data)
// 详情
export const getEquipmentRepairSupplierDetail = (data) =>axios.get(`/jaxrs/equipment_repair_supplier/${data.id}`)
// 保存
export const addEquipmentRepairSupplier = (data)=>axios.post(`/jaxrs/equipment_repair_supplier`,data)
// 编辑
export const editEquipmentRepairSupplier = (data)=>axios.put(`/jaxrs/equipment_repair_supplier`,data)
// 删除
export const deleteEquipmentRepairSupplier = (data)=>axios.delete(`/jaxrs/equipment_repair_supplier/${data.id}`)