import axios from './axios.config'
// 销售报价单 SalesQuotation
export const getSalesQuotationFields = (tableId='salesQuotation') =>axios.get('/jaxrs/sales_quotation/fields')
// 查询条件
export const getSalesQuotationConditions = () =>axios.get('/jaxrs/sales_quotation/conditions')
// 查询
export const getSalesQuotationPage = (data) =>axios.post('/jaxrs/sales_quotation/page',data)
// 导出
export const exportSalesQuotation = (data) =>axios.post(`/jaxrs/sales_quotation/export`,data,{responseType:'blob'})

// export const exportProjectWorkDaily  = (data) => axios.post(`/jaxrs/project_work_daily/export`,data,{
//   responseType:'blob'
// })
// 详情
export const getSalesQuotationDetail = (data) =>axios.get(`/jaxrs/sales_quotation/${data.id}`)
// 保存
export const addSalesQuotation = (data) =>axios.post('/jaxrs/sales_quotation',data)
// 修改
export const editSalesQuotation = (data) =>axios.put('/jaxrs/sales_quotation',data)
// 删除
export const deleteSalesQuotation = (data) =>axios.delete(`/jaxrs/sales_quotation/${data.id}`)
// 修改流程状态
export const getSalesQuotationState = (data) =>axios.put(`/jaxrs/sales_quotation/status`,data)