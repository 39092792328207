<script>
import { defineComponent } from 'vue'
import _ from 'lodash'

export default defineComponent({
  name: 'FilePreviewDrawer',
  data() {
    return {
      previewIndex: 0,
      loading: false
    }
  },
  component: {
  },
  props: {
    show: {
      type: Boolean
    },
    // file: {},
    showIndex: {
      default() {
        return 0
      }
    },
    fileList: {
      default() {
        return []
      }
    }
  },
  computed: {
    showDrawer: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    },
    file() {
      return this.fileList[this.previewIndex] || null
    },
    filePath() {
      if (_.isString(this.file)) {
        return this.file
      } else {
        return this.file.url
      }
    },
    iframeUrl() {
      let baseUrl
      let openUrl
      let url = this.filePath
      if (_.isString(this.file)) {
        baseUrl = encodeURIComponent(url)
        openUrl = `https://view.xdocin.com/view?src=${baseUrl}`
      } else {
        let { type } = this.file
        baseUrl = encodeURIComponent(url)
        // openUrl = `https://wps-view.zhihuipk.com/?src=${baseUrl}`;
        if (this.isOfficeFile(type)) {
          openUrl = `https://view.officeapps.live.com/op/view.aspx?src=${baseUrl}`
        } else if (this.isOfdFile(type)) {
          let ops = {
            "toolbar": false
          };
          openUrl = `https://ofd.xdocin.com/view?src=${baseUrl}`
          for (let a in ops) {
            openUrl += "&" + a + "=" + encodeURIComponent(ops[a]);
          }
        } else if (this.isPdfFile(type)) {
          // openUrl = `https://www.pfile.com.cn/api/profile/onlinePreview?url=${baseUrl}`;
          // openUrl = `https://mozilla.github.io/pdf.js/web/viewer.html?file=${baseUrl}`
          openUrl = `${location.origin}/pdfjs/web/viewer.html?file=${baseUrl}`
        }
      }
      return openUrl
    },
    isImageFile() {
      return this.isImage(this.file.type)
    },
    isOtherFile() {
      return (
        this.isOfficeFile(this.file.type) ||
        this.isPdfFile(this.file.type) ||
        this.isOfdFile(this.file.type)
      )
    },
    nextDisabled() {
      if(!this.fileList){
        return 0
      }
      return this.previewIndex === this.fileList.length - 1
    },
    prevDisabled() {
      return this.previewIndex === 0
    }
  },
  methods: {
    isOfficeFile(filename) {
      let list = ['.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx']
      let name = _.toLower(filename)
      if (list.includes(name)) {
        return true
      }
      return false
    },
    isPdfFile(filename) {
      // let list = [".pdf", ".xml"];
      let list = ['.pdf']
      let name = _.toLower(filename)
      if (list.includes(name)) {
        return true
      }
      return false
    },
    isImage(filename) {
      let list = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.ico']
      let name = _.toLower(filename)
      if (list.includes(name)) {
        return true
      }
      return false
    },
    isOfdFile(filename) {
      let list = ['.ofd']
      let name = _.toLower(filename)
      if (list.includes(name)) {
        return true
      }
      return false
    },
    isVideoFile(filename) {
      let list = ['.mp4', '.webm', '.flv', '.avi', '.wmv']
      let name = _.toLower(filename)
      if (list.includes(name)) {
        return true
      }
      return false
    },
    open() {
      this.previewIndex = this.showIndex
      this.loading = true
    },
    load() {
      this.loading = false
    },
    handleIndexChange(num) {
      if (this.nextDisabled && num > 0) {
        return
      }
      if (this.prevDisabled && num < 0) {
        return
      }
      this.previewIndex += num
    }
  }
})
</script>

<template>
  <el-drawer v-model="showDrawer" size="95%" direction="btt" destroy-on-close @open="open" :append-to-body="true">
    <template #header>
      <div>文件预览</div>
      <div class="button-group">
        <el-button link icon="Back" :disabled="prevDisabled" @click="handleIndexChange(-1)">上一条</el-button>
        <el-divider direction="vertical" />
        <el-button link icon="Right" :disabled="nextDisabled" @click="handleIndexChange(1)">下一条</el-button>
      </div>
    </template>
    <template #default>
      <div class="detail-wrapper" :key="previewIndex">
        <template v-if="showDrawer && file">
          <div v-if="isImageFile" class="image-wrapper">
            <img :src="filePath" alt="" />
          </div>
          <div v-else-if="isVideoFile(file.type)" class="image-wrapper">
            <video :src="filePath" controls />
          </div>
<!--          <OfdPreview v-else-if="isOfdFile(file.type)" :src="filePath"></OfdPreview>-->
<!--          <PdfPreview v-else-if="isPdfFile(file.type)" :src="filePath"></PdfPreview>-->
          <div v-else-if="isOtherFile" class="detail-wrapper" v-loading="loading">
            <iframe
              ref="finishIframe"
              :src="iframeUrl"
              scrolling="auto"
              frameborder="0"
              @load="load"
            ></iframe>
          </div>
          <div v-else class="flex-center">
            <el-empty description="该类型文件暂不支持预览" />
          </div>
        </template>
      </div>
    </template>
  </el-drawer>
</template>

<style scoped lang="scss">
.image-wrapper {
  overflow: hidden;
  height: 100%;
  width: 100%;

  img,
  video {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.detail-wrapper {
  overflow: hidden !important;
  height: 100%;

  iframe {
    height: 100%;
    width: 100%;
  }
}
</style>