import axios from './axios.config'
// 课程（course）
// 获取显示字段
export const getCourseFields = (tableId='course')=>axios.get(`/jaxrs/course/fields?tableId=${tableId}`)
// 获取查询条件
export const getCourseConditions = () =>axios.get('/jaxrs/course/conditions')
// 获取分页数据
export const getCoursePage = (data) =>axios.post('/jaxrs/course/page',data)
// 详情
export const getCourseDetail = (data) =>{
  let url
  if(data.oper){
    url=`/jaxrs/course/${data.id}?oper=${data.oper}`
  }else{
    url=`/jaxrs/course/${data.id}`
  }
  return axios.get(url)
}
// 保存
export const addCourse = (data) =>axios.post(`/jaxrs/course`,data)
// 编辑
export const editCourse = (data)=>axios.put(`/jaxrs/course`,data)
// 删除
export const deleteCourse = (data) =>axios.delete(`/jaxrs/course/${data.id}`)
// 编辑状态
export const updateCourseStatus = (data) =>axios.post(`/jaxrs/course/update_course_status`,data)
