<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "TextareaDialog",
  emits: ["update:modelValue", "update:text"],
  data() {
    return {
      value: ""
    };
  },
  props: {
    modelValue:{
      type:Boolean
    },
    text: {
      required: true
    }
  },
  computed: {
    show: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    }
  },
  watch: {
    text(val) {
      this.setValue(val);
    }
  },
  methods: {
    handleCancelClick() {
      this.show = false;
    },
    handleConfirmClick() {
      this.$emit("update:text", this.value);
      this.show = false;
    },
    setValue(val) {
      this.value = val;
    },
    open() {
      this.setValue(this.text);
    }
  }
});
</script>

<template>
  <el-dialog v-model="show" v-bind="$attrs" title="多行文本编辑" width="70%" append-to-body destroy-on-close
             @open="open">
    <el-input v-model="value" :rows="4" type="textarea" placeholder="请输入" />
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleCancelClick">取消</el-button>
        <el-button type="primary" @click="handleConfirmClick">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<style scoped lang="scss">
.textarea-dialog {
  width: 100%;
}

.text {
  width: 100%;
  overflow: hidden;
}
</style>