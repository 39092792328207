<script>
import { defineComponent } from "vue";
import Dispatch from "../FollowUpDialog/FollowUpDialog.vue";

export default defineComponent({
  name: "IdentityDialog",
  components: {Dispatch},
  emits: ["update:modelValue", "callback"],
  data(){
    return {
      check:{},
      loading: false
    }
  },
  props:{
    modelValue: {
      type: Boolean
    },
    list: {
      type: Array
    },
    processDetail:{
      default(){
        return {}
      }
    }
  },
  computed:{
    show: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    },
    title(){
      return `启动流程-${this.processDetail.name}`
    }
  },
  methods:{
    open(){
      this.check = {}
      this.loading = false
    },
    handleCancelClick() {
      this.show = false
    },
    handleConfirmClick(){
      if(!this.check.distinguishedName){
        this.$message.warning('请选择身份')
        return
      }
      this.$emit('callback',this.check)
      // this.show = false
    },
    filterDuty(item){
      return item.woUnitDutyList.map(item=>item.name).join(',')
    },
    filterCheck(item){
      return item.distinguishedName === this.check.distinguishedName
    },
    checkItem(item){
      this.check = item
    }
  }
});
</script>

<template>
  <el-dialog v-model="show" v-bind="$attrs" :title="title" top="5%" width="60%" destroy-on-close @open="open">
    <div class="identity-dialog-wrapper" v-loading="loading">
      <div class="identity-item" :class="{'active':filterCheck(item)}" v-for="(item,index) in list" :key="index" @click="checkItem(item)">
        <div>姓名：{{item.name}}</div>
        <div>组织：{{item.unitName}}</div>
        <div>职务：{{filterDuty(item)}}</div>
        <el-icon class="icon"><SuccessFilled /></el-icon>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer" v-loading="loading">
        <el-button @click="handleCancelClick">取消</el-button>
        <el-button type="primary" @click="handleConfirmClick" :disabled="loading">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<style scoped lang="scss">
  .identity-dialog-wrapper{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    background: #f9f9f9;
    padding:20px;
    max-height: 500px;
    overflow: auto;
    .identity-item{
      cursor: pointer;
      padding: 10px;
      background: #fff;
      border: 1px solid #ddd;
      border-radius: 5px;
      line-height: 1.8;
      width: 32%;
      position: relative;
      &>div{
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.8;
      }
      .icon{
        color:#60bf78;
        font-size: 20px;
        position: absolute;
        right:12px;
        top:10px;
        display: none;
      }
      &.active{
        border-color:var(--dubhe-color-primary);
        background: var(--dubhe-color-primary-light-9);
        position: relative;
        i{
          display: block;
        }
      }
    }
  }
</style>
