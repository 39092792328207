import axios from './axios.config'
// 获取显示字段
export const getExpensesFields = (tableId='expenses')=>axios.get(`/jaxrs/expenses/fields?tableId=${tableId}`)
// 获取查询条件
export const getExpensesConditions = ()=>axios.get('/jaxrs/expenses/conditions')
// 查询
export const getExpensesPage = (data) =>axios.post('/jaxrs/expenses/page',data)
// 详情
export const getExpensesDetail = (data) => {
  let url
  if (data.oper) {
    url = `/jaxrs/expenses/${data.id}?oper=${data.oper}`
  } else {
    url = `/jaxrs/expenses/${data.id}`
  }
  return axios.get(url)
}
// 保存
export const addExpenses = (data) =>axios.post(`/jaxrs/expenses`,data)
// 编辑
export const editExpenses = (data) =>axios.put(`/jaxrs/expenses`,data)
// 删除
export const deleteExpenses = (data) =>axios.delete(`/jaxrs/expenses/${data.id}`)
// 导出
export const exportExpenses = (data) => axios.post(`/jaxrs/expenses/export`,data,{
  responseType:'blob'
})
// 编辑付款状态
export const editPaymentStatus = (data) =>axios.put(`/jaxrs/expenses/payment_status`,data)