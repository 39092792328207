<script>
import { defineComponent } from 'vue'
import _ from 'lodash'
import { filterMixins } from '../common/filterMixins'
import UploadFileInList from '../UploadFile/UploadFileInList.vue'
import DictTag from '@/components/DictTag/DictTag.vue'
import { ElPagination } from "element-plus";

/**
 * 注意：filter
 * 使用自定义component时，
 */
export default defineComponent({
  name: 'TableNoEdit',
  components: { DictTag, UploadFileInList,ElPagination },
  mixins: [filterMixins],
  data() {
    return {
      page: {
        pageNumber: 1,
        pageSize: 10
      }
    }
  },
  props: {
    title: {
      type: String
    },
    list: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    tableOption: {
      type: Array,
      required: true
    },
    totalOption: {
      type: Array
    },
    height: {},
    pagination: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  computed: {
    tableList: {
      get() {
        return this.list
      },
      set(val) {
        this.$emit('update:list', this.list)
      }
    },
    pageList() {
      let { pageSize, pageNumber } = this.page
      let start = (pageNumber - 1) * pageSize
      let end = pageNumber * pageSize
      let list = this.tableList.slice(start, end)
      return list
    },
    pageTotal() {
      return this.tableList.length
    },
    totalList: {
      get() {
        if (this.tableList.length === 0) {
          return []
        } else if (this.totalOption) {
          let list = [...this.tableList]
          let totalItem = _.cloneDeep(this.totalItem)
          this.totalOption.forEach((item) => {
            let tableOptionItem = this.tableOption.find((tItem) => tItem.name === item)
            if (tableOptionItem?.params?.digit) {
              totalItem[item] = this.getTotalByKey(
                this.tableList,
                item,
                tableOptionItem.params.digit
              )
            } else {
              totalItem[item] = this.getTotalByKey(this.tableList, item)
            }
          })
          list.push(totalItem)
          return list
        } else {
          return this.tableList
        }
      },
      set(val) {
        // this.$emit('update:list', this.totalList)
      }
    },
    showList() {
      if (this.pagination) {
        return this.pageList
      } else {
        return this.totalList
      }
    },
    totalItem() {
      let obj = {
        isTotalRow: true
      }
      this.tableOption.forEach((item) => {
        obj[item.name] = ''
      })
      return obj
    },
    tableStyle() {
      if (this.height) {
        return {
          height: _.isNumber(this.height) ? `${this.height}px` : this.height
        }
      }
      return {}
    },
    positionStyle() {
      if (this.height) {
        return {
          position: 'absolute'
        }
      }
      return { position: 'relative' }
    },
    showOperate() {
      return !!this.$slots.operate
    },
    showPagination(){
      return this.pagination && (this.pageTotal > this.page.pageSize)
    }
  },
  mounted() {
  },
  methods: {
    filterCustom(item, row) {
      if (item.filter) {
        let filterObj = item.filter({ row: row, name: item.name, value: this.filterValue(row,item.name) })
        return {
          params: filterObj.params || {},
          name: _.isNumber(filterObj.name) ? filterObj.name : (filterObj.name || '')
        }
      } else {
        return {
          params: {},
          name: this.filterValue(row,item.name)
        }
      }
    },
    filterValue(row,name){
      if(!name){
        return ''
      }
      let value = _.get(row, name,'')
      return value
    },
    getComponentParams(item, row) {
      if (item.filter) {
        return {
          ...item.componentParams,
          ...this.filterCustom(item, row).params
        }
      } else {
        return item.componentParams || {}
      }
    },
    computedIndex(index) {
      if (this.pagination) {
        let { pageNumber, pageSize } = this.page
        let start = (pageNumber - 1) * pageSize
        return start + index + 1
      }
      return index + 1
    }
  }
})
</script>

<template>
  <div class="header" v-if="title">
    <div class="title">
      <span>{{ title }}</span
      >（{{ list.length }}条）
    </div>
    <div class="button-group">
      <slot name="headerButton"></slot>
    </div>
  </div>
  <div class="table" :style="tableStyle">
    <div class="table-absolute" :style="positionStyle">
      <el-table :data="showList" stripe border empty-text="暂无数据" v-bind="$attrs">
        <el-table-column label="序号" align="center" width="80" fixed="left">
          <template #default="scope">
            <div v-if="scope.row.isTotalRow" class="total">汇总</div>
            <div v-else>{{ computedIndex(scope.$index) }}</div>
          </template>
        </el-table-column>
        <template v-for="item in tableOption" :key="item.name">
          <el-table-column
            :label="item.label"
            :width="item.width"
            :align="item.align"
            :min-width="item.minWidth"
            :fixed="item.fixed"
            show-overflow-tooltip
          >
            <template #default="scope" v-if="item.cellClick">
              <el-button link type="primary" size="small" @click.stop="item.cellClick(scope)">
                {{ filterCustom(item, scope.row).name }}
              </el-button>
            </template>
            <template #default="scope" v-else-if="item.type === 'upload'">
              <UploadFileInList
                v-if="!scope.row.isTotalRow"
                :model-value="filterValue(scope.row,item.name)"
                :disabled="true"
              ></UploadFileInList>
            </template>
            <template #default="scope" v-else-if="item.type === 'dict'">
              <DictTag
                :value="filterValue(scope.row,item.name)"
                v-bind="getComponentParams(item, scope.row)"
              ></DictTag>
            </template>
            <template #default="scope" v-else-if="item.component">
              <div v-if="filterValue(scope.row,item.name)" class="custom">
                <component :is="item.component" v-bind="getComponentParams(item, scope.row)">
                  <template v-if="item.filter" #default>
                    {{ filterCustom(item, scope.row).name }}
                  </template>
                </component>
              </div>
            </template>
            <template #default="scope" v-else>
              <span :class="{ total: scope.row.isTotalRow }">{{
                filterCustom(item, scope.row).name
              }}</span>
            </template>
          </el-table-column>
        </template>
        <slot name="operate" v-if="showOperate"></slot>
      </el-table>
    </div>
  </div>
  <div class="flex-row-end-col-center">
    <el-pagination
      v-if="showPagination"
      layout="total,sizes, prev, pager, next"
      background
      :page-sizes="[10, 20, 50]"
      v-model:current-page="page.pageNumber"
      v-model:page-size="page.pageSize"
      :total="pageTotal"
    ></el-pagination>
  </div>
</template>

<style scoped lang="scss">
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  border-bottom: 1px solid #eee;
  background: var(--dubhe-fill-color-light);
  width: 100%;

  .title {
    & > span {
      line-height: 32px;
      font-weight: bold;
    }
  }

  .button-group {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.table {
  position: relative;
  width: 100%;

  .table-absolute {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  :deep(.dubhe-table) {
    width: 100%;
    height: 100%;
  }

  :deep(td.dubhe-table__cell) {
    padding: 0;

    .cell {
      padding: 6px 10px;
    }

    .total {
      font-weight: bold !important;
      color: #000;
    }
  }

  //:deep(.dubhe-table__row) {
  //  &:last-child {
  //    background: #fafafa;
  //
  //    .dubhe-table__cell {
  //      .cell {
  //        font-weight: bold !important;
  //        color: #000;
  //      }
  //    }
  //  }
  //}
}

.custom {
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;

  .dubhe-progress {
    width: 100%;
  }
}
</style>