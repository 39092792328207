import axios from './axios.config'
// 用车登记
// 获取显示字段
export const getVehicleUserRecordFields = (tableId='vehicleUserRecord') =>axios.get(`/jaxrs/vehicle_user_record/fields`)
// 获取查询条件
export const getVehicleUserRecordConditions = () =>axios.get('/jaxrs/vehicle_user_record/conditions')
// 查询
export const getVehicleUserRecordPage = (data) =>axios.post('/jaxrs/vehicle_user_record/page',data)
// 详情
export const getVehicleUserRecordDetail = (data) =>{
    let url
    if(data.oper){
        url = `/jaxrs/vehicle_user_record/${data.id}?oper=${data.oper}`
    }else{
        url = `/jaxrs/vehicle_user_record/${data.id}`
    }
    return axios.get(url)
}
// 保存（新增）
export const addVehicleUserRecord = (data) =>axios.post(`/jaxrs/vehicle_user_record`,data)
// 修改
export const editVehicleUserRecord = (data) =>axios.put(`/jaxrs/vehicle_user_record`,data)
// 删除
export const deleteVehicleUserRecord = (data) =>axios.delete(`/jaxrs/vehicle_user_record/${data.id}`)
// 导出
export const exportVehicleUserRecord = (data) =>
    axios.post(`/jaxrs/vehicle_user_record/export`,data,{
        responseType:'blob'
    })