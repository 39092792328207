import axios from './axios.config'
// 获取显示字段 EmployeesChange
export const getEmployeesChangeFields = (tableId='employeesChange')=>axios.get(`/jaxrs/hr/employees_change/fields?tableId=${tableId}`)
// 查询字段
export const getEmployeesChangeConditions = ()=>axios.get('/jaxrs/hr/employees_change/conditions')
// 分页查询
export const getEmployeesChangePage = (data)=>axios.post('/jaxrs/hr/employees_change/page',data)
// 详情
export const getEmployeesChangeDetail = (data)=>axios.get(`/jaxrs/hr/employees_change/${data.id}`)
// 保存
export const addEmployeesChange = (data)=>axios.post(`/jaxrs/hr/employees_change`,data)
// 修改
export const editEmployeesChange = (data)=>axios.put(`/jaxrs/hr/employees_change`,data)
// 删除
export const deleteEmployeesChange = (data)=>axios.delete(`/jaxrs/hr/employees_change/${data.id}`)