import axios from './axios.config'
// 应用设置-项目施工图片配置    WorkImageSetting
// 获取显示字段
export const getWorkImageSettingFields = (tableId='workImageSetting')=>axios.get(`/jaxrs/work-image-setting/fields?tableId=${tableId}`)
// 查询字段
export const getWorkImageSettingConditions = () =>axios.get('/jaxrs/work-image-setting/conditions')
// 查询
export const getWorkImageSettingPage = (data) =>axios.post('/jaxrs/work-image-setting/page',data)
// 详情页
export const getWorkImageSettingDetail = (data)=>axios.get(`/jaxrs/work-image-setting/${data.id}`)
// 保存
export const addWorkImageSetting = (data) =>axios.post(`/jaxrs/work-image-setting`,data)
// 修改
export const editWorkImageSetting = (data) =>axios.put(`/jaxrs/work-image-setting`,data)
// 删除
export const deleteWorkImageSetting = (data) =>axios.delete(`/jaxrs/work-image-setting/${data.id}`)
// 根据来源查项目配置
export const getWorkImageSettingBySource = (data) =>axios.get(
  `/jaxrs/work-image-setting/get_by_source?workOrderSource=${data.workOrderSource || ''}&projectType=${data.projectType || ''}&constructionStage=${data.constructionStage || ''}`
)