import axios from './axios.config'
// 获取显示字段
export const getMaintenanceProjectFields = (tableId= 'maintenanceProject') =>axios.get(`/jaxrs/maintenance_project/fields?tableId=${tableId}`)
// 获取查询字段
export const getMaintenanceProjectConditions = () =>axios.get('/jaxrs/maintenance_project/conditions')
//获取列表分页数据
export const getMaintenanceProjectPage = (data) =>axios.post('/jaxrs/maintenance_project/page',data)
// 获取详情
export const getMaintenanceProjectDetail = (data) =>axios.get(`/jaxrs/maintenance_project/${data.id}`)
// 添加（保存）
export const addMaintenanceProject = (data) =>axios.post(`/jaxrs/maintenance_project`,data)
// 编辑
export const editMaintenanceProject = (data) =>axios.put(`/jaxrs/maintenance_project`,data)
// 删除
export const deleteMaintenanceProject = (data) =>axios.delete(`/jaxrs/maintenance_project/${data.id}`)
// 根据商用项目获取维保项目
export const getMaintenanceProjectByCommercialProject = (data) =>axios.get(`/jaxrs/maintenance_project/project_id/${data.id}`)
// 导入工单
export const importMaintenanceProject = (data) =>axios.post(`/jaxrs/maintenance_project/import`,data)
// 导入表头接口(获取导入字段)
export const  getMaintenanceProjectImportFields = () =>axios.get('/jaxrs/maintenance_project/import/fields')