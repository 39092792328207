import axios from './axios.config'
//获取销售订单显示字段
export const getSalesOrderFields = (tableId='salesOrder') =>axios.get(`/jaxrs/sales_order/fields?tableId=${tableId}`)
//获取销售订单查询条件
export const getSalesOrderConditions = () =>axios.get('/jaxrs/sales_order/conditions')
//获取销售订单分页数据
export const getSalesOrderPage = (data) =>axios.post(`/jaxrs/sales_order/page`,data)
//销售订单详情页(上一页)
export const getSalesOrderDetail = (data) =>{
  let url
  if(data.oper){
    url = `/jaxrs/sales_order/${data.id}?oper=${data.oper}`
  }else{
    url = `/jaxrs/sales_order/${data.id}`
  }
  return axios.get(url)
}
// export const getSalesOrderDetail = (data) =>axios.get(`/jaxrs/sales_order/${data.id}`)
//修改销售订单
export const editSalesOrder = (data) =>axios.put(`/jaxrs/sales_order`,data)
//新增销售订单
export const addSalesOrder = (data) =>axios.post(`/jaxrs/sales_order`,data)
//删除销售订单
export const deleteSalesOrder = (data) =>axios.delete(`/jaxrs/sales_order/${data.id}`)
//导出销售订单
export const exportSalesOrder = (data) =>
  axios.post(`/jaxrs/sales_order/export`,data,{
    responseType:'blob'
  })
// 业务类型（字典）
export const getBusinessTypeType = () =>axios.get('/jaxrs/dict/get_by_type/bizType')
// 根据手机号模糊查询客户信息
export const getConsumerByPhone = (data) =>axios.get(`/jaxrs/consumer/load_by_phone/${data.phone}`)
//获取销售订单根据项目ID
export const getSalesOrderByProject = (data) =>axios.get(`/jaxrs/sales_order/project_id/${data.id}`)
// 根据项目获取销售订单
export const getSalesOrderMessage = (data) =>axios.get(`/jaxrs/work_order/civil/project/${data.id}`)