<script>
import { defineComponent } from 'vue'
import Condition from './Condition.vue'
import ConditionV2 from './ConditionV2.vue'
import ColCheckBox from './ColCheckBox.vue'
import ConditionTags from './ConditionTags.vue'
import { setTableColumnConfig } from '@/axios/table'
import _ from 'lodash'
import DictTag from '../DictTag/DictTag.vue'
import TableTempTitle from './TableTempTitle.vue'
import UploadFileInList from '../UploadFile/UploadFileInList.vue'

export default defineComponent({
  name: 'TableTemp',
  emits: [
    'update:checkValue',
    'update:page',
    'search',
    'sortChange',
    'pageChange',
    'update:checkList',
    'update:colList'
  ],
  components: {
    UploadFileInList,
    TableTempTitle,
    DictTag,
    Condition,
    ConditionV2,
    ColCheckBox,
    ConditionTags
  },
  props: {
    v2: {
      type: Boolean,
      default() {
        return false
      }
    },
    loading: {
      type: Boolean,
      default() {
        return false
      }
    },
    page: {
      required: true,
      type: Object
    },
    colList: {
      required: true,
      type: Array
    },
    placeholder: {
      type: String,
      default() {
        return '请输入内容'
      }
    },
    tableList: {
      required: true,
      type: Array
    },
    showAdd: {
      type: Boolean,
      default() {
        return false
      }
    },
    showExport: {
      type: Boolean,
      default() {
        return true
      }
    },
    hideCondition: {
      type: Boolean,
      default() {
        return false
      }
    },
    showSearch: {
      type: Boolean,
      default() {
        return true
      }
    },
    showOperate: {
      type: Boolean,
      default() {
        return true
      }
    },
    showTitle: {
      type: Boolean,
      default() {
        return true
      }
    },
    showHeader: {
      type: Boolean,
      default() {
        return true
      }
    },
    showNumber: {
      type: Boolean,
      default() {
        return true
      }
    },
    check: {
      type: Boolean,
      default() {
        return false
      }
    },
    checkValue: {},
    conditionOptions: {
      type: Array
    },
    searchKey: {
      type: String
    },
    isDialog: {
      type: Boolean,
      default() {
        return false
      }
    },
    title: {
      type: String,
      default() {
        return ''
      }
    },
    valueKey: {
      type: String,
      default: 'id'
    },
    multiplyCheck: {
      type: Boolean,
      default() {
        return true
      }
    },
    checkList: {
      type: Array,
      default() {
        return []
      }
    },
    routeParams: {
      type: Object
    },
    dataType: {
      default() {
        return ''
      }
    },
    pageRefresh: {},
    treeProps: {
      type: Object,
      default() {
        return { hasChildren: 'hasChildren', children: 'children' }
      }
    },
    tableId: {
      type: String
    },
    conditionListHeight: {},
    mergeName: {}
  },
  data() {
    return {
      tableHeight: 0,
      searchContent: '',
      tableLayout: 'auto',
      defaultSort: {},
      tableColList: [],
      defaultConditionsItem: null
    }
  },
  computed: {
    isEmpty() {
      if (this.page) {
        return this.page.total === 0
      } else {
        return this.tableList.length === 0
      }
    },
    checkRadio: {
      get() {
        return this.checkValue
      },
      set(val) {
        this.$emit('update:checkValue', val)
      }
    },
    showCondition() {
      return (
        this.conditionOptions && this.conditionOptions.length > 0 && !this.hideCondition && !this.v2
      )
    },
    showConditionV2() {
      return (
        this.conditionOptions && this.conditionOptions.length > 0 && !this.hideCondition && this.v2
      )
    },
    showTableColList() {
      return this.tableColList.filter((item) => item.show)
    },
    showSummary(){
      return this.showTableColList.some(item=>item.total)
    },
    tableTitle(){
      if(this.title){
        return this.title
      }
      if(this.$route?.meta?.name){
        return this.$route.meta.name
      }
      return ''
    }
  },
  watch: {
    colList: {
      handler(val) {
        this.tableColList = val.map((item) => {
          let newItem = _.cloneDeep(item)
          if ('show' in newItem) {
          } else {
            newItem.show = true
          }
          return newItem
        })
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.collectStore.initCollectList()
  },
  methods: {
    // initDom() {
    //   const observer = new ResizeObserver((entries) => {
    //     entries.forEach((entry) => {
    //       this.tableHeight = entry.target.offsetHeight
    //     })
    //   })
    //   observer.observe(this.$refs['table-wrapper'])
    //   observer.unobserve(this.$refs['table-wrapper'])
    // },
    searchChange() {
      if (this.searchKey) {
        let content = _.trim(this.searchContent)
        if (content) {
          let searchArray = this.searchKey.split('|')
          let searchConditions = []
          searchArray.forEach((item) => {
            let itemArray = item.split('&')
            let field = itemArray[0]
            let alias = itemArray[1]
            let checkItem
            this.conditionOptions.forEach((item) => {
              if (item.fields.some((fItem) => fItem.field === field) && !checkItem) {
                checkItem = item
              }
            })
            let searchItem = {
              alias: alias || (checkItem?checkItem.alias:''),
              field: field,
              operator: 'like',
              params: [content]
            }
            searchConditions.push(searchItem)
          })
          this.$emit('search', { conditions: searchConditions, operator: 'or' })
        } else {
          this.$emit('search', { conditions: [] })
        }
        this.clearCheckList()
      }
    },
    conditionCallback(obj) {
      this.$emit('search', obj)
    },
    conditionTagCallback(item) {
      if (this.$refs.condition) {
        this.$refs.condition.checkSearchParams(item)
        this.$refs.condition.handleTriggerClick()
        // this.$refs.condition.handleSearchClick(item);
      }
    },
    setDefaultConditions(item) {
      this.defaultConditionsItem = item
    },
    clearCheckList() {
      if (this.$refs.table) {
        this.$refs.table.clearSelection()
      }
    },
    hasCheck(id) {
      return id === this.checkRadio
    },
    handleCurrentChange(row) {
      if (row && row[this.valueKey]) {
        this.$emit('update:checkValue', row[this.valueKey])
      }
    },
    handleSelectionChange(selection) {
      this.$emit('update:checkList', selection)
    },
    sortChange(val) {
      let startNum = 0
      if (this.multiplyCheck) {
        startNum++
      }
      if (this.showNumber) {
        startNum++
      }
      let index = val.column.no - startNum
      let showTableCol = this.showTableColList[index]
      let { alias, sortAppendAlias } = showTableCol
      let { prop, order } = val
      this.$emit('sortChange', { prop, order, alias, sortAppendAlias })
    },
    clearSort() {
      if (this.$refs.table) {
        this.$refs.table.clearSort()
      }
    },
    pageCurrentChange() {
      this.$emit('pageChange')
    },
    pageSizeChange(val) {
      this.page.pageSize = val
      this.page.pageNumber = 1
      this.$emit('pageChange')
    },
    refresh() {
      this.clearCheckList()
      if (this.pageRefresh) {
        this.pageRefresh()
      } else {
        this.$emit('pageChange')
      }
    },
    // 外部调用，不要删除
    clearSearch(getPage = true) {
      this.searchContent = ''
      if (this.$refs.condition) {
        this.$refs.condition.handleResetClick()
      }
      this.$nextTick(() => {
        let defaultArgs
        if (this.v2) {
          defaultArgs = []
        } else {
          defaultArgs = { conditions: [] }
        }
        this.$emit('search', defaultArgs, getPage)
      })
    },
    tablePageIndex(index) {
      if (this.page) {
        return index + 1 + (this.page.pageNumber - 1) * this.page.pageSize
      } else {
        return index + 1
      }
    },
    filterCustom(item, row) {
      let custom
      if (item.filter) {
        let filterObj = item.filter({ row: row, name: item.name, value: row[item.name] })
        custom = {
          params: filterObj.params || {},
          name: filterObj.name || ''
        }
      } else {
        custom = {
          params: {},
          name: row[item.name]
        }
      }
      return custom
    },
    headerDragend(newWidth, oldWidth, column, event) {
      if (this.tableId) {
        let columnName = column.property
        if (!columnName) {
          return
        }
        let reqData = {
          tableId: this.tableId,
          column: columnName,
          show: true,
          width: newWidth
        }
        setTableColumnConfig(reqData)
      }
    },
    objectSpanMethod(val) {
      let { row, rowIndex, column, columnIndex } = val
      // if(columnIndex === 0){
      //   console.log(column);
      // }
      if (!this.mergeName) {
        return [1, 1]
      }
      if (column.property || column.label === '操作' || column.type === 'selection') {
        if (column.property) {
          let colItem = this.tableColList.find((item) => item.name === column.property)
          if (!colItem.columnMerge) {
            return [1, 1]
          }
        }
        if (this.spanHasPre(rowIndex, this.mergeName)) {
          return [0, 0]
        } else {
          let rowSpan = this.getRowSpan(rowIndex, this.mergeName)
          return [rowSpan, 1]
        }
      }
      return [1, 1]
    },
    spanHasPre(index, name) {
      if (index === 0) {
        return false
      }
      let preIndex = index - 1
      let row = this.tableList[index]
      let preRow = this.tableList[preIndex]
      return row[name] === preRow[name]
    },
    getRowSpan(index, name, length = 1) {
      let nextIndex = index + 1
      if (!this.tableList[nextIndex]) {
        return length
      }
      let row = this.tableList[index]
      let nextRow = this.tableList[nextIndex]
      if (row[name] === nextRow[name]) {
        length = length + 1
        return this.getRowSpan(nextIndex, name, length)
      } else {
        return length
      }
    },
    selectableFunction(row, index) {
      if (!this.mergeName) {
        return true
      }
      return !this.spanHasPre(index, this.mergeName)
    },
    summaryMethod(param) {
      let { columns, data } = param
      let summaryData = ['合计']
      let startIndex = columns.findIndex((item) => item.property === this.showTableColList[0].name)
      this.showTableColList.forEach((item, index) => {
        // if (item.fieldType === 'money') {
        if (item.total) {
          let num = 0
          let key = item.name
          let valueIndex = index + startIndex
          data.forEach((item) => {
            let newNum = _.toNumber(item[key])
            if (_.isFinite(newNum)) {
              num += newNum
            }
          })
          summaryData[valueIndex] = this.filterNumberMoney(num)
        }
      })
      return summaryData
    },
    setSelection(list){
      list.forEach(item=>{
        let row = {
          [this.valueKey]:item
        }
        this.$refs.table.toggleRowSelection(row,true)
      })
    },
    isBoolean(val){
      return _.isBoolean(val)
    }
  }
})
</script>
<template>
  <div class="search-table" v-loading="loading" :class="{ 'is-dialog': isDialog }">
    <TableTempTitle v-if="showTitle" :title="tableTitle" @refresh="refresh">
      <template #buttons>
        <slot name="titleButtons"></slot>
      </template>
    </TableTempTitle>
    <slot name="searchGroupTop"></slot>
    <div class="header-wrapper" v-if="showHeader">
      <div class="header-search">
        <ColCheckBox v-model:list="tableColList" :tableId="tableId"></ColCheckBox>
        <template v-if="showCondition">
          <el-divider direction="vertical" />
          <Condition
            ref="condition"
            :conditionOptions="conditionOptions"
            :tableId="tableId"
            :conditionListHeight="conditionListHeight"
            :defaultConditionsItem="defaultConditionsItem"
            @callback="conditionCallback"
          ></Condition>
        </template>
        <template v-if="showConditionV2">
          <el-divider direction="vertical" />
          <ConditionV2
            ref="condition"
            v-if="showConditionV2"
            :conditionOptions="conditionOptions"
            @callback="conditionCallback"
          ></ConditionV2>
        </template>
        <el-divider direction="vertical" />
        <ConditionTags :tableId="tableId" @callback="conditionTagCallback"></ConditionTags>
        <slot name="search">
          <el-input
            class="header-search-input"
            v-if="showSearch && showCondition"
            v-model="searchContent"
            :placeholder="placeholder"
            clearable
            :parser="_.trim"
            prefix-icon="Search"
            @change="searchChange"
          />
        </slot>
      </div>
      <div class="header-button-group">
        <slot name="buttons"></slot>
      </div>
    </div>
    <slot name="searchGroup"></slot>
    <div class="table-wrapper" ref="table-wrapper">
      <div class="table-wrapper-absolute" :class="{'overflow-hidden':isEmpty}">
        <el-empty v-if="isEmpty" description="暂无数据" />
<!--        highlight-current-row-->
        <el-table
          ref="table"
          v-else
          :data="tableList"
          height="100%"
          :stripe="!mergeName"
          :class="{'table-no-hover':!!mergeName}"
          border
          :default-sort="defaultSort"
          :row-key="valueKey"
          :tree-props="treeProps"
          :span-method="objectSpanMethod"
          @sort-change="sortChange"
          @current-change="handleCurrentChange"
          @selection-change="handleSelectionChange"
          @header-dragend="headerDragend"
          :show-summary="showSummary"
          :summary-method="summaryMethod"
        >
          <el-table-column
            v-if="multiplyCheck"
            type="selection"
            width="55"
            align="center"
            :reserve-selection="true"
            :selectable="selectableFunction"
            fixed
          />
          <!--          <el-table-column label="序号" align="center" width="80" fixed type="index"></el-table-column>-->
          <el-table-column
            label="序号"
            align="center"
            width="80"
            fixed
            v-if="showNumber && !mergeName"
          >
            <template #default="scope">
              <div
                class="table-radio"
                :class="{ check: check, hasCheck: hasCheck(scope.row[this.valueKey]) }"
              >
                <div class="table-index">
                  {{ tablePageIndex(scope.$index) }}
                </div>
                <div class="radio">
                  <el-radio :label="scope.row[this.valueKey]" v-model="checkRadio"></el-radio>
                </div>
              </div>
            </template>
          </el-table-column>
          <template v-for="item in showTableColList" :key="item.name">
            <el-table-column
              :prop="item.name"
              :label="item.label"
              show-overflow-tooltip
              :min-width="item.width"
              :align="item.align"
              :sortable="item.sortable"
              @header-dragend="headerDragend"
            >
              <template #default="scope">
                <slot name="tableItem" v-bind="scope" :item="item">
                  <span v-if="item.fieldType === 'money'">
                    {{ filterCustom(item, scope.row).name || '--' }}
                  </span>
                  <UploadFileInList
                    v-else-if="item.fieldType === 'file'"
                    v-model="scope.row[item.name]"
                    :disabled="true"
                  ></UploadFileInList>
                  <template v-else-if="item.fieldType === 'boole'">
                    <template v-if="isBoolean(scope.row[item.name])">
                      <DictTag
                        v-if="dictStore.getDictByType(item.name).length"
                        :type="item.name"
                        round
                        :value="`${scope.row[item.name]}`"
                      ></DictTag>
                      <template v-else>
                        {{filterCustom(item, scope.row).name}}
                      </template>
                    </template>
                  </template>
                  <DictTag
                    v-else-if="item.fieldType === 'dict'"
                    :type="item.name"
                    round
                    :value="scope.row[item.name]"
                  ></DictTag>
                  <template v-else-if="item.component">
                    <component
                      v-if="scope.row[item.name]"
                      :is="item.component"
                      v-bind="{
                        ...item.componentParams,
                        ...filterCustom(item, scope.row).params
                      }"
                    >
                      {{ filterCustom(item, scope.row).name }}
                    </component>
                  </template>
                  <el-button
                    link
                    type="primary"
                    size="small"
                    v-else-if="
                      item.cellClick && (item.cellClickFilter ? item.cellClickFilter(scope) : true)
                    "
                    @click.stop="item.cellClick(scope)"
                  >
                    {{ filterCustom(item, scope.row).name }}
                  </el-button>
                  <div
                    v-else-if="item.richText"
                    class="cell-content editor-content-view"
                    v-html="filterCustom(item, scope.row).name"
                  ></div>
                  <span v-else-if="item.filter">
                    {{filterCustom(item, scope.row).name}}
                  </span>
                  <span v-else>
                    <template v-if="Array.isArray(scope.row[item.name])">
                      {{ scope.row[item.name].join(',') }}
                    </template>
                    <template v-else>
                      {{filterCustom(item, scope.row).name}}
                    </template>
                  </span>
                </slot>
              </template>
            </el-table-column>
          </template>
          <slot name="operate" v-if="showOperate"></slot>
        </el-table>
      </div>
    </div>
    <div class="pagination-wrapper" v-if="page && !isEmpty">
      <!--      layout="total, sizes, prev, pager, next, jumper"-->
      <el-pagination
        layout="total,sizes, prev, pager, next"
        background
        :page-sizes="[20, 50, 100, 200, 300]"
        v-model:current-page="page.pageNumber"
        v-model:page-size="page.pageSize"
        :total="page.total"
        :pager-count="3"
        @current-change="pageCurrentChange"
        @update:page-size="pageSizeChange"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.search-table {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 20px;

  &.is-dialog {
    padding: 0;

    .nav-bar {
      display: none;
    }
  }
}

.cell-content {
  white-space: pre-wrap;
}

.header-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  //padding: 6px 0;

  .dubhe-input {
    width: 200px;
  }

  .header-search {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 0;

    & > :deep(*) {
      margin: 0 10px;

      &:nth-last-child(1) {
        margin-right: 0;
      }
    }
  }

  .header-button-group {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
  }
}

.table-wrapper {
  flex: 1;
  position: relative;
  .table-no-hover{
    //background: #ff6700;
    :deep(.hover-row){
      td{
        background: transparent !important;

        &.dubhe-table-fixed-column--right{
          background: #ffffff !important;
        }
      }
    }
  }
  .dubhe-table {
    height: 100% !important;
  }

  .table-wrapper-absolute {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &.overflow-hidden{
      overflow: hidden;
    }
  }
}

.pagination-wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.table-radio {
  display: flex;
  align-items: center;
  justify-content: center;

  .table-index {
    line-height: var(--dubhe-component-size);
  }

  .radio {
    display: none;

    :deep(.dubhe-radio__label) {
      display: none;
    }
  }

  &.check {
    &:hover {
      .table-index {
        display: none;
      }

      .radio {
        display: inline-block;
      }
    }

    &.hasCheck {
      .table-index {
        display: none;
      }

      .radio {
        display: inline-block;
      }
    }
  }
}
.editor-content-view{
  overflow: hidden;
}
</style>