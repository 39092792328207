import axios from './axios.config'
// 工作日报 CustomerChange
// 获取列表信息
export const getCommonWorkDailyFields = (tableId='CustomerChange')=>axios.get(`/jaxrs/common_work_daily/fields?tableId=${tableId}`)
// 查询条件
export const getCommonWorkDailyConditions = ()=>axios.get('/jaxrs/common_work_daily/conditions')
// 查询
export const getCommonWorkDailyPage = (data)=>axios.post('/jaxrs/common_work_daily/page',data)
// 详情
export const getCommonWorkDailyDetail = (data)=>axios.get(`/jaxrs/common_work_daily/${data.id}`)
// 保存
export const addCommonWorkDaily = (data)=>axios.post(`/jaxrs/common_work_daily`,data)
// 编辑
export const editCommonWorkDaily = (data)=>axios.put(`/jaxrs/common_work_daily`,data)
