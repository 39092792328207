<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "CommonFullScreenIcon",
  emits: ['change'],
  props:{
    hasFullScreen:{}
  },
  methods:{
    changeScreen(){
      this.$emit('change')
    }
  }
});
</script>

<template>
  <i
    v-if="hasFullScreen"
    class="ri-fullscreen-exit-line message-button"
    @click="changeScreen"
  ></i>
  <i v-else class="ri-fullscreen-line message-button" @click="changeScreen"></i>
</template>

<style scoped lang="scss">
  .message-button{
    cursor: pointer;
  }
</style>