import axios from './axios.config'
// 获取显示字段
export const getProjectAssetFields = (tableId='projectAsset') =>axios.get(`/jaxrs/project_asset/fields?tableId=${tableId}`)
// 获取查询条件
export const getProjectAssetConditions = () =>axios.get('/jaxrs/project_asset/conditions')
// 查询
export const getProjectAssetPage =(data) =>axios.post('/jaxrs/project_asset/page',data)
// 详情(ProjectAsset)
export const getProjectAssetDetail = (data) =>axios.get(`/jaxrs/project_asset/${data.id}`)
// 保存
export  const addProjectAsset = (data) =>axios.post(`/jaxrs/project_asset`,data)
// 修改
export const editProjectAsset =(data) =>axios.put(`/jaxrs/project_asset`,data)
// 删除
export const deleteProjectAsset =(data) =>axios.delete(`/jaxrs/project_asset/${data.id}`)
// 根据项目查询详情
export const getProjectAssetDetailByProject = (data) =>axios.get(`/jaxrs/project_asset/get_by_project?projectId=${data.id}`)

