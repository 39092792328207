import axios from './axios.config'
// 获取科目管理显示字段
export const getSubjectFields = (tableId='subject')=>axios.get(`/jaxrs/subject/fields?tableId=${tableId}`)
// 获取查询条件
export const getSubjectConditions = () =>axios.get('/jaxrs/subject/conditions')
// 查询
export const getSubjectPage = (data) =>axios.post('/jaxrs/subject/page',data)
// 详情页
export const getSubjectDetail = (data) =>{
    let url
    if(data.oper){
        url = `/jaxrs/subject/${data.id}?oper=${data.oper}`
    }else{
        url = `/jaxrs/subject/${data.id}`
    }
    return axios.get(url)
}
// 保存
export const addSubject = (data) =>axios.post(`/jaxrs/subject`,data)
// 修改
export const editSubject = (data) =>axios.put(`/jaxrs/subject`,data)
// 删除
export const deleteSubject = (data) =>axios.delete(`/jaxrs/subject/${data.id}`)

export const list=()=> axios.post('/jaxrs/subject/list')