<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PageHeaderModule',
  data() {
    return {
      showDrawer: false,
      observer: null,
      scrollDom: null,
      showScrollButton: false
    }
  },
  computed: {},
  watch: {
    $router: {
      handler(newVal) {
        this.$nextTick(() => {
          let activeIndex = this.storageStore.routerHistory.findIndex((item) =>
            this.isActiveTag(item)
          )
          setTimeout(() => {
            if (activeIndex >= 0) {
              let dom = this.$refs.tag[activeIndex].$el
              dom.scrollIntoView({ behavior: 'smooth' })
            }
          }, 500)
        })
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.createObserver()
  },
  beforeUnmount() {
    this.destroyObserver()
  },
  methods: {
    createObserver() {
      this.observer = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          if(this.$refs?.scroll?.wrapRef){
            let width = entry.target.offsetWidth
            let scrollWrapperWidth = this.$refs.scroll.wrapRef.offsetWidth
            if(width >= scrollWrapperWidth){
              this.showScrollButton = true
            }else{
              this.showScrollButton = false
            }
          }else{
            this.showScrollButton = false
          }
        })
      })
      this.scrollDom = this.$refs.tagList
      this.observer.observe(this.scrollDom)
    },
    destroyObserver(){
      if(this.observer){
        this.observer.unobserve(this.scrollDom)
        this.observer = null
        this.scrollDom = null
      }
    },
    filterRouterName(path) {
      let name = path.replace('/', '')
      let item = this.permissionStore.allPermissionsMenuList.find((item) => item.path === name)
      if (item) {
        // return `${item.parent.name}：${item.name}`
        return item.name
      }
      return ''
    },
    isActiveTag(item) {
      return this.$route.path === item
    },
    closeTag(item,index) {
      this.storageStore.deleteRouterHistory(item)
      if (this.isActiveTag(item)) {
        this.$router.push(this.storageStore.routerHistory[index-1])
      }
    },
    clickTag(item) {
      this.$router.push(item)
    },
    closeOther() {
      this.storageStore.deleteOtherRouterHistory(this.$route.path)
    },
    scrollTo(add) {
      if(!this.showScrollButton){
        return
      }
      let dom = this.$refs.scroll.wrapRef
      let offsetWidth = dom.offsetWidth
      let x = add ? offsetWidth : -1 * offsetWidth
      let start = dom.scrollLeft
      let end = (start += x)
      this.$refs.scroll.wrapRef.scroll({
        left: end,
        top: 0,
        behavior: 'smooth'
      })
      // this.$refs.scroll.setScrollLeft(end)
    },
    mousewheel(e) {
      let start = this.$refs.scroll.wrapRef.scrollLeft
      let end = (start += e.deltaY)
      this.$refs.scroll.setScrollLeft(end)
    },
    showClose(item) {
      return !this.storageStore.defaultRouterHistory.includes(item)
    },
    // test(){
    //   let str = location.origin
    //   str += '/pc/CommonDetail?name=SalesOrderDetail&id=34'
    //   window.open(str)
    // }
  }
})
</script>

<template>
  <div class="page-header-left" ref="headerLeft">
    <div class="tag-scroll-wrapper">
      <div class="scroll-button" @click.stop="scrollTo(false)">
        <ri-arrow-left-s-line v-if="showScrollButton"></ri-arrow-left-s-line>
      </div>
      <div class="tag-scroll">
        <el-scrollbar ref="scroll">
          <div class="tag-list" ref="tagList">
            <el-tag
              v-for="(item,index) in storageStore.routerHistory"
              :key="item"
              :type="isActiveTag(item) ? 'primary' : 'info'"
              :effect="isActiveTag(item) ? 'dark' : 'light'"
              round
              ref="tag"
              size="large"
              :closable="showClose(item)"
              @close="closeTag(item,index)"
              @click="clickTag(item)"
            >
              {{ filterRouterName(item) }}
            </el-tag>
          </div>
        </el-scrollbar>
      </div>
      <div class="scroll-button" @click.stop="scrollTo(true)">
        <ri-arrow-right-s-line v-if="showScrollButton"></ri-arrow-right-s-line>
      </div>
      <el-popover placement="bottom" trigger="hover">
        <template #reference>
          <div class="scroll-button">
            <ri-arrow-down-s-line></ri-arrow-down-s-line>
          </div>
        </template>
        <template #default>
          <div class="flex-center">
            <el-button text @click.stop="closeOther">
              <template #icon>
                <i class="ri-close-large-line"></i>
              </template>
              <span>关闭其他标签</span>
            </el-button>
          </div>
        </template>
      </el-popover>
    </div>
  </div>
</template>

<style scoped lang="scss">
.page-header-left {
  position: relative;
  flex: 1;
  height: 100%;
  display: flex;

  .tag-scroll-wrapper {
    display: flex;
    align-items: center;
    flex: 1;
    height: 100%;
    user-select: none;

    & > * {
      height: 100%;
    }

    .tag-scroll {
      position: relative;
      flex: 1;
      height: 100%;

      .dubhe-scrollbar {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }

    .scroll-button {
      display: flex;
      align-items: center;
      width: 30px;
      //padding: 0 5px;
      cursor: pointer;
      transition: all linear 0.2s;
      color: #999;
      &:hover {
        color: var(--dubhe-color-primary);
      }
    }
  }

  :deep(.dubhe-scrollbar__view) {
    height: 100%;
  }
  .tag-list-wrapper{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  :deep(.tag-list) {
    height: 100%;
    display: inline-flex;
    align-items: center;
    flex-wrap: nowrap;
    position: relative;
    .tag-list-width{
      position: absolute;
      top: 0;
      left: 0;
      height: 0;
      width: 100%;
    }

    & > * {
      margin: 0 5px;
    }
  }

  .dubhe-tag {
    cursor: pointer;
  }
  :deep(.dubhe-popper){
    padding:0;
  }
}
</style>
