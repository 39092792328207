import axios from './axios.config'
// 显示字段
export const getProjectWorkDailyFields = (tableId='projectWorkDaily') =>axios.get(`/jaxrs/project_work_daily/fields?tableId=${tableId}`)
// 查询条件
export const getProjectWorkDailyConditions = () =>axios.get('/jaxrs/project_work_daily/conditions')
// 查询
export const getProjectWorkDailyPage = (data) =>axios.post('/jaxrs/project_work_daily/page',data)
// 详情
export const getProjectWorkDailyDetail = (data) =>axios.get(`/jaxrs/project_work_daily/${data.id}`)
// 修改
export const editProjectWorkDaily = (data) =>axios.put(`/jaxrs/project_work_daily`,data)
// 添加
export const addProjectWorkDaily = (data) =>axios.post(`/jaxrs/project_work_daily`,data)
// 删除
export const deleteProjectWorkDaily = (data) =>axios.delete(`/jaxrs/project_work_daily/${data.id}`)
// 获取施工阶段
export const getConstructionStage = () =>axios.get('/jaxrs/dict/get_by_type/constructionStage')
// 导出
export const exportProjectWorkDaily  = (data) => axios.post(`/jaxrs/project_work_daily/export`,data,{
    responseType:'blob'
})