import axios from "./axios.config"
//投标管理 Biding
// 显示字段
export const getBidingFields = (tableId ="biding") =>axios.get(`/jaxrs/biding/fields?tableId=${tableId}`)
// 获取查询条件
export const getBidingConditions = ()=>axios.get('/jaxrs/biding/conditions')
// 查询
export const getBidingPage = (data)=>axios.post('/jaxrs/biding/page',data)
// 详情
export const getBidingDetail = (data)=>axios.get(`/jaxrs/biding/${data.id}`)
// 保存
export const addBiding = (data)=>axios.post(`/jaxrs/biding`,data)
// 编辑
export const editBiding = (data)=>axios.put(`/jaxrs/biding`,data)
// 删除
export const deleteBiding= (data)=>axios.delete(`/jaxrs/biding/${data.id}`)