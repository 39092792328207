// 工单材料统计
// 获取列表分页数据
import axios from "./axios.config";
// 获取显示字段
export const getWorkOrderOutFields = (tableId='sparePart') =>axios.get(`/jaxrs/spare_part/fields?tableId=${tableId}`)
//分页数据
export const getWorkOrderOutPage = (data) => axios.post('/jaxrs/spare_part/page',data)
//详情页
export const getWorkOrderOutDetail = (data) => axios.get(`/jaxrs/spare_part/detail/${data.code}`)
// 查询
export const getWorkOrderOutConditions = () => axios.get('/jaxrs/spare_part/conditions')
