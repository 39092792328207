import axios from "./axios.config"
//薪资标准 SalaryStandard
// 显示字段
export const getSalaryStandardFields = (tableId ="salaryStandard") =>axios.get(`/jaxrs/salary_standard/fields?tableId=${tableId}`)
// 获取查询条件
export const getSalaryStandardConditions = ()=>axios.get('/jaxrs/salary_standard/conditions')
// 查询
export const getSalaryStandardPage = (data)=>axios.post('/jaxrs/salary_standard/page',data)
// 详情
export const getSalaryStandardDetail = (data)=>axios.get(`/jaxrs/salary_standard/${data.id}`)
// 保存
export const addSalaryStandard = (data)=>axios.post(`/jaxrs/salary_standard`,data)
// 编辑
export const editSalaryStandard = (data)=>axios.put(`/jaxrs/salary_standard`,data)
// 删除
export const deleteSalaryStandard= (data)=>axios.delete(`/jaxrs/salary_standard/${data.id}`)